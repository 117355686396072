import { AutoSelectValueGenericContentProps, AutoSelectValueProps } from "./auto_select_value";
import { CalendarBadge, CalendarBadgeProps, CalendarBadgeValue } from "./calendar_badge";

type AutoSelectValueCalendarProps = Omit<AutoSelectValueGenericContentProps, "value"> & {
  displayValue: string;
  onRemove: CalendarBadgeProps["onRemove"];
  value: CalendarBadgeValue;
} & Pick<AutoSelectValueProps, "onDateChange">;

export const AutoSelectValueCalendar = ({
  displayValue,
  value,
  locked,
  onDateChange,
  ...props
}: AutoSelectValueCalendarProps) => {
  if (!onDateChange) {
    return null;
  }

  return (
    <CalendarBadge
      value={value}
      isLocked={locked || false}
      key={value.id}
      displayValue={displayValue}
      onDateChange={onDateChange}
      {...props}
    />
  );
};
