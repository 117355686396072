import { isEmpty } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import { Dispatch, JSX, ReactNode, SetStateAction } from "react";

import css from "./carousel.module.scss";

type CarouselProps = {
  items: JSX.Element[];
  header: ReactNode;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  onAdd?: VoidFunction;
  onDelete?: (index: number) => void;
};

export const Carousel = ({ items, header, activeIndex, setActiveIndex, onAdd, onDelete }: CarouselProps) => {
  if (isEmpty(items)) {
    return null;
  }

  const maxIndex = items.length - 1;
  const goPrev = () => setActiveIndex((index) => Math.max(index - 1, 0));
  const goNext = () => setActiveIndex((index) => Math.min(index + 1, maxIndex));

  const handleAdd = () => {
    onAdd?.();
    setActiveIndex(items.length);
  };

  const handleDelete = () => {
    onDelete?.(activeIndex);
    activeIndex === items.length - 1 && setActiveIndex(activeIndex - 1);
  };

  return (
    <div className={css.root}>
      <div className={css.headerContainer}>
        <ActionIcon name="chevron-left" onClick={goPrev} disabled={activeIndex === 0} />
        <div className={css.header}>
          {header}
          {!!onAdd && <ActionIcon name="add" size="sm" onClick={handleAdd} />}
          {!!onDelete && items.length > 1 && (
            <ActionIcon name="remove" size="sm" onClick={handleDelete} color="paletteRed1" />
          )}
        </div>
        <ActionIcon name="chevron-right" onClick={goNext} disabled={activeIndex === maxIndex} />
      </div>
      <div className={css.content}>{items[activeIndex]}</div>
    </div>
  );
};
