import AppContext from "PFApp/app_context";
import { Tabs } from "PFComponents/tabs";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./search_tabs.module.scss";

const SearchTabs = () => {
  const {
    dispatch,
    store: {
      search: { tab, matchesMeta, allActivitiesMeta, availableTemplates }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");
  const { t: tTranslation } = useTranslation("translation");

  const listOfActivities = useMemo(
    () => availableTemplates?.map((activity) => activity.key) || [],
    [availableTemplates]
  );

  const tabsItems = useMemo(
    () => [
      { id: "all", title: t("tabs.all") },
      { id: "matches", title: t("tabs.profiles"), count: matchesMeta.total || 0 },
      ...listOfActivities.map((activity) => ({
        id: activity,
        title: t(`tabs.${activity}` as unknown as TemplateStringsArray),
        count: allActivitiesMeta?.[activity]?.total
      }))
    ],
    [listOfActivities, t, allActivitiesMeta, matchesMeta.total]
  );

  if (!["all", "matches", ...listOfActivities].includes(tab)) {
    return (
      <Tabs
        onChange={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: "all" })}
        tabs={[{ id: "all", title: tTranslation("back"), icon: "chevron-left" }]}
        className={css.tabs}
      />
    );
  }

  return (
    <Tabs
      onChange={(id) => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: id })}
      tabs={tabsItems}
      activeTab={tab}
      className={css.tabs}
    />
  );
};

export default SearchTabs;
