import { Button } from "PFComponents/button";

import { ContactInfo } from "../use_contact_info_array";

type ContactButtonProps = ContactInfo;

export const ContactButton = ({ value, url, target, iconName, title, onClick }: ContactButtonProps) => (
  <Button
    kind="text"
    icon={iconName}
    text={value}
    href={url}
    target={target}
    tag={url ? "a" : "button"}
    title={title}
    onClick={onClick}
  />
);
