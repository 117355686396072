import AppContext from "PFApp/app_context";
import { DetailsPanelContextProvider } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context_provider";
import { AvailabilityContent } from "PFApp/profiles/common/availability";
import { ProfileBookingsProvider } from "PFApp/profiles/common/profile_bookings_provider";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { isProfileActive } from "PFCore/helpers/profile";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../booking/components/booking_form";
import { BookingFormMode } from "../../../booking/components/booking_form/use_booking_form";
import useIsAvailabilityPermitted from "../../../use_is_availability_permitted";
import ViewInBookingModuleButton from "../../view_in_booking_module_button/view_in_booking_module_button";
import css from "./profile_preview_avalability.module.scss";
import { ProfilePreviewHeader } from "./profile_preview_header";

const ProfilePreviewAvailability = () => {
  const { t } = useTranslation("core");

  const {
    profilePreview: { id, profile, ready, showBookingButtons }
  } = useContext(AppContext).store;

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { modal } = useBookingFormContext();

  const isActive = isProfileActive(profile);
  const isAvailabilityPermitted = useIsAvailabilityPermitted(profile);
  const { canProfileCreateBooking } = useBookingModulePermissions();

  const canSeeBookingModule = canProfileCreateBooking(profile?.id);

  const canAddBooking = showBookingButtons && canSeeBookingModule && isActive;

  if (!ready || !profile || !profile.availability || !isAvailabilityPermitted) {
    return null;
  }

  if (profile.permissions_group === undefined) {
    return null; // probably not needed because "ready" is taking care but left just in case
  }

  const actions = canAddBooking && (
    <Button
      kind="secondary"
      onClick={() => {
        if (id) {
          modal.open(
            {
              profileId: id
            },
            { mode: BookingFormMode.Create }
          );
        }
      }}
      key="booking"
    >
      +{t("booking")}
    </Button>
  );

  return (
    <ProfileBookingsProvider
      currentProfile={currentProfile}
      profile={profile}
      bookingCategories={currentAccount.availability.bookingCategories}
    >
      <DetailsPanelContextProvider>
        <div data-qa-id="ProfilePreviewAvailability">
          <ProfilePreviewHeader headerClassName={css.header} actions={actions}>
            <span>{t("profilePreview.availability")}</span>
            {canSeeBookingModule && <ViewInBookingModuleButton workforceMemberId={profile?.id} />}
          </ProfilePreviewHeader>
          <AvailabilityContent activeViews={["calendar"]} profile={profile} readOnly={true} />
        </div>
      </DetailsPanelContextProvider>
    </ProfileBookingsProvider>
  );
};

export default ProfilePreviewAvailability;
