import PropTypes from "prop-types";

import css from "./footer.module.scss";

const EngagementFooter = ({ numberOfRoles }) => (
  <div className={css.wrapper}>
    {numberOfRoles > 0 && (
      <div className={css.engagement}>
        <b>{numberOfRoles}</b>
        {numberOfRoles === 1 ? " Role" : " Roles"}
      </div>
    )}
  </div>
);

EngagementFooter.propTypes = {
  numberOfRoles: PropTypes.number
};
export default EngagementFooter;
