import { fetchProfile as fetchUserProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";

import { BookingFormWrapper } from "./booking_form_wrapper";
import { BookingFormType, useBookingForm } from "./use_booking_form";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";

type BookingFormContextType = {
  profileId?: number;
  modal: BookingFormType;
  fetchProfile: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  loadingState: Record<string, boolean>;
  setLoadingState: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const BookingFormContext = createContext({} as BookingFormContextType);

export type BookingFormConfig = {
  profileId?: number;
  fetchProfile?: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  onSuccess?: (data: OnSuccessData) => Promise<unknown>;
};

export const BookingFormContextProvider = ({
  children,
  fetchProfile,
  onSuccess,
  profileId
}: PropsWithChildren<BookingFormConfig>) => {
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const modal = useBookingForm();
  const value = useMemo(
    () => ({
      modal,
      fetchProfile: fetchProfile || fetchUserProfile,
      profileId,
      loadingState,
      setLoadingState
    }),
    [modal, fetchProfile, profileId, loadingState]
  );

  return (
    <BookingFormContext.Provider value={value}>
      {modal.state.isOpen && <BookingFormWrapper onSuccess={onSuccess} />}
      {children}
    </BookingFormContext.Provider>
  );
};

export const useBookingFormContext = () => useContext(BookingFormContext);
