import { BookButton as WrappedBookButton } from "PFApp/activities/show/parts/book_button/book_button";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import css from "./shortlist_buttons.module.scss";

export const BookButton = () => {
  const { profile, shortlist, areButtonsDisabled } = useRoleProfileActionButtonsContext();

  return (
    <WrappedBookButton
      shortlistId={shortlist!.id}
      profileId={profile.id}
      className={css.shortlistButton}
      disabled={areButtonsDisabled}
      buttonKind="primary"
    />
  );
};
