import AnimatedText from "PFComponents/animated_text/animated_text";
import ProgressBar from "PFComponents/progress_bar/progress_bar";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import CheckIcon from "PFIcons/check_2.svg";
import { Profile } from "PFTypes";
import { Trans, useTranslation } from "react-i18next";

import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import { ProfileAvatarSection } from "../profile_avatar_section";
import css from "./side_panel.module.scss";

type ProfileSectionProps = {
  profile: Profile;
  progressCompletion: number;
  isCompleted: boolean;
  isMe: boolean;
  onProfileUpdate: OnProfileUpdateHandler;
};

export const ProfileSection = ({
  profile,
  progressCompletion,
  isCompleted,
  isMe,
  onProfileUpdate
}: ProfileSectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const profileName = getProfileName(profile);

  return (
    <>
      <ProfileAvatarSection profile={profile} isMe={isMe} onProfileUpdate={onProfileUpdate} />
      <div className={css.name}>
        <Typography variant="h3">{profileName}</Typography>
        {isCompleted && <CheckIcon className={css.completedIcon} height={17} width={20} />}
      </div>
      {isMe && !isCompleted && (
        <>
          <ProgressBar values={[{ value: progressCompletion }]} totalValue={100} />
          <div className={css.profileCompletionInfo}>
            <Trans
              i18nKey="show.parts.profilePercentComplete"
              t={t}
              components={[<AnimatedText value={progressCompletion} incrementsPerSecond={125} key="0" />]}
            />
          </div>
        </>
      )}
    </>
  );
};
