import { isNil, some } from "lodash";
import { useEngagementFeedbackParticipants } from "PFApp/activities/hooks";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useInfiniteActivityWatchers } from "PFCore/hooks/queries/activity/use_activity_watchers";
import { useMemo, useState } from "react";

export const useActivityPageCounters = () => {
  const {
    task: activity,
    roles,
    matchesMeta,
    shortlistsMeta,
    suspendedShortlistsMeta,
    vacancies
  } = useActivityPageContext();
  const { data: feedbackParticipants } = useEngagementFeedbackParticipants(activity);

  const pendingFeedbacks =
    feedbackParticipants?.entries?.filter((participant) =>
      some(participant.feedback, ({ status }) => status === "pending")
    ) || [];
  const feedbackCount = pendingFeedbacks?.length === 0 ? undefined : pendingFeedbacks?.length;

  const { data: interested } = useInfiniteActivityWatchers(activity, 0, undefined, undefined, {
    onSuccess: (res) => {
      if (isNil(interestsCount)) {
        setInterestsCount(res.pages[0].meta.total);
      }
    }
  });

  const [interestsCount, setInterestsCount] = useState<number | undefined>(interested?.pages[0].meta.total);

  const counters = useMemo(
    () => ({
      shortlist: Math.max((shortlistsMeta?.total || 0) + (suspendedShortlistsMeta?.total || 0), 0),
      matches: matchesMeta.total,
      roles: roles.meta.total,
      vacancies: vacancies.meta.total,
      interested: interestsCount,
      feedback: feedbackCount
    }),
    [
      shortlistsMeta.total,
      suspendedShortlistsMeta.total,
      feedbackCount,
      roles.meta.total,
      matchesMeta.total,
      vacancies.meta.total,
      interestsCount
    ]
  );

  return {
    counters,
    setInterestsCount
  };
};
