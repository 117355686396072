import { ActionIcon } from "PFComponents/action_icon";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { useState } from "react";

import { ViewAdditionalParams } from "./save_as_view.types";
import { SavedViewBadgeText } from "./saved_view_badge_text";

type SavedViewBadgeProps = {
  name: string;
  isSelected: boolean;
  onClick: VoidFunction | null;
  onRemove: (onSuccess?: VoidFunction) => void;
  onShareClick?: VoidFunction;
} & ViewAdditionalParams;

export const SavedViewBadge = ({
  name,
  tooltipContent,
  disabled,
  isSelected,
  allowRemove,
  onClick,
  onShareClick,
  onRemove
}: SavedViewBadgeProps): JSX.Element => {
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);

  return (
    <SimpleBadge
      kind={isSelected ? "selected" : "primary"}
      text={name}
      displayValue={<SavedViewBadgeText name={name} onShareClick={onShareClick} />}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick?.();
      }}
      disabled={disabled}
      tooltipContent={tooltipContent}
      showTooltip={!!tooltipContent}
      tooltipTheme="pf"
      tooltipPlacement="top"
      icon={
        allowRemove ? (
          <ActionIcon
            name="cross"
            size="xs"
            disabled={isBeingDeleted}
            onClick={(event) => {
              event.stopPropagation();
              setIsBeingDeleted(true);
              onRemove(() => {
                setIsBeingDeleted(false);
              });
            }}
          />
        ) : null
      }
    />
  );
};
