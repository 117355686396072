import { ProfileName } from "PFCore/helpers/profile";
import { Activity, Profile, Shortlist } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";

type RoleProfileActionButtonsContextType = {
  role: Activity;
  profile: Pick<Profile, "id"> & ProfileName;
  shortlist: Pick<Shortlist, "id" | "state" | "applicable_custom_values"> | null;
  isFillDisabled: boolean;
  areButtonsDisabled: boolean;
};

export type RoleProfileActionButtonsProviderProps = RoleProfileActionButtonsContextType;

const RoleProfileActionButtonsContext = createContext<RoleProfileActionButtonsContextType>(
  {} as RoleProfileActionButtonsContextType
);

export const RoleProfileActionButtonsProvider = ({
  children,
  ...props
}: PropsWithChildren<RoleProfileActionButtonsProviderProps>) => {
  const contextValue = useMemo(() => ({ ...props }), [props]);

  return (
    <RoleProfileActionButtonsContext.Provider value={contextValue}>
      {children}
    </RoleProfileActionButtonsContext.Provider>
  );
};

export const useRoleProfileActionButtonsContext = () => {
  const context = useContext(RoleProfileActionButtonsContext);

  if (!context) {
    throw new Error(
      "useRoleProfileActionButtonsContext must be used within a RoleProfileActionButtonsContextProvider"
    );
  }

  return context;
};
