import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendInvitation } from "PFCore/services/invites/send_invitation";
import { MutationOptions } from "PFTypes/request_options";

import { invitesKeys } from "./query_keys";

export const useSendInvitation = (
  targetIds: number[],
  profileIds: number[],
  options: MutationOptions<void | null> = {}
) => {
  const queryClient = useQueryClient();
  const mutationKey = invitesKeys.single(targetIds, profileIds);

  return useMutation({
    mutationFn: async () => sendInvitation({ targetIds, profileIds, targetType: "Task" }),
    mutationKey,
    onSuccess: (data, ...params) => {
      queryClient.invalidateQueries({ queryKey: invitesKeys.list("Task", targetIds) });
      options.onSuccess?.(data, ...params);
    },
    ...options
  });
};
