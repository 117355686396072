import { omitBy } from "lodash";
import { AriaAttributes } from "react";

export const getAriaProps = <T extends {}>(
  props: React.PropsWithChildren<T>
): { ariaProps: AriaAttributes; rest: T } => {
  const ariaProps = omitBy(props, (_, key) => !key.startsWith("aria-")) as AriaAttributes;
  const rest = omitBy(props, (_, key) => key.startsWith("aria-")) as T;

  return { ariaProps, rest };
};
