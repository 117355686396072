import classNames from "classnames";
import { Radios } from "PFComponents/radios/radios";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BinaryAnswer, FormValues } from "../../questions_modal.types";
import RequiredText from "../required_text";
import css from "./question.module.scss";

interface Props {
  name: keyof FormValues;
  label: string;
  isEditMode: boolean;
  control: Control<FormValues>;
}

const Question = ({ name, control, label, isEditMode }: Props): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <div>
      <li className={css.radioLabel}>
        <RequiredText label={label} />
      </li>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
          isEditMode ? (
            <Radios
              name={name}
              value={field.value}
              items={[
                { id: BinaryAnswer.Yes, label: t("yes") },
                { id: BinaryAnswer.No, label: t("no") }
              ]}
              onChange={(id) => field.onChange(id)}
              inline
            />
          ) : (
            <h4 className={classNames(css.noMargin, css.questionAnswer)}>
              {t(`${field.value === BinaryAnswer.Yes ? "yes" : "no"}`)}
            </h4>
          )
        }
      />
    </div>
  );
};

export default Question;
