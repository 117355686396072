import MisalignmentWarningIcon from "PFIcons/calendar_misalignment.svg";

import WarningIcon from "./legend_warning_icon";

type LegendWarningType = {
  translation: {
    key: "projects.misalignment" | "legend.overbookingLevel";
    variables?: Record<string, number>;
  };
  translationVariables?: object;
  type: "error" | "warning" | "critical";
  IconComponent: React.FC<any> | any;
};

const basicLegendWarnings: LegendWarningType[] = [
  {
    translation: {
      key: "projects.misalignment"
    },
    type: "warning",
    IconComponent: MisalignmentWarningIcon
  },
  {
    translation: {
      key: "legend.overbookingLevel",
      variables: { level: 1 }
    },
    type: "warning",
    IconComponent: WarningIcon
  },
  {
    translation: {
      key: "legend.overbookingLevel",
      variables: { level: 2 }
    },
    type: "error",
    IconComponent: WarningIcon
  },
  {
    translation: {
      key: "legend.overbookingLevel",
      variables: { level: 3 }
    },
    type: "critical",
    IconComponent: WarningIcon
  }
];

export default basicLegendWarnings;
