import isEmpty from "lodash/isEmpty";
import slice from "lodash/slice";
import { PinnedData } from "PFApp/booking/types";
import useStorage from "PFCore/helpers/use_storage";
import { usePinnedWorkforceMembers } from "PFCore/hooks/queries/bookings/workforce/use_pinned_workforce_members";
import { WorkforceResponse } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { CalendarRange } from "PFTypes/calendar_range";

export const PINS_LIMIT = 200;

type UseWorkforcePinnedData = {
  dateRange: CalendarRange;
  enabled: boolean;
};

export const useWorkforcePinnedData = ({
  dateRange,
  enabled
}: UseWorkforcePinnedData): PinnedData<WorkforceResponse> => {
  const [storedPinnedIds, setPinnedIds] = useStorage<number[]>(
    "booking_module_overview_workforce_pinned_profile_ids",
    []
  );
  const pinnedIds = slice(storedPinnedIds, 0, PINS_LIMIT);

  const { data, isFetching } = usePinnedWorkforceMembers({ pinnedIds, dateRange, options: { enabled } });

  return {
    data: isEmpty(pinnedIds) ? undefined : data,
    pinnedIds,
    setPinnedIds,
    loading: isFetching
  };
};
