import classNames from "classnames";
import moment, { Moment, MomentInput } from "moment";
import { ActionIcon } from "PFComponents/action_icon";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "./calendar_navigation.module.scss";

type ChangeMonthButtonProps = {
  direction: "prev" | "next";
  dateLow: MomentInput;
  dateHigh: MomentInput;
  displayMonth: Moment;
  setDisplayMonth: (value: Moment) => void;
};

export const ChangeMonthButton = ({
  direction,
  dateLow,
  dateHigh,
  displayMonth,
  setDisplayMonth
}: ChangeMonthButtonProps): JSX.Element => {
  const { t } = useTranslation("core", { keyPrefix: "components.calendar" });

  const handleClick = useCallback(
    () => setDisplayMonth(displayMonth.clone().add(direction === "next" ? 1 : -1, "month")),
    [setDisplayMonth, displayMonth, direction]
  );

  return (
    <ActionIcon
      name={direction === "next" ? "chevron-right" : "chevron-left"}
      size="sm"
      fill="currentcolor"
      title={t(direction === "prev" ? "prevMonth" : "nextMonth")}
      data-qa-id={`Calendar.ChangeMonth.${direction}`}
      className={classNames(css.changeMonth, {
        [css.next]: direction === "next",
        [css.disabled]:
          !dateLow || !dateHigh || moment(dateLow).format("YYYY-MM") <= moment(dateHigh).format("YYYY-MM")
      })}
      onClick={handleClick}
    />
  );
};
