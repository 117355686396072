import { RequestHelpModal } from "PFApp/activities/show/parts/request_help_modal";
import CloseConfirmModal from "PFApp/components/activity_actions/modals/close_confirm_modal";
import PriorityDropdown from "PFApp/components/table/priority_dropdown";
import { useTemplate } from "PFApp/hooks";
import { useGrowl } from "PFApp/use_growl";
import useIsRoleRemovalPermitted from "PFApp/use_is_role_removal_permitted";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Modal } from "PFComponents/modal";
import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { getVisibleProperties, isSubtemplate } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useActivityDelete } from "PFCore/hooks/queries/activity/use_activity_delete";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import RightArrowIcon from "PFIcons/right_arrow.svg";
import DotsIcon from "PFIcons/vertical_dots.svg";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import { useHistory } from "react-router-dom";

import { ExpiryDateModal } from "../../components/activity_actions/modals/expiry_date_modal";
import css from "./three_dots_menu.module.scss";

const ThreeDotsMenu = ({ afterRemove, item, type, coowned, pinned, setPinned, share }) => {
  const isAudit = type.includes("audit");
  const history = useHistory();
  const removePermitted = useIsRoleRemovalPermitted();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  const [showRequestHelpModal, setShowRequestHelpModal] = useState(false);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });

  const { mutateAsync: deleteActivity } = useActivityDelete();

  const template = useTemplate({ key: type });
  const isSubtemplateMissing = !!item.subtemplate_key && !isSubtemplate(template, item.subtemplate_key);

  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [expiryDateModalData, setExpiryDateModalData] = useState({
    show: false,
    mode: "publish"
  });

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "left-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 20]
        }
      }
    ]
  });

  const priorityCustomType = useMemo(() => {
    const priorityProperty = getVisibleProperties(template).find(({ name }) => name === "priority");
    const priorityCustomType = customTypes.find((type) => type.name === "priority");
    return (
      priorityProperty &&
      priorityCustomType &&
      activityCustomTypesAccessLevels(currentProfile)[priorityCustomType.id]
    );
  }, [customTypes, currentProfile, template]);

  const growl = useGrowl();

  const handlePinnedChange = (isPinned) => {
    const newPinned = [...pinned];
    const index = newPinned.indexOf(item.id);
    if (isPinned) {
      index === -1 && newPinned.unshift(item.id);
    } else {
      index !== -1 && newPinned.splice(index, 1);
    }
    setPinned(newPinned);
  };

  const handleExpiryDate = (onNotExpiredCallback, mode) => {
    const { post_until } = item;
    const isExpired = new Date(post_until) < new Date();
    if (isExpired) {
      setExpiryDateModalData({
        show: true,
        mode
      });
    } else {
      onNotExpiredCallback();
    }
  };

  const options = useMemo(() => {
    const items = [];

    if (setPinned) {
      const isPinned = pinned.includes(item.id);
      items.push({
        id: "pinned",
        displayElement: isPinned ? t("unmarkImportant") : t("markAsImportant"),
        item: () => handlePinnedChange(!isPinned)
      });
    }

    !isAudit &&
      coowned &&
      !isSubtemplateMissing &&
      items.push({
        id: "edit",
        displayElement: t("translation:edit"),
        item: () => handleExpiryDate(() => history.push(`/activities/${item.id}/edit`), "edit")
      });

    if (share) {
      items.push({
        id: "share",
        displayElement: t("share"),
        item: () => share(item.id)
      });
    }

    if (template && !template.hidden && template.create_permitted && !isSubtemplateMissing) {
      items.push({
        id: "clone",
        displayElement: t("clone"),
        item: () =>
          handleExpiryDate(() => history.push(`/activities/${template.key}/new/${item.id}`, "clone"), "clone")
      });
    }

    if (coowned) {
      items.push({
        id: "request_help",
        displayElement: t("requestHelp"),
        item: () => setShowRequestHelpModal(true)
      });
    }

    if (coowned && priorityCustomType) {
      items.push({
        id: "priority",
        displayElement: (
          <div ref={setPopperReferenceElement} className={css.priorityButton}>
            {t("priority.option")}
            <RightArrowIcon width={23} height={20} />
          </div>
        ),
        item: {
          keepOpen: true,
          func: () => setShowPriorityDropdown((prev) => !prev)
        }
      });
    }

    if (!isAudit && coowned && removePermitted) {
      items.push({
        id: "remove",
        displayElement: <span className={css.dangerButton}>{t("translation:remove")}</span>,
        item: () => setShowRemoveConfirm(true)
      });
    }

    if (coowned && [null, "new", "review"].includes(item.state)) {
      items.push({
        id: "close",
        displayElement: <span className={css.dangerButton}>{t("translation:close")}</span>,
        item: () => setShowCloseConfirm(true)
      });
    }

    return items;
  }, [pinned, priorityCustomType, t]);

  const handleRemove = () => {
    deleteActivity(item.id).then(
      () => {
        growl({ message: t("activityTypeRemove.success", { type }) });
        setPinned && handlePinnedChange(false);
        afterRemove && afterRemove();
      },
      (resp) => {
        const msg = resp.status === 404 ? "activityTypeRemove.error404" : "activityTypeRemove.error";
        growl({ message: t(msg, { type }), kind: "error" });
      }
    );
  };

  return (
    <>
      <DropdownButton
        style={{ marginLeft: "auto" }}
        options={options}
        buttonKind="blank"
        buttonStyle={{ height: 18 }}
        dropDownClassName={css.dropdown}
        dropDownStyle={{ maxHeight: 300 }}
        handleClick={(event) => event.preventDefault()}
        handleChange={(optionItem) => (optionItem.func ? optionItem.func() : optionItem())}
        handleClose={() => setShowPriorityDropdown(false)}
        qaId={`WorkflowPageRowMoreButton.${item.id}`}
      >
        <DotsIcon width={20} height={17} />
      </DropdownButton>

      {showRemoveConfirm && (
        <Modal
          title={t("activityTypeRemove.title", { type })}
          onOK={handleRemove}
          onClose={() => setShowRemoveConfirm(false)}
          kindOK="danger"
        >
          {t("activityTypeRemove.confirm", { type })}
        </Modal>
      )}
      {showCloseConfirm && (
        <CloseConfirmModal
          activity={item}
          handleClose={() => setShowCloseConfirm(false)}
          type={type}
          handleSuccess={afterRemove}
        />
      )}

      {expiryDateModalData.show && (
        <ExpiryDateModal
          activityId={item.id}
          templateKey={template.key}
          mode={expiryDateModalData.mode}
          onClose={() => setExpiryDateModalData(false)}
        />
      )}

      {showPriorityDropdown && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 4 }} {...attributes.popper}>
          <PriorityDropdown activity={item} onSelected={afterRemove} />
        </div>
      )}

      {showRequestHelpModal && (
        <RequestHelpModal activity={item} onClose={() => setShowRequestHelpModal(false)} />
      )}
    </>
  );
};

export default ThreeDotsMenu;

ThreeDotsMenu.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    state: PropTypes.string,
    subtemplate_key: PropTypes.string,
    post_until: PropTypes.string
  }).isRequired,
  afterRemove: PropTypes.func,
  coowned: PropTypes.bool,
  removePermitted: PropTypes.bool,
  type: PropTypes.string.isRequired,
  pinned: PropTypes.arrayOf(PropTypes.number),
  setPinned: PropTypes.func,
  share: PropTypes.func
};
