import moment from "moment";
import { Button } from "PFComponents/button";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./prop_node.module.scss";

const PropNode = ({ row, task, top, changeTab }) => {
  let valNode = null;
  if (row.id === "bio") {
    valNode = null;
  } else if (row.id === "availability") {
    valNode = <AvailabilityNode task={task} changeTab={changeTab} />;
  } else {
    valNode = <CustomFieldNode task={task} row={row} />;
  }

  return !top ? null : (
    <div className={css.prop} style={{ top }}>
      {row.display_as}
      <div className={css.propValue}>{valNode}</div>
    </div>
  );
};

PropNode.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    display_as: PropTypes.string
  }).isRequired,
  task: PropTypes.object.isRequired,
  top: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired
};

const AvailabilityNode = ({ task, changeTab }) => {
  const { formatDate } = useDateFormatter();
  const { t } = useTranslation("activities");

  const availability = task.metadata && task.metadata.availability;

  if (!availability) {
    return null;
  }

  const start = formatDate(moment.min(availability.ranges.map((range) => moment(range.start))));
  const end = formatDate(moment.max(availability.ranges.map((range) => moment(range.end))));

  return (
    <>
      {start}
      {" - "}
      <br />
      {end}
      <br />
      <Button kind="text" text={t("compare.viewAsGantt")} onClick={() => changeTab("availability")} />
    </>
  );
};

AvailabilityNode.propTypes = {
  task: PropTypes.object.isRequired,
  changeTab: PropTypes.func.isRequired
};

const CustomFieldNode = ({ task, row }) => {
  const { t } = useTranslation("activities");

  const fields = task.custom_fields;
  const field = row.name && fields && fields.find(({ type: { name } }) => name === row.name);
  const values = field ? field.values : [];
  return (
    <>
      {values.length === 1 && <span>{values[0].text}</span>}
      {values.length > 1 && (
        <Link to={`/activities/${task.id}`} target="_blank" rel="noopener noreferrer">
          {t("compare.lengthSpecified", { length: values.length })}
        </Link>
      )}
    </>
  );
};

CustomFieldNode.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    display_as: PropTypes.string
  }).isRequired,
  task: PropTypes.object.isRequired
};

export default PropNode;
