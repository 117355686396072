import { Alert } from "PFComponents/alert";
import { useTranslation } from "react-i18next";

import { useFiltersContext } from "../context/filters_context";

export const AutoRefreshWarning = () => {
  const { showAutoRefreshWarning, closeAutoRefreshWarning } = useFiltersContext();
  const { t } = useTranslation();

  return showAutoRefreshWarning ? (
    <Alert kind="general" title={t("filters.autoRefreshWarning")} small onClose={closeAutoRefreshWarning} />
  ) : null;
};
