import { Id } from "PFTypes";

export const customTypeKeys = {
  all: () => ["customTypes"],
  single: (id) => [...customTypeKeys.all(), id]
};

export const customValueKeys = {
  single: (id: Id, profileId: Id) => ["customValues", id, profileId]
};
