import classNames from "classnames";
import { Button } from "PFComponents/button";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import PropTypes from "prop-types";

import css from "./arrows.module.scss";
import scrollToLeft from "./scroll_to_left";

const Arr = ({ el, step, className, scroll }) => {
  const isDisabled = !el
    ? true
    : step > 0
    ? scroll + el.clientWidth >= el.scrollWidth // fully scrolled
    : scroll === 0; // starting position

  return (
    <Button
      kind="blank"
      className={classNames(css.arr, className, { [css.off]: isDisabled })}
      onClick={() => scrollToLeft(el, el.scrollLeft + step)}
    >
      <DownArrowIcon style={{ transform: `rotate(${step > 0 ? -90 : 90}deg)` }} height={22} width={22} />
    </Button>
  );
};

Arr.propTypes = {
  el: PropTypes.instanceOf(Element),
  step: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  scroll: PropTypes.number.isRequired
};

const Arrows = ({ scrollEl, scrollLeft }) => {
  const step = scrollEl ? scrollEl.offsetWidth / 2 : 0; // half the page
  const bottom = scrollEl ? scrollEl.offsetHeight - scrollEl.clientHeight : 0;

  return (
    <div className={css.root} style={{ bottom }}>
      <div className={css.leftFade} />
      <div className={css.rightFade} />
      <Arr el={scrollEl} className={css.prev} scroll={scrollLeft} step={-step} />
      <Arr el={scrollEl} className={css.next} scroll={scrollLeft} step={step} />
    </div>
  );
};

Arrows.propTypes = {
  scrollEl: PropTypes.instanceOf(Element),
  scrollLeft: PropTypes.number.isRequired
};

export default Arrows;
