import classNames from "classnames";
import { groupBy } from "lodash";
import { Button } from "PFComponents/button";
import AutoSelect from "PFComponents/select/autoselect";
import SliderWithTooltip from "PFComponents/slider/slider_with_tooltip";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import AddIcon from "PFIcons/add.svg";
import CloseIcon from "PFIcons/close.svg";
import { Importance } from "PFTypes";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChoosenKeyword } from "../search_box/choosen_keywords";
import css from "./keywords_sliders.less";

const TypeSection = ({
  remove,
  handleChange,
  search,
  pushWithNewImportance,
  type,
  keywords,
  options,
  error,
  initialOpen = false,
  onChangeOpen
}) => {
  const { t } = useTranslation("search");
  const [open, setOpen] = useState(initialOpen);

  const notChosen = (option) => !keywords.find(({ id }) => id === option.id);

  const handleOpen = (open) => {
    setOpen(open);
    onChangeOpen?.(open);
  };

  return (
    <li
      onClick={open ? null : () => handleOpen(true)}
      onKeyDown={open ? null : () => handleOpen(true)}
      className={classNames(css.section, { [css.sectionClosed]: !open, [css.error]: error })}
    >
      <Button kind="blank" className={css.sectionToggle} onClick={() => handleOpen(!open)}>
        {open ? <CloseIcon width={20} height={20} /> : <AddIcon width={20} height={20} />}
      </Button>
      <span className={css.typeName}>
        {type.display_as} ({keywords.length}) {!search && ` - ${t("parts.keywords.editOriginalWarn")}`}
      </span>
      {error && <span className={css.errorMessage}>{error}</span>}
      {open && (
        <ul className={css.sectionList}>
          <li>
            {handleChange && (
              <div className={css.input}>
                <AutoSelect
                  key={keywords.map(({ id }) => id).join("")}
                  alwaysEmpty
                  query={(term) => fetchAutocompleteCustomValues({ term, type: [type.name], global: false })}
                  handleChange={handleChange}
                  letClear={false}
                  closeOnChange={true}
                  multi={false}
                  values={[]}
                  placeholder={t("parts.keywords.addAttribute")}
                  filterOptions={(options) => options.filter(notChosen)}
                  formatOption={(option) => {
                    if (typeof option?.text === "object") {
                      // ignore location. this is considered misconfiguration (missing NON_MATCHABLE section)
                      // want to avoid the whole page breaking up in such a case though.
                      return false;
                    }

                    return {
                      id: option?.id,
                      displayElement: <span>{option?.text}</span>,
                      item: {
                        ...option,
                        name: type.name,
                        type
                      }
                    };
                  }}
                />
              </div>
            )}
          </li>
          {keywords.map((keyword) => (
            <li key={keyword.id}>
              <ChoosenKeyword
                tag="div"
                text={keyword.value}
                onRemove={remove ? () => remove(keyword) : null}
              />
              <SliderWithTooltip
                min={0}
                max={2}
                handleChange={(val) => pushWithNewImportance(keyword, val)}
                options={options}
                value={keyword.importance}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

TypeSection.propTypes = {
  type: PropTypes.shape({
    display_as: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  keywords: PropTypes.array,
  handleChange: PropTypes.func,
  remove: PropTypes.func,
  search: PropTypes.bool,
  pushWithNewImportance: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      importance: PropTypes.number,
      label: PropTypes.string,
      style: PropTypes.string
    })
  ).isRequired,
  error: PropTypes.string,
  initialOpen: PropTypes.bool,
  onChangeOpen: PropTypes.func
};

const KeywordsSliders = ({
  remove,
  search,
  pushWithNewImportance,
  handleAddOne,
  choosenKeywords,
  matchableTypes,
  style,
  errors,
  typesOpened = [],
  onChangeOpen
}) => {
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("search");

  const matchableTypeNames = matchableTypes.map((type) => type.name);

  const keywords = choosenKeywords.filter(({ type }) => matchableTypeNames.includes(type.name));

  // Compliance fields should not be edited/removed from this view
  const matchablePermittedTypeNames = matchableTypes
    .filter(({ compliance_field }) => !compliance_field)
    .map(({ name }) => name);

  // We need to have different logic to allow KPMG Uk to add new grades
  // but we want to keep them from removing, hence we need matchablePermittedTypeNames
  // and matchablePermittedAddTypeNames
  const matchablePermittedAddTypeNames = matchableTypes
    .filter(({ compliance_field, name }) => !compliance_field || name === "grade")
    .map(({ name }) => name);

  const notChosen = (option) => !keywords.find(({ id }) => id === option.id);

  const keywordsByType = groupBy(keywords, (keyword) => keyword.type.id);
  const typesToDisplay = customTypes
    .filter((type) => matchableTypeNames.includes(type.name))
    .sort((typeA, typeB) => (typeA.display_as > typeB.display_as ? 1 : -1));

  const options = useMemo(
    () => [
      { importance: Importance.Supporting, label: t("parts.keywords.supporting"), style: "secondary" },
      { importance: Importance.Essential, label: t("parts.keywords.essential") },
      { importance: Importance.Required, label: t("parts.keywords.hardFilter"), style: "highlight" }
    ],
    [t]
  );

  return (
    <div className={css.root} style={style}>
      {typesToDisplay.length > 0 && (
        <ul className={css.list}>
          {typesToDisplay.map((type) => (
            <TypeSection
              key={type.id}
              search={search}
              pushWithNewImportance={pushWithNewImportance}
              remove={matchablePermittedTypeNames.includes(type.name) ? remove : null}
              type={type}
              handleChange={
                matchablePermittedAddTypeNames.includes(type.name)
                  ? (options) => {
                      if (notChosen(options[0])) {
                        handleAddOne(options[0]);
                      }
                    }
                  : null
              }
              keywords={keywordsByType[type.id] || []}
              options={options}
              error={errors && errors[type.name]}
              initialOpen={typesOpened.includes(type.name)}
              onChangeOpen={(open) => onChangeOpen?.(open, type.name)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

KeywordsSliders.defaultProps = {
  search: false
};

KeywordsSliders.propTypes = {
  remove: PropTypes.func,
  search: PropTypes.bool,
  pushWithNewImportance: PropTypes.func,
  handleAddOne: PropTypes.func,
  choosenKeywords: PropTypes.array,
  matchableTypes: PropTypes.arrayOf(
    PropTypes.shape({
      compliance_field: PropTypes.bool,
      name: PropTypes.string.isRequired
    })
  ),
  style: PropTypes.object,
  errors: PropTypes.object,
  typesOpened: PropTypes.arrayOf(PropTypes.string),
  onChangeOpen: PropTypes.func
};

export default KeywordsSliders;
