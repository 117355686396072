import DropDown from "PFComponents/dropdown/dropdown";
import i18n from "PFCore/i18n";
import TickedBoxIcon from "PFIcons/tickedbox.svg";
import UntickedBoxIcon from "PFIcons/untickedbox.svg";
import PropTypes from "prop-types";
import { Component } from "react";

import css from "./chat_menu.module.scss";
import { getChatSendOnEnter, setChatSendOnEnter } from "./send_on_enter";

export default class ChatListMenu extends Component {
  render() {
    const { markAllRead, forceUpdateAll, handleChange } = this.props;
    const sendsOnEnter = getChatSendOnEnter();

    const options = [];
    const addOption = (id, el, fn) => options.push({ id, displayElement: el || id, item: fn });

    addOption(
      sendsOnEnter ? "_show" : "_hide",
      <span>
        <span className={css.tickbox}>
          {sendsOnEnter ? (
            <TickedBoxIcon width={16} height={16} />
          ) : (
            <UntickedBoxIcon width={16} height={16} />
          )}
        </span>
        {i18n.t("core:chat.menu.pressEnterToSend")}
      </span>,
      () => {
        setChatSendOnEnter(!sendsOnEnter);
        forceUpdateAll();
      }
    );

    addOption("mark_all_read", i18n.t("core:chat.menu.markAllRead"), markAllRead);

    return (
      <DropDown
        style={{
          width: 210,
          minWidth: 210,
          right: 8,
          top: 32,
          maxHeight: 300
        }}
        options={options}
        selectedIndex={-1}
        handleClose={() => {}}
        handleChange={handleChange}
      />
    );
  }
}

ChatListMenu.propTypes = {
  forceUpdateAll: PropTypes.func.isRequired,
  markAllRead: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};
