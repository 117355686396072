import { chunk } from "lodash";
import { useActivityPermissions } from "PFApp/activities/hooks";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { TooltipButtonIcon } from "PFApp/activities/show/activity_page/components/tooltip_button_icon";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityShortlistsFilters } from "PFApp/activities/show/hooks/use_activity_shortlists_filters";
import RefreshAction from "PFApp/activities/show/parts/refresh_action";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel, PagePrefix } from "PFApp/components/filters/filters_panel";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import SortBy from "PFApp/components/sort_by";
import { checkFiltersAreClear } from "PFApp/use_filtered_collection";
import { Alert } from "PFComponents/alert";
import { Modal } from "PFComponents/modal";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { Id, Shortlist } from "PFTypes";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToggle } from "react-use";

import { AddShortlistModal } from "./modals/add_shortlist_modal";
import css from "./shortlist_header.module.scss";

const blockedList = ["availability"];

const getProfilesCollection = (
  shortlists: Shortlist[],
  activityId: Id,
  paginationPage = 1,
  paginationSize = 50
) => {
  const items = (shortlists || []).map(({ profile }) => profile);
  const paginatedArray = chunk(items, paginationSize);
  return getExportProfiles({
    profileIds: paginatedArray[paginationPage - 1].map(({ id }) => id),
    activityId: activityId
  }).then((resp) => ({
    entries: resp.map((profile) => ({
      profile
    })),
    meta: { total: resp.length, page: paginationPage }
  }));
};

type ShortlistHeaderProps = {
  isCardsCompressed: boolean;
  setIsCardsCompressed: (value: boolean) => void;
  handleClearShortlist: VoidFunction;
};

export const ShortlistHeader = ({
  isCardsCompressed,
  setIsCardsCompressed,
  handleClearShortlist
}: ShortlistHeaderProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const {
    task: activity,
    shortlists,
    shortlistsMeta,
    isUnbiasedEnabled,
    isActivityUnbiased,
    isLoadingShortlists
  } = useActivityPageContext();
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { setFilters, updateFilter, handleOrderChange, clearFilters } = useActivityShortlistsFilters();
  const history = useHistory();

  const { openFiltersPanel, isFiltersPanelOpen, areFiltersDisabled } = useFiltersPanelContext();
  const [isClearShortlistConfirmModalOpen, toggleClearShortlistConfirmModalOpen] = useToggle(false);
  const [isAddShortlistModalOpen, toggleAddShortlistModalOpen] = useToggle(false);
  const orderMeta = shortlistsMeta.order;

  const { canAddShortlist } = useActivityPermissions(activity);
  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(shortlistsMeta);

  const handleCompareAll = () => {
    history.push(`/activities/${activity!.id}/compare/details`, { prevPath: location.pathname });
  };

  const canShowFilters = shortlistsMeta.filters;
  const filtersAreClear = checkFiltersAreClear(shortlistsMeta.filters, blockedList);

  const isCompleted = activity.state === "complete";

  const isCompareAllDisabled = shortlists.length < 2;

  return (
    <>
      <div className={css.container}>
        <div className={css.sortBy}>
          {!!orderMeta && (
            <SortBy
              sort={orderMeta.value?.text}
              options={orderMeta.options.map((option) => ({
                id: option.text,
                displayElement: option.text,
                item: option
              }))}
              onSortChange={handleOrderChange}
              noLabel
            />
          )}
        </div>
        {canShowFilters && (
          <FiltersButton
            filtersAreClear={filtersAreClear}
            onClick={() => openFiltersPanel(!isFiltersPanelOpen)}
          />
        )}
        <TooltipButtonIcon
          icon={isCardsCompressed ? "expand" : "collapse"}
          onClick={() => setIsCardsCompressed(!isCardsCompressed)}
          tooltipContent={
            isCardsCompressed
              ? t("activities:show.iconsTooltips.standardCards")
              : t("activities:show.iconsTooltips.compressedCards")
          }
          qaId="ActivityShortlistTabToggleCardsButton"
        />
        <TooltipButtonIcon
          icon="compare"
          onClick={handleCompareAll}
          tooltipContent={
            isCompareAllDisabled
              ? t("activities:show.iconsTooltips.compareAllDisabled")
              : t("activities:show.iconsTooltips.compareAll")
          }
          disabled={isCompareAllDisabled}
          qaId="ActivityShortlistTabCompareButton"
        />
        {canAddShortlist && (
          <>
            <TooltipButtonIcon
              icon="add-profile"
              onClick={() => toggleAddShortlistModalOpen()}
              tooltipContent={t("activities:show.iconsTooltips.addToShortlist")}
              qaId="ActivityShortlistTabAddProfileButton"
            />
            <TooltipButtonIcon
              icon="remove-all"
              onClick={() => toggleClearShortlistConfirmModalOpen()}
              tooltipContent={t("activities:show.iconsTooltips.clearShortlist")}
              qaId="ActivityShortlistTabRemoveAllButton"
            />
          </>
        )}
        <LazyLocalExportTrigger
          quantity={shortlists?.length || 0}
          getProfilesCollection={() => getProfilesCollection(shortlists, activity.id)}
          isBlindModeOn={isUnbiasedEnabled && isActivityUnbiased}
          buttonKind="secondary"
          icon="export"
          tooltipContent={t("activities:show.iconsTooltips.export")}
          qaId="ActivityShortlistTabExportButton"
        />
        {!isCompleted && activity.coowned && (
          <RefreshAction
            activityId={activity.id}
            onUpdate={() => {
              invalidateShortlists();
            }}
            showLabel={false}
            qaId="ActivityShortlistTabRefreshMatchesButton"
          />
        )}
        <SearchInput
          term={termValue}
          disabled={areFiltersDisabled || !isTermFilterAvailable}
          pageKey={RecentSearchesPageKey.ActivityShortlist}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              useAsTerm: true
            }
          ]}
          includeRecentSearches={false}
          popperOptions={{
            placement: "bottom-end"
          }}
          classes={{ dropdown: commonCss.searchDropdown }}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          qaId="ActivityShortlistTabSearchInput"
        />
      </div>
      {isFiltersPanelOpen && canShowFilters && (
        <FiltersPanel
          keyPrefix={PagePrefix.ActivityShortlists}
          disabled={areFiltersDisabled}
          disabledLabel={t("activities:show.matches.filtersDisabled")}
          isLoading={isLoadingShortlists}
          closeFilters={() => openFiltersPanel(false)}
          filtersAreClear={filtersAreClear}
          meta={shortlistsMeta}
          clearFilters={clearFilters}
          onFilterChange={updateFilter}
          onFiltersChange={setFilters}
          blockedList={blockedList}
        />
      )}
      {!filtersAreClear && (
        <Alert
          title={t("activities:show.shortlist.filtersChangedWarning")}
          action={{
            text: t("translation:filters.clearAll"),
            icon: "filter-clean",
            kind: "text",
            onClick: () => clearFilters()
          }}
          small
        />
      )}
      {isClearShortlistConfirmModalOpen && (
        <Modal
          title={t("activities:show.shortlist.clearShortlist")}
          onOK={handleClearShortlist}
          onClose={() => toggleClearShortlistConfirmModalOpen()}
          labelOK={t("activities:show.shortlist.yesRemove")}
          kindOK="danger"
        >
          {t("activities:show.shortlist.wantClearPendingShortlists")}
        </Modal>
      )}
      {isAddShortlistModalOpen && <AddShortlistModal onClose={() => toggleAddShortlistModalOpen()} />}
    </>
  );
};
