import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./clash.module.scss";

const Clash = ({ width, left, top, dayWidth, title, className }) => (
  <div
    title={title || "booking clash"}
    className={classNames(css.root, className)}
    style={{
      width,
      minWidth: dayWidth,
      transform: `translate(${left}px, ${top}px)`
    }}
  />
);

Clash.propTypes = {
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  dayWidth: PropTypes.number.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
};

export default Clash;
