import AppContext from "PFApp/app_context";
import { AvatarProps } from "PFComponents/avatar";
import { ProfileAvatar, ProfileAvatarProps } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useProfileChatIcon } from "./use_profile_chat_icon";

type ProfileAvatarConnectedProps = Pick<
  ProfileAvatarProps,
  "handleIconClick" | "Icon" | "size" | "profile"
> & {
  onClick?: ProfileAvatarProps["onClick"] | null;
  withChat?: boolean;
} & React.AriaAttributes;

export const ProfileAvatarConnected = (props: ProfileAvatarConnectedProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const { "aria-label": ariaLabel, onClick, profile, withChat } = props;

  const { Icon, handleClick: handleChatClick } = useProfileChatIcon(profile);

  const handleOpenInRightBar = (event) => {
    event.preventDefault();

    dispatch({
      type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
      payload: { id: profile.id, profile }
    });
  };

  const handleIconClick = useMemo(
    () => props.handleIconClick || (withChat ? handleChatClick : undefined),
    [handleChatClick, withChat, props.handleIconClick]
  );

  const IconComponent = useMemo(
    () => props.Icon || (withChat ? Icon : undefined),
    [Icon, withChat, props.Icon]
  );

  let usedOnClick: AvatarProps["onClick"];
  let defaultAriaLabel: React.AriaAttributes["aria-label"];
  let usedHref: AvatarProps["href"];

  if (onClick !== undefined) {
    usedOnClick = onClick ?? undefined;
  } else if (profile.viewable) {
    usedOnClick = handleOpenInRightBar;
    defaultAriaLabel = t("openProfileInTheSidebar", { name: getProfileName(profile) });
    usedHref = `/profiles/${profile.id}`;
  }

  return (
    <ProfileAvatar
      {...props}
      onClick={usedOnClick}
      href={usedHref}
      handleIconClick={handleIconClick}
      Icon={IconComponent}
      aria-label={ariaLabel || defaultAriaLabel}
    />
  );
};
