import classNames from "classnames";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Profile } from "PFTypes";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SkillsExplorer from "../charts/skills_explorer";
import { PageSection } from "../page_section";
import { SkillsPowerList } from "../skills_power_list";
import { SkillsChartActions } from "./skills_chart_actions";
import css from "./skills_chart_section.modules.scss";

export type SelectedChart = "drilldown" | "bubble";

type SkillsChartSectionProps = {
  profile: Profile;
  className?: string;
};

export const SkillsChartSection = ({ profile, className }: SkillsChartSectionProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");

  const [selectedChart, setSelectedChart] = useState<SelectedChart>(
    (currentProfile.preferences.display?.skills_insights || "bubble") as SelectedChart
  );
  const [isListVisible, setIsListVisible] = useState(false);
  const [wasChartLastView, setWasChartLastView] = useState(true);

  const searchParams = useQueryParams();

  const [searchTerm, setSearchTerm] = useState(searchParams.get("term"));

  const handleTermChange = useCallback(
    (newTerm: string) => {
      setSearchTerm(newTerm);
      if (newTerm.length > 0) {
        isListVisible && setWasChartLastView(false);
        setIsListVisible(true);
      } else {
        setIsListVisible(!wasChartLastView);
      }
    },
    [isListVisible, wasChartLastView]
  );

  const handleClearSearch = useCallback(() => {
    setSearchTerm(null);
    setIsListVisible(!wasChartLastView);
  }, [wasChartLastView]);

  const isMeProfile = currentProfile.id === profile.id;

  const skills = useMemo(
    () =>
      (profile.skills || []).filter((item) =>
        (item.text || String(item.value) || "").toLowerCase().includes(searchTerm?.toLowerCase() || "")
      ),
    [searchTerm, profile.id, JSON.stringify(profile.skills)]
  );

  const skillsExplorerAvailable = profile.skill_categories && profile.skill_categories.length > 0;

  const handleListClick = () =>
    setIsListVisible((prev) => {
      if (prev === true) {
        setSelectedChart((prev) => prev);
        return false;
      } else {
        return true;
      }
    });

  const handleChartChange = () => {
    setIsListVisible(false);
    setWasChartLastView(true);
    isListVisible && setSearchTerm(null);
  };

  return (
    <PageSection
      className={classNames(css.pageSectionWrapper, className)}
      title={t("show.parts.skills")}
      action={
        <SkillsChartActions
          isMeProfile={isMeProfile}
          searchTerm={searchTerm}
          onTermChange={handleTermChange}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          skillsExplorerAvailable={skillsExplorerAvailable}
          isListVisible={isListVisible}
          onListClick={handleListClick}
          onChartChange={handleChartChange}
        />
      }
      actionWrapStyle={{ display: "flex", flex: 1, alignItems: "center" }}
      contentStyle={{ overflow: "auto" }}
    >
      {isListVisible || !skillsExplorerAvailable ? (
        <SkillsPowerList
          profile={profile}
          searchTerm={searchTerm ?? ""}
          skills={skills}
          clearSearch={handleClearSearch}
        />
      ) : (
        <SkillsExplorer profile={profile} skills={skills} selectedChart={selectedChart} />
      )}
    </PageSection>
  );
};
