import { useMutation } from "@tanstack/react-query";
import { useGrowl } from "PFApp/use_growl";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import activityKeys from "PFCore/hooks/queries/activity/query_keys";
import { Id, MutationOptions } from "PFTypes";
import { useTranslation } from "react-i18next";

import { revealBlindMode } from "../../../services/reviewers";

export const useRevealBlindMode = (
  activityId: number,
  { onSuccess, onError, ...options }: MutationOptions<Id> = {}
) => {
  const { t } = useTranslation();
  const growl = useGrowl();

  const mutationKey = activityKeys.revealReviewers(activityId);
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  return useMutation({
    mutationKey,
    mutationFn: revealBlindMode,
    onSuccess: (...args) => {
      invalidateActivities([activityId]);
      onSuccess?.(...args);
    },
    onError: (...args) => {
      growl({ message: t("somethingWentWrong"), kind: "error" });
      onError?.(...args);
    },
    ...options
  });
};
