import { LocationState } from "PFTypes/router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useActivityPermissions } from "../../hooks";

const ACTIVITIES_PATH = "activities";

export type ActivityPageTab = {
  id: string;
  title: string;
};

type UseActivityPageTabsReturn = {
  showTabs: boolean;
  tabs: ActivityPageTab[];
  tabActiveId: string | undefined;
  tabsIds: string[];
  handleChangeTab: (tab: ActivityPageTab["id"], navigate?: boolean) => void;
};

const useActivityPageTabs = (task, initialTabId = "details"): UseActivityPageTabsReturn => {
  const { t } = useTranslation("activities", { keyPrefix: "show.tabs" });
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const { section } = useParams<{ section: string }>();
  const tabId = section || initialTabId;

  const {
    canSeeMatches,
    canSeeShortlist,
    canSeeVacancies,
    canSeeFeedback,
    canSeeInterested,
    canSeeRoles,
    canSeeEconomics
  } = useActivityPermissions(task);

  const tabValues: Record<string, ActivityPageTab> = useMemo(
    () => ({
      matches: {
        id: "matches",
        title: t("matches")
      },
      shortlist: {
        id: "shortlist",
        title: t("shortlist")
      },
      roles: {
        id: "roles",
        title: t("roles")
      },
      details: {
        id: "details",
        title: t("details")
      },
      interested: {
        id: "interested",
        title: t("interested")
      },
      vacancies: {
        id: "vacancies",
        title: t("vacancies")
      },
      feedback: {
        id: "feedback",
        title: t("feedback")
      },
      economics: {
        id: "economics",
        title: t("economics")
      }
    }),
    [t]
  );

  const tabs = useMemo(() => {
    const result: ActivityPageTab[] = [tabValues.details];

    if (canSeeInterested) {
      result.push(tabValues.interested);
    }

    if (canSeeMatches) {
      result.push(tabValues.matches);
    }

    if (canSeeShortlist) {
      result.push(tabValues.shortlist);
    }

    if (canSeeRoles) {
      result.push(tabValues.roles);
    }

    if (canSeeVacancies) {
      result.push(tabValues.vacancies);
    }

    if (canSeeFeedback) {
      result.push(tabValues.feedback);
    }

    if (canSeeEconomics) {
      result.push(tabValues.economics);
    }
    return result;
  }, [
    canSeeMatches,
    canSeeShortlist,
    canSeeRoles,
    canSeeVacancies,
    canSeeFeedback,
    canSeeEconomics,
    canSeeInterested,
    tabValues
  ]);

  const handleChangeTab = (tabId: ActivityPageTab["id"]) => {
    const utmSource = location?.state?.utmSource;

    if (tabId === "details") {
      history.push({ pathname: `/${ACTIVITIES_PATH}/${task.id}`, state: { utmSource } });
    } else {
      history.push({ pathname: `/${ACTIVITIES_PATH}/${task.id}/${tabId}`, state: { utmSource } });
    }
  };

  const showTabs = useMemo(() => tabs && tabs.length > 1, [tabs]);
  const tabsIds = useMemo(() => (tabs ? tabs.map((tab) => tab.id) : []), [tabs]);

  return {
    showTabs,
    tabs,
    tabActiveId: tabId,
    tabsIds,
    handleChangeTab
  };
};

export default useActivityPageTabs;
