import classNames from "classnames";
import { isNil } from "lodash";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";

import css from "./match_card_scores.module.scss";

type MatchScoreProps = {
  title: string;
  score?: number | null;
  unit?: string;
  isBold?: boolean;
  isLarge?: boolean;
};

export const MatchScore = ({ title, score, unit, isBold, isLarge }: MatchScoreProps) => {
  if (isNil(score)) {
    return null;
  }

  const formatScore = (score: number, digits: number) =>
    getMatchDisplayValue(score, { digits, skipTrailingZeros: true });

  const tooltipValue = `${formatScore(score, 3)}${unit}`;

  return (
    <div className={css.matchScore}>
      <Typography variant="labelRegular" className={css.title} title={title} clipOverflow noMargin>
        {title}
      </Typography>
      <Tooltip content={tooltipValue}>
        <div className={css.value}>
          <Typography
            variant={isLarge ? "h1" : "h4"}
            className={classNames({
              [css.bold]: isBold,
              [css.thin]: !isBold
            })}
            noMargin
          >
            {formatScore(score, 0)}
          </Typography>
          {unit && (
            <Typography
              variant="bodyRegular"
              tag="span"
              className={classNames(css.unit, css.bold, {
                [css.large]: isLarge
              })}
            >
              {unit}
            </Typography>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
