import { compact } from "lodash";
import { Profile } from "PFTypes";

import { ProfileInfoEntry, useProfileCustomValues } from "./use_profile_custom_values";

type UseMatchProfileInfoReturn = Record<"headerInfo" | "sideInfo", ProfileInfoEntry[]>;

export const useMatchProfileInfo = (
  profile: Partial<Profile> & Pick<Profile, "positions">
): UseMatchProfileInfoReturn => {
  const { getCustomValue, getJobTitleEntry } = useProfileCustomValues();

  return {
    headerInfo: compact([
      getJobTitleEntry(profile),
      getCustomValue(profile, "match_info_1"),
      getCustomValue(profile, "match_info_2"),
      getCustomValue(profile, "match_info_3")
    ]),
    sideInfo: compact([
      getCustomValue(profile, "match_sidebar_info_1"),
      getCustomValue(profile, "match_sidebar_info_2"),
      getCustomValue(profile, "match_sidebar_info_3")
    ])
  };
};
