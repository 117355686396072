import { EconomicsScenarioDetails } from "PFTypes";
import { useState } from "react";

import { SkillsChartModal } from "./modals/skills_chart_modal";
import { SkillsRadarChart } from "./skills_radar_chart";

type SkillsChartProps = {
  skillsRadarData: EconomicsScenarioDetails["skillsRadarData"];
  skillsCoveredPercentage: number;
};

export const SkillsChart = ({ skillsRadarData = [], skillsCoveredPercentage }: SkillsChartProps) => {
  const [isChartModalOpened, setChartModalOpened] = useState(false);

  return (
    <>
      <SkillsRadarChart
        coverage={skillsCoveredPercentage}
        skillsRadarData={skillsRadarData}
        onHeaderIconClick={() => setChartModalOpened(true)}
      />
      {isChartModalOpened && (
        <SkillsChartModal
          skillsRadarData={skillsRadarData}
          coverage={skillsCoveredPercentage}
          onClose={() => setChartModalOpened(false)}
        />
      )}
    </>
  );
};
