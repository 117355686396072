import classNames from "classnames";
import { useModalContext } from "PFComponents/modal/modal_context";
import { useState } from "react";
import { useForm } from "react-hook-form";

import Footer from "./parts/footer";
import Header from "./parts/header";
import QuestionsSection from "./parts/questions_section";
import StatementSection from "./parts/statement_section/statement_section";
import css from "./questions_modal.module.scss";
import { FormValues, QuestionData } from "./questions_modal.types";

export interface QuestionsModalProps {
  role: string;
  questions: string[];
  showQuestions: boolean;
  showStatement: boolean;
  onSubmit: (values: FormValues) => void;
}

const QuestionsModal = ({
  role,
  questions,
  showQuestions,
  showStatement,
  onSubmit
}: QuestionsModalProps): JSX.Element => {
  const { closeWithAnimation } = useModalContext();
  const [isEditMode, setIsEditMode] = useState<boolean>(true);

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      statementOfQualification: showStatement ? "" : undefined
    },
    mode: "onChange"
  });

  const questionFields: QuestionData[] = questions
    .filter((label) => !!label)
    .map((label, index) => ({
      id: index + 1,
      name: `question${index + 1}` as keyof FormValues,
      label
    }));

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
        closeWithAnimation();
      })}
      className={css.modalContent}
    >
      <Header onClose={() => closeWithAnimation()} role={role} />
      <hr className={classNames(css.noMargin, css.separator)} />
      <div className={css.formFields}>
        {showQuestions && (
          <QuestionsSection
            questionFields={questionFields}
            isEditMode={isEditMode}
            control={control}
            className={classNames(css.questionsSection, { [css.noMargin]: !showStatement })}
          />
        )}
        {showStatement && <StatementSection control={control} isEditMode={isEditMode} />}
      </div>
      <hr className={css.separator} />
      <Footer
        isEditMode={isEditMode}
        isFormValid={formState.isValid}
        onClose={() => closeWithAnimation()}
        onEdit={() => setIsEditMode(true)}
        onReview={() => setIsEditMode(false)}
      />
    </form>
  );
};

export default QuestionsModal;
