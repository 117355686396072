import { Booking, Profile } from "PFTypes";

import BookingActivityContextProvider from "../../../providers/booking_activity_context_provider";
import { BookingDetailContent } from "./booking_detail_content";

type BookingDetailProps = {
  booking: Booking | undefined;
  loading: boolean;
  profile: Profile | undefined;
  goToDate?: (date: Date) => void;
  checkCanGoToDate?: (date: string) => boolean;
};

const BookingDetail = (props: BookingDetailProps): JSX.Element | null => {
  const { booking } = props;

  const bookingActivityId = booking?.activity_id ?? undefined;
  return (
    <BookingActivityContextProvider id={bookingActivityId}>
      <BookingDetailContent {...props} />
    </BookingActivityContextProvider>
  );
};

export default BookingDetail;
