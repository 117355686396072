import useIsBookingModulePermitted from "PFApp/use_is_booking_module_permitted";
import { Option } from "PFComponents/multi_toggle/multi_toggle";
import { Booking } from "PFTypes";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";

type UseBookingTypeOptions = {
  initialBooking?: Booking;
  mode: BookingFormMode;
};

export const useBookingTypeOptions = ({ initialBooking, mode }: UseBookingTypeOptions) => {
  const { t } = useTranslation("bookingModule");

  const isBookingPermitted = useIsBookingModulePermitted();

  const activityId = useWatch<BookingFormValues>({ name: "activityId" });

  const isCreatingActivityRelated = mode === BookingFormMode.Create && !!activityId;
  const isUpdatingNonRepeatedBooking =
    mode !== BookingFormMode.Create && !initialBooking?.booking_template_id;
  const disableRepeatedBooking =
    !isBookingPermitted || isCreatingActivityRelated || isUpdatingNonRepeatedBooking;
  return useMemo<Option<BookingType>[]>(
    () => [
      {
        id: BookingType.Single,
        value: t("bookings.create.singleBooking")
      },
      ...(disableRepeatedBooking
        ? []
        : [
            {
              id: BookingType.Repeated,
              value: t("bookings.repeatedBooking")
            }
          ])
    ],
    [disableRepeatedBooking, t]
  );
};
