import { isNil } from "lodash";
import { InlineRangeCalendar } from "PFComponents/calendar/inline_calendar";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { letFilterRestore } from "../../use_filtered_collection";

const DateRangeItem = ({ filter, handleChange, error, displayMonth, portalRef, disabled }) => {
  const { formatISODate } = useDateFormatter();
  const { value, isClearAvailable, minDate, options } = filter;
  const letClear = isNil(isClearAvailable) || isClearAvailable;
  const { start, end, period } = value || {};

  const onHandleChange = (start, end, selectedPeriod) => {
    if (!start && !end && !selectedPeriod) {
      handleChange(null);
    }
    if (start && end) {
      handleChange({
        start: formatISODate(start),
        end: formatISODate(end),
        period: selectedPeriod
      });
    }
  };

  const startDate = useMemo(() => {
    if (start) {
      return formatISODate(start);
    }
    const periodOption = period ? options.find(({ id }) => id === period) : null;
    return periodOption ? formatISODate(periodOption.item.start) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, period, options]);

  const endDate = useMemo(() => {
    if (end) {
      return formatISODate(end);
    }
    const periodOption = period ? options.find(({ id }) => id === period) : null;
    return periodOption ? formatISODate(periodOption.item.end) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, period, options]);

  const letRestore = letFilterRestore(filter);

  return (
    <div style={{ position: "relative" }}>
      <InlineRangeCalendar
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        label={filter.title}
        error={error}
        selectedPeriod={period}
        displayMonth={displayMonth}
        handleChange={onHandleChange}
        letClear={letClear}
        onRestore={letRestore ? () => handleChange(filter.defaultValue) : undefined}
        options={options}
        portalRef={portalRef}
        disabled={disabled}
      />
    </div>
  );
};

DateRangeItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        period: PropTypes.string
      })
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        period: PropTypes.string
      })
    ]),
    isClearAvailable: PropTypes.bool,
    minDate: PropTypes.string,
    options: PropTypes.array
  }).isRequired,
  error: PropTypes.string,
  displayMonth: PropTypes.object,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

DateRangeItem.defaultProps = {
  isClearAvailable: true
};

export default DateRangeItem;
