import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { CurrentProfile } from "PFTypes";
import { useTranslation } from "react-i18next";

import AvatarUploader from "../../../../common/avatar_uploader";
import { FormSection } from "./form_section";

type AvatarSectionProps = {
  profile: CurrentProfile;
};

export const AvatarSection = ({ profile }: AvatarSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });
  const { data: currentAccount } = useCurrentAccount();

  const canEditPhoto = currentAccount.profile_view_customization.edit_photo;

  return (
    <FormSection title={t("profilePhoto")}>
      <AvatarUploader profile={profile} isEditable={canEditPhoto} />
    </FormSection>
  );
};
