import { last } from "lodash";
import { useEffect } from "react";

type UseCloseOnEscapeClick = {
  modalId: string;
  closeWithAnimation: VoidFunction;
};

export const useCloseOnEscapeClick = ({ modalId, closeWithAnimation }: UseCloseOnEscapeClick) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const topModal = last(document.getElementsByTagName("dialog"));
      // Avoid closing all the dialogs with single ESC click
      const isTopModal = modalId === topModal?.getAttribute("data-dialog-id");

      if (event.key === "Escape" && isTopModal) {
        event.preventDefault();
        closeWithAnimation();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalId, closeWithAnimation]);
};
