import classNames from "classnames";
import range from "lodash/range";
import moment from "moment";
import { Typography } from "PFComponents/typography";

import css from "./week_days_select.module.scss";

const weekdays = range(0, 7)
  .map((i) => moment.weekdaysShort(i + 1))
  .map((wd) => wd[0]);

type WeekdayProps = {
  readonly?: boolean;
  weekDayNumber: number;
  onClick: (day: number) => void;
  selected: boolean;
  disabled: boolean;
};

export const Weekday = ({ readonly, disabled, weekDayNumber, onClick, selected }: WeekdayProps) => (
  <button
    type="button"
    disabled={disabled || readonly}
    className={classNames(css.day, {
      [css.selected]: selected,
      [css.disabled]: disabled,
      [css.readonly]: readonly
    })}
    onClick={() => onClick(weekDayNumber)}
  >
    <Typography variant="bodyBold" noMargin>
      {weekdays[weekDayNumber]}
    </Typography>
  </button>
);
