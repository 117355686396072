import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { ReactNode } from "react";

import css from "./booking_detail_item.module.scss";

const isTextValue = (value: any): boolean => typeof value === "string" || typeof value === "number";

type BookingDetailItemProps = {
  label: string;
  value?: ReactNode;
  infoContent?: ReactNode;
};

export const BookingDetailItem = ({ label, value, infoContent }: BookingDetailItemProps) => (
  <div className={css.root}>
    <Typography variant="labelRegular" noMargin>
      {label}
    </Typography>
    <div className={css.container}>
      {isTextValue(value) ? (
        <Typography variant="bodyBold" noMargin>
          {value ?? ""}
        </Typography>
      ) : (
        value
      )}
      {!!infoContent && (
        <Tooltip content={infoContent} appendTo={"parent"}>
          <div className={css.container}>
            <Icon name="info" size="sm" />
          </div>
        </Tooltip>
      )}
    </div>
  </div>
);
