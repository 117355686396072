import isEmpty from "lodash/isEmpty";
import { LoadingDots } from "PFComponents/loading_dots";

import { useBookingDetailSidePanelContext } from "../../booking_detail_side_panel_context";
import { BookingsCarousel } from "./bookings_carousel";
import { OverallDuration } from "./overall_duration";

type BookingSiblingsProps = {
  goToDate?: (date: Date) => void;
  checkCanGoToDate?: (date: string) => boolean;
};

export const BookingSiblings = ({ goToDate, checkCanGoToDate }: BookingSiblingsProps) => {
  const { siblingBookings, isSiblingBookingsFetching, bookingTemplate, isBookingTemplateFetching } =
    useBookingDetailSidePanelContext();

  if (isSiblingBookingsFetching || isBookingTemplateFetching) {
    return <LoadingDots />;
  }
  if (!siblingBookings || isEmpty(siblingBookings)) {
    return null;
  }

  return (
    <>
      <OverallDuration bookings={siblingBookings} bookingTemplate={bookingTemplate} />
      <BookingsCarousel goToDate={goToDate} checkCanGoToDate={checkCanGoToDate} />
    </>
  );
};
