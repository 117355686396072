import { useIsMutating, useMutation } from "@tanstack/react-query";
import { createBooking, CreateBookingPayload } from "PFApp/booking/services/api";

import { useBookingsForUpdateInvalidate } from "./bulk_operation/use_bookings_for_update_invalidate";
import { bookingMutationsKeys } from "./mutation_keys";

export const useBookingCreate = () => {
  const { invalidate: invalidateSelectMode } = useBookingsForUpdateInvalidate();
  const mutationKey = bookingMutationsKeys.create();

  const createMutation = useMutation(
    async (bookingData: CreateBookingPayload) => {
      if (!bookingData) {
        return;
      }

      return createBooking(bookingData);
    },
    {
      mutationKey,
      onSuccess: () => {
        invalidateSelectMode("total_selection");
      }
    }
  );

  const isCreating = useIsMutating({
    mutationKey
  });

  return {
    mutation: createMutation,
    create: createMutation.mutateAsync,
    isCreating
  };
};
