import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EconomicsScenario, Id, MutationOptions } from "PFTypes";

import { updateActivityEconomicsScenario } from "../../../services/activity/activity_economics_scenario_update";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioUpdate = (
  scenarioId: Id,
  options?: MutationOptions<string, EconomicsScenario>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsScenario, unknown, string>({
    mutationFn: (name: string) => updateActivityEconomicsScenario(scenarioId, name),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(activityKeys.economicsScenario(scenarioId));
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
