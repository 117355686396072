import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { UpdateBookingPayload } from "PFApp/booking/services/api";

import { isNotLimited } from "../use_booking_payload";
import { mapBasicSingleBooking } from "./get_payload.utils";
import { isBookingFormForSingleBookingEditModeValid } from "./validate";

export type GetPayloadsForEditGroupBookingsOfSingleType = {
  values: BookingFormValues;
  bookingGroupId?: number;
};

export const getPayloadsForEditGroupBookingsOfSingleType = ({
  values,
  bookingGroupId
}: GetPayloadsForEditGroupBookingsOfSingleType): UpdateBookingPayload[] => {
  const bookingsToProcess = values.bookings.filter(isNotLimited);
  if (!isBookingFormForSingleBookingEditModeValid({ ...values, bookings: bookingsToProcess })) {
    throw new Error("Invalid form values for editing a group booking!");
  }

  return bookingsToProcess.map((formBooking) => {
    const { id, category, title, description, overridesDiaryTime, overridesNonWorkingTime, phaseSourceId } =
      formBooking;
    return {
      ...mapBasicSingleBooking(formBooking),
      id: id!,
      bookingCategoryId: category!.id,
      ...(title ? { title } : {}),
      ...(description ? { description } : {}),
      overridesCalendar: overridesDiaryTime!,
      overridesNonWorkingDays: overridesNonWorkingTime!,
      phaseSourceId,
      bookingGroupId
    };
  });
};
