import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useTemplate } from "PFApp/hooks";
import React from "react";

import { useActivityPermissions } from "../../../hooks";
import AddRoleButton from "../../parts/add_role_button";
import BecomeCoownerButton from "../../parts/become_coowner_button";
import css from "./activity_details_actions.module.scss";

export const ActivityDetailsActions = () => {
  const { task, canCreateRoles, roleKey, isEngagement } = useActivityPageContext();
  const template = useTemplate(task.template_id);
  const { canBecomeCoOwner } = useActivityPermissions(task);

  const canAddRole = task.coowned && isEngagement && canCreateRoles;

  if (!canBecomeCoOwner && !canAddRole) {
    return null;
  }

  return (
    <div className={css.container}>
      {canBecomeCoOwner && <BecomeCoownerButton templateName={template?.name} />}
      {canAddRole && <AddRoleButton activityId={task.id} roleKey={roleKey} />}
    </div>
  );
};
