/* eslint-disable camelcase */
import { CancelToken } from "axios";
import { Camelized, decamelizeKeys } from "humps";
import omit from "lodash/omit";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { BookingPartPayload } from "PFCore/hooks/queries/bookings/part_update/use_booking_part_update";
import { ApiRoute } from "PFCore/utilities/routes";
import {
  BookingTemplate,
  CalendarRange,
  Collection,
  DateRange,
  PaginatedCollection,
  ProfileDayAvailability,
  ShortlistMinimized
} from "PFTypes";
import { Booking } from "PFTypes";

import { getAPIFilters } from "../../use_filtered_collection";
import { BookingResponse } from "../components/booking_form/use_handle_submit/use_handle_submit";
import { CalendarGranularity } from "../types";

export type FetchBookings = {
  profileId: number;
  activityId?: number | boolean;
  bookingTemplateId?: number;
  dateRange?: { min: string; max: string };
  withDurationBreakdown?: boolean;
  perPage?: number;
};

/* /duration_breakdown endpoint should return the same response, at most with additional fields */
export const fetchBookings = ({
  profileId,
  activityId,
  bookingTemplateId,
  dateRange,
  withDurationBreakdown,
  perPage = PER_PAGE_UNPAGINATED
}: FetchBookings): Promise<PaginatedCollection<Booking[]>> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(
      withDurationBreakdown
        ? `/api/booking_module/bookings/duration_breakdown`
        : `/api/booking_module/bookings`
    ),
    method: "GET",
    api_version: 2,
    data: {
      activity_id: activityId,
      profile_id: profileId,
      per_page: perPage,
      ...(bookingTemplateId ? { booking_template_id: bookingTemplateId } : {}),
      ...(dateRange ? { date_range: dateRange } : {})
    }
  });

export const fetchBooking = (id) =>
  api({
    url: `booking_module/bookings/${id}`
    // TODO: [PROF-4641] - remove decamelizeKeys when app is ready
    // converted to axios api to be working on outlook plugin
  }).then((resp) => decamelizeKeys(resp) as Booking);

export type DeleteBookingProps = {
  id?: number;
  profile_id?: number;
  date_range?: CalendarRange;
  activity_id?: number;
};

export const fetchBookingExternal = (profileId?: number, bookingId?: number): Promise<Booking> =>
  api({
    url: `profiles/${profileId}/bookings/${bookingId}`,
    method: "GET"
    // TODO: [PROF-4641] - remove decamelizeKeys when app is ready
  }).then((resp) => decamelizeKeys(resp) as Booking);

export const deleteBooking = (payload: DeleteBookingProps): Promise<void> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/bookings`),
    method: "DELETE",
    api_version: 2,
    data: payload
  });

export const undoBooking = (shortlistId) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/shortlists/${shortlistId}/reset`),
    method: "PUT",
    api_version: 2
  });

export type BookingDataToUpdate = Partial<
  Camelized<
    Pick<
      Booking,
      | "start_date"
      | "end_date"
      | "requirement_type"
      | "requirement_value"
      | "booking_category_id"
      | "title"
      | "description"
      | "overrides_calendar"
      | "overrides_non_working_days"
      | "phase_source_id"
      | "booking_group_id"
      | "profile_id"
    >
  >
>;

export type CreateBookingPayload = ({ profileId: number; activityId?: number } | { searchId: number }) &
  Camelized<{
    sourceType: "ui";
    bookings: BookingDataToUpdate[];
  }>;

export const createBooking = (payload: CreateBookingPayload) =>
  api({
    url: `booking_module/bookings`,
    method: "POST",
    data: payload
  });

export type UpdateBookingPayload = BookingDataToUpdate & Pick<Booking, "id">;

export const updateBooking = (payload: UpdateBookingPayload) =>
  api({
    url: `booking_module/bookings/${payload.id}`,
    method: "PUT",
    data: omit(payload, "id")
  });

export type UpdateBookingsPayload = (
  | { profileId: number; activityId?: number }
  | { bookingGroupId: number }
) &
  Camelized<{
    bookings: (BookingDataToUpdate & Pick<Booking, "id">)[];
  }>;

export const updateManyBookings = (payload: UpdateBookingsPayload): Promise<Collection<BookingResponse[]>> =>
  api({
    url: "booking_module/bookings/update_many",
    method: "PUT",
    data: payload
  });

export const updateBookingPart = (id: number, payload: BookingPartPayload) =>
  api({
    url: `booking_module/bookings/${id}/update_part`,
    method: "PUT",
    data: payload
  });

export const fetchProfileWarnings = (profile_id, date_range, new_booking = false) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/profile_warnings`),
    method: "GET",
    api_version: 2,
    stringify: true,
    contentType: "application/json; charset=utf-8",
    data: { profile_id, date_range, new_booking }
  });

// TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
export const bookRoleByShortlist = (shortlistId): Promise<ShortlistMinimized> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/shortlists/${shortlistId}/book`),
    method: "PUT",
    api_version: 2
  });

export const fetchActivityWarnings = (activityId) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/booking_module/activity_warnings"),
    method: "GET",
    api_version: 2,
    data: { activity_id: activityId }
  });

export const fetchProfileAvailabilities = ({
  profileId: profile_id,
  calendarRange: date_range,
  perPage: per_page = PER_PAGE_UNPAGINATED,
  page = 1,
  calendarGranularity
}) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(
      calendarGranularity === CalendarGranularity.Daily
        ? "/api/booking_module/availabilities"
        : "/api/booking_module/availabilities/weekly"
    ),
    method: "GET",
    api_version: 2,
    stringify: true,
    contentType: "application/json; charset=utf-8",
    data: { profile_id, date_range, page, per_page }
  });

export const fetchProfileAvailabilitiesExternal = (
  profileId: number,
  dateRange?: DateRange
): Promise<Collection<ProfileDayAvailability[]>> =>
  api({
    url: `profiles/${profileId}/availabilities`,
    method: "GET",
    params: {
      dateRange
    }
  });

type WorkforceMetricsData = {
  availableMinutes: number | null;
  billableUtilization: number | null;
  bookedMinutes: number | null;
  nominalWorkingMinutes: number | null;
  totalUtilization: number | null;
};

export const fetchWorkforceMetrics = ({ dateRange, filters }): Promise<WorkforceMetricsData> =>
  api({
    url: "booking_module/utilizations/index",
    method: "post",
    data: {
      dateRange,
      filters: getAPIFilters(filters)
    }
  });

export const fetchPotentialWarningsForSingleBookings = (
  profileId: number,
  bookings: Partial<Booking>[],
  cancelToken: CancelToken
) =>
  api({
    url: "booking_module/bookings/potential_warnings",
    method: "post",
    data: {
      profileId,
      bookings
    },
    cancelToken
  });
export const fetchPotentialWarningsForBookingTemplates = (
  profileId: number,
  bookingTemplates: Partial<BookingTemplate>[],
  cancelToken: CancelToken
) =>
  api({
    url: "booking_module/booking_templates/potential_warnings",
    method: "post",
    data: {
      profileId,
      bookingTemplates
    },
    cancelToken
  });

export const fetchBookingHistory = ({ bookingId, page, perPage }) =>
  fetchPaginatedCollection(ApiRoute(`/api/booking_module/bookings/${bookingId}/history`), {
    page,
    perPage
  });
export const fetchBookingHistoryExternal = ({ profileId, bookingId, page, perPage }) =>
  fetchPaginatedCollection(ApiRoute(`/api/profiles/${profileId}/booking_history/${bookingId}`), {
    page,
    perPage
  });

export const fetchActivity = (activity_id) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/activities/${activity_id}`),
    method: "GET",
    api_version: 2,
    stringify: true,
    contentType: "application/json; charset=utf-8"
  });

export type BookingTemplateDataToUpdate = Camelized<
  Pick<
    BookingTemplate,
    | "start_date"
    | "end_date"
    | "start_time"
    | "end_time"
    | "booking_category_id"
    | "title"
    | "description"
    | "overrides_calendar"
    | "overrides_non_working_days"
    | "wday_mask"
  >
>;

export type CreateBookingTemplatePayload = ({ profileId: number } | { searchId: number }) &
  BookingTemplateDataToUpdate;

export const createBookingTemplate = (payload: CreateBookingTemplatePayload) =>
  api({
    url: `booking_module/booking_templates`,
    method: "POST",
    data: payload
  });

export const fetchBookingTemplate = (id) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_templates/${id}`),
    method: "GET",
    api_version: 2
  });

export type UpdateBookingTemplatePayload = { id: number; profileId: number } & BookingTemplateDataToUpdate &
  Partial<{ dateRange: CalendarRange }>;

export const updateBookingTemplate = (payload: UpdateBookingTemplatePayload) =>
  api({
    url: `booking_module/booking_templates/${payload.id}`,
    method: "PUT",
    data: omit(payload, "id")
  });

export const deleteBookingTemplate = (id?: number, payload = {}) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_templates${id ? `/${id}` : ""}`),
    method: "DELETE",
    api_version: 2,
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(payload)
  });

export const fetchBookingApprovals = (params: { [key: string]: any } = {}) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals`),
    method: "GET",
    api_version: 2,
    data: { ...params, page: params.page || 1 }
  });

export const approveBookingApproval = (id, data) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/approve`),
    method: "PUT",
    api_version: 2,
    data
  });

export const rejectBookingApproval = (id, data) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/reject`),
    method: "PUT",
    api_version: 2,
    data
  });

export const requestBookingApproval = (id, data) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/request_approval`),
    method: "PUT",
    api_version: 2,
    data
  });

const fetchProjects = (url, { page, perPage, order, filters }) =>
  fetchPaginatedCollection(
    ApiRoute(url),
    {
      page,
      perPage,
      /* @ts-ignore */
      order,
      filters: getAPIFilters(filters)
    },
    null,
    {
      method: "POST",
      contentType: "application/json; charset=utf-8",
      stringify: true
    }
  );

export const fetchProjectsEngagements = (params) =>
  fetchProjects(`/api/booking_module/engagements/index`, params);
export const fetchProjectsRoles = (params) => fetchProjects(`/api/booking_module/roles/index`, params);

export const fetchWorkforce = (page, perPage, order, filters, dateRange) =>
  fetchPaginatedCollection(
    ApiRoute(`/api/booking_module/profiles/index`),
    {
      page,
      perPage,
      /* @ts-ignore */
      order,
      filters: getAPIFilters(filters),
      date_range: dateRange
    },
    null,
    {
      method: "POST",
      contentType: "application/json; charset=utf-8",
      stringify: true
    }
  );

export const fetchAdminBookingCategories = (page, perPage, params) =>
  fetchPaginatedCollection(ApiRoute(`/api/admin/booking_module/booking_categories`), {
    page,
    perPage,
    ...params
  });

export const fetchBookingsFromAvailability = (activity_id, profile_id) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/activities/${activity_id}/query_bookings`),
    method: "GET",
    api_version: 2,
    stringify: true,
    contentType: "application/json; charset=utf-8",
    data: {
      profile_id
    }
  });
