import moment from "moment";
import { InlineRangeCalendar } from "PFComponents/calendar/inline_calendar";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AvailabilityRangeItem = ({ filter, handleChange, error, portalRef, disabled }) => {
  const { t } = useTranslation();
  const { formatISODate } = useDateFormatter();
  const value = filter.value || {};
  const { start, end } = value;

  const availabilityFromToday = (number, string) => ({
    start: formatISODate(),
    end: formatISODate(moment().add(number, string))
  });

  const { data: currentAccount } = useCurrentAccount();

  const availabilityMatchMonthsLimit = currentAccount?.availability?.match_months_limit;

  const options = useState([
    {
      id: "week",
      item: availabilityFromToday(1, "weeks"),
      displayElement: t("calendar.ranges.nextNWeeks", { count: 1 })
    },
    {
      id: "two_weeks",
      item: availabilityFromToday(2, "weeks"),
      displayElement: t("calendar.ranges.nextNWeeks", { count: 2 })
    },
    {
      id: "one_month",
      item: availabilityFromToday(1, "months"),
      displayElement: t("calendar.ranges.nextNMonths", { count: 1 })
    },
    {
      id: "two_months",
      item: availabilityFromToday(2, "months"),
      displayElement: t("calendar.ranges.nextNMonths", { count: 2 })
    },
    {
      id: "six_months",
      item: availabilityFromToday(6, "months"),
      displayElement: t("calendar.ranges.nextNMonths", { count: 6 })
    }
  ])[0];

  return (
    <div style={{ padding: "0px 10px", margin: "0px 10px", position: "relative" }}>
      <InlineRangeCalendar
        startDate={start ? formatISODate(start) : null}
        minDate={formatISODate()}
        maxDate={formatISODate(moment().add(availabilityMatchMonthsLimit || 6, "months"))}
        endDate={end ? formatISODate(end) : null}
        label={filter.title}
        style={{ marginLeft: -10 }}
        handleChange={(start, end) =>
          handleChange({
            start: formatISODate(start),
            end: formatISODate(end)
          })
        }
        options={options}
        error={error}
        portalRef={portalRef}
        disabled={disabled}
      />
    </div>
  );
};

AvailabilityRangeItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf(["availability_range"]),
    value: PropTypes.oneOfType([
      PropTypes.oneOf([""]), // empty string is valid and consider null
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
      })
    ])
  }).isRequired,
  error: PropTypes.string,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default AvailabilityRangeItem;
