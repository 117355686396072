import { RefObject, useCallback, useState } from "react";
import { useMount, useUnmount } from "react-use";

type UseModalVisibility = {
  dialogRef: RefObject<HTMLDialogElement>;
  onClose?: VoidFunction;
  onBeforeClose?: (closeFunction: VoidFunction) => boolean;
};

const ANIMATION_DURATION_MS = 500;

export const useModalVisibility = ({ dialogRef, onClose, onBeforeClose }: UseModalVisibility) => {
  const [fadeIn, setFadeIn] = useState(false);
  // renderContent boolean fixes cases when there is some some element height calculation in the modal content
  // When rendering content before ref.showModal(), elements have 0 height
  const [renderContent, setRenderContent] = useState(false);

  const closeWithAnimation = useCallback(
    (withBeforeClose = true) => {
      const doClose = () => {
        setFadeIn(false);
        setTimeout(() => {
          setRenderContent(false);
          onClose?.();
        }, ANIMATION_DURATION_MS);
      };

      if (withBeforeClose && onBeforeClose?.(doClose) === false) {
        return;
      }

      doClose();
    },
    [onBeforeClose, onClose]
  );

  useMount(() => {
    setFadeIn(true);
    dialogRef.current?.showModal();
    setRenderContent(true);
  });

  useUnmount(() => {
    dialogRef.current?.close();
  });

  return {
    fadeIn,
    renderContent,
    closeWithAnimation
  };
};
