import classNames from "classnames";

import { Icon } from "../icon";
import { Typography } from "../typography";
import { DropdownOption, GroupDropdownOption } from "./dropdown";
import css from "./dropdown_group.module.scss";
import { DropDownItem } from "./dropdown_item";

type DropdownGroupProps = {
  onChange: (value: any, item: DropdownOption | DropdownOption[]) => void;
  dropDownClassName?: string;
  checkIsSelected: (value: DropdownOption, index: number) => boolean;
  checkIsHovered: (index: number) => boolean;
  checkIsSelectedByKey: (value: DropdownOption) => boolean;
  indexOffset: number;
} & GroupDropdownOption;

export const DropdownGroup = ({
  label,
  options,
  disabled,
  indexOffset,
  onChange,
  checkIsSelected,
  checkIsHovered,
  checkIsSelectedByKey
}: DropdownGroupProps) => (
  <div className={css.groupContainer}>
    {!!label && (
      <div className={css.groupTitleContainer}>
        <Typography variant="labelRegular" tag="span" className={css.groupTitle}>
          {label}
        </Typography>
      </div>
    )}

    {options.map((option, index) => {
      const groupOption = { ...option, disabled };
      const absoluteIndex = indexOffset + index;

      return (
        <div key={option.id} className={css.dropdownItemContainer}>
          {checkIsSelected(groupOption, index) && (
            <div className={css.dropdownItemCheckContainer}>
              <Icon name="check" size="sm" opacity={disabled ? 0.5 : 1} />
            </div>
          )}
          <div className={classNames(css.dropdownItem, { [css.disabled]: disabled })}>
            <DropDownItem
              value={groupOption}
              selected={checkIsSelected(groupOption, index) || checkIsSelectedByKey(groupOption)}
              hovered={checkIsHovered(absoluteIndex)}
              handleChange={(item) => onChange(groupOption, item)}
              tooltipMsg={option.tooltipMsg}
              highlightSelected={false}
            />
          </div>
        </div>
      );
    })}
  </div>
);
