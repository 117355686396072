import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { CreateBookingPayload } from "PFApp/booking/services/api";

import { getProfileActivityOrSearchObject, mapBasicSingleBooking } from "./get_payload.utils";
import { isBookingFormForSingleBookingCreateModeValid } from "./validate";

export type GetPayloadForCreateSingle = { values: BookingFormValues };

export const getPayloadForCreateSingle = ({ values }: GetPayloadForCreateSingle): CreateBookingPayload => {
  if (!isBookingFormForSingleBookingCreateModeValid(values)) {
    throw new Error("Invalid form values for creating a booking!");
  }
  const {
    bookings: formBookings,
    globalCategory,
    globalTitle,
    globalOverridesNonWorkingTime,
    globalOverridesDiaryTime,
    globalDescription
  } = values;

  const profileActivityOrSearchObject = getProfileActivityOrSearchObject(values);
  const bookings = formBookings.map((formBooking) => ({
    ...mapBasicSingleBooking(formBooking),
    bookingCategoryId: globalCategory!.id,
    ...(globalTitle ? { title: globalTitle } : {}),
    ...(globalDescription ? { description: globalDescription } : {}),
    overridesCalendar: globalOverridesDiaryTime!,
    overridesNonWorkingDays: globalOverridesNonWorkingTime!
  }));

  return {
    ...profileActivityOrSearchObject,
    sourceType: "ui",
    bookings
  };
};
