import { Booking, BookingCategory, Profile } from "PFTypes";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { BookingSelectOption } from "../booking_questions/booking_questions";
import { UpdateQuestionsModal } from "../booking_questions/update_questions_modal";
import { BookingForm, BookingFormProps } from "./booking_form";
import { useBookingFormContext } from "./booking_form_context_provider";
import { useInitialActivityId, useInitialBookingType, useInitialGlobalCategory } from "./initial_data";
import { BookingDataItem, BookingFormMode } from "./use_booking_form";
import { useHandleSubmit } from "./use_handle_submit";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";
import { GroupBookingOption } from "./workforce_member_select/workforce_member_select_dropdown";

const DEFAULT_FORM_VALUES = {
  updateGroupOptionSelected: BookingSelectOption.This,
  updateRepeatOptionSelected: BookingSelectOption.All,
  globalOverridesDiaryTime: true,
  globalOverridesNonWorkingTime: false
};

export enum BookingType {
  Single = "single",
  Repeated = "repeated"
}

type BookingFormWorkforceMember = Profile | GroupBookingOption;

export type BookingFormValues = {
  workforceMember?: BookingFormWorkforceMember;
  activityId?: number;
  bookingType: BookingType;
  bookings: BookingDataItem[];
  globalTitle?: string;
  globalDescription?: string;
  globalCategory?: BookingCategory | null;
  globalOverridesDiaryTime: boolean;
  globalOverridesNonWorkingTime: boolean;
  updateGroupOptionSelected: BookingSelectOption;
  updateRepeatOptionSelected: BookingSelectOption;
};

type BookingFormProviderProps = BookingFormProps & {
  initialBooking?: Booking;
  onSuccess?: (data?: OnSuccessData) => Promise<void>;
};

export const BookingFormProvider = ({
  show,
  initialData = {},
  initialBooking,
  onSuccess,
  mode
}: BookingFormProviderProps) => {
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);

  const {
    modal: { close }
  } = useBookingFormContext();

  const isCreateMode = mode === BookingFormMode.Create;

  const methods = useForm<BookingFormValues>({
    defaultValues: DEFAULT_FORM_VALUES
  });
  useInitialActivityId({
    setValue: methods.setValue
  });
  useInitialGlobalCategory({
    enabled: isCreateMode,
    setValue: methods.setValue
  });
  useInitialBookingType({
    initialBooking,
    setValue: methods.setValue
  });

  const handleClose = () => {
    methods.reset();
    close();
  };

  const { handleSubmit } = useHandleSubmit({
    initialData,
    mode,
    onSuccess: (data) => {
      onSuccess?.(data);
      handleClose();
    },
    onError: () => handleClose(),
    formMethods: methods
  });

  const handlePreSubmitActions = async (values: BookingFormValues) => {
    const { bookingType } = methods.getValues();
    const isGrouped = !!initialBooking?.booking_group_id;
    const isRepeated = bookingType === BookingType.Repeated;
    if (mode === BookingFormMode.Edit && (isGrouped || isRepeated)) {
      setShowQuestionsModal(true);
    } else {
      await handleSubmit(values);
    }
  };

  const handleFormSubmit = methods.handleSubmit(handlePreSubmitActions);
  const handleConfirmSubmit = methods.handleSubmit(handleSubmit);

  return (
    <FormProvider {...methods}>
      <form id="booking_form" onSubmit={handleFormSubmit}>
        <BookingForm show={show} initialData={initialData} mode={mode} onClose={handleClose} />
      </form>
      {showQuestionsModal && (
        <UpdateQuestionsModal
          onClose={() => setShowQuestionsModal(false)}
          onConfirm={handleConfirmSubmit}
          initialBooking={initialBooking}
        />
      )}
    </FormProvider>
  );
};
