import { Markdown } from "PFComponents/markdown";
import { Modal } from "PFComponents/modal";
import { useTranslation } from "react-i18next";

import css from "./summary_modal.module.scss";

type SummaryModalProps = {
  summary: string;
  onClose: VoidFunction;
};

export const SummaryModal = ({ onClose, summary }: SummaryModalProps) => {
  const { t } = useTranslation(["translation", "profiles"]);

  return (
    <Modal
      title={t("profiles:show.parts.profileSummary")}
      labelCancel={t("translation:close")}
      onClose={onClose}
      classes={{ modal: css.modal }}
      showOKButton={false}
    >
      <Markdown raw={summary} crop={1e10} emojify={false} />
    </Modal>
  );
};
