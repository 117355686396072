import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { RecentSearch } from "PFTypes/recent_searches";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EntityRow } from "../parts/entity_row";
import { SearchDropdownItem } from "../search_autocomplete.types";

type UseAutocompleteRecentSearchesOptions = {
  recentSearches: RecentSearch[];
};

export const useAutocompleteRecentSearchesOptions = ({
  recentSearches
}: UseAutocompleteRecentSearchesOptions) => {
  const { t } = useTranslation();

  const recentSearchesOptions = useMemo(
    () =>
      recentSearches.map<DropdownOption>(({ value, timestamp }) => {
        const id = `recent-${timestamp}-${value}`;
        const item: SearchDropdownItem = {
          text: value
        };

        return {
          id,
          item,
          displayElement: <EntityRow key={id} title={value} iconName="search" redirect={false} />,
          ariaLabel: `${t("recentSearch")}: ${value}`
        };
      }),
    [recentSearches, t]
  );

  return recentSearchesOptions;
};
