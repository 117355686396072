import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { CreateBookingTemplatePayload } from "PFApp/booking/services/api";

import { getProfileOrSearchObject, mapBasicBookingTemplate } from "./get_payload.utils";
import { isBookingFormForBookingTemplateCreateModeValid } from "./validate";

export type GetPayloadsForCreateBookingTemplate = { values: BookingFormValues };

export const getPayloadsForCreateBookingTemplate = ({
  values
}: GetPayloadsForCreateBookingTemplate): CreateBookingTemplatePayload[] => {
  if (!isBookingFormForBookingTemplateCreateModeValid(values)) {
    throw new Error("Invalid form values for creating a booking template!");
  }
  const {
    bookings: formBookings,
    globalCategory,
    globalTitle,
    globalOverridesNonWorkingTime,
    globalOverridesDiaryTime,
    globalDescription
  } = values;

  const profileOrSearchObject = getProfileOrSearchObject(values);

  return formBookings.map((formBooking) => ({
    ...profileOrSearchObject,
    ...mapBasicBookingTemplate(formBooking),
    bookingCategoryId: globalCategory!.id,
    overridesCalendar: globalOverridesDiaryTime!,
    overridesNonWorkingDays: globalOverridesNonWorkingTime!,
    ...(globalTitle ? { title: globalTitle } : {}),
    ...(globalDescription ? { description: globalDescription } : {})
  }));
};
