import pick from "lodash/pick";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { EngagementSelect } from "PFApp/components/engagement_select";
import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useEngagementQuery } from "PFApp/hooks/use_engagement_query";
import { LoadingDots } from "PFComponents/loading_dots";
import { ActivitiesResponse } from "PFCore/services/activities";
import { Engagement, Template } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { truncateTitle } from "../booking_form_common_inputs";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";

type SimpleEngagement = Pick<Engagement, "id" | "name" | "description">;

const parseEngagementToOption = (activity) => ({
  id: activity.id,
  name: activity.name,
  description: activity.description
});

type EngagementSelectorProps = {
  titleFormValueName: keyof BookingFormValues;
  mode: BookingFormMode;
};

export const EngagementSelector = ({ titleFormValueName, mode }: EngagementSelectorProps) => {
  const { activity, isLoading } = useBookingActivityContext();
  const engagementTemplate = useTemplate(TEMPLATE_KEYS.ENGAGEMENT as Template["key"]);
  const isInitialActivityEngagement = activity && engagementTemplate?.id === activity.template_id;
  const [selectedEngagement, setSelectedEngagement] = useState<SimpleEngagement | null>(null);
  const { control, setValue } = useFormContext();
  const [title, bookingType] = useWatch<BookingFormValues>({ name: [titleFormValueName, "bookingType"] });
  const isRepeated = bookingType === BookingType.Repeated;

  const { engagementSelectQuery, engagementSelectParseResponse } = useEngagementQuery({
    isAudit: false
  });

  const handleChange = useCallback(
    (engagementOption: SimpleEngagement, onChange: (value: any) => void) => {
      const isTitlePreviousEngagementName = selectedEngagement?.name === title;

      if (engagementOption && (!title || isTitlePreviousEngagementName)) {
        setValue(titleFormValueName, truncateTitle(engagementOption.name));
      }
      setSelectedEngagement(engagementOption);
      onChange(engagementOption?.id);
    },
    [selectedEngagement, title, setValue]
  );

  useEffect(() => {
    const initialSelectedEngagement = isInitialActivityEngagement
      ? pick(activity, ["id", "name", "description"])
      : null;
    setSelectedEngagement(initialSelectedEngagement);
  }, [activity]);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <Controller
      name="activityId"
      control={control}
      render={({ field }) => (
        <EngagementSelect<ActivitiesResponse, SimpleEngagement>
          selectedEngagement={selectedEngagement}
          letClear={!isInitialActivityEngagement || mode !== BookingFormMode.Edit}
          query={engagementSelectQuery}
          parseResponse={engagementSelectParseResponse}
          handleChange={(option) => {
            handleChange(option, field.onChange);
          }}
          parseEngagementToOption={parseEngagementToOption}
          isRepeatedBooking={isRepeated}
        />
      )}
    />
  );
};
