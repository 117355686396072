import { Collection, PureCustomValue } from "PFTypes";

import api from "../../api";

export type DiscoveredSkillsResponse = Collection<PureCustomValue[]>;

export const fetchDiscoveredSkills = (): Promise<DiscoveredSkillsResponse> =>
  api({
    url: "discovered_skills",
    method: "GET"
  });
