import classNames from "classnames";

import { Modal, ModalProps } from "../modal";
import css from "./legacy_modal.module.scss";

// TODO: [PROF-6920] Replace LegacyModal usages with Modal

/**
 * @deprecated Use Modal instead
 */
export const LegacyModal = ({
  classes = {},
  ...props
}: Omit<ModalProps, "showCancelButton" | "showOKButton" | "footerLabel" | "title">) => (
  <Modal
    {...props}
    classes={{ ...classes, modal: classNames(css.modal, classes.modal) }}
    showCancelButton={false}
    showOKButton={false}
    footerLabel={undefined}
    title={null}
  />
);
