import classNames from "classnames";
import moment from "moment";
import isIOS from "PFCore/helpers/ios";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { lpad } from "underscore.string";

import css from "./calendar_date.module.scss";

export const getDayClass = (config, ...classes) =>
  classNames(
    css.day,
    {
      [css.today]: config.today,
      [css.prevBusy]: config.prevBusy,
      [css.nextBusy]: config.nextBusy,
      [css.nonWorking]: config.nonWorking,
      [css.pending]: config.pending,
      [css.partial]: config.partial,
      [css.hovered]: config.hovered,
      [css.disabled]: config.disabled,
      [css.selected]: config.selected,
      [css.keyboardSelected]: config.keyboardSelected,
      [css.readOnly]: config.readOnly,
      [css.notCurrentMonth]: config.notCurrentMonth,
      [css.hardKind]: config.hardKind,
      [css.softKind]: config.softKind,
      [css.otherKind]: config.otherKind,
      [css.freeKind]: config.freeKind,
      [css.offLimit]: config.offLimit,
      [css.plannedKind]: config.plannedKind,
      [css.noneKind]: config.noneKind // just for the legend
    },
    ...classes
  );

const CalendarDate = ({
  date,
  handleClick,
  handleMouseLeave,
  handleMouseEnter,
  handleKeyDown,
  prevBusy,
  nextBusy,
  highlighted,
  hovered,
  disabled,
  selected,
  keyboardSelected,
  readOnly,
  isCurrentMonth,
  matchMaxDate
}) => {
  const dateCellRef = useRef(null);

  const isToday = date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
  // NOTE: if you bind to mouseover ios simulates that with a click (you'd need to doubleclick to get a click)
  const _handleMouseLeave = isIOS() ? null : () => handleMouseLeave && handleMouseLeave(date);
  const _handleMouseEnter = isIOS() ? null : () => handleMouseEnter && handleMouseEnter(date);
  const _handleClick = (event) => !disabled && handleClick && handleClick(date, event);
  const dateNumber = lpad(date.get("date"), 2, "0");

  if (!Array.isArray(highlighted)) {
    highlighted = [highlighted];
  }

  const classConfig = {
    today: isToday,
    prevBusy,
    nextBusy,
    busy: highlighted.includes(true),
    hovered,
    disabled,
    selected,
    keyboardSelected,
    readOnly,
    offLimit: matchMaxDate && date > moment.utc(matchMaxDate),
    notCurrentMonth: !isCurrentMonth
  };

  // partial, pending, nonWorking, *Kind
  highlighted.forEach((item) => {
    if (typeof item === "string") {
      classConfig[item] = true;
    }
  });

  useEffect(() => {
    if (keyboardSelected) {
      dateCellRef.current.focus();
    }
  }, [keyboardSelected]);

  return (
    <span
      ref={dateCellRef}
      role="gridcell"
      aria-current={isToday ? "date" : null}
      tabIndex={selected || keyboardSelected ? 0 : -1}
      data-date={date.format("YYYY-MM-DD")}
      onClick={_handleClick}
      onKeyDown={handleKeyDown}
      onMouseLeave={_handleMouseLeave}
      onBlur={_handleMouseEnter}
      onMouseEnter={_handleMouseEnter}
      onFocus={_handleMouseEnter}
      className={getDayClass(classConfig)}
      data-highlighted={highlighted}
      data-not-current-month={!isCurrentMonth}
    >
      <span>{dateNumber}</span>
    </span>
  );
};

CalendarDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(window.moment)]).isRequired,
  isCurrentMonth: PropTypes.bool.isRequired,
  matchMaxDate: PropTypes.string,

  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  keyboardSelected: PropTypes.bool,
  highlighted: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),

  prevBusy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nextBusy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hovered: PropTypes.bool,
  readOnly: PropTypes.bool,

  handleClick: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  handleKeyDown: PropTypes.func
};

CalendarDate.defaultProps = {
  disabled: false,
  selected: false,
  highlighted: false,
  hovered: false,
  readOnly: false
};

export default CalendarDate;
