import { BookingNotPermittedMessage } from "PFApp/booking/components/booking_not_permitted_message";
import { Tab, Tabs } from "PFComponents/tabs";
import { useBookingNotesInfinite } from "PFCore/hooks/queries/bookings/notes/use_booking_notes";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking, BookingTemplate } from "PFTypes";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { BookingHistory } from "../../booking_history";
import { BookingNotes } from "../../booking_notes";
import { useBookingNotesPermissions } from "../../booking_notes/use_booking_notes_permissions";
import css from "./single_booking.module.scss";
import { SingleBookingDetails } from "./single_booking_details";

type TabId = "details" | "notes" | "history";

type SingleBookingProps = {
  booking: Booking;
  bookingTemplate?: BookingTemplate;
};

export const SingleBooking = ({ booking, bookingTemplate }: SingleBookingProps) => {
  const { t } = useTranslation("bookingModule");
  const { canCreate: canCreateBookingNote } = useBookingNotesPermissions(booking);

  const [selectedTabId, setSelectedTabId] = useState<TabId>("details");

  const { getCategory } = useBookingCategories();
  const category = booking ? getCategory(booking) : undefined;

  const { data: notesData } = useBookingNotesInfinite(booking.profile_id, booking.id, 15, {
    enabled: !!booking.id && !!category
  });

  if (!category) {
    return <BookingNotPermittedMessage />;
  }

  const tabs: Tab<TabId>[] = [
    {
      id: "details",
      title: t("details.detailsTitle")
    },
    {
      id: "notes",
      title: t("details.notesTitle"),
      count: notesData?.pages?.[0]?.meta?.total ?? 0
    },
    {
      id: "history",
      title: t("details.historyTitle")
    }
  ];

  const TAB_CONTENT: Record<TabId, ReactNode> = {
    details: <SingleBookingDetails booking={booking} bookingTemplate={bookingTemplate} category={category} />,
    notes: (
      <BookingNotes readonly={!canCreateBookingNote} bookingId={booking.id} profileId={booking.profile_id} />
    ),
    history: <BookingHistory profileId={booking.profile_id} bookingId={booking.id} />
  };

  return (
    <>
      <Tabs<TabId>
        tabs={tabs}
        activeTab={selectedTabId}
        onChange={(id) => setSelectedTabId(id)}
        className={css.tabs}
      />
      {TAB_CONTENT[selectedTabId]}
    </>
  );
};
