import { datadogRum } from "@datadog/browser-rum";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useEffect } from "react";

export const useDataDogRum = () => {
  const { data: currentProfile, isSignedIn } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();

  useEffect(() => {
    if (!PF.config.datadog_client_token || !PF.config.datadog_application_id || !PF.config.datadog_site) {
      return;
    }

    let version = PF.config.ui_version;
    if (PF.config.environment === "multistaging") {
      version = window.location.hostname.split(".")[0];
    }
    datadogRum.init({
      applicationId: PF.config.datadog_application_id,
      clientToken: PF.config.datadog_client_token,
      site: PF.config.datadog_site,
      service: "ui",
      env: PF.config.datadog_environment,
      version: version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingOrigins: [
        /https:\/\/.*\.profinda\.com/,
        /https:\/\/.*\.profinda-staging\.com/,
        /https:\/\/.*\.profinda-integration\.com/,
        /https:\/\/.*\.profinda-uat\.com/,
        /https:\/\/.*\.profinda-uat\.us/,
        /https:\/\/.*\.profinda\.us/
      ]
    });

    datadogRum.startSessionReplayRecording();
  }, [
    PF.config.environment,
    PF.config.datadog_environment,
    PF.config.datadog_client_token,
    PF.config.datadog_application_id,
    PF.config.datadog_site
  ]);

  useEffect(() => {
    if (isSignedIn && currentAccount.id) {
      dataDogSetUser(currentProfile, currentAccount);
    }
  }, [isSignedIn, currentAccount?.id]);
};

export const dataDogSetUser = (profile, account) => {
  const { permissions_group: permissionsGroup } = profile;
  datadogRum.setUser({
    id: profile.id,
    name: getProfileName(profile),
    email: profile.email,
    domain: account.full_domain,
    permissions_group_id: permissionsGroup.id,
    permissions_group_name: permissionsGroup.name
  });
};
export const dataDogRemoveUser = () => datadogRum.removeUser();
