import { Camelized, camelizeKeys } from "humps";
import { last } from "lodash";
import { OverbookingPotentialBooking } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import { getDaysDifference } from "PFApp/booking/parts/overview/calendar/calendar.utils";
import { Overbooking } from "PFApp/booking/types";
import { useThresholds } from "PFCore/hooks/queries/bookings/thresholds/use_thresholds";
import { Booking } from "PFTypes";
import { useMemo } from "react";

export const sortOverbookings = <T extends { date: string }>(overbookings?: T[]): T[] =>
  (overbookings ?? [])
    .map((item) => ({ ...item, timestamp: new Date(item.date).getTime() }))
    .sort(({ timestamp: t1 }, { timestamp: t2 }) => t1 - t2);

type UseOverbookingRangesProps = {
  bookings?: ((Partial<Booking> & { id: number }) | OverbookingPotentialBooking)[];
  overbookings: Overbooking[];
};

export type OverbookingsRange = {
  start: string;
  end: string;
  overbookings: Camelized<Overbooking>[];
  bookings: ((Partial<Booking> & { id: number }) | OverbookingPotentialBooking)[];
};

export const useOverbookingRanges = ({ bookings, overbookings }: UseOverbookingRangesProps) => {
  const thresholds = useThresholds(["overbooking"]);

  const rangesWithBookings: OverbookingsRange[] = useMemo(() => {
    if (!bookings?.length) {
      return [];
    }
    const sortedOverbookings = sortOverbookings(camelizeKeys(overbookings) as Camelized<Overbooking>[]);
    const intervals: Omit<OverbookingsRange, "bookings">[] = sortedOverbookings.reduce(
      (acc: OverbookingsRange[], curr) => {
        const { availableMinutes, date, utilization } = curr;
        const lastRange = last(acc);
        if (!lastRange) {
          return [{ start: date, end: date, overbookings: [curr] }];
        }
        const currentRangeAvailableMinutes = lastRange.overbookings[0].availableMinutes;
        const currentRangeUtilization = lastRange.overbookings[0].utilization;
        const currentRangeLastDate = last(lastRange.overbookings)!.date;
        const currentRangeThreshold = thresholds?.overbooking?.find(
          (item) => currentRangeUtilization * 100 >= item.min && currentRangeUtilization * 100 <= item.max
        );
        const currentItemThreshold = thresholds?.overbooking?.find(
          (item) => utilization * 100 >= item.min && utilization * 100 <= item.max
        );
        const daysDiff = getDaysDifference(new Date(currentRangeLastDate), new Date(date));
        const isNextInRange =
          daysDiff === 1 &&
          currentRangeAvailableMinutes === availableMinutes &&
          currentRangeThreshold === currentItemThreshold;
        if (isNextInRange) {
          return [
            ...acc.slice(0, -1),
            {
              ...lastRange,
              end: date,
              overbookings: [...lastRange.overbookings, curr]
            }
          ];
        }
        return [...acc, { start: date, end: date, overbookings: [curr] }];
      },
      []
    );
    const overbookingRanges: OverbookingsRange[] = intervals.map(({ start, end, overbookings }) => {
      const filteredBookings =
        (bookings || []).filter(
          ({ start_date, end_date }) =>
            start_date &&
            end_date &&
            new Date(start).getTime() <= new Date(end_date).getTime() &&
            new Date(start_date).getTime() <= new Date(end).getTime()
        ) || [];
      return { start, end, bookings: filteredBookings, overbookings };
    });

    return overbookingRanges;
  }, [overbookings, bookings]);

  return { rangesWithBookings };
};
