import { RadioItem, Radios } from "PFComponents/radios";
import React from "react";
import { useTranslation } from "react-i18next";

export enum BookingDeleteAllOption {
  ReopenVacancy = "ReopenVacancy",
  KeepVacancy = "KeepVacancy"
}

type DeleteAllBookingsQuestionProps = {
  value: BookingDeleteAllOption;
  onChange: (selectedOption: BookingDeleteAllOption) => void;
  profileName: string;
};

export const DeleteAllBookingsQuestion = ({
  value,
  onChange,
  profileName
}: DeleteAllBookingsQuestionProps) => {
  const { t } = useTranslation("bookingModule");
  const DELETE_ALL_OPTIONS: RadioItem<BookingDeleteAllOption>[] = [
    {
      id: BookingDeleteAllOption.ReopenVacancy,
      label: t("bookings.delete.body.resetShortlist.reopenVacancy")
    },
    {
      id: BookingDeleteAllOption.KeepVacancy,
      label: t("bookings.delete.body.resetShortlist.keepVacancy", { profileName })
    }
  ];

  return (
    <Radios<BookingDeleteAllOption>
      name="vacancyQuestion"
      items={DELETE_ALL_OPTIONS}
      value={value}
      onChange={onChange}
    />
  );
};
