import { BookingFormContextProvider } from "PFApp/booking/components/booking_form";
import ProfilePreviewSkills from "PFApp/components/profile_preview/parts/profile_preview_skills";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import { isAdmin } from "PFCore/helpers/profile";
import { useFocusableElements } from "PFCore/helpers/use_focusable_elements";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { fetchProfileAsAdmin } from "PFCore/services/profile";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import ProfilePreviewActionButtons from "./parts/profile_preview_action_buttons";
import ProfilePreviewAvailability from "./parts/profile_preview_availability";
import ProfilePreviewBasicInfo from "./parts/profile_preview_basic_info";
import { ProfilePreviewContact } from "./parts/profile_preview_contact";
import ProfilePreviewCustomFields from "./parts/profile_preview_custom_fields";
import { ProfilePreviewSummary } from "./parts/profile_preview_summary";
import css from "./profile_preview.module.scss";

export const ProfilePreview = () => {
  const { data: currentProfile } = useCurrentProfile();
  const {
    unmountPreview,
    previewState: { id, ready, show, isClosing },
    completePreview
  } = useProfilePreview();
  const { pathname } = useLocation();
  const { onSidePanelClose: closeWithAnimation } = useSidePanelClose({
    onClose: unmountPreview
  });

  useEffect(() => {
    if (isClosing) {
      closeWithAnimation();
    }
  }, [isClosing, closeWithAnimation]);

  useEffect(() => {
    closeWithAnimation();
  }, [pathname, closeWithAnimation]);

  const profilePreviewRef = useRef<HTMLDivElement>(null);
  const getFocusableElements = useFocusableElements(profilePreviewRef.current);

  const admin = isAdmin(currentProfile);

  const { data: profile, isSuccess } = useProfile(id, { enabled: !!(id && show && !ready) });

  useEffect(() => {
    if (isSuccess && !ready) {
      completePreview(profile);
      setTimeout(() => {
        const focusableElements = getFocusableElements();
        focusableElements?.[0] && focusableElements[0]?.focus();
      }, 200);
    }
  }, [isSuccess, profile, ready, completePreview, getFocusableElements]);

  const [masterExpand, setMasterExpand] = useState(false);

  return (
    <BookingFormContextProvider fetchProfile={admin ? fetchProfileAsAdmin : undefined} profileId={id}>
      <div ref={profilePreviewRef}>
        <SidePanel
          show={show}
          title={
            <div>
              <ProfilePreviewBasicInfo />
              <ProfilePreviewActionButtons />
            </div>
          }
          isSidePanelClosing={isClosing}
          onClose={unmountPreview}
          fullHeight
        >
          <div className={css.content}>
            <ProfilePreviewContact />
            <ProfilePreviewAvailability />
            <ProfilePreviewSkills masterExpand={masterExpand} setMasterExpand={setMasterExpand} />
            <ProfilePreviewCustomFields only={["grade", "location", "brands", "sectors"]} />
            <ProfilePreviewSummary />

            {!ready && <LoadingDots className={css.loading} />}
          </div>
        </SidePanel>
      </div>
    </BookingFormContextProvider>
  );
};
