import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { UpdateBookingsPayload } from "PFApp/booking/services/api";
import { Booking, Profile } from "PFTypes";

import { isNotLimited } from "../use_booking_payload";
import { mapBasicSingleBooking } from "./get_payload.utils";
import { isBookingFormForSingleBookingEditModeValid } from "./validate";

export type GetPayloadForEditMultipleBookingsOfSingleType = {
  values: BookingFormValues;
};

export const getPayloadForEditMultipleBookingsOfSingleType =
  (initialBooking?: Booking) =>
  ({ values }: GetPayloadForEditMultipleBookingsOfSingleType): UpdateBookingsPayload => {
    const bookingsToProcess = values.bookings.filter(isNotLimited);
    if (!isBookingFormForSingleBookingEditModeValid({ ...values, bookings: bookingsToProcess })) {
      throw new Error("Invalid form values for editing a booking!");
    }
    const { workforceMember, activityId } = values;

    const bookings = bookingsToProcess.map((formBooking) => {
      const { id, category, title, description, overridesDiaryTime, overridesNonWorkingTime, phaseSourceId } =
        formBooking;
      return {
        ...mapBasicSingleBooking(formBooking),
        id: id!,
        activityId,
        bookingCategoryId: category!.id,
        ...(title ? { title } : {}),
        ...(description ? { description } : {}),
        overridesCalendar: overridesDiaryTime!,
        overridesNonWorkingDays: overridesNonWorkingTime!,
        phaseSourceId
      };
    });

    return {
      profileId: (workforceMember as Profile)?.id,
      activityId: initialBooking?.activity?.id,
      bookings
    };
  };
