import { Modal } from "PFComponents/modal";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import WarningIcon from "PFIcons/warning.svg";
import { Booking, BookingTemplate } from "PFTypes";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getDaysDifference } from "../../parts/overview/calendar/calendar.utils";
import { BookingFormValues, BookingType } from "../booking_form/booking_form_provider";
import { GroupBookingsQuestions, GroupBookingsQuestionsMode } from "./group_bookings_questions";
import { SpecificTimeBookingsQuestions } from "./specific_time_bookings_questions";
import css from "./update_questions_modal.module.scss";

const checkIsSingleDayBookingTemplate = (bookingTemplate?: BookingTemplate) =>
  bookingTemplate &&
  getDaysDifference(new Date(bookingTemplate.start_date), new Date(bookingTemplate.end_date)) <= 0;

type UpdateBookingModalProps = {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  initialBooking?: Booking;
};

export const UpdateQuestionsModal = ({ onClose, onConfirm, initialBooking }: UpdateBookingModalProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { control } = useFormContext<BookingFormValues>();

  const { data: bookingTemplate } = useBookingTemplate(initialBooking?.booking_template_id, {
    enabled: !!initialBooking?.booking_template_id
  });

  const [watchBookingType, watchWorkforceMember] = useWatch<BookingFormValues>({
    name: ["bookingType", "workforceMember"]
  });

  const isRepeated = watchBookingType === BookingType.Repeated;
  const isGrouped = !!initialBooking?.booking_group_id;
  const isSingleDayBookingTemplate = checkIsSingleDayBookingTemplate(bookingTemplate);
  const profileFullName = watchWorkforceMember ? getProfileName(watchWorkforceMember) : "";

  return (
    <Modal
      title={
        <div className={css.title}>
          <WarningIcon className={css.warningIcon} width={30} height={30} />
          {t("bookingModule:bookings.update.editBooking")}
        </div>
      }
      labelOK={t("translation:continue")}
      onOK={onConfirm}
      onClose={onClose}
    >
      <div>
        {isGrouped && (
          <Controller
            name="updateGroupOptionSelected"
            control={control}
            render={({ field }) => (
              <GroupBookingsQuestions
                mode={GroupBookingsQuestionsMode.Update}
                bookingGroupId={initialBooking.booking_group_id!}
                value={field.value}
                onChange={field.onChange}
                userName={profileFullName}
              />
            )}
          />
        )}
        {isRepeated && !isSingleDayBookingTemplate && (
          <Controller
            name="updateRepeatOptionSelected"
            control={control}
            render={({ field }) => (
              <SpecificTimeBookingsQuestions
                label={t("bookingModule:bookings.update.body.question")}
                isEditMode
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </div>
    </Modal>
  );
};
