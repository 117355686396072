import { Importance } from "PFTypes";

import { useRoleMatchCustomFieldData } from "./use_role_match_custom_field_data";

export const useSupportingSkills = () => {
  const { getCustomFieldValuesOfImportance } = useRoleMatchCustomFieldData();
  const { roleCustomValues, matchCustomValues, missingMatchCustomValues } = getCustomFieldValuesOfImportance(
    "skills",
    Importance.Supporting
  );
  return {
    roleSupportingSkills: roleCustomValues,
    matchSupportingSkills: matchCustomValues,
    missingMatchSupportingSkills: missingMatchCustomValues
  };
};
