import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Id } from "PFTypes";
import { SavedFilter } from "PFTypes/saved_filters";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "./profile_item";
import css from "./save_as_view_modal.module.scss";

export enum SaveAsViewModalMode {
  Create = "create",
  Share = "share"
}

export type SaveAsViewModalInitialData = {
  viewName: string;
  mode: SaveAsViewModalMode;
  id?: Id;
};

type ProfileToShare = {
  id: number;
  profileName: string;
  email: string;
  text: string;
};

type SaveAsViewModalProps = {
  views: SavedFilter[];
  initialData: SaveAsViewModalInitialData;
  onSave: (filterName: string | null, profilesIds: number[], mode: SaveAsViewModalMode, id?: Id) => void;
  onClose: () => void;
};

export const SaveAsViewModal = ({
  views,
  initialData,
  onSave,
  onClose
}: SaveAsViewModalProps): JSX.Element => {
  const { t } = useTranslation();

  const { data: currentProfile } = useCurrentProfile();

  const [viewName, setViewName] = useState<string | null>(initialData.viewName);
  const [profilesToShare, setProfilesToShare] = useState<ProfileToShare[]>([]);

  const fetchProfiles = (term) => fetchProfilesOptions({ term });

  const viewNameUsed =
    initialData.mode !== SaveAsViewModalMode.Share
      ? !!views.find(({ name }) => name === viewName && name !== initialData.viewName)
      : false;

  const handleSave = () => {
    onSave(
      viewName?.trim() || "",
      profilesToShare.map(({ id }) => id),
      initialData.mode,
      initialData.id
    );
    setViewName(null);
  };

  const isSaveModeAcceptEnabled =
    initialData.mode === SaveAsViewModalMode.Create && viewName && !viewNameUsed && viewName.trim();

  const isShareModeAcceptEnabled =
    initialData.mode === SaveAsViewModalMode.Share &&
    viewName &&
    viewName.trim() &&
    profilesToShare.length > 0;

  const isAcceptEnabled = isSaveModeAcceptEnabled || isShareModeAcceptEnabled;

  const modalTitle =
    initialData.mode === SaveAsViewModalMode.Share
      ? t("filters.share.filterNameModalTitle")
      : t("filters.save.filterNameModalTitle");

  return (
    <Modal
      title={modalTitle}
      onOK={isAcceptEnabled ? handleSave : undefined}
      onClose={() => {
        setViewName(null);
        onClose();
      }}
      labelOK={initialData.mode === SaveAsViewModalMode.Share ? t("filters.share.shareButton") : undefined}
    >
      <InputFieldSet
        label={
          initialData.mode === SaveAsViewModalMode.Share
            ? t("filters.share.filterName")
            : t("filters.save.filterName")
        }
        value={viewName ?? ""}
        maxLength={100}
        required
        error={viewNameUsed ? t("filters.save.nameUsed") : ""}
        onChange={setViewName}
      />
      {initialData.mode === SaveAsViewModalMode.Share && (
        <>
          <AutoSelect
            label={t("filters.share.shareWith")}
            closeOnChange
            multi
            values={profilesToShare}
            query={fetchProfiles}
            parseResponse={(response) => {
              const ids = profilesToShare.map(({ id }) => id);
              return response.entries
                .filter((item) => ids.indexOf(item.id) < 0)
                .map((profile) => ({
                  id: profile.id,
                  profileName: getProfileName(profile),
                  email: profile.email
                }));
            }}
            filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
            formatOption={(item: ProfileToShare) => ({
              id: item.id,
              displayElement: <ProfileItem profileName={item.profileName} email={item.email} />,
              item: item
            })}
            handleChange={(value) => {
              const parsedValues = value.map((profile) => ({
                ...profile,
                text: profile.profileName
              }));
              setProfilesToShare(parsedValues);
            }}
            handleRemove={setProfilesToShare}
            letClear
          />
          <div className={css.modalMessageContainer}>
            <Typography variant="bodyBold" tag="span">
              {t("filters.share.informationTitle")}
            </Typography>
            <Typography variant="bodyRegular" tag="span">
              {t("filters.share.informationContent")}
            </Typography>
          </div>
        </>
      )}
    </Modal>
  );
};
