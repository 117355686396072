import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { ApiRoute } from "PFCore/utilities/routes";
import { Id } from "PFTypes/id";

import { CustomValueState, OrderOption } from "../../types";

export type FetchCustomValuesParams = {
  unapproved?: boolean;
  uncurated?: boolean;
  term?: string;
  order?: OrderOption;
  state?: CustomValueState | null;
};

export type CreateCustomValuePayload = {
  value: string;
  ordinal?: number;
};

type FetchParams = {
  page: number;
  perPage: number;
  custom_field_id: number;
  apiVersion: 1 | 2;
  approved?: boolean;
  curated_at?: boolean;
  term?: string;
};

const rootPath = (customTypeId: number) => `/api/admin/custom_fields/${customTypeId}/custom_values`;

export const fetchCustomValues = (
  customTypeId: number,
  page: number,
  perPage: number = 30,
  params: FetchCustomValuesParams
) => {
  const { unapproved, uncurated, term, order, state } = params;

  const fetchParams = {
    page,
    perPage,
    apiVersion: 1,
    ...(unapproved ? { approved: false } : {}),
    ...(uncurated ? { curated_at: false } : {}),
    ...(term ? { term } : {}),
    ...(order ? { order } : {}),
    ...(state ? { state } : {})
  } as FetchParams;

  return fetchPaginatedCollection(ApiRoute(rootPath(customTypeId)), fetchParams);
};

export const createCustomValueByField = (customTypeId: Id, data: CreateCustomValuePayload) =>
  api({
    url: `admin/custom_fields/${customTypeId}/custom_values`,
    method: "POST",
    headers: {
      apiVersion: 1
    },
    data
  });

export const setOrdinalCustomValues = (customTypeId: number, customValuesIds: number[]) =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/set_order`,
    method: "PUT",
    data: {
      customValueIds: customValuesIds
    }
  });

export const cleanupCustomValues = (customTypeId: number): Promise<void> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/cleanup`,
    method: "PUT"
  });
