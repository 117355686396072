import { isContent, isSeparator } from "../../../helpers";
import { NavSwitchItem } from "../../../types";
import { NavItemProps } from "../nav_item";
import css from "./any_nav_dropdown_item.module.scss";
import { NavDropdownItem } from "./nav_dropdown_item";

type AnyNavDropdownItemProps = {
  item: NavSwitchItem;
  closeSubMenu: NavItemProps["closeSubMenu"];
};

export const AnyNavDropdownItem = ({ item, closeSubMenu }: AnyNavDropdownItemProps) => {
  if (isSeparator(item)) {
    return <div className={css.sep} />;
  } else if (isContent(item)) {
    return <li>{item.content}</li>;
  }
  return <NavDropdownItem item={item} closeSubMenu={closeSubMenu} />;
};
