import { useBookingsUpdateMany, useBookingTemplateUpdate, useBookingUpdate } from "PFCore/hooks/queries";
import { useBookingTemplateCreate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template_create";
import { useBookingCreate } from "PFCore/hooks/queries/bookings/use_booking_create";
import { Booking } from "PFTypes";

import { BookingSelectOption } from "../../booking_questions/booking_questions";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { useBookingPayload } from "./use_booking_payload";
import { BookingResponse } from "./use_handle_submit";

type UseSubmitUpdate = {
  mode: BookingFormMode;
  initialBooking?: Booking;
};
type UseSubmitUpdateReturn = (values: BookingFormValues) => Promise<BookingResponse[]>;

export const useSubmitUpdate = ({ mode, initialBooking }: UseSubmitUpdate): UseSubmitUpdateReturn => {
  const { update: updateBooking } = useBookingUpdate();
  const { update: updateBookingsMany } = useBookingsUpdateMany();
  const { update: updateBookingTemplate } = useBookingTemplateUpdate();
  const { create: createBooking } = useBookingCreate();
  const { create: createBookingTemplate } = useBookingTemplateCreate();

  const {
    getPayloadForCreateSingle,
    getPayloadForCloneSingle,
    getPayloadsForEditGroupBookingsOfSingleType,
    getPayloadForEditMultipleBookingsOfSingleType,
    getPayloadsForCreateBookingTemplate,
    getPayloadsForEditBookingTemplate
  } = useBookingPayload({ initialBooking });

  return (values: BookingFormValues): Promise<BookingResponse[]> => {
    const { bookingType } = values;
    switch (mode) {
      case BookingFormMode.Edit: {
        if (bookingType === BookingType.Repeated) {
          const payloads = getPayloadsForEditBookingTemplate({ values });
          return Promise.all(payloads.map((payload) => updateBookingTemplate(payload)));
        }
        // in case of updating the booking for all group, we need to use single booking update endpoint
        // in case of updating bookings not for a group, we can use update_many endpoint
        const { updateGroupOptionSelected } = values;
        const isUpdatingAllGroup =
          updateGroupOptionSelected === BookingSelectOption.All && !!initialBooking?.booking_group_id;
        if (isUpdatingAllGroup) {
          const payloads = getPayloadsForEditGroupBookingsOfSingleType({
            values,
            bookingGroupId: initialBooking.booking_group_id
          });
          return Promise.all(payloads.map((payload) => updateBooking(payload)));
        } else {
          const payload = getPayloadForEditMultipleBookingsOfSingleType({
            values
          });
          return updateBookingsMany(payload).then(({ entries }) => entries);
        }
      }
      case BookingFormMode.Create: {
        if (bookingType === BookingType.Repeated) {
          const payloads = getPayloadsForCreateBookingTemplate({ values });
          return Promise.all(payloads.map((payload) => createBookingTemplate(payload)));
        } else {
          const payload = getPayloadForCreateSingle({ values });
          return createBooking(payload);
        }
      }
      case BookingFormMode.Clone: {
        const payload = getPayloadForCloneSingle({ values });
        return createBooking(payload);
      }
    }
  };
};
