import { Modal } from "PFComponents/modal";
import { useTranslation } from "react-i18next";

type PublishConfirmModalProps = {
  templateName: string;
  onOk: () => void;
  onClose: () => void;
};

export const PublishConfirmModal = ({
  templateName,
  onOk,
  onClose
}: PublishConfirmModalProps): JSX.Element => {
  const { t } = useTranslation("activities");
  return (
    <Modal title={t("edit.noRequirements")} onOK={onOk} onClose={onClose}>
      {t("edit.creatingNoRequirementsWarning", { templateName })}
    </Modal>
  );
};
