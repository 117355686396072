import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { CurrentProfile, Profile } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import { MyInformationEditModal } from "../my_information_edit_modal";
import { AvatarPreviewModal } from "./modals/avatar_preview_modal";
import { ProfileActionsMenu } from "./parts/profile_actions_menu";
import css from "./profile_avatar_section.module.scss";

type ProfileAvatarSectionProps = {
  profile: Profile | CurrentProfile;
  isMe: boolean;
  onProfileUpdate: OnProfileUpdateHandler;
};

export const ProfileAvatarSection = ({ profile, isMe, onProfileUpdate }: ProfileAvatarSectionProps) => {
  const { t } = useTranslation("profiles");

  const queryParams = useQueryParams();
  const isChatEnabled = useIsChatEnabled();

  const [renderEditModal, setRenderEditModal] = useState(false);
  const [renderPhotoModal, setRenderPhotoModal] = useState(false);

  useEffect(() => {
    if (/contact_info/.test(window.location.href) && queryParams.get("edit") === "true") {
      setRenderEditModal(true);
    }
  }, []);

  const profileName = getProfileName(profile);

  return (
    <>
      <div className={css.avatarWrap}>
        <ProfileAvatarConnected
          profile={profile}
          size={170}
          withChat={isChatEnabled}
          onClick={isMe ? () => setRenderPhotoModal(true) : null}
          aria-label={t("show.parts.openProfileAvatar", { name: profileName })}
        />
        <span className={css.profileActionsIcons}>
          {isMe && (
            <Button
              icon="edit"
              small
              kind="secondary"
              onClick={() => setRenderEditModal(true)}
              title={t("show.parts.editProfile")}
            />
          )}
          <ProfileActionsMenu profile={profile} isMe={isMe} />
        </span>
      </div>

      {renderEditModal && (
        <MyInformationEditModal
          profile={profile as CurrentProfile}
          onProfileUpdate={onProfileUpdate}
          onClose={() => setRenderEditModal(false)}
        />
      )}
      {renderPhotoModal && (
        <AvatarPreviewModal
          profile={profile}
          onClose={() => setRenderPhotoModal(false)}
          onEditClick={() => setRenderEditModal(true)}
        />
      )}
    </>
  );
};
