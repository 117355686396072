import classNames from "classnames";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import SkillRatingIcon from "PFCore/components/icons/skill_rating_icon";
import { createDictionaryConnection, fetchCustomValue } from "PFCore/services/custom_values";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCustomValueInvalidate } from "../../hooks/queries/custom_fields/use_custom_value_invalidate";
import { useCurrentProfile, useCurrentProfileSet } from "../../hooks/queries/profile";
import { ActionIcon } from "../action_icon";
import css from "./custom_values_list.module.scss";

const RatingIcon = ({ customType, customValue, isEditMode, onRateChange, color, size, disabled }) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });

  const [loading, setLoading] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { data: currentProfile } = useCurrentProfile();
  const setCurrentProfile = useCurrentProfileSet();
  const { invalidate: invalidateCustomValue } = useCustomValueInvalidate();

  const experience = customValue.experience || customValue.suggested_experience;

  const isSuggested = !experience && !customValue.top && !customValue.developmental && !customValue.rare;

  const updateCurrentProfileCustomValues = (fullCustomValue, newExperience) => {
    const customValueIndex = currentProfile[customType.name].findIndex(
      (profileCustomValue) => fullCustomValue.id === profileCustomValue.id
    );
    const profileCustomValues = [...currentProfile[customType.name]];
    if (customValueIndex >= 0) {
      profileCustomValues[customValueIndex].experience = newExperience;
    } else {
      profileCustomValues.push({ ...fullCustomValue, experience: newExperience });
    }

    setCurrentProfile({ ...currentProfile, [customType.name]: profileCustomValues });
  };

  const handleWeightChange = (experience) => {
    setLoading(true);
    Promise.all([
      fetchCustomValue(customValue.id, currentProfile.id),
      createDictionaryConnection({
        experience,
        interest: true,
        source: isSuggested ? "skill_suggestion" : null,
        customValueId: customValue.id
      })
    ]).then(([fullCustomValue]) => {
      updateCurrentProfileCustomValues(fullCustomValue, experience);
      invalidateCustomValue(customValue.id, currentProfile.id);
      onRateChange && onRateChange(customValue);
      setLoading(false);
    });
  };

  const updateExperience = (experience) => {
    setIsTooltipVisible(false);
    if (isEditMode) {
      handleWeightChange(experience);
    }
  };

  if (isEditMode && isSuggested) {
    return (
      <Tooltip
        content={
          <div>
            <div className={css.suggestionTooltipHeader}>{t("prompt")}</div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(1)}
              >
                {t("basic")}
              </Button>
            </div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(3)}
              >
                {t("intermediate")}
              </Button>
            </div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(5)}
              >
                {t("advanced")}
              </Button>
            </div>
          </div>
        }
        visible={isTooltipVisible}
        enableVisible
        onClickOutside={() => setIsTooltipVisible(false)}
        theme="pf-white"
        interactive
      >
        <ActionIcon name="add" size="sm" onClick={() => setIsTooltipVisible(true)} disabled={loading} />
      </Tooltip>
    );
  }

  return (
    <SkillRatingIcon
      className={classNames({ [css.disabled]: disabled || loading, [css.weightButton]: isEditMode })}
      updateExperience={updateExperience}
      value={experience}
      isEditMode={isEditMode}
      color={color}
      size={size}
    />
  );
};

RatingIcon.propTypes = {
  isEditMode: PropTypes.bool,
  disabled: PropTypes.bool,
  customType: PropTypes.any,
  customValue: PropTypes.shape({
    experience: PropTypes.number,
    interest: PropTypes.bool,
    suggested_experience: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    top: PropTypes.bool,
    developmental: PropTypes.bool,
    rare: PropTypes.bool
  }).isRequired,
  onRateChange: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RatingIcon.defaultProps = {
  isEditMode: false
};

export default RatingIcon;
