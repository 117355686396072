import {
  CreateBookingPayload,
  CreateBookingTemplatePayload,
  UpdateBookingPayload,
  UpdateBookingsPayload,
  UpdateBookingTemplatePayload
} from "PFApp/booking/services/api";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking } from "PFTypes";

import { BookingDataItem, BookingDataItemLimitation } from "../../use_booking_form";
import {
  GetPayloadsForCreateBookingTemplate,
  getPayloadsForCreateBookingTemplate
} from "./booking_template/get_payload_create";
import {
  GetPayloadsForEditBookingTemplate,
  getPayloadsForEditBookingTemplate
} from "./booking_template/get_payload_edit";
import { GetPayloadForCloneSingle, getPayloadForCloneSingle } from "./single_booking/get_payload_clone";
import { GetPayloadForCreateSingle, getPayloadForCreateSingle } from "./single_booking/get_payload_create";
import {
  GetPayloadForEditMultipleBookingsOfSingleType,
  getPayloadForEditMultipleBookingsOfSingleType
} from "./single_booking/get_payload_edit";
import {
  GetPayloadsForEditGroupBookingsOfSingleType,
  getPayloadsForEditGroupBookingsOfSingleType
} from "./single_booking/get_payload_edit_group";

export const isNotLimited = ({ limitation }: BookingDataItem) =>
  limitation === BookingDataItemLimitation.None;

type UseBookingPayload = {
  initialBooking?: Booking;
};
type UseBookingPayloadReturn = {
  getPayloadForCreateSingle: (data: GetPayloadForCreateSingle) => CreateBookingPayload;
  getPayloadForCloneSingle: (data: GetPayloadForCloneSingle) => CreateBookingPayload;
  getPayloadForEditMultipleBookingsOfSingleType: (
    data: GetPayloadForEditMultipleBookingsOfSingleType
  ) => UpdateBookingsPayload;
  getPayloadsForEditGroupBookingsOfSingleType: (
    data: GetPayloadsForEditGroupBookingsOfSingleType
  ) => UpdateBookingPayload[];
  getPayloadsForCreateBookingTemplate: (
    data: GetPayloadsForCreateBookingTemplate
  ) => CreateBookingTemplatePayload[];
  getPayloadsForEditBookingTemplate: (
    data: GetPayloadsForEditBookingTemplate
  ) => UpdateBookingTemplatePayload[];
};

export const useBookingPayload = ({ initialBooking }: UseBookingPayload): UseBookingPayloadReturn => {
  const { formatISODate } = useDateFormatter();

  return {
    getPayloadForCreateSingle,
    getPayloadForCloneSingle,
    getPayloadForEditMultipleBookingsOfSingleType:
      getPayloadForEditMultipleBookingsOfSingleType(initialBooking),
    getPayloadsForEditGroupBookingsOfSingleType,
    getPayloadsForCreateBookingTemplate,
    getPayloadsForEditBookingTemplate: getPayloadsForEditBookingTemplate(formatISODate, initialBooking)
  };
};
