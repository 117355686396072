import { uniqBy } from "lodash";
import AppContext from "PFApp/app_context";
import ChatBody from "PFApp/components/chat/chat_body";
import ChatLoading from "PFApp/components/chat/chat_loading";
import ChatSubheader from "PFApp/components/chat/chat_subheader";
import showProfile from "PFApp/components/chat/show_profile";
import { Button } from "PFComponents/button";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import i18n from "PFCore/i18n";
import { fetchParticipants } from "PFCore/services/chat/fetch_paricipants";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import css from "./chat_participants.module.scss";

export default class ChatParticipants extends Component {
  state = {
    loading: true,
    allParticipants: []
  };

  componentDidMount() {
    const { conversationId, ownerId, withMessage } = this.props;

    const participantsPromise = fetchParticipants({
      conversationId,
      withMessage
    });

    const ownerParticipantsPromise = fetchParticipants({
      perPage: 1,
      conversationId,
      profileId: ownerId
    });

    Promise.all([ownerParticipantsPromise, participantsPromise]).then(
      ([{ entries: ownerParticipants }, { entries: participants }]) => {
        this.setState({
          allParticipants: ownerParticipants.length
            ? uniqBy([ownerParticipants[0], ...participants], "profile.id")
            : participants,
          loading: false
        });
      }
    );
  }

  profileFullName(profile, ownerId) {
    let fullName = <span>{getProfileName(profile)}</span>;

    if (this.isOwner(profile, ownerId)) {
      fullName = (
        <React.Fragment>
          {fullName} <small className={css.owner}>(owner)</small>
        </React.Fragment>
      );
    }

    return fullName;
  }

  isOwner(profile, ownerId) {
    return profile.id === ownerId;
  }

  isViewable(profile) {
    return profile.viewable === true;
  }

  render() {
    const { dispatch } = this.context;
    const { handleClose, ownerId, isPrivate, handleMinimize } = this.props;
    const { allParticipants } = this.state;
    const { loading } = this.state;

    const firstLoading = loading && allParticipants.length === 0;

    return (
      <React.Fragment>
        <ChatSubheader
          handleClose={handleClose}
          title={isPrivate ? i18n.t("core:chat.type.private") : i18n.t("core:chat.type.public")}
          center
        />
        {firstLoading && <ChatLoading />}
        {!firstLoading && (
          <ChatBody opacity={loading ? 0.5 : 1}>
            <ul className={css.list} data-qa-id="chat-participants">
              {allParticipants.map((participant) => {
                const { profile } = participant;
                const avatar = (
                  <div className={css.avatarWrap}>
                    <ProfileAvatar profile={profile} size={32} />
                  </div>
                );
                const name = <div className={css.name}>{this.profileFullName(profile, ownerId)}</div>;

                if (this.isViewable(profile) || this.isOwner(profile, ownerId)) {
                  return (
                    <li className={css.item} key={participant.id}>
                      <Link
                        to={`/profiles/${profile.id}`}
                        onClick={(event) => {
                          event.preventDefault();
                          showProfile(handleMinimize, event, profile.id, dispatch);
                        }}
                        className={css.link}
                      >
                        {avatar} {name}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li className={css.item} key={participant.id}>
                      {avatar} {name}
                    </li>
                  );
                }
              })}

              {allParticipants.currentPage < allParticipants.totalPages && (
                <li className={css.more} key="more">
                  <Button
                    kind="secondary"
                    onClick={() => {
                      this.setState({ loading: true });
                      this.participants
                        .getNextPage({ remove: false, sort: false })
                        .then(() => this.setState({ loading: false }));
                    }}
                  >
                    more
                  </Button>
                </li>
              )}
            </ul>
          </ChatBody>
        )}
      </React.Fragment>
    );
  }
}

ChatParticipants.propTypes = {
  conversationId: PropTypes.number.isRequired,
  ownerId: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  withMessage: PropTypes.bool.isRequired,

  handleClose: PropTypes.func.isRequired,
  handleMinimize: PropTypes.func
};

ChatParticipants.contextType = AppContext;
