import { keys, map } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Divider } from "PFComponents/divider";
import Hr from "PFComponents/hr/hr";
import { Typography } from "PFComponents/typography";
import { useActivityEconomicsScenario } from "PFCore/hooks/queries/activity";
import { useActivityEconomicsScenarioClone } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_clone";
import { useActivityEconomicsScenarioInvalidate } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_invalidate";
import { EconomicsVacancy } from "PFTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";

import { useGrowl } from "../../../../use_growl";
import { useActivityPageContext } from "../../activity_page_context";
import { RemoveScenarioModal } from "../remove_scenario_modal";
import { ScenarioNameModal } from "../scenario_name_modal";
import { ShareScenarioModal } from "../share_scenario_modal";
import { useEconomicsPermissions } from "../use_economics_permissions";
import { useScenarioNavigate } from "../use_scenario_navigate";
import { BudgetVsCostChart } from "./charts/budget_vs_cost_chart";
import { GradeChart } from "./charts/grade_chart";
import { HoursChart } from "./charts/hours_chart";
import { SkillsChart } from "./charts/skills_chart";
import { RoleCard } from "./role_card/role_card";
import css from "./scenario_view.module.scss";
import { groupVacanciesByRole } from "./utils";

type ScenarioViewProps = {
  scenarioId: string;
};

export const ScenarioView = ({ scenarioId }: ScenarioViewProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario" });

  const { task: activity } = useActivityPageContext();
  const { canCreateScenarios, canManageScenario } = useEconomicsPermissions();
  const { openScenario, closeScenario } = useScenarioNavigate();

  const { mutate: cloneScenario } = useActivityEconomicsScenarioClone(activity.id);
  const { data: scenario } = useActivityEconomicsScenario(scenarioId);
  const { invalidate: invalidateScenario } = useActivityEconomicsScenarioInvalidate();

  const isEditable = useMemo(
    () => (scenario ? canManageScenario(scenario.creatorId) : false),
    [scenario, canManageScenario]
  );

  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [groupedVacancies, setGroupedVacancies] = useState<Record<string, EconomicsVacancy[]>>({});

  const { filledRoles, allRoles } = useMemo(() => {
    const allRoles = keys(groupedVacancies).length;
    const filledRoles = keys(groupedVacancies).filter((role) =>
      groupedVacancies[role].some((vacancy) => vacancy.profile)
    ).length;
    return { filledRoles, allRoles };
  }, [groupedVacancies]);

  useEffect(() => {
    if (scenario?.vacancies) {
      setGroupedVacancies(groupVacanciesByRole(scenario.vacancies));
    }
  }, [scenario?.vacancies]);

  //matches may not be ready so we want to reaload
  //scenario in case matches updated for select input options
  useUnmount(() => invalidateScenario(scenarioId));

  const closeEditNameModal = useCallback(() => {
    setIsEditNameModalOpen(false);
  }, []);

  const handleClone = () => {
    cloneScenario(scenarioId, {
      onSuccess: (newScenario) => {
        growl({
          kind: "success",
          message: t("cloneSuccess")
        });

        openScenario(newScenario.id);
      },
      onError: () => {
        growl({
          kind: "error",
          message: t("cloneError")
        });
      }
    });
  };

  return (
    <main className={css.root}>
      <section className={css.content}>
        <header>
          <span className={css.editableHeader}>
            <Typography variant="h3" noMargin clipOverflow>
              {scenario?.name}
            </Typography>
            {isEditable && <ActionIcon name="edit" size="sm" onClick={() => setIsEditNameModalOpen(true)} />}
          </span>
          <Divider />
        </header>
        <article className={css.scenarioDetails}>
          <Typography variant="h5" noMargin>
            {t("header", { filled: filledRoles, all: allRoles })}
          </Typography>
          {map(groupedVacancies, (vacancies, vacancyId) => (
            <RoleCard key={vacancyId} scenarioId={scenarioId} vacancies={vacancies} isEditable={isEditable} />
          ))}
        </article>
      </section>
      <aside className={css.sidebar}>
        <div className={css.toolbar}>
          {canCreateScenarios && <Button icon="duplicate" kind="secondary" onClick={handleClone} />}
          {isEditable && <Button icon="share" kind="secondary" onClick={() => setIsShareModalOpen(true)} />}
          {isEditable && <Button icon="remove" kind="secondary" onClick={() => setIsRemoveModalOpen(true)} />}
          <Divider type="vertical" />
          <ActionIcon name="cross" onClick={closeScenario} />
        </div>
        {scenario && (
          <>
            <BudgetVsCostChart budget={activity.metadata.budget} cost={scenario.cost} />
            {!!scenario?.skillsRadarData && (
              <>
                <Hr />
                <SkillsChart
                  skillsRadarData={scenario.skillsRadarData}
                  skillsCoveredPercentage={scenario.skillsCoveredPercentage}
                />
              </>
            )}
            {!!scenario?.durationInHours && (
              <>
                <Hr />
                <HoursChart
                  hoursTotal={scenario.durationInHours}
                  coverage={scenario.hoursCoveredPercentage}
                />
              </>
            )}
            {!!scenario?.costFieldData && (
              <>
                <Hr />
                <GradeChart data={scenario.costFieldData} />
              </>
            )}
          </>
        )}
      </aside>
      {isEditNameModalOpen && (
        <ScenarioNameModal
          isEdit
          scenario={scenario}
          onClose={closeEditNameModal}
          onSave={closeEditNameModal}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveScenarioModal
          scenarioId={scenario?.id!}
          activityId={activity.id}
          onRemove={closeScenario}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      )}
      {isShareModalOpen && (
        <ShareScenarioModal scenarioId={scenarioId} onClose={() => setIsShareModalOpen(false)} />
      )}
    </main>
  );
};
