import { TimePicker } from "PFComponents/time_picker";
import { RefObject } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../../booking_detail_item";
import { BookingFormValues } from "../../booking_form_provider";
import css from "./range_item.module.scss";

type TimePickerProps = {
  itemIndex: number;
  portalRef: RefObject<HTMLDivElement>;
};

export const TimeSelector = ({ itemIndex, portalRef }: TimePickerProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { control } = useFormContext<BookingFormValues>();

  const startTime = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.startTime` });
  const endTime = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.endTime` });

  return (
    <div className={css.datesContainer}>
      <BookingDetailItem
        label={t("bookingModule:bookings.create.startTime")}
        value={
          <Controller
            name={`bookings.${itemIndex}.startTime`}
            control={control}
            render={({ field }) => (
              <TimePicker
                value={field.value}
                max={endTime}
                onChange={field.onChange}
                portalRef={portalRef}
                fixedWidth={false}
              />
            )}
          />
        }
      />
      <BookingDetailItem
        label={t("bookingModule:bookings.create.endTime")}
        value={
          <Controller
            name={`bookings.${itemIndex}.endTime`}
            control={control}
            render={({ field }) => (
              <TimePicker
                value={field.value}
                min={startTime}
                onChange={field.onChange}
                portalRef={portalRef}
                fixedWidth={false}
              />
            )}
          />
        }
      />
    </div>
  );
};
