import classNames from "classnames";
import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import { useFiltersContext } from "../context/filters_context";
import { SaveAsView } from "../save_as_view";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import { FiltersPanelProps } from "./filters_panel";
import css from "./filters_panel.module.scss";

type FiltersFooterProps = Pick<
  FiltersPanelProps,
  | "meta"
  | "onFiltersChange"
  | "isRelevantToMeDisabled"
  | "viewsKey"
  | "allowToClose"
  | "clearFilters"
  | "closeFilters"
>;

export const FiltersFooter = ({
  meta,
  onFiltersChange,
  isRelevantToMeDisabled,
  viewsKey,
  allowToClose,
  closeFilters
}: FiltersFooterProps) => {
  const { isAutoRefresh, applyChanges, isEdited } = useFiltersContext();
  const { setSelectedViewId } = useSavedViewsContext();
  const { t } = useTranslation();

  return (
    <>
      {viewsKey && (
        <SaveAsView
          className={css.footerRow}
          meta={meta}
          clearFilters={onFiltersChange}
          viewsKey={viewsKey}
          isRelevantToMeDisabled={isRelevantToMeDisabled}
        />
      )}
      <div className={classNames(css.footerRow, { [css.spaceBetween]: allowToClose })}>
        {allowToClose && (
          <Button kind={isAutoRefresh ? "primary" : "secondary"} onClick={() => closeFilters?.()}>
            {t(isAutoRefresh ? "done" : "cancel")}
          </Button>
        )}
        {!isAutoRefresh && (
          <Button
            onClick={() => {
              setSelectedViewId(null);
              applyChanges();
              allowToClose && closeFilters?.();
            }}
            disabled={!isEdited}
          >
            {t("filters.apply")}
          </Button>
        )}
      </div>
    </>
  );
};
