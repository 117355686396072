import classNames from "classnames";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import SkillRatingIcon from "PFComponents/icons/skill_rating_icon";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import { CustomValue, Experience } from "PFTypes";
import { Id } from "PFTypes/id";
import { useState } from "react";

import SkillsList from "../../skills_list";
import css from "./skills_proficiency_group.module.scss";

type SkillsProficiencyGroupProps = {
  experience: Experience;
  skills: CustomValue[];
  profileId: Id;
};

export const SkillsProficiencyGroup = ({ skills, experience, profileId }: SkillsProficiencyGroupProps) => {
  const [isCategoryCollapsed, setIsCategoryCollapsed] = useState(false);

  const experienceLabelMap = useExperienceLabelMap();

  return (
    <div className={css.content}>
      <div className={css.headLeft}>
        <SkillRatingIcon value={experience} className={css.skillRatingIcon} />
        <button
          className={classNames(css.iconButton, { [css.arrowClosed]: isCategoryCollapsed })}
          onClick={() => setIsCategoryCollapsed((prev) => !prev)}
        >
          <DownArrowIcon height={24} width={24} />
        </button>
      </div>
      <div className={css.headRight}>{`${experienceLabelMap[experience]} (${skills.length})`}</div>
      <div className={classNames(css.skillColumn, { [css.categoryCollapsed]: isCategoryCollapsed })}>
        <SkillsList collection={skills} profileId={profileId} />
      </div>
    </div>
  );
};
