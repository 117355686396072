import { InputFieldSet } from "PFComponents/text/input_field_set";

import { useNumberInput } from "./use_number_input";

interface NumberInputProps {
  onChange: (value: number | undefined) => void;
  min: number;
  max?: number;
  defaultValue: number | undefined;
  autofocus?: boolean;
  error?: boolean;
}

export const NumberInput = ({
  defaultValue,
  onChange,
  min,
  max,
  autofocus,
  error
}: NumberInputProps): JSX.Element => {
  const { value, handleChange } = useNumberInput({
    defaultValue,
    onChange,
    min,
    max
  });

  return <InputFieldSet autofocus={autofocus} value={value} onChange={handleChange} error={error} />;
};
