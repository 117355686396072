import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import { CompletionRateBadge } from "../completion_rate_badge";
import css from "./header.module.scss";

export type HeaderProps = {
  reversePositive?: boolean;
  coverage?: number;
  title: string;
  series: {
    data: { value: number; unit?: string }[];
  }[];
  headerIconName?: IconName;
  onHeaderIconClick?: VoidFunction;
};

export const Header = ({
  coverage,
  reversePositive,
  series,
  title,
  headerIconName,
  onHeaderIconClick
}: HeaderProps) => (
  <div className={css.header}>
    <span className={css.titleWithIcon}>
      <Typography variant="h4" noMargin clipOverflow>
        {title}
      </Typography>
      {headerIconName && onHeaderIconClick && (
        <ActionIcon name={headerIconName} size="sm" onClick={onHeaderIconClick} />
      )}
    </span>
    <CompletionRateBadge series={series} reversePositive={reversePositive} coverage={coverage} />
  </div>
);
