import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import { Id } from "PFTypes";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Tab } from "../tabs";
import { SidePanelBody } from "./body";
import { SidePanelFooter } from "./footer";
import { SidePanelHeader } from "./header";
import { getHeaderOffset } from "./header/side_panel_header.utils";
import css from "./side_panel.module.scss";
import { useSidePanelClose } from "./use_side_panel_close";
import { useSidePanelScroll } from "./use_side_panel_scroll";
import { useSidePanelTabs } from "./use_side_panel_tabs";

const BASE_Z_INDEX = 610;

type Size = "default" | "medium" | "large";

export type SidePanelTabs<TAB_ID extends Id> = {
  tabs: Tab<TAB_ID>[];
  defaultSelectedTabId: TAB_ID;
  tabsContent: Record<TAB_ID, React.ReactNode>;
};

type FooterRendererArgs = {
  onSidePanelClose: () => void;
};

export type SidePanelProps<TAB_ID extends Id> = React.PropsWithChildren<{
  id?: string;
  title?: string | React.ReactNode;
  subTitle?: string;
  actions?: React.ReactNode;
  show: boolean;
  size?: Size;
  onClose?: VoidFunction;
  classes?: Partial<{ show: string }>;
  fullHeight?: boolean;
  footerRenderer?: (args: FooterRendererArgs) => React.ReactNode;
  tabsConfig?: SidePanelTabs<TAB_ID>;
  isOnTop?: boolean;
  zIndex?: number;
  isSidePanelClosing?: boolean;
  noOverflow?: boolean;
}>;

const SidePanel = <TAB_ID extends Id>({
  id,
  title,
  subTitle,
  actions,
  show,
  children,
  onClose,
  size = "default",
  classes = {},
  fullHeight,
  footerRenderer,
  tabsConfig,
  isOnTop = true,
  zIndex = 0,
  isSidePanelClosing = false,
  noOverflow
}: SidePanelProps<TAB_ID>): JSX.Element | null => {
  const modalTarget = document.getElementById("modal_region") as HTMLDivElement;

  const [footerHeight, setFooterHeight] = useState(0);
  const { isClosing, onSidePanelClose } = useSidePanelClose({
    onClose,
    isSidePanelClosing
  });
  const { internalTabsConfig, selectedTabId } = useSidePanelTabs({
    tabsConfig
  });
  const { bodyRef, footerContentRef, hasBorderShadow, hasScroll, handleScroll } = useSidePanelScroll({
    selectedTabId
  });

  useEffect(() => {
    setFooterHeight(footerContentRef.current?.offsetHeight ?? 0);
  }, [footerContentRef]);

  if (!show) {
    return null;
  }

  return createPortal(
    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true, initialFocus: false }}>
      <div
        id={id}
        role="dialog"
        style={{ zIndex: BASE_Z_INDEX + zIndex }}
        className={classNames(
          css.root,
          isClosing ? css.closing : css.animateIn,
          { [css.sizeDefault]: size === "default" },
          { [css.sizeMedium]: size === "medium" },
          { [css.sizeLarge]: size === "large" },
          { [css.fullHeight]: fullHeight },
          { [css.onTop]: isOnTop },
          { [css.noOverflow]: noOverflow },
          classes.show
        )}
      >
        <SidePanelHeader
          title={title}
          subTitle={subTitle}
          actions={actions}
          onClose={onSidePanelClose}
          tabsConfig={internalTabsConfig}
        />
        <SidePanelBody
          ref={bodyRef}
          maxHeight={`calc(100% - ${footerHeight + getHeaderOffset(!!tabsConfig)}px)`}
          onScroll={handleScroll}
          noOverflow={noOverflow}
        >
          {tabsConfig ? tabsConfig.tabsContent[selectedTabId] : children}
        </SidePanelBody>
        {footerRenderer && (
          <SidePanelFooter ref={footerContentRef} hasBorderShadow={hasBorderShadow} hasScroll={hasScroll}>
            {footerRenderer({ onSidePanelClose })}
          </SidePanelFooter>
        )}
      </div>
    </FocusTrap>,
    modalTarget
  );
};

export default SidePanel;
