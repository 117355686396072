import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { BookingSelectOption } from "PFApp/booking/components/booking_questions/booking_questions";
import { UpdateBookingTemplatePayload } from "PFApp/booking/services/api";
import { UNDETERMINED_FUTURE } from "PFApp/constants/dates";
import { DateFormatter } from "PFCore/helpers/date_formatter";
import { Booking, Profile } from "PFTypes";

import { isNotLimited } from "../use_booking_payload";
import { mapBasicBookingTemplate } from "./get_payload.utils";
import { isBookingFormForBookingTemplateEditModeValid } from "./validate";

export type GetPayloadsForEditBookingTemplate = { values: BookingFormValues };

export const getPayloadsForEditBookingTemplate =
  (formatISODate: DateFormatter["formatISODate"], initialBooking?: Booking) =>
  ({ values }: GetPayloadsForEditBookingTemplate): UpdateBookingTemplatePayload[] => {
    const bookingsToProcess = values.bookings.filter(isNotLimited);
    if (!isBookingFormForBookingTemplateEditModeValid({ ...values, bookings: bookingsToProcess })) {
      throw new Error("Invalid form values for editing a booking template!");
    }
    const { workforceMember, updateRepeatOptionSelected } = values;

    return bookingsToProcess.map((formBooking) => {
      const { bookingTemplateId, category, title, description, overridesDiaryTime, overridesNonWorkingTime } =
        formBooking;
      return {
        id: bookingTemplateId!,
        profileId: (workforceMember as Profile).id,
        ...mapBasicBookingTemplate(formBooking),
        bookingCategoryId: category!.id,
        overridesCalendar: overridesDiaryTime!,
        overridesNonWorkingDays: overridesNonWorkingTime!,
        ...(title ? { title } : {}),
        ...(description ? { description } : {}),
        ...(updateRepeatOptionSelected === BookingSelectOption.Future && !!initialBooking
          ? {
              dateRange: {
                min: formatISODate(initialBooking.start_date),
                max: formatISODate(UNDETERMINED_FUTURE)
              }
            }
          : {})
      };
    });
  };
