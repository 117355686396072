import classNames from "classnames";
import { Button } from "PFComponents/button";
import { useForm } from "react-hook-form";

import BookingNoteInput from "../booking_note_form";
import { IInputs } from "../types";
import css from "./booking_note_form.module.scss";

interface IProps {
  defaultValues?: IInputs;
  onSubmit: (props: { data: IInputs; onSuccess?: () => void }) => void;
  classes?: Record<string, string>;
  disabled: boolean;
  submitLabel: string;
  placeholder?: string;
}

const DEFAULT_VALUES: IInputs = { content: "" };

const BookingNoteForm = ({
  defaultValues = DEFAULT_VALUES,
  onSubmit,
  classes = {},
  disabled,
  submitLabel,
  placeholder
}: IProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty }
  } = useForm<IInputs>({
    mode: "onChange",
    defaultValues
  });

  const clearContent = () =>
    setValue("content", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });

  const onFormSubmit = (data: IInputs) => {
    onSubmit({ data, onSuccess: clearContent });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={classNames(css.root, classes.root)}>
        <BookingNoteInput control={control} disabled={disabled} placeholder={placeholder} />
        <Button text={submitLabel} type="submit" disabled={!isDirty || !isValid || disabled} />
      </div>
    </form>
  );
};

export default BookingNoteForm;
