import AppContext from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { Importance } from "PFTypes";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import css from "./empty.module.scss";

const EmptyMatches = () => {
  const {
    dispatch,
    store: {
      search: {
        term,
        choosenKeywords,
        suggestedKeywords,
        profilesMeta: { total }
      }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");

  if (total > 0) {
    return (
      <div className={css.root}>
        {total > 0 && (
          <>
            <strong>{t("results.searchingByName")}</strong>
            <Button
              kind="text"
              onClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: "profiles" })}
              rootClassName={css.emptyMatchesButton}
            >
              {t("results.countFoundProfiles", { count: total, term })}
            </Button>
          </>
        )}
        {choosenKeywords.length === 0 && suggestedKeywords.length > 0 && (
          <div style={{ paddingTop: 30 }}>
            <strong>{t("results.countSuggestionsFound", { count: suggestedKeywords.length })}</strong>
            <p>{t("results.trySuggestionsOrChange")}</p>
          </div>
        )}
      </div>
    );
  }

  const requiredCount = choosenKeywords.filter((keyword) => keyword.importance >= Importance.Required).length;

  return (
    <div className={css.root}>
      <strong>{t("results.noMatchesFound")}</strong>

      {choosenKeywords.length === 0 && <p>{t("results.trySuggestionsOrChange")}</p>}
      {choosenKeywords.length > 0 && <p>{t("results.tryMoreSuggestionsOrChange")}</p>}
      {requiredCount > 0 && <p>{t("results.countMarkedTags", { count: requiredCount })}</p>}
    </div>
  );
};

export default EmptyMatches;
