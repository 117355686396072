import { datadogRum } from "@datadog/browser-rum";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import i18n from "PFCore/i18n";
import { Component, ErrorInfo, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import css from "./error_boundary.module.scss";

type ErrorBoundaryProps = RouteComponentProps & {
  children?: ReactNode;
  errorMessage?: string;
};

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  showCleanStorageModal: boolean;
};

export class _ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null, showCleanStorageModal: false };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { error } = this.state;
    if (error && prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ error: null, errorInfo: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });

    if (datadogRum) {
      datadogRum.addError(error, errorInfo);
    }
  }

  translate = (key: string): string => i18n.t(key);

  render() {
    const message = this.props.errorMessage || i18n.t("somethingWentWrong");
    const { error, showCleanStorageModal } = this.state;

    if (error) {
      return (
        <div className={css.container}>
          <Icon name="error" size="xxxl" />
          <Typography variant="h2" noMargin>
            {message}
          </Typography>
          <Typography variant="h5" noMargin>
            {this.translate("errorBoundary.actionMessage")}
          </Typography>
          <div className={css.buttons}>
            <Button onClick={() => window.location.reload()}>{this.translate("errorBoundary.reload")}</Button>
            <Button kind="secondary" onClick={() => (window.location.href = "mailto:support@profinda.com")}>
              {this.translate("errorBoundary.contactSupport")}
            </Button>
            <Button kind="secondary" onClick={() => this.setState({ showCleanStorageModal: true })}>
              {this.translate("errorBoundary.cleanLocalStorage")}
            </Button>

            {showCleanStorageModal && (
              <Modal
                title={this.translate("errorBoundary.cleanLocalStorage")}
                labelOK={this.translate("errorBoundary.clean")}
                onOK={() => {
                  window.storage?.clear();
                  window.location.reload();
                }}
                onClose={() => this.setState({ showCleanStorageModal: false })}
              >
                {this.translate("errorBoundary.cleanStorageWarning")}
              </Modal>
            )}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(_ErrorBoundary);
