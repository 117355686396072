import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { isGroupOption } from "PFApp/booking/components/booking_form/workforce_member_select/workforce_member_select_dropdown";
import { BookingTemplateDataToUpdate } from "PFApp/booking/services/api";

import { convertToWeekDayMask } from "../../../week_days_select";

export const getProfileOrSearchObject = (
  values: BookingFormValues
): { searchId: number } | { profileId: number } => {
  const { workforceMember } = values;
  if (isGroupOption(workforceMember)) {
    return { searchId: workforceMember.searchId };
  } else {
    return { profileId: workforceMember?.id || -1 };
  }
};

type MapBasicBookingTemplateReturn = Pick<
  BookingTemplateDataToUpdate,
  "startDate" | "startTime" | "endDate" | "endTime" | "wdayMask"
>;
export const mapBasicBookingTemplate = (
  formBooking: BookingFormValues["bookings"][number]
): MapBasicBookingTemplateReturn => {
  const { startDate, startTime, endDate, endTime, repeatDays } = formBooking;
  return {
    startDate,
    startTime: startTime!,
    endDate,
    endTime: endTime!,
    wdayMask: convertToWeekDayMask(repeatDays?.selectedWeekDays ?? [])
  };
};
