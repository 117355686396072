import range from "lodash/range";
import { useCallback } from "react";

import css from "./week_days_select.module.scss";
import { Weekday } from "./weekday";

const WEEKDAY_INDICES = range(0, 7);
export const convertWeekDayMaskToDays = (wdayMask: number): number[] => {
  const selectedIndexes = (wdayMask || 0).toString(2).split("").reverse();
  return selectedIndexes.reduce(
    (result, current, index) => (current === "1" ? [...result, index] : result),
    []
  );
};
export const convertToWeekDayMask = (repeatDays: number[] = []) =>
  repeatDays.map((i) => Math.pow(2, i)).reduce((curr, acc) => curr + acc, 0);

type WeekDaysSelectProps = {
  selectedDays: number[];
  onChange?: (days: number[]) => void;
  readonly?: boolean;
  disabledDays?: number[];
};

export const WeekDaysSelect = ({ disabledDays, selectedDays, onChange, readonly }: WeekDaysSelectProps) => {
  const toggleDay = useCallback(
    (i) => {
      if (readonly) {
        return;
      }
      const isAlreadySelected = selectedDays.includes(i);
      const newSelectedDays = isAlreadySelected
        ? selectedDays.filter((day) => day !== i)
        : [...selectedDays, i];
      onChange && onChange(newSelectedDays);
    },
    [selectedDays, readonly, onChange]
  );

  return (
    <div className={css.root}>
      {WEEKDAY_INDICES.map((i) => (
        <Weekday
          key={i}
          readonly={readonly}
          disabled={!!disabledDays?.includes(i)}
          weekDayNumber={i}
          selected={selectedDays.includes(i)}
          onClick={toggleDay}
        />
      ))}
    </div>
  );
};
