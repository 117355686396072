import CvUploader from "PFApp/profiles/common/cv_uploader";
import { ActionIcon } from "PFComponents/action_icon";
import CardHeader from "PFComponents/card/card_header";
import { LegacyModal } from "PFComponents/modal/legacy_modal";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import css from "./upload_cv_modal.module.scss";

const UploadCvModal = ({ handleModalClose, t }) => (
  <LegacyModal onClose={() => handleModalClose(false)} classes={{ modal: css.modal }}>
    <CardHeader
      titleQaId="profile-cv-upload"
      title={t("show.parts.uploadNewCV")}
      style={{ padding: 0, marginBottom: 24 }}
      action={<ActionIcon name="cross" size="xs" onClick={() => handleModalClose(false)} />}
    />
    <CvUploader />
  </LegacyModal>
);

UploadCvModal.propTypes = {
  handleModalClose: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation("profiles")(UploadCvModal);
