import isNil from "lodash/isNil";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import { useDetailsPanelApiContext } from "../../details_panel";

type WorkforceMemberReadonlyProps = {
  profile?: Profile;
};

export const WorkforceMemberReadonly = ({ profile }: WorkforceMemberReadonlyProps) => {
  const { t } = useTranslation("bookingModule");
  const { openDetailsPanel } = useDetailsPanelApiContext();

  const openProfileSidePanel = (id: number) => {
    openDetailsPanel({
      type: "profile",
      id
    });
  };

  return (
    <BookingDetailItem
      label={t("bookings.create.workforceMember")}
      value={
        !isNil(profile) ? (
          <Button type="button" kind="text" onClick={() => openProfileSidePanel(profile.id)}>
            <Typography variant="bodyBold" noMargin>
              {getProfileName(profile)}
            </Typography>
          </Button>
        ) : (
          <LoadingDots />
        )
      }
    />
  );
};
