import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { Activity } from "PFTypes";

import { useActivityCurrentUserRole } from "../../hooks";

type UseNotesPermissions = {
  activity: Activity;
};

export const useNotesPermissions = ({ activity }: UseNotesPermissions) => {
  // TODO: [PROF-1152] Add notes - replace LS flag with permission
  const notesEnabled = localStorage.getItem("notes_enabled") === "true";

  const hasPermission = useIsCurrentUserPermittedTo();

  const isPrivate = activity.private;

  const {
    isReviewer: currentProfileIsReviewer,
    isOwner: currentProfileIsOwner,
    isAssignee: currentProfileIsAssignee
  } = useActivityCurrentUserRole(activity);

  const hasShortlistWithoutOwnershipPermission = hasPermission("shortlist_without_ownership");

  const shouldDisplayPublicRole =
    !isPrivate &&
    (currentProfileIsOwner ||
      currentProfileIsReviewer ||
      currentProfileIsAssignee ||
      hasShortlistWithoutOwnershipPermission);

  const shouldDisplayPrivateRole = isPrivate && currentProfileIsOwner;

  const shouldDisplayNotes = !!(notesEnabled && (shouldDisplayPublicRole || shouldDisplayPrivateRole));

  return {
    shouldDisplayNotes
  };
};
