import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { sumBookedChildren } from "../../../parts/helpers";
import css from "../../../parts/overview/detail_view/detail_view.module.scss";

type EngagementDetailChildrenRolesProps = {
  activityId: number;
  childrenWorkflowStatesTally: Record<string, number>;
};

export const EngagementDetailChildrenRoles = ({
  activityId,
  childrenWorkflowStatesTally
}: EngagementDetailChildrenRolesProps) => {
  const { t } = useTranslation();

  const rolesTotal = Object.values(childrenWorkflowStatesTally).reduce((sum, num) => sum + num, 0);
  const rolesBooked = sumBookedChildren(childrenWorkflowStatesTally);

  return (
    <>
      <span className={css.item}>
        <div className={css.label}>{t("roles")}</div>
        <span className={classNames(css.alignCenter, css.gap5)}>
          <Icon name="role" size="sm" />
          <Link to={`/activities/${activityId}/roles`}>{rolesTotal}</Link>
        </span>
      </span>
      <span className={css.item}>
        <div className={css.label}>{t("booked")}</div>
        <span className={classNames(css.alignCenter, css.gap5)}>
          <Icon name="check" size="sm" />
          <Link to={`/activities/${activityId}/roles`}>{rolesBooked}</Link>
        </span>
      </span>
    </>
  );
};
