import classNames from "classnames";
import ActivityPreviewLink from "PFApp/components/activity_commons/activity_preview_link";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import PropTypes from "prop-types";

import css from "./content.module.scss";
import Description from "./description";

const QuestionSuggestionContent = ({ task, type }) => {
  const { profile } = task;

  return (
    <div className={css.wrapper}>
      <ActivityPreviewLink activity={task}>
        <div className={css.column}>
          <div className={css.row}>
            <ProfileAvatar profile={profile} size={50} />
            <div className={css.name}>
              <span>{getProfileName(profile)}</span>
              <span>{type === "question" ? " asks..." : " suggests..."}</span>
            </div>
          </div>
          <div
            className={classNames(css.bubble, {
              [css.bubbleSuggestion]: type === "suggestion"
            })}
          >
            <b>{task.name}</b>
            <Description description={task.description} id={task.id} coloredBg />
          </div>
        </div>
      </ActivityPreviewLink>
    </div>
  );
};

QuestionSuggestionContent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    profile: PropTypes.shape({
      avatar: PropTypes.shape({
        thumbnail_url: PropTypes.string
      }),
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    description: PropTypes.string
  }),
  type: PropTypes.string
};

export default QuestionSuggestionContent;
