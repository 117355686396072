import classNames from "classnames";
import EditInternalExperienceForm from "PFApp/profiles/common/experience/edit_internal_experience_form";
import { Accordion } from "PFComponents/accordion";
import { Button } from "PFComponents/button";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Typography } from "PFComponents/typography";
import { useReadableDuration } from "PFCore/helpers/use_readable_duration";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Activity, CustomType, Profile } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExperienceDescription } from "./experience_description";
import css from "./experience_section.modules.scss";
import { InternalExperienceFeedback } from "./internal_experience_feedback";

type InternalExperienceProps = {
  experience: Activity;
  customTypes: CustomType[];
  isCfPermitted: (cfName: string) => boolean;
  fetchInternalExperience: () => void;
  profile: Profile;
};

type InfoSectionProps = {
  label: string;
  content: React.ReactNode | string;
};

export const InfoSection = ({ label, content }: InfoSectionProps) => (
  <div key={label} className={css.experienceGrid}>
    <Typography variant="bodyButtons" noMargin clipOverflow>
      {label}
    </Typography>
    <Typography variant="bodyRegular" noMargin>
      {content}
    </Typography>
  </div>
);

export const InternalExperience = ({
  experience,
  customTypes,
  isCfPermitted,
  fetchInternalExperience,
  profile
}: InternalExperienceProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const [editMode, setEditMode] = useState(false);
  const readableDuration = useReadableDuration({
    duration: experience.metadata.date_range
  });

  const customFields = useMemo(() => experience.custom_fields || [], [experience.custom_fields]);
  const experienceCustomFields = useMemo(() => {
    const experienceCustomFields = {};

    customFields.forEach((customField) => {
      const customType = (customTypes || []).find((customType) => customType.name === customField.type.name);
      if (customType?.name) {
        experienceCustomFields[customType.name] = {
          type: customType,
          values: customField.values
        };
      }
    });
    return experienceCustomFields;
  }, [customTypes, customFields]);

  const allCustomFields = useMemo(() => Object.keys(experienceCustomFields), [experienceCustomFields]);

  return (
    <div
      className={classNames(css.row, css.internalPositionDetails)}
      key={`${experience.id}`}
      data-scroll-anchor={`internal-experience-${experience.id}`}
    >
      <div className={classNames(css.experienceTitle)}>
        <Typography variant="h5" noMargin>
          {readableDuration}
        </Typography>
      </div>
      <div className={css.internalPositionAccordion}>
        <Accordion
          animated={false}
          headerSize="small"
          header={`${t("translation:role")}: ${experience.name}`}
          defaultState="closed"
        >
          <div className={classNames(css.experienceGridWrapper)}>
            <InfoSection label={t("translation:role")} content={experience.name} />
            {allCustomFields.map(
              (name) =>
                isCfPermitted(name) && (
                  <div key={name} className={css.experienceGrid}>
                    <Typography variant="bodyBold" noMargin clipOverflow>
                      {name}
                    </Typography>
                    <div>
                      <CustomValuesList
                        type={experienceCustomFields[name]}
                        customValues={experienceCustomFields[name].values.map((value) => ({
                          ...value,
                          className: "light"
                        }))}
                        profileId={profile.id}
                        currentProfile={currentProfile}
                        currentAccount={currentAccount}
                        moreLimit={6}
                        showMoreButton
                      />
                    </div>
                  </div>
                )
            )}
            {experience.metadata.location && (
              <InfoSection label={t("profiles:show.parts.location")} content={experience.metadata.location} />
            )}
            <InfoSection
              label={t("profiles:show.parts.description")}
              content={<ExperienceDescription content={experience.description} maxLength={100} />}
            />
            {experience.metadata.engagement_hours && (
              <InfoSection
                label={t("profiles:show.parts.hours")}
                content={experience.metadata.engagement_hours}
              />
            )}
            {experience.parent_activity_id && (
              <InternalExperienceFeedback roleId={experience.parent_activity_id} profileId={profile.id} />
            )}
          </div>
        </Accordion>
        <Button
          aria-label={t("profiles:common.experience.internalExperience.editInternalExperience")}
          aria-expanded={editMode}
          aria-haspopup="dialog"
          className={css.editIcon}
          kind="secondary"
          icon="edit"
          onClick={() => setEditMode(true)}
        />
        {editMode && (
          <EditInternalExperienceForm
            experience={experience}
            profile={profile}
            adminPage={false}
            handleSuccess={() => {
              fetchInternalExperience();
              setEditMode(false);
            }}
            handleRemove={() => {
              fetchInternalExperience();
              setEditMode(false);
            }}
            onClose={() => setEditMode(false)}
          />
        )}
      </div>
    </div>
  );
};
