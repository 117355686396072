import classNames from "classnames";
import moment from "moment";
import { DropDownItem } from "PFComponents/dropdown/dropdown_item";
import Tooltip from "PFComponents/tooltip/tooltip";
import SkillRatingIcon from "PFCore/components/icons/skill_rating_icon";
import i18n from "PFCore/i18n";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import FilterIcon from "PFIcons/filter_2.svg";
import { Experience } from "PFTypes";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { DraggableSkill } from "./draggable_skill";
import css from "./section_skills.module.scss";

export const skillsSortingOptions = [
  {
    id: 0,
    item: 0,
    by: "alphabetical",
    direction: "DESC",
    displayElement: (
      <span className={css.dropdownItem}>
        {i18n.t("profiles:show.parts.sectionSkills.alphabetical")} <DownArrowIcon height={20} />
      </span>
    ),
    sortFunc: (s1, s2) => {
      const v1 = s1.value || s1.text;
      const v2 = s2.value || s2.text;
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    }
  },
  {
    id: 1,
    item: 1,
    by: "alphabetical",
    direction: "ASC",
    displayElement: (
      <span className={css.dropdownItem}>
        {i18n.t("profiles:show.parts.sectionSkills.alphabetical")}{" "}
        <DownArrowIcon style={{ transform: "rotate(180deg)" }} height={20} />
      </span>
    ),
    sortFunc: (s1, s2) => {
      const v1 = s1.value || s1.text;
      const v2 = s2.value || s2.text;
      return v1 < v2 ? 1 : v1 > v2 ? -1 : 0;
    }
  },
  {
    id: 2,
    item: 2,
    by: "date",
    direction: "DESC",
    displayElement: (
      <span className={css.dropdownItem}>
        {i18n.t("profiles:show.parts.sectionSkills.dateAdded")} <DownArrowIcon height={20} />
      </span>
    ),
    sortFunc: (s1, s2) => {
      const v1 = moment(s1.created_at).valueOf();
      const v2 = moment(s2.created_at).valueOf();
      return v1 < v2 ? 1 : v1 > v2 ? -1 : 0;
    }
  },
  {
    id: 3,
    item: 3,
    by: "date",
    direction: "ASC",
    displayElement: (
      <span className={css.dropdownItem}>
        {i18n.t("profiles:show.parts.sectionSkills.dateAdded")}{" "}
        <DownArrowIcon style={{ transform: "rotate(180deg)" }} height={20} />
      </span>
    ),
    sortFunc: (s1, s2) => {
      const v1 = moment(s1.created_at).valueOf();
      const v2 = moment(s2.created_at).valueOf();
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    }
  }
];

const experienceToTextMap = {
  [Experience.Basic]: "basic",
  [Experience.Intermediate]: "intermediate",
  [Experience.Advanced]: "advanced"
};

export const Header = ({ experience, skills, skillsSortingSelected, setSkillsSortingSelected }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  return (
    <div className={classNames(css.header, css[experienceToTextMap[experience]])}>
      <div className={css.title}>
        <span>
          {t(`sectionSkills.rating.${experienceToTextMap[experience]}.name`)}{" "}
          <span className={css.number}>{skills.length}</span>
        </span>
        <span>
          <SkillRatingIcon value={experience} color="#fff" size={20} />
          <Tooltip
            key={skillsSortingSelected[experience].item}
            trigger="click"
            theme="pf-dropdown"
            hideOnClick
            interactive
            content={
              <div>
                <ul className={css.actionsList}>
                  {skillsSortingOptions.map((option) => (
                    <DropDownItem
                      key={option.id}
                      value={option}
                      selected={skillsSortingSelected[experience].item === option.item}
                      handleChange={(item) =>
                        setSkillsSortingSelected({
                          ...skillsSortingSelected,
                          [experience]: skillsSortingOptions[item]
                        })
                      }
                    />
                  ))}
                </ul>
              </div>
            }
            placement="bottom-end"
            className={css.skill}
          >
            <span className={classNames(css.icon)} role="button">
              <FilterIcon className={css.filterIcon} width={20} height={20} />
            </span>
          </Tooltip>
        </span>
      </div>
      <div className={css.info}>{t(`sectionSkills.rating.${experienceToTextMap[experience]}.info`)}</div>
    </div>
  );
};

Header.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired
};

export const Skills = ({ experience, skills, addToLog, coreSkillsCount, developmentalSkillsCount }) => (
  <div className={classNames(css.skills, css[experienceToTextMap[experience]])}>
    <Droppable droppableId={String(experience)}>
      {(provided) => (
        <div className={css.droppable} ref={provided.innerRef} {...provided.droppableProps}>
          <div className={css.skillsInner}>
            {skills.map((skill, index) => (
              <DraggableSkill
                key={skill.id}
                skill={skill}
                index={index}
                addToLog={addToLog}
                coreSkillsCount={coreSkillsCount}
                developmentalSkillsCount={developmentalSkillsCount}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </div>
);

Skills.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired
};

const SkillsGrid = ({
  basicSkills,
  intermediateSkills,
  advancedSkills,
  skillsSortingSelected,
  setSkillsSortingSelected,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount
}) => (
  <div className={css.skillRatingGrid}>
    {[
      { experience: 1, skills: basicSkills },
      { experience: 3, skills: intermediateSkills },
      { experience: 5, skills: advancedSkills }
    ].map(({ experience, skills }) => (
      <Header
        key={experience}
        experience={experience}
        skills={skills}
        skillsSortingSelected={skillsSortingSelected}
        setSkillsSortingSelected={setSkillsSortingSelected}
        addToLog={addToLog}
      />
    ))}
    {[
      { experience: 1, skills: basicSkills },
      { experience: 3, skills: intermediateSkills },
      { experience: 5, skills: advancedSkills }
    ].map(({ experience, skills }) => (
      <Skills
        key={experience}
        experience={experience}
        skills={skills}
        addToLog={addToLog}
        coreSkillsCount={coreSkillsCount}
        developmentalSkillsCount={developmentalSkillsCount}
      />
    ))}
  </div>
);

SkillsGrid.propTypes = {
  basicSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  intermediateSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  advancedSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired
};

export default SkillsGrid;
