import classNames from "classnames";
import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import BookingPill from "PFApp/components/pills/booking_pill";
import { BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";

type BookingDetailCategoryProps = {
  category: BookingCategory;
};

export const BookingDetailCategory = ({ category }: BookingDetailCategoryProps): JSX.Element => {
  const { t } = useTranslation("translation");
  return (
    <div className={classNames(css.alignCenter, css.bookingCategory)}>
      <BookingDetailItem label={t("category")} value={category.display_as} />
      <BookingPill category={category} className={css.bookingCategoryPill} />
    </div>
  );
};
