import { BookingDataItem } from "../use_booking_form";

type AdditionalData = { id: undefined } | { bookingTemplateId: undefined };
type Source = { id: number; start_date: string; end_date: string };

export const mapBookings = (bookings: BookingDataItem[], additionalData: AdditionalData, source?: Source) =>
  bookings.map((booking) => ({
    ...booking,
    ...additionalData,
    ...(source
      ? {
          id: source.id,
          startDate: source.start_date,
          endDate: source.end_date
        }
      : {})
  }));
