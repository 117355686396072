import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Carousel, CarouselActions, CarouselItem } from "PFComponents/carousel";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsFrameworksInvalidate } from "PFCore/hooks/queries/skills_frameworks/use_skills_frameworks_invalidate";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { createDictionaryConnection } from "PFCore/services/custom_values";
import { fetchRareSkills } from "PFCore/services/skills/fetch_rare_skills";
import { Experience, Profile } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { useProfileSuggestedSkills } from "../../hooks/use_profile_suggested_skills";
import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import { PageSection } from "../page_section";
import { Skill } from "../section_skills/skill";
import { AddSkillsButton } from "./add_skills_button";
import { SkillsFrameworksSuggestedSkillsModal } from "./skills_frameworks_suggested_skills_modal";
import css from "./skills_groups.module.scss";

type SkillsGroupsProps = {
  profile: Profile;
  className: string;
  onProfileUpdate: OnProfileUpdateHandler;
};

const SkillsGroups = ({ profile, className, onProfileUpdate }: SkillsGroupsProps): JSX.Element | null => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  const { invalidateProfileSkillsFrameworks } = useSkillsFrameworksInvalidate();
  const [isSkillsFrameworksModalOpened, toggleSkillsFrameworksModalOpened] = useToggle(false);
  const isMe = profile.id === currentProfile.id;

  const { suggestedFrameworksSkills, isLoading: isLoadingSuggestedFrameworksSkills } =
    useProfileSuggestedSkills({ profileId: profile.id, enabled: isMe });

  const [updatingSkills, setUpdatingSkills] = useState(false);
  const [rareSkills, setRareSkills] = useState([]);

  useEffect(() => {
    fetchRareSkills(profile.id).then((response) => setRareSkills(response.entries));
  }, [profile.id]);

  const skillsType = useSkillsType();

  const [coreSkills, developmentalSkills] = useMemo(() => {
    const allSkills = profile?.skills || [];
    return [
      allSkills.filter((skill) => skill.top),
      allSkills.filter((skill) => skill.developmental).slice(0, 5)
    ];
  }, [profile]);

  const suggestedSkills = useMemo(
    () => (profile.suggestions?.skills || []).map((skill) => ({ experience: 0, ...skill })).slice(0, 5),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(profile.suggestions?.skills || []).map(({ id }) => id).join(",")]
  );

  const removeSuggestedSkill = (skill) => {
    setUpdatingSkills(true);
    return createDictionaryConnection({ interest: false, experience: 0, customValueId: skill.id }).then(() =>
      onProfileUpdate().then(() => {
        setUpdatingSkills(false);
        invalidateProfileSkillsFrameworks(profile.id);
      })
    );
  };

  const getInfoTooltipContent = (selectedOptionId: "tag_as_core" | "tag_as_developmental") => (
    <div className={css.skillItemContainer}>
      <Skill
        skill={{ text: t("sectionSkills.skill"), experience: Experience.Basic }}
        staticProps={{
          cursorPointer: true,
          keepOptionsOpen: true,
          selectedOptionId
        }}
        classes={{
          actionsList: css.skillActionsList,
          tooltipContentContainer: css.skillTooltipContentContainer
        }}
      />
    </div>
  );

  const items: CarouselItem[] = [
    {
      content: (
        <div className={classNames(css.skillsTile, css.suggested)}>
          <div className={css.skillsTileHeader}>
            {t("sectionSkills.suggestions.framework.title")}
            <Tooltip
              theme="pf-white"
              content={t("sectionSkills.suggestions.framework.info", { name: currentAccount.name })}
            >
              <span>
                <Icon name="info" size="sm" className={css.infoIcon} />
              </span>
            </Tooltip>
            <ActionIcon
              name="open"
              size="sm"
              color="paletteWhite0"
              classes={{ root: css.skillsFrameworksModalIcon }}
              onClick={toggleSkillsFrameworksModalOpened}
            />
          </div>
          <CustomValuesList
            type={skillsType}
            rootClassName={classNames({
              [css.disabled]: updatingSkills || isLoadingSuggestedFrameworksSkills
            })}
            customValues={suggestedFrameworksSkills
              .slice(0, 5)
              .map((skill) => ({ ...skill, state: "approved" }))}
            showMoreButton
            openByDefault
            profileId={profile.id}
            currentProfile={currentProfile}
            currentAccount={currentAccount}
            isEditMode
            onRemoveClick={removeSuggestedSkill}
            onRateChange={() => invalidateProfileSkillsFrameworks(profile.id)}
          />
        </div>
      ),
      hide: !isMe || suggestedFrameworksSkills.length === 0
    },
    {
      content: (
        <div className={classNames(css.skillsTile, css.core)}>
          <div className={css.skillsTileHeader}>
            {t("coreSkills")}
            <Tooltip theme="pf-white" content={getInfoTooltipContent("tag_as_core")}>
              <span>
                <Icon name="info" size="sm" className={css.infoIcon} />
              </span>
            </Tooltip>
          </div>
          {Boolean(coreSkills.length) && (
            <CustomValuesList
              type={skillsType}
              customValues={coreSkills}
              showMoreButton
              openByDefault
              showTooltip
              profileId={profile.id}
              currentProfile={currentProfile}
              currentAccount={currentAccount}
              showModal
            />
          )}
          {Boolean(!coreSkills.length) && isMe && <AddSkillsButton skillsType={t("coreSkills")} />}
        </div>
      )
    },
    {
      content: (
        <div className={classNames(css.skillsTile, css.developmental)}>
          <div className={css.skillsTileHeader}>
            {t("developmentSkills")}
            <Tooltip theme="pf-white" content={getInfoTooltipContent("tag_as_developmental")}>
              <span>
                <Icon name="info" size="sm" className={css.infoIcon} />
              </span>
            </Tooltip>
          </div>
          {Boolean(developmentalSkills.length) && (
            <CustomValuesList
              type={skillsType}
              customValues={developmentalSkills}
              showMoreButton
              showTooltip
              profileId={profile.id}
              currentProfile={currentProfile}
              currentAccount={currentAccount}
              showModal
            />
          )}
          {Boolean(!developmentalSkills.length) && isMe && (
            <AddSkillsButton skillsType={t("developmentSkills")} />
          )}
        </div>
      ),
      hide: !isMe && developmentalSkills.length === 0
    },
    {
      content: (
        <div className={classNames(css.skillsTile, css.suggested)}>
          <div className={css.skillsTileHeader}>
            {t("suggestedSkills")}
            <Tooltip theme="pf-white" content={t("sectionSkills.suggestions.suggested.info")}>
              <span>
                <Icon name="info" size="sm" className={css.infoIcon} />
              </span>
            </Tooltip>
          </div>
          <CustomValuesList
            type={skillsType}
            rootClassName={classNames({ [css.disabled]: updatingSkills })}
            customValues={suggestedSkills}
            showMoreButton
            openByDefault
            profileId={profile.id}
            currentProfile={currentProfile}
            currentAccount={currentAccount}
            onRemoveClick={removeSuggestedSkill}
            isEditMode
          />
        </div>
      ),
      hide: !isMe || suggestedSkills.length === 0
    },
    {
      content: (
        <div className={classNames(css.skillsTile, css.rare)}>
          <div className={css.skillsTileHeader}>
            {t("rareSkills")}
            <Tooltip theme="pf-white" content={t("rareSkillsInfo")}>
              <span>
                <Icon name="info" size="sm" className={css.infoIcon} />
              </span>
            </Tooltip>
          </div>
          <CustomValuesList
            type={skillsType}
            customValues={rareSkills}
            showMoreButton
            openByDefault
            showTooltip
            profileId={profile.id}
            currentProfile={currentProfile}
            currentAccount={currentAccount}
            showModal
          />
        </div>
      ),
      hide: rareSkills.length === 0
    }
  ]
    .filter(({ hide }) => !hide)
    .map((item, index) => ({ id: index, ...item }));

  const [index, setIndex] = useState(0);

  const action = (
    <CarouselActions
      onClickLeft={() => setIndex(Math.max(index - 1, 0))}
      onClickRight={() => setIndex(Math.min(index + 1, items.length - 1))}
      index={index}
      scrollLength={items.length - 1}
    />
  );

  const noSkills =
    coreSkills.length +
      developmentalSkills.length +
      suggestedSkills.length +
      rareSkills.length +
      suggestedFrameworksSkills.length ===
    0;

  return !noSkills || isMe ? (
    <PageSection
      className={className}
      contentStyle={{ overflow: "auto" }}
      title={t("skillsGroups")}
      action={action}
    >
      {noSkills && isMe && <AddSkillsButton />}
      {!noSkills && <Carousel items={items} activeIndex={index} visibleItems={2} />}
      {isSkillsFrameworksModalOpened && (
        <SkillsFrameworksSuggestedSkillsModal
          handleClose={toggleSkillsFrameworksModalOpened}
          profile={profile}
        />
      )}
    </PageSection>
  ) : null;
};

export default SkillsGroups;
