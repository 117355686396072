import moment, { Moment, utc } from "moment";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Typography } from "PFCore/components/typography/typography";
import { BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import roundToDecimals from "../../../../../helpers/round_to_decimals";
import { getBlendedColorOnWhite, getContrastingTextColor } from "../../../../../utilities/color";
import { CalendarPeriod } from "../../bookings_calendar.types";
import { PeriodTooltips } from "./period_tooltips";
import { DAY_WIDTH, MOBILE_QUERY, MOBILE_VERTICAL_OFFSET, VERTICAL_OFFSET } from "./utils";
import css from "./week_period.module.scss";

const getPeriodDuration = (period: CalendarPeriod, startWeekDate: Moment, endWeekDate: Moment): number => {
  const { start_date, end_date } = period;

  const calculateDiff = () => {
    if (start_date.startOf("day") < startWeekDate.startOf("day")) {
      return Math.abs(utc(end_date).endOf("day").diff(startWeekDate.startOf("day"), "days")) + 1;
    } else if (end_date.endOf("day") > endWeekDate.endOf("day")) {
      return Math.abs(utc(start_date).startOf("day").diff(endWeekDate.endOf("day"), "days")) + 1;
    } else {
      return Math.abs(utc(end_date).endOf("day").diff(utc(start_date).startOf("day"), "days")) + 1;
    }
  };

  return calculateDiff();
};

type WeekPeriodProps = {
  period: CalendarPeriod;
  level: number;
  startWeekDate: Moment;
  endWeekDate: Moment;
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  onClick?: (period: CalendarPeriod) => void;
};

export const WeekPeriod = ({
  period,
  level,
  startWeekDate,
  endWeekDate,
  bookingCategories,
  jobCodeDisplayAs,
  onClick
}: WeekPeriodProps): JSX.Element => {
  const mobileMatches = useMediaQuery({ query: MOBILE_QUERY });
  const { t } = useTranslation();
  const periodShift = period.start_date.diff(startWeekDate, "days");
  const periodDuration = getPeriodDuration(period, startWeekDate, endWeekDate);
  const periodInWeekDuration = Math.min(periodDuration, 7);

  const momentDuration = moment.duration(period.duration, "minutes");
  const hours = roundToDecimals(momentDuration.asHours());

  const category = bookingCategories.find(({ id }) => id === period.booking_category_id);

  const periodLabel = `${period.title ?? t("untitled")} - ${hours}h`;

  const periodColor = getBlendedColorOnWhite([period.rgbHexColor].reverse(), 0.5).string();
  const textColor = getContrastingTextColor(periodColor).string();

  const mobileOffset = mobileMatches ? MOBILE_VERTICAL_OFFSET : 0;
  return (
    <BookingPill
      title={periodLabel}
      onClick={() => onClick && onClick(period)}
      category={{ color: periodColor }}
      className={css.period}
      style={{
        top: level * VERTICAL_OFFSET + mobileOffset,
        width: `${DAY_WIDTH * periodInWeekDuration}%`,
        left: `${DAY_WIDTH * (periodShift > 0 ? periodShift : 0)}%`,
        borderWidth: "1px"
      }}
    >
      <PeriodTooltips
        period={period}
        startWeekDate={startWeekDate}
        bookingCategories={bookingCategories}
        jobCodeDisplayAs={jobCodeDisplayAs}
        daysDuration={periodInWeekDuration}
      />
      <div
        className={css.periodLabel}
        style={{
          color: textColor
        }}
      >
        <Typography className={css.periodText} variant="labelRegular" tag="span">
          {category ? periodLabel : ""}
        </Typography>
      </div>
    </BookingPill>
  );
};
