import { useMutation, useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import xor from "lodash/xor";
import { updateBooking, UpdateBookingPayload } from "PFApp/booking/services/api";
import { MutationOptions } from "PFTypes/request_options";
import { useCallback } from "react";

import { useProfileInvalidate } from "../profile";
import { bookingKeys } from "./query_keys";

const mutationKey = ["booking-update"];

export const useBookingUpdate = (options?: MutationOptions<UpdateBookingPayload>) => {
  const cache = useQueryClient();
  const { invalidateProfileWithBooking } = useProfileInvalidate();

  const updateMutation = useMutation(
    async (payload: UpdateBookingPayload) => {
      const bookingCacheKey = bookingKeys.single(payload.id);
      await cache.cancelQueries(bookingCacheKey);
      return updateBooking(payload);
    },
    {
      mutationKey,
      onSuccess: (result, payload) => {
        const bookingCacheKey = bookingKeys.single(payload.id);

        return Promise.all([
          cache.invalidateQueries(bookingCacheKey),
          invalidateProfileWithBooking(payload.id)
        ]);
      },
      ...options
    }
  );

  const checkIsUpdating = useCallback(
    (bookingId: number) =>
      cache.isMutating({
        predicate: (mutation) => {
          const isUpdateMutation = isEmpty(xor(mutationKey, mutation.options.mutationKey));
          return isUpdateMutation && mutation.state.variables?.id === bookingId;
        }
      }),
    [cache]
  );

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    checkIsUpdating
  };
};
