import css from "./loading_spinner.scss";

type LoadingSpinnerProps = { height?: number | string; width?: number | string; style?: React.CSSProperties };

export const LoadingSpinner = ({ height, width, style }: LoadingSpinnerProps) => (
  <svg
    height={height || 20}
    width={width || 20}
    viewBox="0 0 14 14"
    className={css.rotating}
    style={style || { marginRight: 5 }}
  >
    <circle
      r="5"
      cx="7"
      cy="7"
      fill="transparent"
      stroke="var(--paletteBlue0)"
      strokeWidth="2"
      strokeDasharray="calc(80 * 31.42 / 100) 31.42"
    />
  </svg>
);
