import { useTranslation } from "react-i18next";

import { BookingDetailBooleanProperty } from "./booking_detail_boolean_property";
import css from "./booking_detail_category_settings.module.scss";

type BookingDetailCategorySettingsProps = {
  billable?: boolean;
  affectsAvailability?: boolean;
};

export const BookingDetailCategorySettings = ({
  billable,
  affectsAvailability
}: BookingDetailCategorySettingsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={css.root}>
      <BookingDetailBooleanProperty checked={billable} label={t("billable")} />
      <BookingDetailBooleanProperty checked={affectsAvailability} label={t("affectsAvailability")} />
    </div>
  );
};
