import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchBookingsFromAvailability } from "PFApp/booking/services/api";
import { RequirementType } from "PFTypes";

import { bookingsFromAvailabilityKeys } from "./query_keys";

interface BookingFromAvailability {
  start_date: string;
  end_date: string;
  duration: number;
  overrides_calendar: boolean;
  overrides_non_working_days: boolean;
  requirement_value: number;
  requirement_type: RequirementType;
  phase_source_id: string;
}

export type BookingsFromAvailabilityResponse = {
  ranges: BookingFromAvailability[];
  title?: string;
  description?: string;
};

interface Props {
  activityId?: number;
  profileId?: number;
  options?: Omit<
    UseQueryOptions<BookingsFromAvailabilityResponse>,
    "queryKey" | "queryFn" | "initialData"
  > & {
    initialData?: () => undefined;
  };
}

export const useBookingsFromAvailability = ({ activityId, profileId, options = {} }: Props) =>
  useQuery<BookingsFromAvailabilityResponse>(
    bookingsFromAvailabilityKeys.byActivityAndProfile(activityId, profileId),
    () =>
      fetchBookingsFromAvailability(
        activityId,
        profileId
      ) as unknown as Promise<BookingsFromAvailabilityResponse>,
    {
      retry: false,
      ...options
    }
  );
