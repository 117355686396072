import AppContext from "PFApp/app_context";
import ProfileCardConnected from "PFApp/components/profile_card_connected";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { calcFiltersLength } from "../helpers/calc_filters_length";
import MoreButton from "../parts/more_button";
import EmptyProfiles from "./empty_profiles";
import css from "./results.less";

const Info = () => {
  const {
    dispatch,
    store: {
      search: { profilesPending, profilesRequestFilters, profilesMeta, term }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");

  if (profilesMeta.total > 0) {
    const count = calcFiltersLength(profilesRequestFilters);

    return (
      <div className={css.profilesMatchInfo}>
        {profilesPending ? "..." : t("results.countProfilesMatched", { count: profilesMeta.total, term })}
        {count > 0 && (
          <Button
            kind="text"
            onClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_CLEAR_PROFILES_FILTERS })}
          >
            {t("results.clearFilters", { count })}
          </Button>
        )}
      </div>
    );
  }
  return false;
};

const ResultsProfiles = () => {
  const {
    dispatch,
    store: {
      search: { profiles, profilesMeta, profilesPending, profilesPage }
    }
  } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const isChatEnabled = useIsChatEnabled();

  return (
    <>
      <Info />
      {profiles.length === 0 &&
        (!profilesMeta.search_id ? <LoadingDots circlesEnabled pageViewEnabled /> : <EmptyProfiles />)}
      <ul style={{ padding: 0, opacity: profilesPending ? 0.5 : 1 }}>
        {profiles.map((profile) => (
          <ProfileCardConnected
            key={profile.id}
            profileResponse={profile}
            withChat={isChatEnabled}
            currentProfile={currentProfile}
          />
        ))}
      </ul>
      <MoreButton
        isLoading={profilesPending}
        renderedItemsCount={profiles.length}
        total={profilesMeta.total || 0}
        handleClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_MORE_PROFILES })}
        perPage={10}
        page={profilesPage}
      />
    </>
  );
};
export default ResultsProfiles;
