import classNames from "classnames";

import css from "./pill.module.scss";

type BasicPillProps = {
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export const BasicPill = ({ children, onClick, className, style, title, ...props }: BasicPillProps) => (
  <div
    role="button"
    aria-label={title}
    title={title}
    tabIndex={onClick ? 0 : -1}
    className={classNames(css.pill, className)}
    style={style}
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === "Enter" || event.key === " ") {
        onClick?.(event);
      }
    }}
    {...props}
  >
    {children}
  </div>
);
