import { trim } from "lodash";
import {
  SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID,
  SEARCH_AUTOCOMPLETE_FOOTER_ID
} from "PFApp/components/search_autocomplete";
import { SearchEntityData } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { AUTOCOMPLETE_ACTION_ICON_ID } from "PFApp/components/search_input";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { FocusEvent, RefObject, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

type UseSearchInput = {
  term: string;
  onTermChange: (text: string, entity?: SearchEntityData | null, focus?: boolean) => void;
  withUrlChanges?: boolean;
  searchInputRef: RefObject<HTMLInputElement>;
};

export const useSearchInput = ({
  term,
  onTermChange,
  withUrlChanges = true,
  searchInputRef
}: UseSearchInput) => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const [localTerm, setLocalTerm] = useState(term);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  useEffect(() => {
    setLocalTerm(term ?? "");
  }, [term]);

  const updateUrl = (term: string) => {
    if (!withUrlChanges) {
      return;
    }
    term ? searchParams.set("term", term) : searchParams.delete("term");
    history.replace({ search: searchParams.toString() });
  };

  const handleTermChange = (newTerm: string, entity: SearchEntityData | null = null) => {
    const trimmedTerm = trim(newTerm);

    onTermChange(trimmedTerm, entity);
    searchInputRef?.current?.focus();
    setIsDropdownOpened(false);
  };

  const handleSubmit = () => {
    updateUrl(localTerm);
    handleTermChange(localTerm);
  };

  const handleClear = () => {
    setLocalTerm("");
    handleTermChange("", null);
    updateUrl("");
  };

  const handleInputChange = (newTerm: string) => {
    setLocalTerm(newTerm);
    setIsDropdownOpened(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLDivElement>) => {
    const target = event.relatedTarget || document.activeElement;
    // to fix nothing happening on dropdown item click
    const clickedInDropdown =
      target &&
      (target.hasAttribute("data-dropdown-root") ||
        target.hasAttribute("data-dropdown-item") ||
        target.hasAttribute("data-dropdown-list"));
    // to fix nothing happening on search icon click
    const clickedOnSearchIcon = target?.id === AUTOCOMPLETE_ACTION_ICON_ID;
    // to fix nothing happening on see all results button click
    const clickedInDropdownFooter =
      target?.id === SEARCH_AUTOCOMPLETE_FOOTER_ID || target?.id === SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID;
    if (!clickedInDropdown && !clickedOnSearchIcon && !clickedInDropdownFooter) {
      setIsDropdownOpened(false);
    }
  };

  return {
    localTerm,
    isDropdownOpened,
    setIsDropdownOpened,
    handleClear,
    handleSubmit,
    handleInputChange,
    setLocalTerm,
    handleBlur,
    handleTermChange
  };
};
