import moment, { Moment } from "moment";
import { ISO_DATE_FORMAT } from "PFCore/helpers/date";

export const isDateWithTime = (date: Moment): boolean => {
  if (!date) {
    return false;
  }
  if (date.creationData().format === ISO_DATE_FORMAT) {
    return false;
  }
  return (
    date.diff(moment(date).startOf("day"), "seconds") !== 0 &&
    date.diff(moment(date).endOf("day"), "seconds") !== 0
  );
};

export const isBookingWithTime = (startDateUtc: Moment, endDateUtc: Moment): boolean =>
  isDateWithTime(startDateUtc) || isDateWithTime(endDateUtc);
