import { useBookingsOverviewContext } from "PFApp/booking/parts/overview/context/bookings_overview_context";
import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useTranslation } from "react-i18next";

import useBookingModulePermissions from "../../../../../use_booking_module_permissions";
import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import { useBookingFormContext } from "../../../booking_form";
import { BookingFormMode } from "../../../booking_form/use_booking_form";

interface ButtonsProps {
  id: number;
}

export const Buttons = ({ id }: ButtonsProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  const { modal } = useBookingFormContext();
  const { isPermittedToEdit } = useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const {
    calendarData: { data }
  } = useBookingsOverviewContext();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !demandRelated)
    .some((i) => !i.readonly);

  return (
    <div className={css.buttons}>
      <Button
        text={t("details.profile.addBooking")}
        className={css.button}
        kind="secondary"
        onClick={() =>
          modal.open(
            {
              profileId: id,
              ...(data.meta.search_id
                ? {
                    groupBookingData: {
                      searchId: data.meta.search_id,
                      total: data.meta.total
                    }
                  }
                : {})
            },
            { mode: BookingFormMode.Create }
          )
        }
        disabled={!hasWriteAccessToAtLeastOneCategory}
      />
      {isPermittedToEdit && (
        <ButtonLink rootClassName={css.button} kind="secondary" href={`/profiles/${id}/matching_roles`}>
          {t("details.profile.findRoles")}
        </ButtonLink>
      )}
    </div>
  );
};
