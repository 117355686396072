import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import some from "lodash/some";
import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";

export const isBookingFormForSingleBookingCreateModeValid = (values: BookingFormValues): boolean => {
  const { workforceMember, bookings: formBookings } = values;
  if (!workforceMember || isEmpty(formBookings)) {
    return false;
  }
  const areSomeBookingsInvalid = formBookings.some((formBooking) =>
    some(["startDate", "endDate", "requirement.type", "requirement.value"], (path) =>
      isNil(get(formBooking, path))
    )
  );
  const areGlobalValuesInvalid = some(
    ["globalCategory", "globalOverridesNonWorkingTime", "globalOverridesDiaryTime"],
    (path) => isNil(get(values, path))
  );
  return !areSomeBookingsInvalid && !areGlobalValuesInvalid;
};

export const isBookingFormForSingleBookingValid = (values: BookingFormValues): boolean => {
  const { workforceMember, bookings: formBookings } = values;
  if (!workforceMember || isEmpty(formBookings)) {
    return false;
  }
  return !formBookings.some((formBooking) =>
    some(
      [
        "startDate",
        "endDate",
        "requirement.type",
        "requirement.value",
        "category",
        "overridesNonWorkingTime",
        "overridesDiaryTime"
      ],
      (path) => isNil(get(formBooking, path))
    )
  );
};

export const isBookingFormForSingleBookingEditModeValid = (values: BookingFormValues): boolean => {
  if (!isBookingFormForSingleBookingValid(values)) {
    return false;
  }
  const { bookings: formBookings } = values;
  const isMissingId = formBookings.some(({ id }) => isNil(id));
  return !isMissingId;
};
