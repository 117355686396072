import PropTypes from "prop-types";

import css from "./zoom.module.scss";

const WIDTHS = [7, 15, 30, 45, 60, 100];

const Zoom = ({ dayWidth, setDayWidth }) => {
  const val = WIDTHS.indexOf(dayWidth);

  return (
    <div className={css.wrap}>
      <div className={css.push} />

      <div className={css.box}>
        <input
          type="range"
          title="Zoom"
          className={css.range}
          onMouseUp={(e) => setDayWidth(WIDTHS[e.target.value])}
          onTouchEnd={(e) => setDayWidth(WIDTHS[e.target.value])}
          onChange={(e) => setDayWidth(WIDTHS[e.target.value])}
          onInput={(e) => setDayWidth(WIDTHS[e.target.value])}
          value={val !== undefined ? val : 1}
          min={0}
          max={WIDTHS.length - 1}
          step={1}
        />
      </div>
    </div>
  );
};

Zoom.propTypes = {
  dayWidth: PropTypes.number.isRequired,
  setDayWidth: PropTypes.func.isRequired
};

export default Zoom;
