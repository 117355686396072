import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityMode, AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";

type AvailabilityInfoProps = {
  availability: AvailabilityRule;
};

export const AvailabilityInfo = ({ availability }: AvailabilityInfoProps) => {
  const { t } = useTranslation("bookingModule");
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();
  const { readableAvailability } = useReadableAvailability({ availability: availability });

  const getRangesTooltipContent = (availability) => {
    if (!availability) {
      return undefined;
    }
    return availability.mode === AvailabilityMode.TimeRule
      ? availabilityRangesFormat(availability, true)
      : availabilityIntervalFormat(availability);
  };

  const rangesTooltipContent = getRangesTooltipContent(availability);

  return (
    <BookingDetailItem
      label={t("bookings.availabilityRules")}
      value={readableAvailability ?? ""}
      infoContent={rangesTooltipContent}
    />
  );
};
