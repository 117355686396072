import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { CreateBookingPayload } from "PFApp/booking/services/api";
import { Profile } from "PFTypes";

import { getProfileActivityOrSearchObject, mapBasicSingleBooking } from "./get_payload.utils";
import { isBookingFormForSingleBookingValid } from "./validate";

export type GetPayloadForCloneSingle = { values: BookingFormValues };

export const getPayloadForCloneSingle = ({ values }: GetPayloadForCloneSingle): CreateBookingPayload => {
  if (!isBookingFormForSingleBookingValid(values)) {
    throw new Error("Invalid form values for cloning a booking!");
  }
  const { workforceMember, activityId, bookings: formBookings } = values;

  const profileActivityOrSearchObject = getProfileActivityOrSearchObject(values);
  const bookings = formBookings.map((formBooking) => {
    const { category, title, description, overridesDiaryTime, overridesNonWorkingTime, phaseSourceId } =
      formBooking;
    return {
      ...mapBasicSingleBooking(formBooking),
      bookingCategoryId: category!.id,
      ...(title ? { title } : {}),
      ...(description ? { description } : {}),
      overridesCalendar: overridesDiaryTime!,
      overridesNonWorkingDays: overridesNonWorkingTime!,
      phaseSourceId
    };
  });

  return {
    ...profileActivityOrSearchObject,
    profileId: (workforceMember as Profile).id,
    activityId,
    sourceType: "ui",
    bookings
  };
};
