import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { Activity, Owner, Profile } from "PFTypes";
import { useMemo } from "react";

import { useActivityTemplateType } from "../../../hooks";
import { getOrderedActivityProfiles } from "../helpers/get_ordered_activity_profiles";

type OwnersResult = {
  owners: Profile[];
  principalOwner?: Profile;
  canView: boolean;
  canWrite: boolean;
};

export const useOwners = (activity?: Partial<Activity>): OwnersResult => {
  const activeOwners = getActiveActivityProfiles<Owner>(activity?.owners || []);
  const principalOwner = activeOwners.find((item) => item.principal)?.profile;

  const owners = useMemo(
    () =>
      getOrderedActivityProfiles(
        activeOwners.filter(({ principal }) => !principal).map(({ profile }) => profile)
      ),
    [activeOwners]
  );

  const { isRole, isEngagement } = useActivityTemplateType(activity);
  const canWrite = (isRole || isEngagement) && !!activity?.coowned;

  return { owners, principalOwner, canView: owners.length > 0, canWrite };
};
