import uniqueId from "lodash/uniqueId";
import { minutesToHours } from "PFCore/helpers/date";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, BookingTemplate, RequirementType } from "PFTypes";
import { useCallback } from "react";

import { BookingDataItem, BookingDataItemLimitation, BookingFormDataCreateMode } from "../use_booking_form";
import { convertWeekDayMaskToDays } from "../week_days_select";
import { calculateRepeatDays } from "./range_item/calculate_repeat_days";

export const UNIQUE_ID_PREFIX = "default-booking-key";

type CheckLimitation = { bookingCategoryId?: number; source?: string; readonly?: boolean };

type APIBooking = Pick<Booking, "start_date" | "end_date" | "requirement_type" | "requirement_value"> &
  Partial<
    Pick<
      Booking,
      | "id"
      | "title"
      | "description"
      | "booking_category_id"
      | "overrides_calendar"
      | "overrides_non_working_days"
      | "phase_source_id"
      | "readonly"
      | "source"
    >
  >;

type UseDefaultBookingsMapper = {
  withId?: boolean;
  mapTitle?: (title?: string) => string;
};

type UseDefaultBookingMapperReturn = {
  getDefaultBooking: () => BookingDataItem;
  mapDefaultBookings: (bookings: BookingFormDataCreateMode["bookings"]) => BookingDataItem[];
  mapAPIBookings: (bookings: APIBooking[]) => BookingDataItem[];
  mapBookingTemplate: (bookingTemplate: BookingTemplate) => BookingDataItem[];
};

export const useDefaultBookingsMapper = ({
  withId = false,
  mapTitle
}: UseDefaultBookingsMapper): UseDefaultBookingMapperReturn => {
  const { utc, formatISODate, formatISOTime } = useDateFormatter();
  const { getBookingCategory } = useBookingCategories();

  const checkLimitation = useCallback(
    ({ bookingCategoryId, source, readonly }: CheckLimitation): BookingDataItemLimitation => {
      const category = getBookingCategory(bookingCategoryId);
      if (bookingCategoryId && !category) {
        return BookingDataItemLimitation.Hidden;
      }
      if ((category && category.readonly) || !!readonly || (source && source !== "ui")) {
        return BookingDataItemLimitation.Readonly;
      }
      return BookingDataItemLimitation.None;
    },
    [getBookingCategory]
  );

  const getDefaultBooking = useCallback(
    () => ({
      key: uniqueId(UNIQUE_ID_PREFIX),
      startDate: formatISODate(utc()),
      endDate: formatISODate(utc()),
      overridesDiaryTime: true,
      overridesNonWorkingTime: false,
      // default for Single Booking type:
      requirement: {
        type: RequirementType.Load,
        value: 100
      },
      // default for Repeated Booking type:
      startTime: formatISOTime(utc().startOf("day")),
      endTime: formatISOTime(utc().endOf("day")),
      limitation: BookingDataItemLimitation.None
    }),
    [formatISOTime, formatISODate, utc]
  );

  const mapDefaultBookings = useCallback(
    (bookings: BookingFormDataCreateMode["bookings"] = []): BookingDataItem[] =>
      bookings.map(({ startDate, endDate }) => ({
        ...getDefaultBooking(),
        startDate,
        endDate
      })),
    [getDefaultBooking]
  );

  const mapAPIBookings = useCallback<(bookings: APIBooking[]) => BookingDataItem[]>(
    (bookings) =>
      bookings.map(
        ({
          id,
          start_date: startDate,
          end_date: endDate,
          requirement_type: requirementType,
          requirement_value: requirementValue,
          title,
          description,
          booking_category_id: bookingCategoryId,
          overrides_calendar: overridesDiaryTime,
          overrides_non_working_days: overridesNonWorkingTime,
          phase_source_id: phaseSourceId,
          readonly,
          source
        }) => ({
          ...getDefaultBooking(),
          ...(withId ? { id } : {}),
          startDate,
          endDate,
          requirement: {
            type: requirementType,
            value:
              requirementType === RequirementType.Load ? requirementValue : minutesToHours(requirementValue)
          },
          title: mapTitle ? mapTitle(title) : title,
          description,
          category: getBookingCategory(bookingCategoryId),
          overridesDiaryTime,
          overridesNonWorkingTime,
          phaseSourceId,
          limitation: checkLimitation({ bookingCategoryId, readonly, source })
        })
      ),
    [getDefaultBooking, getBookingCategory, withId, mapTitle, checkLimitation]
  );

  const mapBookingTemplate = useCallback<(bookingTemplate: BookingTemplate) => BookingDataItem[]>(
    (bookingTemplate) => {
      const {
        id,
        start_date: startDate,
        start_time: startTime,
        end_date: endDate,
        end_time: endTime,
        booking_category_id: bookingCategoryId,
        title,
        description,
        wday_mask: wdayMask
      } = bookingTemplate;
      const { allowedWeekDays } = calculateRepeatDays({ start: utc(startDate), end: utc(endDate) });
      const selectedWeekDays = convertWeekDayMaskToDays(wdayMask);
      const repeatDays = { selectedWeekDays, allowedWeekDays };
      return [
        {
          ...getDefaultBooking(),
          ...(withId ? { bookingTemplateId: id } : {}),
          startDate,
          startTime,
          endDate,
          endTime,
          title: mapTitle ? mapTitle(title) : title,
          description,
          category: getBookingCategory(bookingCategoryId),
          limitation: checkLimitation({ bookingCategoryId }),
          repeatDays
        }
      ];
    },
    [getDefaultBooking, getBookingCategory, withId, mapTitle, checkLimitation]
  );

  return {
    getDefaultBooking,
    mapDefaultBookings,
    mapAPIBookings,
    mapBookingTemplate
  };
};
