import classNames from "classnames";
import { isArray, noop, some } from "lodash";
import { Button, ButtonProps } from "PFComponents/button";
import DropDown, { DropdownOptions, DropDownProps } from "PFComponents/dropdown/dropdown";
import { getAriaProps } from "PFCore/helpers/get_aria_props";
import useClickOutside from "PFCore/helpers/use_click_outside";
import { IconName } from "PFTheme/graphics/icons";
import {
  AriaAttributes,
  CSSProperties,
  ForwardRefExoticComponent,
  MouseEvent,
  ReactNode,
  RefAttributes,
  useRef,
  useState
} from "react";
import { usePopper } from "react-popper";

import css from "./dropdown_button.module.scss";

type DropdownButtonProps = AriaAttributes & {
  children?: ReactNode;
  icon?: IconName;
  text?: string;
  small?: boolean;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  qaId?: string;
  options: DropdownOptions;
  buttonKind?: ButtonProps["kind"];
  /**
   * @deprecated Use one of the Button variants
   */
  buttonStyle?: CSSProperties;
  buttonClassName?: string;
  dropDownStyle?: CSSProperties;
  dropDownClassName?: string;
  dropdownProps?: Partial<DropDownProps>;
  handleChange: (item: any) => void;
  handleClose?: () => void;
  handleClick?: (event: any, menuShown: boolean) => void;
  popperOptions?: object;
  customButtonComponent?: ForwardRefExoticComponent<
    {
      disabled: boolean;
      onClick: (event: MouseEvent) => void;
    } & RefAttributes<HTMLButtonElement>
  >;
};

export const DropdownButton = ({
  icon,
  text,
  small,
  style,
  className,
  disabled = false,
  qaId = "Button",
  options,
  buttonKind,
  buttonStyle,
  buttonClassName,
  dropDownStyle,
  dropDownClassName,
  dropdownProps = {},
  children,
  handleChange,
  handleClose,
  handleClick = noop,
  popperOptions,
  customButtonComponent: CustomButtonComponent,
  ...props
}: DropdownButtonProps): JSX.Element => {
  const [menuShown, setMenuShown] = useState(false);
  const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "auto-end",
    strategy: "fixed",
    ...popperOptions
  });

  const { ariaProps } = getAriaProps(props);
  const dropDownRef = useRef(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => {
    setMenuShown(false);
    handleClose && handleClose();
  });

  const defaultStyle: React.CSSProperties = {
    minWidth: 100,
    maxWidth: 300,
    textAlign: "left",
    position: "relative",
    display: "block",
    marginBottom: 0,
    ...dropDownStyle
  };

  // TODO: [PROF-7192] It should be refactored as a part of Dropdown refactor ticket
  // This is a hack to make the dropdown work with keyboard navigation
  // The same implementation is done in select.tsx
  // @ts-ignore
  const handleKeyDown = (event) => dropDownRef.current && dropDownRef.current.handleKeyDown(event);

  const handleBlur = ({ relatedTarget }) => {
    const clickedInDropdown =
      relatedTarget &&
      some(
        ["data-dropdown-root", "data-dropdown-item", "data-dropdown-list", "data-dropdown-button-menu"],
        (attribute) => relatedTarget.hasAttribute(attribute)
      );

    if (clickedInDropdown) {
      return;
    }

    setMenuShown(false);
    handleClose && handleClose();
  };

  const childContent = children
    ? {
        children: children
      }
    : { icon, text };

  const button = CustomButtonComponent ? (
    <CustomButtonComponent
      ref={setPopperReferenceElement}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        setMenuShown(!menuShown);
        handleClick && handleClick(event, !menuShown);
      }}
    />
  ) : (
    <Button
      {...childContent}
      aria-expanded={menuShown}
      aria-haspopup="true"
      ref={setPopperReferenceElement}
      small={small}
      kind={buttonKind}
      disabled={disabled}
      qaId={qaId}
      onClick={(event) => {
        event.stopPropagation();
        setMenuShown(!menuShown);
        handleClick && handleClick(event, !menuShown);
      }}
      style={buttonStyle}
      className={buttonClassName}
      {...ariaProps}
    />
  );

  return (
    <div
      tabIndex={-1}
      role="menu"
      ref={menuRef}
      className={classNames(css.dropdownButton, className)}
      style={style}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      data-dropdown-button-menu="true"
    >
      {button}
      {menuShown && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 20 }} {...attributes.popper}>
          <DropDown
            ref={dropDownRef}
            style={defaultStyle}
            rootClassName={dropDownClassName}
            options={options}
            handleChange={(item) => {
              if (!isArray(item)) {
                !item.keepOpen && setMenuShown(false);
              }

              handleChange && handleChange(item);
            }}
            handleClose={() => {
              setMenuShown(false);
              handleClose && handleClose();
            }}
            {...dropdownProps}
          />
        </div>
      )}
    </div>
  );
};
