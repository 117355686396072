import SidePanel from "PFComponents/side_panel/side_panel";
import React from "react";

import BookingActivityContextProvider from "../../../parts/providers/booking_activity_context_provider";
import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../details_panel";
import { EngagementDetailContent } from "./engagement_detail";
import { EngagementDetailHeader } from "./engagement_detail_header";

type EngagementDetailSidePanelProps = DetailsSidePanelProps & Pick<DetailsPanelComponentProps, "data">;

const EngagementDetailSidePanel = ({
  isOnTop,
  show,
  onClose,
  zIndex,
  data
}: EngagementDetailSidePanelProps) => (
  <SidePanel
    show={show}
    zIndex={zIndex}
    isOnTop={isOnTop}
    isSidePanelClosing={data.isClosing}
    onClose={onClose}
    title={<EngagementDetailHeader />}
  >
    <EngagementDetailContent />
  </SidePanel>
);

export const EngagementSidePanelWrapper = React.memo((props: EngagementDetailSidePanelProps) => (
  <BookingActivityContextProvider id={props.show ? props.data?.id : undefined}>
    <EngagementDetailSidePanel {...props} />
  </BookingActivityContextProvider>
));

EngagementSidePanelWrapper.displayName = "EngagementSidePanelWrapper";
