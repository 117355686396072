import { Button } from "PFComponents/button";
import { Markdown } from "PFComponents/markdown";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./experience_section.modules.scss";

type DescriptionProps = {
  content: string;
  maxLength: number;
};

export const ExperienceDescription = ({ content, maxLength }: DescriptionProps) => {
  const { t } = useTranslation("translation");

  const [collapsedDescription, setCollapsedDescription] = useState(true);

  return (
    <div className={css.markdownRow}>
      <Markdown
        raw={content}
        crop={collapsedDescription ? maxLength : 1e10}
        displayToggle={false}
        emojify={false}
      />
      {content && content.length > maxLength && (
        <Button
          kind="text"
          text={t(collapsedDescription ? "more" : "less")}
          onClick={() => setCollapsedDescription((prev) => !prev)}
        />
      )}
    </div>
  );
};
