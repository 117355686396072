import BookingPill from "PFApp/components/pills/booking_pill";
import Tooltip from "PFComponents/tooltip/tooltip";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./bar.module.scss";

const daysToMs = (days) => days * 24 * 3600 * 1000;

const Bar = ({
  bookingCategoryId,
  width,
  left,
  top,
  load,
  tooltipTheme,
  tooltipContent,
  dayWidth,
  startTime,
  partial,
  partialDayContent,
  forceBg
}) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();

  const isFullLoad = (!load && load !== 0) || load === 100;

  const [tooltipFooter, setTooltipFooter] = useState("");

  const { getBookingCategory } = useBookingCategories();
  const category = getBookingCategory(bookingCategoryId);

  // merge adjacent days
  const processedPartials = useMemo(() => {
    if (!isFullLoad || !partial) {
      return null;
    }
    // if it's not full then the whole thing is in stripes anyway
    const partials = [...partial].map((iso) => ({
      left: Math.floor((new Date(iso.date).getTime() - startTime.valueOf()) / (24 * 3600 * 1000)),
      width: 1
    }));

    for (let i = partials.length - 1; i >= 0; i--) {
      const cur = partials[i];
      const prev = partials[i - 1];

      if (prev && cur.left - 1 === prev.left) {
        prev.width += cur.width;
        cur.width = 0;
      }
    }

    return partials.filter((it) => it.width !== 0);
  }, [load, partial, startTime]);

  const tooltipContentComplete = useMemo(
    () => (
      <>
        {tooltipContent}
        <div style={{ marginTop: 5 }}>{tooltipFooter}</div>
      </>
    ),
    [tooltipContent, tooltipFooter]
  );

  const handleMouseMove = (event) => {
    const targetDimensions = event.target.getBoundingClientRect();
    const dayCount = targetDimensions.width / dayWidth;
    const dayIndex = Math.floor(
      ((event.clientX - targetDimensions.left) / targetDimensions.width) * dayCount
    );

    const targetDay = formatDate(moment.utc(startTime.valueOf() + daysToMs(dayIndex)));

    const availableMinutes = partialDayContent && partialDayContent[dayIndex];
    setTooltipFooter(
      availableMinutes ? t("ganttAvailableMinutes", { day: targetDay, minutes: availableMinutes }) : targetDay
    );
  };

  const handleMouseLeave = () => setTooltipFooter("");

  return (
    <Tooltip
      className={css.tooltip}
      placement="bottom"
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altBoundary: true
            }
          }
        ]
      }}
      interactive
      content={tooltipContentComplete}
      theme={tooltipTheme || "pf-light"}
    >
      <div
        className={css.root}
        style={{
          width,
          minWidth: dayWidth,
          transform: `translate(${left}px, ${top}px)`
        }}
      >
        <BookingPill
          className={css.pill}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          category={forceBg ? { color: forceBg } : category}
          withNoCategoryIcon={false}
        >
          <div>
            {processedPartials && (
              <div className={css.partials}>
                {processedPartials.map((partial, index) => (
                  <div
                    key={index}
                    className={css.partialItem}
                    style={{
                      left: partial.left * dayWidth,
                      width: partial.width * dayWidth
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </BookingPill>
      </div>
    </Tooltip>
  );
};

Bar.propTypes = {
  badge: PropTypes.any,
  bookingCategoryId: PropTypes.number,
  dayWidth: PropTypes.number.isRequired,
  forceBg: PropTypes.string,
  left: PropTypes.number.isRequired,
  load: PropTypes.number,
  partial: PropTypes.array,
  partialDayContent: PropTypes.object,
  startTime: PropTypes.object,
  tooltipContent: PropTypes.node.isRequired,
  tooltipTheme: PropTypes.string,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default Bar;
