import classNames from "classnames";
import ActivityActions from "PFApp/components/activity_actions/activity_actions";
import HeaderTimeAgo from "PFApp/components/card_tx/header/header_time_ago";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import PropTypes from "prop-types";

import css from "./header.module.scss";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
const PostHeader = ({
  task,
  isFeed,
  handleEditPost,
  handleHeaderClick,
  onShare,
  handleCloseActivitySuccess
}) => (
  <div className={classNames(css.wrapper, css.postHeader)}>
    <div className={classNames(css.section, css.clickable)} onClick={handleHeaderClick}>
      <div className={css.avatarSection}>
        <ProfileAvatar size={40} profile={task.profile} />
        <div className={css.name} title={getProfileName(task.profile)}>
          {getProfileName(task.profile)}
        </div>
      </div>
    </div>
    <div className={classNames(css.section, css.actions)}>
      <HeaderTimeAgo time={task.created_at} />
      {task.coowned && (
        <ActivityActions
          task={task}
          buttonSize="xs"
          isFeed={isFeed}
          handleEditPost={handleEditPost}
          handleCloseActivitySuccess={handleCloseActivitySuccess}
          additionalOptions={
            onShare && [
              {
                id: "share",
                displayElement: "Share",
                item: () => onShare(task.id)
              }
            ]
          }
        />
      )}
    </div>
  </div>
);

PostHeader.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    profile: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.shape({
        thumbnail_url: PropTypes.string
      })
    }),
    coowned: PropTypes.bool
  }),
  isFeed: PropTypes.bool,
  handleEditPost: PropTypes.func,
  handleHeaderClick: PropTypes.func,
  onShare: PropTypes.func,
  handleCloseActivitySuccess: PropTypes.func
};

export default PostHeader;
