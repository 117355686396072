import EngagementIcon from "PFIcons/engagement";
import EventIcon from "PFIcons/event";
import LessonIcon from "PFIcons/lesson";
import PositionIcon from "PFIcons/position";
import ProjectIcon from "PFIcons/project";
import QuestionIcon from "PFIcons/question";
import RoleIcon from "PFIcons/role";
import SuggestionIcon from "PFIcons/suggestion";
import PropTypes from "prop-types";

import css from "./activity_type_badge.module.scss";

const Icons = {
  role: RoleIcon,
  event: EventIcon,
  question: QuestionIcon,
  suggestion: SuggestionIcon,
  position: PositionIcon,
  lesson: LessonIcon,
  engagement: EngagementIcon,
  project: ProjectIcon
};

const ActivityTypeBadge = ({ type }) => {
  const Icon = Icons[type.key];

  return (
    <div className={css.root}>
      {Icon && (
        <div className={css.icon}>
          <Icon />
        </div>
      )}
      <span className={css.text}>{type.name}</span>
    </div>
  );
};

ActivityTypeBadge.propTypes = {
  type: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }).isRequired
};

export default ActivityTypeBadge;
