import { useWatch } from "react-hook-form";

import { BookingFormValues } from "./booking_form_provider";
import { useArePhasesRequired } from "./phases_selector/use_are_phases_required";

export const useAreBookingsValid = (): boolean => {
  const bookings = useWatch<BookingFormValues>({
    name: "bookings"
  });
  const arePhasesRequired = useArePhasesRequired();

  if (!arePhasesRequired) {
    return true;
  } else {
    return (bookings ?? []).every(({ phaseSourceId }) => !!phaseSourceId);
  }
};
