import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";
import { useEffect } from "react";
import { FieldPath, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingFormValues } from "./booking_form_provider";

interface UseCategoryError {
  categoryFormValueName: FieldPath<BookingFormValues>;
}

export const useCategoryError = ({ categoryFormValueName }: UseCategoryError) => {
  const { t } = useTranslation("bookingModule");
  const isEnabled = useIsFeatureEnabled();
  const { setError, clearErrors } = useFormContext();
  const [activityId, category] = useWatch({ name: ["activityId", categoryFormValueName] });
  const isBookingModuleEnabled = isEnabled(FeatureFlag.BookingModule);
  const isDemandRelated = !!activityId;

  useEffect(() => {
    if (!category || !isBookingModuleEnabled) {
      clearErrors(categoryFormValueName);
      return;
    }

    if (isDemandRelated && !category.activity_assignable) {
      setError(categoryFormValueName, {
        message: t("bookings.create.categoryErrors.selectDemandRelatedCategory")
      });
      return;
    }

    if (!isDemandRelated && category.activity_assignable) {
      setError(categoryFormValueName, {
        message: t("bookings.create.categoryErrors.selectNonDemandRelatedCategory")
      });
      return;
    }

    clearErrors(categoryFormValueName);
  }, [category, isDemandRelated]);
};
