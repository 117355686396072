import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";

import { customValueKeys } from "./query_keys";

export const useCustomValuesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: () => queryClient.invalidateQueries(customValueKeys.all()),
    invalidateForType: (customTypeId: Id) => queryClient.invalidateQueries(customValueKeys.list(customTypeId))
  };
};
