import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import Tooltip from "PFComponents/tooltip/tooltip";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { FeatureFlag, PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import React, { lazy, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./lazy_local_export_trigger.module.scss";

const LocalExportTrigger = lazy(() => import(/* webpackChunkName: "export" */ "./local_export_trigger"));

const LazyLocalExportTrigger = ({
  quantity,
  getProfilesCollection,
  getRawCollection,
  isBlindModeOn,
  buttonKind,
  icon,
  label,
  tooltipContent,
  qaId,
  triggerButtonRef
}) => {
  const { t } = useTranslation();
  const [profilesEntities, setProfilesEntities] = useState(null);
  const [rawCollection, setRawCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isEnabled = useIsFeatureEnabled();
  const isProfileExportEnabled = isEnabled(FeatureFlag.ProfileExporter);

  const canExportDocs = useIsCurrentUserPermittedTo(PermissionRule.ExportDocuments);

  const Wrapper = useMemo(
    () =>
      tooltipContent
        ? ({ children }) => <Tooltip content={tooltipContent}>{children}</Tooltip>
        : React.Fragment,
    [tooltipContent]
  );

  if (!isProfileExportEnabled || !canExportDocs) {
    return null;
  }

  if (quantity === 0) {
    return null;
  }

  if (profilesEntities && (rawCollection || !getRawCollection)) {
    return (
      <Wrapper>
        <LocalExportTrigger
          initModalShown={true}
          handleModalClose={() => {
            setProfilesEntities(null);
            setIsLoading(false);
          }}
          profilesEntity={profilesEntities}
          rawDataCollection={rawCollection}
          getProfilesCollection={getProfilesCollection}
          isBlindModeOn={isBlindModeOn}
          icon={icon}
          label={label}
          buttonKind={buttonKind}
          qaId={qaId}
        />
      </Wrapper>
    );
  }

  if (isLoading) {
    return <LoadingDots className={css.loadingDots} />;
  }

  return (
    <Wrapper>
      <Button
        ref={triggerButtonRef}
        icon={icon}
        text={label || (!icon && t("export.export"))}
        kind={buttonKind}
        onClick={() => {
          setIsLoading(true);
          getRawCollection &&
            getRawCollection().then((resp) => {
              setRawCollection(resp);
            });
          getProfilesCollection().then((resp) => {
            setProfilesEntities(resp);
            setIsLoading(false);
          });
        }}
        qaId={qaId}
      />
    </Wrapper>
  );
};

LazyLocalExportTrigger.propTypes = {
  classes: PropTypes.object,
  quantity: PropTypes.number,
  getProfilesCollection: PropTypes.func.isRequired,
  getRawCollection: PropTypes.func,
  isBlindModeOn: PropTypes.bool,
  buttonKind: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.node,
  tooltipContent: PropTypes.string,
  qaId: PropTypes.string,
  triggerButtonRef: PropTypes.any
};

LazyLocalExportTrigger.defaulProps = {
  isBlindModeOn: false,
  buttonKind: "text"
};

export default LazyLocalExportTrigger;
