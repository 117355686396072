import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { MatchCard } from "PFApp/components/activity_match_card";
import { LoadingDots } from "PFComponents/loading_dots";
import { useToggle } from "react-use";

import { ShortlistEmptyState } from "./shortlist_empty_state";
import { ShortlistHeader } from "./shortlist_header";
import { useClearShortlist } from "./use_clear_shortlist";

export const ShortlistMainPanel = () => {
  const {
    task: activity,
    allShortlists,
    rankedShortlists,
    isLoadingShortlists,
    isActivityUnbiased,
    vacanciesLoading,
    existsOpenVacancy,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison
  } = useActivityPageContext();
  const [isCardsCompressed, toggleCardsCompressed] = useToggle(true);
  const { handleClearShortlist, isLoading: isClearShortlistLoading } = useClearShortlist();

  const isLoading = isLoadingShortlists || isClearShortlistLoading;

  return (
    <div className={commonCss.pageCardMainPanel}>
      <ShortlistHeader
        isCardsCompressed={isCardsCompressed}
        setIsCardsCompressed={toggleCardsCompressed}
        handleClearShortlist={handleClearShortlist}
      />
      {isLoading && <LoadingDots circlesEnabled circleSize="xs" className={commonCss.loading} />}
      <div className={commonCss.pageCardEntriesList} data-qa-id="ActivityShortlistTabMatchesContainer">
        {allShortlists.length > 0 &&
          allShortlists.map((shortlist) => {
            const match = (rankedShortlists || []).find((it) => it.profile.id === shortlist.profile.id);

            return (
              <MatchCard
                key={shortlist.id}
                match={match}
                shortlist={shortlist}
                role={activity!}
                isCompressed={isCardsCompressed}
                isBlindModeOn={isActivityUnbiased}
                isProfileInComparison={isProfileInComparison}
                addProfileToComparison={addProfileToComparison}
                removeProfileFromComparison={removeProfileFromComparison}
                isFillDisabled={vacanciesLoading || !existsOpenVacancy}
                areButtonsDisabled={isLoading}
              />
            );
          })}
        {!isLoading && !allShortlists.length && <ShortlistEmptyState />}
      </div>
    </div>
  );
};
