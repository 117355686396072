import { chain, cloneDeep, filter, omit, set } from "lodash";
import moment from "moment";
import { useTemplate } from "PFApp/hooks";
import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, AvailabilityRule, FeatureFlag } from "PFTypes";
import { useCallback, useMemo } from "react";

import { extractValuesWithTypes } from "./custom_fields";

export const activityNotificationTypes = [
  "Task",
  "Activities::Activity",
  "ProfileActivities::Assignee",
  "ProfileActivities::Reviewer",
  "ProfileActivities::Owner"
];

export const useRoleName = () => {
  const isEnabled = useIsFeatureEnabled();
  const showRoleId = isEnabled(FeatureFlag.ShowActivityId);

  const getRoleName = useCallback(
    (role) => {
      const name = role.name || role.title;
      if (showRoleId) {
        return `${role.id} - ${name}`;
      } else {
        return name;
      }
    },
    [showRoleId]
  );

  return getRoleName;
};

export const useActivityNameAndId = () => {
  const isEnabled = useIsFeatureEnabled();
  const showRoleId = isEnabled(FeatureFlag.ShowActivityId);

  const getRoleNameId = useCallback(
    (role) => {
      const name = role.name || role.title;

      return { name: name, id: showRoleId ? role.id : null };
    },
    [showRoleId]
  );

  return getRoleNameId;
};

export const useMatchableTypes = (templateId) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();

  const template = useTemplate(templateId);

  const matchableTypes = useMemo(() => {
    const visibleProperties = getVisibleProperties(template);

    const accessLevels = activityCustomTypesAccessLevels(currentProfile);
    return visibleProperties.filter((element) => {
      if (element.type === "custom_field" && !!element.match) {
        const customType = customTypes.find((type) => type.name === element.name);
        return customType && accessLevels[customType.id] === "rw";
      } else {
        return false;
      }
    });
  }, [template?.id, customTypes, currentProfile]);

  return matchableTypes;
};

export const isOpen = (activity: Activity) => [null, "new", "review"].includes(activity.state);

export const isUserParticipating = (activity: Activity, id) =>
  !!chain(activity.participants).map("profile").find({ id: id }).value();

export const getCustomFieldValues = (activity: Activity, options) => {
  // does not work with v1
  let customValues = extractValuesWithTypes(activity.custom_fields);
  /* eslint-disable no-prototype-builtins */
  if (options && options.hasOwnProperty("importance")) {
    customValues = filter(customValues, { importance: options.importance });
  }
  return customValues.sort((aValue, bValue) => {
    if (aValue.importance === bValue.importance) {
      return 0;
    } else if ((aValue.importance || 0) > (bValue.importance || 0)) {
      return -1;
    } else {
      return 1;
    }
  });
};

export const sanitizeActivityToClone = (activity: Activity, fromSearch: boolean) => {
  const clonedActivity = cloneDeep(omit(activity, ["id", "state", "post_until"]));

  if (fromSearch) {
    set(clonedActivity, "metadata", {});
  } else {
    const availability = clonedActivity.metadata?.availability as AvailabilityRule;

    if (availability) {
      const rangesToCopy = availability.ranges.filter(
        (range) => !moment.utc(range.start).isBefore(moment.utc())
      );

      if (rangesToCopy.length > 0) {
        set(clonedActivity, "metadata.availability.ranges", rangesToCopy);
      } else {
        set(clonedActivity, "metadata", omit(activity.metadata, "availability"));
      }
    }
  }

  return clonedActivity;
};
