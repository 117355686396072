import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { getMainPosition } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CustomType, Profile } from "PFTypes";
import { useCallback } from "react";

import css from "../organisation_data.module.scss";
import { Pair } from "./pair";

type CustomFieldItemProps = {
  profile: Profile;
  isMe: boolean;
  customType: CustomType;
  hasValuesWithExpiryDate: boolean;
  translation: (key: string, options?: any) => string;
};

export const CustomFieldItem = ({
  profile,
  isMe,
  customType: { name, value_type: valueType, display_as: displayAs },
  hasValuesWithExpiryDate,
  translation
}: CustomFieldItemProps) => {
  const { formatDate, utc } = useDateFormatter();
  const { getCustomValuesListString } = useCustomValueToString();

  const valueWithTime = useCallback(
    (cf, index, itemsLength, translation) => (
      <div key={cf.text} className={css.valueTime}>
        {cf.text}
        {cf.expiry_date && (
          <>
            {" | "}
            <div className={css.valueTimeText}>
              {translation("show.parts.expDate", { date: formatDate(utc(cf.expiry_date)) })}
            </div>
          </>
        )}
        {index < itemsLength - 1 && <div className={css.coma}>{", "}</div>}
      </div>
    ),
    [formatDate, utc]
  );

  const valueWithTimeFormater = useCallback(
    (items, translation) => items.map((cf, index) => valueWithTime(cf, index, items.length, translation)),
    [valueWithTime]
  );

  const getMainPositionCfVals = useCallback(
    (name: string) => {
      const mainPositionValue = getMainPosition(profile)[name];
      // if value is not array it means it is not a custom field but other position attribute e.g. start_date
      return Array.isArray(mainPositionValue) ? mainPositionValue : null;
    },
    [profile]
  );

  const qaId = `profile-${name}`;
  const profileCfVals = profile[name] || [];
  const cfVals = profileCfVals.length > 0 ? profileCfVals : getMainPositionCfVals(name) || [];

  if (valueType && (cfVals.length > 0 || isMe)) {
    const text = hasValuesWithExpiryDate
      ? valueWithTimeFormater(cfVals, translation)
      : getCustomValuesListString(cfVals, valueType);

    return (
      <Pair title={displayAs || name} qaId={qaId}>
        {hasValuesWithExpiryDate ? text.map((item) => item) : text || "-"}
      </Pair>
    );
  }

  return null;
};
