import { Activity, Template } from "PFTypes";

import { TEMPLATE_KEYS } from "../constants/templates";

export const isEngagement = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TEMPLATE_KEYS.ENGAGEMENT, TEMPLATE_KEYS.AUDIT_ENGAGEMENT].includes(
        (templateOrKey as Template).key || templateOrKey
      )
  );

export const isRole = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TEMPLATE_KEYS.ROLE, TEMPLATE_KEYS.AUDIT_ROLE].includes(
        (templateOrKey as Template).key || templateOrKey
      )
  );

export const isAudit = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(((templateOrKey as Template)?.key || templateOrKey || "").includes("audit"));

export const checkIfIsDefaultPrivate = (template: Template, parentActivity?: Activity) => {
  if (parentActivity && template.default_visibility === "inherit_from_parent") {
    return parentActivity.private;
  } else {
    return template.default_visibility === "private";
  }
};
