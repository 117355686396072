import { includes, some } from "lodash";
import moment from "moment";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import { Booking } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type UseBookingsTotal = {
  bookings: Booking[];
};
type UseBookingsTotalReturn = {
  total: {
    hours: number;
    days: number;
  } | null;
  warning: string | undefined;
};

export const useBookingsTotal = ({ bookings }: UseBookingsTotal): UseBookingsTotalReturn => {
  const { t } = useTranslation(["bookingModule", "translation"]);

  const warning = useMemo(() => {
    const noDuration = bookings.length === 0 || some(bookings, ({ duration }) => duration === undefined);
    const states = bookings.map(({ state }) => state);
    if (includes(states, "pending")) {
      return t("bookingModule:bookings.totalWarnings.pending");
    }
    if (noDuration || includes(states, "editing")) {
      return t("bookingModule:bookings.totalWarnings.editing");
    }
  }, [bookings, t]);

  const total = useMemo(() => {
    const noDuration = bookings.length === 0 || some(bookings, ({ duration }) => duration === undefined);
    if (warning || noDuration) {
      return null;
    }
    const calculatedTime = bookings.reduce((acc, current) => acc + current.duration || 0, 0);
    const momentDuration = moment.duration(calculatedTime, "minutes");
    const hours = roundToDecimals(momentDuration.asHours());
    const days = bookings.reduce(
      (sum, current) => sum + moment(current.end_date).diff(current.start_date, "days") + 1,
      0
    );
    return { hours, days };
  }, [bookings, warning]);

  return { total, warning };
};
