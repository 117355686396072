import { Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

export const useExperienceLabelMap = () => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });

  return {
    [Experience.Basic]: t("basic"),
    [Experience.Intermediate]: t("intermediate"),
    [Experience.Advanced]: t("advanced")
  };
};
