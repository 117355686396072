import { debounce } from "lodash";
import Toggle from "PFComponents/toggle/toggle";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import { NumberInput } from "./number_input";
import css from "./requirement_option.module.scss";

interface RequirementOptionProps {
  label: string;
  onChange: (value: number | undefined) => void;
  max?: number;
  defaultValue: number | undefined;
  autofocus: boolean;
  useTimeVisible: boolean;
  withTime: boolean;
  onWithTimeChange: () => void;
}

export const RequirementOption = ({
  label,
  onChange,
  max,
  defaultValue,
  autofocus,
  useTimeVisible,
  withTime,
  onWithTimeChange
}: RequirementOptionProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });

  const debouncedHandleChange = useRef(
    debounce((value: number | undefined) => {
      onChange(value);
    }, 300)
  ).current;

  return (
    <BookingDetailItem
      label={label}
      value={
        <div className={css.root}>
          <NumberInput
            defaultValue={defaultValue}
            onChange={debouncedHandleChange}
            min={0}
            max={max}
            autofocus={autofocus}
          />
          {useTimeVisible && (
            <Toggle
              reversed
              compact
              checked={withTime}
              onChange={onWithTimeChange}
              description={t("useTime")}
            />
          )}
        </div>
      }
    />
  );
};
