import { useInfiniteQuery, UseInfiniteQueryOptions, useMutation } from "@tanstack/react-query";
import { IRoleWatcher, IRoleWatchersResponse } from "PFApp/activities/types/watchers";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import {
  fetchActivityWatchers,
  QuestionnaireAnswers,
  removeInterest,
  showInterest,
  ShowInterestResponse
} from "PFCore/services/activities";
import { Activity, OrderOption } from "PFTypes";
import { useMemo } from "react";

import activityKeys from "./query_keys";

export const useInfiniteActivityWatchers = (
  activity: Activity,
  perPage: number = 15,
  filters: Record<string, unknown> = {},
  order: Partial<OrderOption> | undefined = {},
  options: Omit<
    UseInfiniteQueryOptions<IRoleWatchersResponse>,
    "queryKey" | "queryFn" | "getNextPageParam"
  > & {
    initialData?: () => undefined;
  } = {}
) => {
  const watchersQuery = useInfiniteQuery({
    queryKey: activityKeys.watchers(activity.id, { filters, order, perPage }),
    queryFn: ({ pageParam = 1 }) => fetchActivityWatchers(activity.id, pageParam, perPage, filters, order),
    getNextPageParam,
    ...options
  });

  const watchers = useMemo(
    () =>
      (watchersQuery?.data?.pages?.reduce((acc, current) => [...acc, ...current.entries], []) || []).map(
        (watcher: IRoleWatcher) => ({
          ...watcher,
          profile: {
            ...watcher.profile,
            ...watcher.profile.additional_custom_fields
          },
          applicable_custom_values: watcher.applicable_custom_values // eslint-disable-line camelcase
        })
      ),
    [watchersQuery?.data?.pages]
  );

  return { ...watchersQuery, watchers };
};

interface ShowInterestVariables {
  activityId: number;
  questionnaireAnswers?: QuestionnaireAnswers;
}

interface RemoveInterestVariables {
  activityId: number;
  interestedId: number;
}

export const useShowInterestMutation = () =>
  useMutation<ShowInterestResponse, unknown, ShowInterestVariables>(
    ["show_interest"],
    ({ activityId, questionnaireAnswers }) => showInterest(activityId, questionnaireAnswers),
    {
      retry: 1
    }
  );

export const useRemoveInterestMutation = () =>
  useMutation<unknown, unknown, RemoveInterestVariables>(
    ["remove_interest"],
    ({ activityId, interestedId }) => removeInterest(activityId, interestedId),
    {
      retry: 1
    }
  );
