import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Activity, Id } from "PFTypes";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import WarningIcons from "../../warning_icons/warning_icons";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { PotentialWarnings } from "../use_potential_warnings";
import css from "./footer.module.scss";

type FooterProps = {
  profileId: Id | undefined;
  activity: Activity | undefined;
  onClose: () => void;
  isSubmitDisabled: boolean;
  isLoading: boolean;
  mode: BookingFormMode;
} & Pick<PotentialWarnings, "overbookings" | "misalignments">;

export const Footer = ({
  profileId,
  activity,
  overbookings,
  misalignments,
  onClose,
  isSubmitDisabled,
  isLoading,
  mode
}: FooterProps) => {
  const { t } = useTranslation();
  const [bookings, globalCategory, globalTitle] = useWatch<BookingFormValues>({
    name: ["bookings", "globalCategory", "globalTitle"]
  });

  const potentialBookings =
    mode !== BookingFormMode.Edit
      ? (bookings ?? []).map(({ key, title, category, startDate, endDate }) => ({
          key,
          title: title ?? globalTitle,
          booking_category_id: category?.id ?? globalCategory?.id,
          start_date: startDate,
          end_date: endDate
        }))
      : [];

  const misalignmentsWithProfile = profileId
    ? [
        {
          profileId,
          misalignments
        }
      ]
    : [];

  const getConfirmButtonLabel = () => {
    if (mode === BookingFormMode.Edit) {
      return t("update");
    } else {
      return t("create");
    }
  };

  return (
    <div className={css.root}>
      <div className={css.buttonsSection}>
        <Button type="button" kind="secondary" text={t("cancel")} onClick={onClose} />
        <div className={css.content}>
          <WarningIcons
            profileId={profileId}
            roleId={activity?.id}
            roleName={activity?.name ?? ""}
            overbookings={overbookings}
            misalignments={misalignmentsWithProfile}
            potentialBookings={potentialBookings}
          />
        </div>
        <Button form="booking_form" type="submit" disabled={isSubmitDisabled}>
          {isLoading ? <LoadingDots /> : getConfirmButtonLabel()}
        </Button>
      </div>
    </div>
  );
};
