import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { FetchBookings } from "PFApp/booking/services/api";

import { bookingKeys } from "./query_keys";

export const useBookingInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id?: number) => {
      if (id === undefined) {
        return queryClient.invalidateQueries(bookingKeys.all());
      }

      return queryClient.invalidateQueries(bookingKeys.single(id));
    },
    invalidateBookingsForBookingGroup: (bookingGroupId: number | string) => {
      const keys = queryClient
        .getQueryCache()
        .findAll(bookingKeys.all())
        //@ts-ignore
        .filter(({ state }) => state.data?.booking_group_id === bookingGroupId)
        .map(({ queryKey }) => queryKey);

      keys.forEach((key) =>
        queryClient.invalidateQueries({
          queryKey: key
        })
      );
    },
    invalidateProfileBookingsList: (profileId: number, activityId: number) => {
      const cachedQueryKeys: QueryKey[] = queryClient
        .getQueriesData({ predicate: bookingKeys.isBookingQuery })
        .filter(([queryKey]) => {
          const queryKeyParams = queryKey[2] as any;
          return (
            queryKey[1] === "list" &&
            queryKeyParams.profileId === profileId &&
            queryKeyParams.activityId === activityId
          );
        })
        .map(([queryKey]) => queryKey);
      return cachedQueryKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey }));
    },
    invalidateList: (params: FetchBookings) => queryClient.invalidateQueries(bookingKeys.list(params))
  };
};
