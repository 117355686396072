import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import { CSSProperties } from "react";

import css from "./experience_radial.module.scss";

export enum ExperienceColorVariant {
  Neutral = "neutral",
  Active = "active",
  Missing = "missing",
  Extra = "extra"
}

export type CustomColors = Partial<Record<ExperienceColorVariant, string>>;

export type ExperienceRadialProps = {
  colorVariants: [ExperienceColorVariant, ExperienceColorVariant, ExperienceColorVariant];
  icon?: "arrow-up" | "arrow-down" | "cross" | "check" | null;
  customColors?: CustomColors;
};

const getStyleForCustomColor = (
  color: ExperienceColorVariant,
  customColors: CustomColors
): CSSProperties => ({
  borderColor: customColors[color]
});

export const ExperienceRadial = ({
  colorVariants,
  icon = null,
  customColors = {}
}: ExperienceRadialProps): JSX.Element => (
  <div className={css.circle}>
    <div
      className={classNames(css.segment, css.first, css[colorVariants[0]])}
      style={getStyleForCustomColor(colorVariants[0], customColors)}
    />
    <div
      className={classNames(css.segment, css.second, css[colorVariants[1]])}
      style={getStyleForCustomColor(colorVariants[1], customColors)}
    />
    <div
      className={classNames(css.segment, css.third, css[colorVariants[2]])}
      style={getStyleForCustomColor(colorVariants[2], customColors)}
    />
    {icon && (
      <Icon
        name={icon}
        size="xxs"
        color={icon === "cross" ? "paletteRed0" : "paletteBlue0"}
        className={css.icon}
      />
    )}
  </div>
);
