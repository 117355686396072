import { useMutation } from "@tanstack/react-query";
import { setOrdinalCustomValues } from "PFCore/services/admin/custom_fields";
import { MutationOptions } from "PFTypes/request_options";

import { useCustomValuesInvalidate } from "./use_custom_values_invalidate";

type MutationFnParams = {
  customTypeId: number;
  customValueIds: number[];
};

export const useCustomValuesOrdinalUpdate = ({
  onSuccess,
  ...queryParams
}: MutationOptions<MutationFnParams>) => {
  const { invalidate } = useCustomValuesInvalidate();

  return useMutation({
    mutationFn: ({ customTypeId, customValueIds }: MutationFnParams): any =>
      setOrdinalCustomValues(customTypeId, customValueIds),
    onSuccess: (...args) => {
      invalidate();
      onSuccess?.(...args);
    },
    ...queryParams
  });
};
