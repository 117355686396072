import { isEmpty } from "lodash";
import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { DropdownButton } from "PFComponents/dropdown_button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistFillAndBook } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Activity, Id } from "PFTypes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../booking/components/booking_form";
import { BookingFormMode } from "../../booking/components/booking_form/use_booking_form";
import css from "./fill_and_book_button.module.scss";

type FillAndBookButtonProps = {
  profileId: Id;
  activity: Activity;
  shortlistId?: Id;
  isFillButtonDisabled: boolean;
  disabled?: boolean;
};

export const FillAndBookButton = ({
  profileId,
  shortlistId,
  activity,
  isFillButtonDisabled,
  disabled
}: FillAndBookButtonProps) => {
  const { t } = useTranslation("translation");
  const { modal } = useBookingFormContext();
  const growlError = useErrorsGrowl();

  const { mutateAsync: fillAndBook, isLoading: isFillAndBooking } = useShortlistFillAndBook();
  const { mutateAsync: createShortlist, isLoading: isCreatingShortlist } = useShortlistCreate();
  const { invalidateVacancies } = useActivityInvalidate();

  const isActivityWithoutAvailability = isEmpty(activity?.metadata.availability);
  const isCompleted = activity?.state === "complete";

  const handleChange = () => {
    modal.open(
      {
        profileId: Number(profileId),
        activityId: activity.id
      },
      {
        mode: BookingFormMode.Create,
        onSuccess: () => {
          handleFillAndBook(true);
        }
      }
    );
  };

  const handleFillAndBook = useCallback(
    async (skipBookingsCreation: boolean = false) => {
      try {
        const finalShortlistId =
          shortlistId || (await createShortlist({ activityId: activity.id, profileId })).id;
        await fillAndBook({
          shortlistId: finalShortlistId,
          skipBookingsCreation,
          task: activity,
          profileId: Number(profileId)
        });
        await invalidateVacancies(activity.id);
      } catch ({ response }) {
        growlError(response);
      }
    },
    [shortlistId, createShortlist, activity, profileId, fillAndBook, invalidateVacancies, growlError]
  );

  const options: DropdownOption[] = useMemo(
    () => [
      {
        id: "custom_fill_and_book",
        displayElement: t("customFillAndBook"),
        item: "custom"
      }
    ],
    [t]
  );

  const isLoading = isFillAndBooking || isCreatingShortlist;
  const isDisabled = isCompleted || isLoading || isFillButtonDisabled || disabled;

  const renderButtons = () =>
    isActivityWithoutAvailability ? (
      <Button
        text={t("customFillAndBook")}
        onClick={(event) => {
          event.stopPropagation();
          handleChange();
        }}
        disabled={isDisabled}
      />
    ) : (
      <ButtonGroup compact autoWidth>
        <Button
          text={t("fillAndBook")}
          onClick={(event) => {
            event.stopPropagation();
            handleFillAndBook();
          }}
          disabled={isDisabled}
        />
        <DropdownButton
          className={css.dropdownButton}
          icon="chevron-down"
          options={options}
          handleChange={handleChange}
          popperOptions={{ placement: "bottom-end" }}
          disabled={isDisabled}
        />
      </ButtonGroup>
    );

  return !isCompleted ? (
    renderButtons()
  ) : (
    <Tooltip content={t("bookingNotAvailable")}>
      <div>{renderButtons()}</div>
    </Tooltip>
  );
};
