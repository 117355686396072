import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { useRoleJobCode } from "PFApp/use_role_job_code";
import { Booking } from "PFTypes/booking";
import { useTranslation } from "react-i18next";

type BookingDetailContentProps = {
  booking: Booking;
};

export const JobCodeValueBookingRef = ({ booking }: BookingDetailContentProps) => {
  const { activity } = useBookingActivityContext();
  const { t } = useTranslation("bookingModule");

  const { booking_ref: bookingRef, job_code: jobCode } = booking.metadata ?? {};
  const { id: activityId } = activity || {};

  const jobCodeDisplayAs = useRoleJobCode();
  const jobCodeValue = jobCode || activityId;

  return (
    <>
      {jobCodeValue && (
        <BookingDetailItem
          label={t(jobCodeDisplayAs as unknown as TemplateStringsArray)}
          value={jobCodeValue}
        />
      )}
      {bookingRef && <BookingDetailItem label={t("details.bookingRef")} value={bookingRef} />}
    </>
  );
};
