import { IconName } from "PFTheme/graphics/icons";

export const CONTACT_TYPE_TO_ICON_MAP: Record<string, IconName> = {
  email: "mail",
  twitter: "twitter",
  facebook: "facebook",
  instagram: "instagram",
  skype: "skype",
  skype_business: "skype-for-business",
  website: "web",
  phone: "mobile",
  ms_teams: "teams"
};
