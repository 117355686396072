import AppContext from "PFApp/app_context";
import TermsOfService from "PFApp/terms_of_service/terms_of_service";
import { LegacyModal } from "PFComponents/modal/legacy_modal";
import CloseIcon from "PFIcons/close.svg";
import { MODALS_ACTIONS } from "PFReducers/modals_reducer";
import { useContext } from "react";

import css from "./terms_of_service_modal.less";

const TermsOfServiceModal = () => {
  const { dispatch } = useContext(AppContext);

  const hide = () => {
    dispatch({ type: MODALS_ACTIONS.TERMS_HIDE });
  };

  return (
    <LegacyModal onClose={hide} classes={{ modal: css.modal }}>
      <div className={css.markdownWrap}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a className={css.close} onClick={hide}>
          <CloseIcon width={25} height={25} />
        </a>
        <TermsOfService />
      </div>
    </LegacyModal>
  );
};

export default TermsOfServiceModal;
