import { difference, intersection, range, uniq } from "lodash";
import { Moment } from "moment";

import { RepeatDaysData } from "../../use_booking_form";

type CalculateRepeatDays = {
  start: Moment;
  end: Moment;
};

export const calculateRepeatDays = ({ start, end }: CalculateRepeatDays): RepeatDaysData => {
  const periodLength = end.diff(start, "days") + 1;
  const defaultSelectedDays = range(0, 5);
  if (periodLength >= 7) {
    return { selectedWeekDays: defaultSelectedDays, allowedWeekDays: range(0, 7) };
  }
  const boundaries = uniq([start.isoWeekday(), end.isoWeekday()].map((i) => (i + 6) % 7));
  if (boundaries.length === 1) {
    const selectedWeekDays = intersection(defaultSelectedDays, boundaries);
    return { selectedWeekDays, allowedWeekDays: boundaries };
  }
  if (boundaries[0] > boundaries[1]) {
    const weekdaysInRange = difference(range(0, 7), range(boundaries[1] + 1, ((boundaries[0] + 6) % 7) + 1));
    const selectedWeekDays = intersection(defaultSelectedDays, weekdaysInRange);
    return { selectedWeekDays, allowedWeekDays: weekdaysInRange };
  }
  const allowedWeekDays = range(boundaries[0], boundaries[1] + 1);
  const selectedWeekDays = intersection(defaultSelectedDays, allowedWeekDays);
  return { selectedWeekDays, allowedWeekDays };
};
