import { flatten, isNumber, range, times } from "lodash";
import moment, { Moment, MomentInput } from "moment";
import { UNDETERMINED_FUTURE, UNDETERMINED_PAST } from "PFApp/constants/dates";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { ReactElement, SyntheticEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT, ISO_TIME_FORMAT } from "../../helpers/date";
import { TimePicker } from "../time_picker";
import css from "./calendar.module.scss";
import CalendarDate from "./parts/calendar_date";
import { CalendarHeader } from "./parts/calendar_header";

const DEFAULT_HOUR = 12;
const DEFAULT_MINUTE = 0;

interface DateTime {
  date?: Moment;
  hour?: Number;
  minute?: Number;
}

interface CalendarProps {
  displayMonthValue?: MomentInput;
  selectedDate?: string;
  label?: string;
  checkHighlighted?: (date: string) => boolean;
  checkHover?: (date: string) => boolean;
  checkSelected?: (date: string) => boolean;
  qaId?: string;
  minDate?: string;
  maxDate?: string;
  matchMaxDate?: string;
  showArrows?: boolean;
  showTime?: boolean;
  readOnly?: boolean;
  useDateDropdowns?: boolean;
  style?: React.CSSProperties;
  children?: ReactElement;
  handleChange?: (date: string, options: { timeChanged?: boolean }, e: SyntheticEvent) => void;
  handleMouseLeave?: () => void;
  handleMouseEnter?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  footerMessage?: ReactElement | string;
  sidePanel?: ReactElement;
  portalRef?: React.RefObject<HTMLDivElement>;
}

const Calendar = ({
  displayMonthValue,
  selectedDate,
  maxDate = UNDETERMINED_FUTURE.toISOString(),
  minDate = UNDETERMINED_PAST.toISOString(),
  checkHighlighted,
  checkHover,
  checkSelected,
  readOnly,
  handleMouseEnter,
  handleMouseLeave,
  handleChange,
  onKeyDown,
  qaId = "Calendar",
  style,
  showArrows = true,
  label = "",
  matchMaxDate,
  showTime,
  sidePanel,
  footerMessage,
  children,
  portalRef
}: CalendarProps) => {
  const { t } = useTranslation("core", { keyPrefix: "components.calendar" });
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const { formatISODate, utc, formatISOTime } = useDateFormatter();

  const getDisplayMonth = (date) => {
    if (date.diff(minDate) < 0) {
      date = moment(minDate).clone();
    } else if (date.diff(maxDate) > 0) {
      date = moment(maxDate).clone();
    }
    return date;
  };
  const [displayMonth, setDisplayMonth] = useState(
    getDisplayMonth(moment(selectedDate || displayMonthValue || moment()))
  );
  const [keyboardDate, setKeyboardDate] = useState<Moment | null>(null);

  const getDayProps = (momentDate) => {
    const date = momentDate
      .clone()
      .date(momentDate.get("date"))
      .hour(DEFAULT_HOUR)
      .minute(DEFAULT_MINUTE)
      .second(0);

    const isSelected = (dateFormatted) => {
      const momentSelectedDate = utc(selectedDate || undefined);
      return !!(selectedDate && formatISODate(momentSelectedDate) === dateFormatted);
    };

    const dateFormat = formatISODate(date);
    const isMin = dateFormat === formatISODate(minDate);
    const isMax = dateFormat === formatISODate(maxDate);
    const isBeforeMin = isMin ? false : dateFormat < formatISODate(minDate);
    const isAfterMax = isMax ? false : dateFormat > formatISODate(maxDate);
    // We need to check if time will be within the range after changing to this date
    const isTimeInRange =
      !selectedDate ||
      ((isMax ? formatISOTime(maxDate) >= formatISOTime(selectedDate) : true) &&
        (isMin ? formatISOTime(minDate) <= formatISOTime(selectedDate) : true));

    const disabled = isBeforeMin || isAfterMax || !isTimeInRange;
    const selected = checkSelected ? checkSelected(date) : isSelected(dateFormat);
    const keyboardSelected = keyboardDate && formatISODate(keyboardDate) === dateFormat;
    const highlighted = checkHighlighted ? checkHighlighted(date) : false;
    const hovered = checkHover ? checkHover(date) : false;

    return {
      key: dateFormat,
      isCurrentMonth: displayMonth.get("month") === date.get("month"),
      date,
      selected,
      keyboardSelected,
      disabled,
      readOnly,
      highlighted,
      hovered,
      prevBusy: undefined,
      nextBusy: undefined,
      handleMouseEnter,
      handleMouseLeave,
      handleClick: (date, event) => handleChangeTime({ date }, event)
    };
  };

  const handleChangeTime = ({ date, hour, minute }: DateTime, event) => {
    let nextDate = selectedDate ? utc(selectedDate) : null;

    if (!nextDate) {
      nextDate = utc();

      if (formatISODate(nextDate) < formatISODate(minDate)) {
        nextDate = moment(minDate).clone();
      } else if (formatISODate(nextDate) > formatISODate(maxDate)) {
        nextDate = moment(maxDate).clone();
      }

      nextDate.set({ hour: DEFAULT_HOUR, minute: DEFAULT_MINUTE, second: 0 });
    }

    if (date) {
      nextDate.set({ year: date.year(), month: date.month(), date: date.date() });
    }

    if (isNumber(hour)) {
      nextDate.hour(hour).second(0);
    }
    if (isNumber(minute)) {
      nextDate.minute(minute).second(0);
    }

    handleChange?.(
      nextDate.format(showTime ? ISO_DATE_TIME_FORMAT : ISO_DATE_FORMAT),
      { timeChanged: true },
      event
    );
  };

  const handleKeyDown = (event) => {
    let newKeyboardSelected;
    const currentKeyboardSelected = keyboardDate || displayMonth;

    if (event.key === "ArrowLeft") {
      newKeyboardSelected = currentKeyboardSelected.clone().subtract(1, "day");
    } else if (event.key === "ArrowRight") {
      newKeyboardSelected = currentKeyboardSelected.clone().add(1, "day");
    } else if (event.key === "ArrowUp") {
      newKeyboardSelected = currentKeyboardSelected.clone().subtract(1, "week");
    } else if (event.key === "ArrowDown") {
      newKeyboardSelected = currentKeyboardSelected.clone().add(1, "week");
    } else if ((event.key === "Enter" || event.key === " ") && keyboardDate) {
      handleChange?.(keyboardDate.format(ISO_DATE_FORMAT), { timeChanged: false }, event);
      return;
    }

    if (newKeyboardSelected) {
      setKeyboardDate(newKeyboardSelected);
      setDisplayMonth(getDisplayMonth(newKeyboardSelected));
    }

    onKeyDown?.(event);
  };

  const dateStart = displayMonth.clone().startOf("month");
  const dateEnd = displayMonth.clone().endOf("month");
  const prevCount = (dateStart.get("day") - 1 + 7) % 7;
  const nextCount = 42 - prevCount - dateEnd.get("date");
  const isMinDate = minDate && formatISODate(utc(selectedDate)) === formatISODate(minDate);
  const isMaxDate = maxDate && formatISODate(utc(selectedDate)) === formatISODate(maxDate);
  const maxTime = isMaxDate ? formatISOTime(maxDate) : null;
  const minTime = isMinDate ? formatISOTime(minDate) : null;

  const daysProps = flatten([
    times(prevCount, (index) => getDayProps(dateStart.clone().add(index - prevCount, "days"))),
    range(1, dateEnd.get("date") + 1).map((index) => getDayProps(dateStart.clone().date(index))),
    times(nextCount, (index) => getDayProps(dateEnd.clone().add(index + 1, "days")))
  ]);

  const isBusy = (value) => {
    if (Array.isArray(value)) {
      return value.find(isBusy);
    }
    return value === true || value === "partial" || /Kind$/.test(value);
  };
  daysProps.forEach((props, i) => {
    props.prevBusy = isBusy(daysProps[i - 1]?.highlighted);
    props.nextBusy = isBusy(daysProps[i + 1]?.highlighted);
  });

  return (
    <div
      role="grid"
      tabIndex={-1}
      ref={calendarContainerRef}
      aria-label={t("chooseDate")}
      className={css.root}
      style={style}
      data-qa-id={qaId}
      onKeyDown={handleKeyDown}
    >
      <div className={css.calendar} role="rowgroup">
        <CalendarHeader
          showArrows={showArrows !== undefined ? showArrows : true}
          label={label}
          minDate={minDate || UNDETERMINED_PAST.toISOString()}
          maxDate={maxDate || UNDETERMINED_FUTURE.toISOString()}
          displayMonth={displayMonth}
          setDisplayMonth={setDisplayMonth}
        />

        <div className={css.days} role="row">
          {daysProps.map((props) => (
            <CalendarDate {...props} matchMaxDate={matchMaxDate} key={props.key} />
          ))}
          {times(6, (number) => (
            <span key={number} className={css.dayBorder} style={{ left: `${(number + 1) * (100 / 7)}%` }} />
          ))}
        </div>

        {children}
        {showTime && (
          <TimePicker
            className={css.timePicker}
            max={maxTime}
            min={minTime}
            value={formatISOTime(selectedDate || utc().hours(DEFAULT_HOUR).minutes(DEFAULT_MINUTE))}
            onChange={(value) => {
              const timeMoment = moment.utc(value, ISO_TIME_FORMAT);
              handleChangeTime({ hour: timeMoment.get("hour"), minute: timeMoment.get("minute") }, null);
            }}
            portalRef={portalRef}
          />
        )}

        {footerMessage}
      </div>
      {sidePanel}
    </div>
  );
};

export default Calendar;
