import { chunk, first, last } from "lodash";
import moment, { Moment } from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useEffect, useMemo } from "react";

import { CalendarPeriod, CommonCalendarProps } from "./bookings_calendar.types";
import css from "./calendar_display.module.scss";
import { CalendarHeader } from "./calendar_header";
import { CalendarNavigation } from "./calendar_navigation/calendar_navigation";
import { useCalendarDays } from "./use_calendar_days";
import { getPeriodsLevelsInWeek, separateOverlappingPeriods } from "./utils/splitBookingsHelper";
import { Week } from "./week/week";

export type CalendarDisplayProps = {
  onClick?: (period: CalendarPeriod) => void;
  isWorkingCheck?: (date: Moment) => boolean;
  displayMonth: Moment;
  onDisplayMonthChange: (date: Moment) => void;
  calendarPeriods: CalendarPeriod[];
} & CommonCalendarProps;

export const CalendarDisplay = ({
  qaId = "CalendarDisplay",
  style,
  label = "",
  minDate = moment.utc().subtract(100, "years").toISOString(),
  maxDate = moment.utc().add(100, "years").toISOString(),
  footerMessage,
  sidePanel,
  calendarPeriods,
  onClick,
  isWorkingCheck,
  onDisplayRangeChange,
  displayMonth,
  onDisplayMonthChange,
  isExpanded,
  bookingCategories,
  jobCodeDisplayAs,
  showArrows = true,
  availabilities,
  calendarSearch
}: CalendarDisplayProps): JSX.Element => {
  const { utc } = useDateFormatter();

  const displayMonthInSelectedRange = useMemo(() => {
    if (displayMonth.diff(minDate) < 0) {
      return moment(minDate).clone();
    }

    if (displayMonth.diff(maxDate) > 0) {
      return moment(maxDate).clone();
    }

    return displayMonth.clone();
  }, [displayMonth, minDate, maxDate]);

  const daysProps = useCalendarDays({
    minDate,
    maxDate,
    displayMonth: displayMonthInSelectedRange,
    isWorkingCheck
  });

  useEffect(() => {
    if (daysProps && daysProps.length) {
      onDisplayRangeChange &&
        onDisplayRangeChange(utc(daysProps[0].date), utc(daysProps[daysProps.length - 1].date), displayMonth);
    }
  }, [daysProps[0]?.date, daysProps[daysProps.length - 1]?.date]);

  const separatedOverlappingPeriods = useMemo(
    () => separateOverlappingPeriods(calendarPeriods),
    [calendarPeriods]
  );

  const allPeriodsByLevels = useMemo(
    () => getPeriodsLevelsInWeek(separatedOverlappingPeriods, first(daysProps)?.date, last(daysProps)?.date),
    [separatedOverlappingPeriods, daysProps]
  );

  return (
    <>
      <div data-qa-id={qaId} style={style}>
        <CalendarNavigation
          showArrows={showArrows}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          displayMonth={displayMonthInSelectedRange}
          setDisplayMonth={onDisplayMonthChange}
          calendarSearch={calendarSearch}
        />
        <div className={css.root} role="grid">
          <CalendarHeader />
          <div>
            {chunk(daysProps, 7).map((weekProps, index) => {
              const weekKey = first(weekProps)?.date ?? `week-${index}`;
              const periodsInWeekByLevels = allPeriodsByLevels
                .map((periodsLevel) => periodsLevel[weekKey])
                .filter((periodsLevel) => periodsLevel.length);

              const weekPropsWithAvailability = weekProps.map((dayProps) => ({
                ...dayProps,
                availability: availabilities?.find((availability) => availability.date === dayProps.date)
              }));

              return (
                <Week
                  key={weekKey}
                  isFirstWeek={index === 0}
                  days={weekPropsWithAvailability}
                  onClick={onClick}
                  isExpanded={isExpanded}
                  periodsByLevels={periodsInWeekByLevels}
                  bookingCategories={bookingCategories}
                  jobCodeDisplayAs={jobCodeDisplayAs}
                />
              );
            })}
          </div>
          {footerMessage}
        </div>
        {sidePanel}
      </div>
    </>
  );
};
