import { useQuery } from "@tanstack/react-query";
import { fetchCustomValues, FetchCustomValuesParams } from "PFCore/services/admin/custom_fields";
import {
  FetchCtCustomValuesParams,
  fetchCustomValue,
  fetchCustomValuesByType
} from "PFCore/services/admin/custom_values";
import { CustomValue, CustomValueState, Id, MetaWithPagination, OrderOption } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValueKeys } from "./query_keys";

export interface CustomValuesByFieldResponse {
  entries: CustomValue[];
  meta: {
    total: number;
    counters: {
      state_tally: Record<CustomValueState, number>;
    };
    order: {
      options: OrderOption[];
    };
  };
}

export interface CustomValuesByTypeResponse {
  entries: CustomValue[];
  meta: MetaWithPagination & {
    counters: {
      state_tally: Record<CustomValueState, number>;
    };
  };
}

export const useCustomValuesByField = (
  customTypeId: number,
  page: number,
  perPage: number,
  params: FetchCustomValuesParams,
  options: QueryOptions<CustomValuesByFieldResponse>
) =>
  useQuery<CustomValuesByFieldResponse>(
    customValueKeys.list(customTypeId, page, perPage, params),
    () => fetchCustomValues(customTypeId, page, perPage, params),
    {
      retry: false,
      ...options
    }
  );

export const useCustomValuesByType = (
  customTypeId: Id,
  page: number,
  perPage: number,
  params: FetchCtCustomValuesParams,
  options: QueryOptions<CustomValuesByTypeResponse>
) =>
  useQuery<CustomValuesByTypeResponse>(
    customValueKeys.list(customTypeId, page, perPage, params),
    () => fetchCustomValuesByType(customTypeId, page, perPage, params),
    {
      retry: false,
      ...options
    }
  );

export const useCustomValue = (
  customTypeId: Id,
  customValueId: Id,
  options: QueryOptions<CustomValue> = {}
) =>
  useQuery<CustomValue>(
    customValueKeys.single(customTypeId, customValueId),
    () => fetchCustomValue(customTypeId, customValueId),
    options
  );
