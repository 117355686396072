import axios from "axios";
import omit from "lodash/omit";
import { MisalignmentItem, Overbooking } from "PFApp/booking/types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { FeatureFlag } from "PFTypes";
import { useEffect, useState } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

import { clearCategoryOrPhaseErrors, setCategoryOrPhaseErrors } from "../booking_form.utils";
import { useBookingFormContext } from "../booking_form_context_provider";
import { BookingFormValues } from "../booking_form_provider";
import { useAreBookingsValid } from "../use_are_bookings_valid";
import { BookingFormMode } from "../use_booking_form";
import { isGroupOption } from "../workforce_member_select/workforce_member_select_dropdown";
import { fetchPotentialWarnings } from "./fetch_potential_warnings";

const POTENTIAL_WARNINGS_LOADING = "potential-warnings-loading";

type UsePotentialWarnings = {
  mode: BookingFormMode;
};
export type PotentialWarnings = {
  overbookings: Overbooking[];
  misalignments: MisalignmentItem[];
  exeeds_calendar_hours: boolean;
  includes_non_working_days: boolean;
};

export const usePotentialWarnings = ({ mode }: UsePotentialWarnings): Partial<PotentialWarnings> => {
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const { getFormattedErrors } = useResponseErrors();
  const [potentialWarnings, setPotentialWarnings] = useState<Partial<PotentialWarnings>>({});
  const { setLoadingState } = useBookingFormContext();
  const { setError, clearErrors, getValues } = useFormContext<BookingFormValues>();
  const { isValid, isSubmitting } = useFormState();
  const [bookings, activityId, workforceMember, bookingType] = useWatch<BookingFormValues>({
    name: ["bookings", "activityId", "workforceMember", "bookingType"]
  });
  const globalValuesArray = useWatch<BookingFormValues>({
    name: ["globalCategory", "globalOverridesDiaryTime", "globalOverridesNonWorkingTime"]
  });
  const areBookingsValid = useAreBookingsValid();
  const isFormValid = isValid && areBookingsValid;
  const requestTrigger = JSON.stringify({
    bookingsData: (bookings ?? []).map((booking) => omit(booking, "title", "description")),
    activityId,
    workforceMemberId: workforceMember?.id,
    bookingType,
    globalValuesArray
  });

  useEffect(() => {
    if (!isBookingModuleEnabled) {
      return;
    }
    const cancelTokenSource = axios.CancelToken.source();
    if (!isFormValid || isSubmitting || !workforceMember || isGroupOption(workforceMember)) {
      setPotentialWarnings({});
      setLoadingState((prev) => ({ ...prev, [POTENTIAL_WARNINGS_LOADING]: false }));
      return;
    }

    setLoadingState((prev) => ({ ...prev, [POTENTIAL_WARNINGS_LOADING]: true }));
    fetchPotentialWarnings({
      values: getValues(),
      mode,
      cancelTokenSource
    })
      .then((response) => {
        setPotentialWarnings({
          ...response,
          misalignments: activityId ? response.misalignments[activityId]?.[workforceMember.id] || [] : []
        });
        clearCategoryOrPhaseErrors(mode, bookings, clearErrors, "potential-warnings");
        setLoadingState((prev) => ({ ...prev, [POTENTIAL_WARNINGS_LOADING]: false }));
      })
      .catch((err: any) => {
        if (err.statusText === "abort" || err.code === "ERR_CANCELED") {
          return;
        }
        console.debug("Potential warnings request error", err);
        const responseErrors = getFormattedErrors(err.response.data);
        if (Object.keys(responseErrors).length > 0) {
          setCategoryOrPhaseErrors(
            mode,
            bookings,
            responseErrors,
            setError,
            clearErrors,
            "potential-warnings"
          );
        }
        setLoadingState((prev) => ({ ...prev, [POTENTIAL_WARNINGS_LOADING]: false }));
      });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [requestTrigger, isFormValid, isBookingModuleEnabled, clearErrors, setError]);

  return potentialWarnings;
};
