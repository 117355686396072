import { isNil } from "lodash";
import { SkillsList } from "PFApp/components/skills_list";
import { MatchCustomValue } from "PFTypes";
import { Trans, useTranslation } from "react-i18next";

import { useMatchCardContext } from "../../match_card_context";
import { InfoSection } from "../info_section";

type MatchCustomFieldSectionProps = {
  customFieldLabel?: string;
  customFieldName: string;
  i18nKey: string;
  total: number;
  matchCustomValues: MatchCustomValue[];
  skillTruncateLength?: number;
};

export const MatchCustomFieldSection = ({
  customFieldLabel,
  customFieldName,
  i18nKey,
  total,
  matchCustomValues,
  skillTruncateLength
}: MatchCustomFieldSectionProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });
  const { id: profileId, animate } = useMatchCardContext();
  const matchedCustomValues = matchCustomValues.filter(({ experience }) => !isNil(experience));

  return (
    <InfoSection
      title={
        <Trans
          t={t}
          i18nKey={i18nKey as any}
          values={{
            name: customFieldLabel,
            count: matchedCustomValues.length,
            total
          }}
          components={[<strong key="0" />]}
        />
      }
    >
      <SkillsList
        customFieldName={customFieldName}
        skills={matchCustomValues}
        profileId={profileId}
        skillTruncateLength={skillTruncateLength}
        onMoreLessButtonClick={animate}
      />
    </InfoSection>
  );
};
