import api from "PFCore/api";
import { Id, Reviewer } from "PFTypes";

export type ReviewerCreatePayload = {
  profileId: number;
  activityId: number;
  unbiased?: boolean;
};

export const createReviewer = (data: ReviewerCreatePayload): Promise<Reviewer> =>
  api({
    url: "reviewers",
    method: "POST",
    data
  });

export const deleteReviewer = (id: number): Promise<Reviewer> =>
  api({
    url: `reviewers/${id}`,
    method: "DELETE"
  });

export const revealBlindMode = (reviewerId: Id) =>
  api({
    method: "PUT",
    url: `reviewers/${reviewerId}`,
    data: JSON.stringify({ unbiased: false })
  });
