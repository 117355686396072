import { Modal } from "PFComponents/modal";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import {
  useActivityEconomicsScenarioCreate,
  useActivityEconomicsScenarioUpdate
} from "PFCore/hooks/queries/activity";
import { EconomicsScenario, EconomicsScenarioDetails, Id } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../../use_growl";

type ScenarioNameModalProps = {
  isEdit?: boolean;
  scenario?: EconomicsScenario | EconomicsScenarioDetails;
  activityId?: Id;
  onSave?: (scenarioId: Id) => void;
  onClose: () => void;
};

export const ScenarioNameModal = ({
  isEdit = false,
  scenario,
  activityId,
  onSave,
  onClose
}: ScenarioNameModalProps) => {
  const growl = useGrowl();

  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenarioNameModal" });
  const { getFormattedErrors } = useResponseErrors();
  const { mutateAsync: createScenario } = useActivityEconomicsScenarioCreate(activityId || "");
  const { mutateAsync: updateScenario } = useActivityEconomicsScenarioUpdate(scenario?.id || "");

  const [name, setName] = useState(scenario ? scenario.name : "");
  const [nameError, setNameError] = useState<string | null>(null);

  const handleSave = useCallback(async () => {
    const saveScenario = isEdit ? updateScenario : createScenario;

    await saveScenario(name, {
      onSuccess: (scenario) => {
        onSave?.(scenario.id);

        growl({ message: t(isEdit ? "editScenarioSuccess" : "createScenarioSuccess"), kind: "success" });
      },
      onError: (err: any) => {
        const responseErrors = getFormattedErrors(err.response);

        if (responseErrors.name) {
          setNameError(responseErrors.name);
          return;
        }

        growl({ message: t(isEdit ? "editScenarioError" : "createScenarioError"), kind: "error" });
      }
    });
  }, [isEdit, updateScenario, createScenario, name, onSave, growl, t, getFormattedErrors]);

  return (
    <Modal title={isEdit ? t("edit") : t("new")} onOK={handleSave} onClose={onClose}>
      <InputFieldSet value={name} onChange={setName} error={nameError} maxLength={255} />
    </Modal>
  );
};
