import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import SearchFatIcon from "PFIcons/search_fat.svg";
import { PermissionRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";

export const SearchNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });

  if (!useIsCurrentUserPermittedTo(PermissionRule.ProfileList)) {
    return null;
  }

  return (
    <NavItem
      icon={<SearchFatIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/search/)}
      name={t("search")}
      href="/search"
      id="search"
      {...props}
    />
  );
};
