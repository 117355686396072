import { ECOption } from "PFCore/helpers/echarts_wrapper";
import EchartsWrapper from "PFCore/helpers/echarts_wrapper";
import colors from "PFTheme/tokens/colors";
import { useMemo } from "react";

import { Header } from "./header";

const getTotalValue = (name: string, series: BarChartProps["series"]) =>
  series
    .find((serie) => serie.name === name)
    ?.data.map(({ value }) => value)
    .reduce((acc, curr) => acc + curr, 0);

const getOptions = (
  props: BarChartProps,
  config: { hideLegend?: boolean; symbol?: string; unit?: string } = {},
  customOptions?: Partial<ECOption>
): ECOption => {
  const { names, series } = props;
  const { hideLegend } = config;
  const symbol = config.symbol || "";
  const unit = config.unit || "";

  return {
    legend: {
      show: !hideLegend,
      type: "scroll",
      bottom: -4,
      formatter: (name) => {
        const total = getTotalValue(name, series);

        return `${name}: ${symbol}${total}${unit}`;
      }
    },
    grid: {
      top: "15px",
      left: names ? "6px" : "-6px",
      bottom: "30px",
      containLabel: true
    },
    xAxis: customOptions?.xAxis ?? {
      type: "value",
      boundaryGap: [0, 0.05],
      axisLabel: {
        formatter: (value) => (value ? `${value}${unit}` : " 0")
      }
    },
    yAxis: {
      type: "category",
      data: names || [""],
      axisLine: { lineStyle: { width: 1, type: "solid" } },
      axisTick: { show: false },
      axisLabel: {
        formatter: (value) => {
          if (!value) {
            return "";
          }

          if (value.length > 12) {
            return `${value.slice(0, 12)}...`;
          }

          return value;
        }
      }
    },
    series: [
      {
        name: series[0].name,
        data: series[0].data,
        type: "bar",
        itemStyle: { color: colors.paletteEcharts0 },
        barMaxWidth: 5
      },
      {
        name: series[1].name,
        data: series[1].data,
        type: "bar",
        itemStyle: { color: colors.paletteEcharts1 },
        barMaxWidth: 5,
        barGap: "0%"
      }
    ]
  };
};

export type BarChartProps = {
  coverage?: number;
  hideLegend?: boolean;
  reversePositive?: boolean;
  title?: string;
  symbol?: string;
  unit?: string;
  names?: string[];
  series: {
    name: string;
    data: { value: number; unit?: string }[];
  }[];
  barVerticalSpacing?: number;
  customOptions?: Partial<ECOption>;
};

export const BarChart = ({
  coverage,
  reversePositive,
  hideLegend,
  title,
  unit = "",
  symbol = "",
  names,
  series,
  barVerticalSpacing = 10,
  customOptions
}: BarChartProps) => {
  const options: ECOption = useMemo(
    () => getOptions({ series, names }, { hideLegend, symbol, unit }, customOptions),
    [hideLegend, names, symbol, unit, series, customOptions]
  );

  const height = series[0].data.length * barVerticalSpacing + 90;

  return (
    <>
      {title && (
        <Header coverage={coverage} title={title} series={series} reversePositive={reversePositive} />
      )}
      <EchartsWrapper options={options} height={height} minWidth={150} />
    </>
  );
};
