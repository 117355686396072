import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";
import useForceUpdate from "PFCore/helpers/use_force_update";
import UserIcon from "PFIcons/user.svg";
import React, { MouseEventHandler, ReactNode, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./avatar.module.scss";

const USER_ICON_SIZE = 80;

const calculateDimensions = (size: number, fullSize: boolean): string | number => (fullSize ? "100%" : size);
const calculateFontSize = (
  refSize: number | undefined,
  size: number,
  fontSize: number | undefined,
  fullSize: boolean
): number => {
  if (fullSize && refSize) {
    return refSize / 2;
  }
  return fontSize || size / 2;
};
const calculateBarWidth = (size?: number): number => (size ? size * 0.15 : 0);
const calculateIconSizeFactor = (size?: number): number => (size ? size / USER_ICON_SIZE : 1);

export type AvatarProps = {
  text?: string;
  size?: number;
  fullSize?: boolean;
  imagePath?: string;
  fontSize?: number;
  backgroundColor?: string;
  href?: string;
  inactive?: boolean;
  onClick?: MouseEventHandler;
  target?: "_blank" | null;
  qaId?: string;
  Icon?: React.FC<{ width?: number; height?: number }>;
  handleIconClick?: MouseEventHandler;
  buttonTitle?: string;
  isHighlighted?: boolean;
  tooltipContent?: ReactNode;
} & React.AriaAttributes;

export const Avatar = ({
  text,
  size = 72,
  fullSize = false,
  imagePath,
  fontSize,
  backgroundColor,
  href,
  inactive = false,
  onClick,
  target,
  qaId,
  Icon,
  handleIconClick,
  buttonTitle,
  tooltipContent,
  isHighlighted,
  "aria-label": ariaLabel
}: AvatarProps): JSX.Element => {
  const { t } = useTranslation("core");
  const avatarRef = useRef<HTMLDivElement & HTMLButtonElement>(null);
  const forceUpdate = useForceUpdate();

  const refSize: number | undefined = avatarRef?.current?.clientWidth;

  const useImage = !!imagePath;

  const style = {
    width: calculateDimensions(size, fullSize),
    height: calculateDimensions(size, fullSize),
    minWidth: calculateDimensions(size, fullSize),
    minHeight: calculateDimensions(size, fullSize),
    lineHeight: fullSize && refSize ? refSize : size,
    fontSize: calculateFontSize(refSize, size, fontSize, fullSize),
    backgroundColor: inactive ? "var(--Color-gray-8)" : backgroundColor,
    backgroundImage: useImage && !inactive ? `url(${imagePath})` : "",
    cursor: href || onClick ? "pointer" : "inherit"
  };

  const inactiveBarWidth = calculateBarWidth(refSize);
  const userIconSizeFactor = calculateIconSizeFactor(refSize);

  useEffect(() => {
    if (avatarRef?.current) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarRef?.current]);

  const isLink = !!href;

  const AvatarTag = useMemo(() => {
    if (isLink) {
      return Link as React.FC<{ children: React.ReactNode }>;
    } else if (onClick) {
      return "button";
    } else {
      return "div";
    }
  }, [isLink, onClick]);

  const iconSize = fullSize ? 52 : Math.floor(size * 0.4);

  const showTooltip = !!tooltipContent || inactive;
  const finalTooltipContent = inactive ? t("profileIsInactive") : tooltipContent;

  const Wrapper = useMemo(() => (showTooltip ? Tooltip : React.Fragment), [showTooltip]);

  return (
    <>
      {Icon && (
        <div className={css.icon}>
          <button onClick={handleIconClick} title={buttonTitle}>
            <Icon height={iconSize} width={iconSize} />
          </button>
        </div>
      )}
      <Wrapper {...(showTooltip ? { content: finalTooltipContent } : {})}>
        <AvatarTag
          ref={avatarRef}
          className={classNames(css.avatar, { [css.highlighted]: isHighlighted })}
          style={style}
          data-qa-id={qaId}
          onClick={onClick}
          aria-label={ariaLabel}
          {...(isLink ? { to: href, target } : {})}
        >
          {!useImage && !inactive && <span>{text}</span>}
          {inactive && (
            <>
              <UserIcon
                className={css.userIcon}
                style={{ transform: `translate(-50%, -50%) scale(${userIconSizeFactor})` }}
                width={USER_ICON_SIZE}
                height={USER_ICON_SIZE}
                viewBox="0 0 20 20"
              />
              <div className={css.inactive}>
                <div className={css.circle} style={{ borderWidth: inactiveBarWidth }} />
                <div className={css.obliqueBar} style={{ width: inactiveBarWidth }} />
              </div>
            </>
          )}
        </AvatarTag>
      </Wrapper>
    </>
  );
};
