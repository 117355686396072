import { Activity, ActivityCustomField, TemplateConfiguration } from "PFTypes";

export const isSubtemplatesLogicActive = (
  hasSubtemplates: boolean,
  hasSubtemplateKey: boolean,
  isEdit: boolean,
  isClone: boolean
): boolean => {
  if (!hasSubtemplates) {
    return false;
  }

  return !((isEdit || isClone) && !hasSubtemplateKey);
};

export const getPayloadCustomFields = (customFields: ActivityCustomField[]) =>
  customFields.map((customField) => ({
    ...customField,
    values: customField.values.map(({ id, text, value, importance, required_experience }) => ({
      id,
      text,
      value,
      importance,
      required_experience
    }))
  }));

export const getInitialQuestionValue = (
  questionNumber: number,
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean
): string => {
  const activityQuestion = activity.metadata?.interest_questions?.[questionNumber - 1];
  if (isEdit) {
    return activityQuestion || "";
  }

  const areQuestionsProvidedInActivity = !!activity.metadata?.interest_questions;
  if (isClone && areQuestionsProvidedInActivity) {
    return activityQuestion || "";
  }

  return templateConfig?.[`question${questionNumber}`] || "";
};

export const getInitialQuestionsToggleValue = (
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean
): boolean => {
  if (isEdit || isClone) {
    return !!activity.metadata?.interest_questions;
  }

  return !!templateConfig?.show_questions;
};

export const getQuestionsTileVisibility = (
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean,
  isAtLeastOneQuestion: boolean
): boolean => {
  if (isEdit) {
    return isAtLeastOneQuestion;
  }

  if (isClone) {
    const areQuestionsProvidedInActivity = !!activity.metadata?.interest_questions;

    return areQuestionsProvidedInActivity;
  }

  return !!templateConfig?.show_questions && (isAtLeastOneQuestion || !!templateConfig?.allow_rm_to_edit);
};

export const WORKFLOW_STATUSES_WITH_BOOKINGS = ["partially_booked", "booked", "confirmed"];
