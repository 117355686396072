import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Experience } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import css from "./skill_rating_icon.module.scss";

const SkillRatingIcon = ({ value, size, className, isEditMode, updateExperience, color, strokeWidth }) => {
  const experienceLabelMap = useExperienceLabelMap();

  const [levelToShow, setLevelToShow] = useState(value);
  useEffect(() => {
    setLevelToShow(value);
  }, [value]);

  const onMouseOut = isEditMode ? () => setLevelToShow(value) : null;

  const onMouseOver = isEditMode
    ? (event) => {
        event.stopPropagation();
        setLevelToShow(event.target.getAttribute("data-level"));
      }
    : null;

  const selectedIcon = (rating) => (
    <>
      <Tooltip theme="pf-dark-blue" disabled={!isEditMode} content={experienceLabelMap[Experience.Basic]}>
        <circle
          cx="9"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={Experience.Basic}
          fill={rating >= Experience.Basic ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(Experience.Basic)}
        />
      </Tooltip>
      <line
        data-level={Experience.Basic}
        x1="17"
        y1="10"
        x2="22"
        y2="10"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <Tooltip
        theme="pf-dark-blue"
        disabled={!isEditMode}
        content={experienceLabelMap[Experience.Intermediate]}
      >
        <circle
          cx="30"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={Experience.Intermediate}
          fill={rating >= Experience.Intermediate ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(Experience.Intermediate)}
        />
      </Tooltip>
      <line
        data-level={Experience.Intermediate}
        x1="38"
        y1="10"
        x2="43"
        y2="10"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <Tooltip theme="pf-dark-blue" disabled={!isEditMode} content={experienceLabelMap[Experience.Advanced]}>
        <circle
          cx="51"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={Experience.Advanced}
          fill={rating >= Experience.Advanced ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(Experience.Advanced)}
        />
      </Tooltip>
    </>
  );

  return (
    <svg
      className={className}
      data-level={value}
      height={size}
      focusable="false"
      role="presentation"
      viewBox="0 0 60 19"
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      <g className={css.fill}>{selectedIcon(levelToShow)}</g>
    </svg>
  );
};

SkillRatingIcon.propTypes = {
  /** Normalized value (between 0 and 1). */
  value: PropTypes.number,
  /** 't-shirt' size. */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Visual style */
  className: PropTypes.string,
  isEditMode: PropTypes.bool,
  updateExperience: PropTypes.func,
  color: PropTypes.string,
  strokeWidth: PropTypes.number
};

SkillRatingIcon.defaultProps = {
  value: null,
  size: "1em",
  color: "#0C1457",
  strokeWidth: 2
};

export default SkillRatingIcon;
