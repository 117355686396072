import classNames from "classnames";
import { getOrientation } from "get-orientation/browser";
import { useGrowl } from "PFApp/use_growl";
import { useSession } from "PFApp/use_session";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { usePusherEvent } from "PFCore/base/pusher";
import { getCroppedImg, getRotatedImage } from "PFCore/helpers/canvas";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfileAvatarDelete } from "PFCore/hooks/queries/profile/use_profile_avatar_delete";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { ApiRoute } from "PFCore/utilities/routes";
import UploadFileIcon from "PFIcons/upload_file.svg";
import defaultAvatarUrl from "PFImages/premium/avatar_default.png";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

import css from "./avatar_uploader.module.scss";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
};

const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

const PhotoEditModal = ({ profile, onClose, onSave, onError }) => {
  const { data: currentAccount } = useCurrentAccount();

  const growl = useGrowl();
  const growlErrors = useErrorsGrowl();
  const { getAccessToken } = useSession();
  const { t } = useTranslation("profiles", { keyPrefix: "common.avatarUploader" });

  const [imageSrc, setImageSrc] = useState(profile.avatar.custom ? profile.avatar.full_url : null);
  const [fileName, setFileName] = useState("edited.jpg");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line id-length
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
      setFileName(file.name);
      setImageSrc(imageDataUrl);
    }
  };

  const handleError = (resp) => {
    // eslint-disable-next-line camelcase
    growlErrors(resp, { display_all: true, version: "v2" });
    onError();
  };

  const uploadImage = async () => {
    try {
      const blob = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      const requestHeaders = {
        ACCOUNT: currentAccount.full_domain,
        ACCEPT: `application/vnd.profinda+json;version=2`,
        Authorization: `Bearer ${getAccessToken()}`
      };
      setLoading(true);
      const formData = new FormData();
      formData.append("avatar", blob, fileName.replace(".", `_${new Date().getTime()}.`));

      const req = new XMLHttpRequest();
      req.open("POST", ApiRoute(`/api/profiles/${profile.id}/avatar`));

      for (var key in requestHeaders) {
        req.setRequestHeader(key, requestHeaders[key]);
      }

      req.onload = () => {
        handleError(JSON.parse(req.response));
        onClose();
      };

      req.onerror = () => {
        growl({ kind: "error", message: t("core:errors.networkError") });
        setLoading(false);
      };

      req.send(formData);
      onSave();
    } catch (event) {
      growl({
        kind: "error",
        message: t("errors.couldNotProcess")
      });
    }
  };

  return (
    <Modal
      title={t("photoEdit")}
      onOK={uploadImage}
      onClose={onClose}
      labelOK={t("translation:save")}
      disableOKButton={!imageSrc || loading}
      classes={{ modal: css.modal }}
    >
      <div
        className={classNames(css.cropperWithInputs, {
          [css.disabled]: !imageSrc
        })}
      >
        <div className={css.photoEditInputWrapper}>
          <label className={css.photoEditInputLabel} htmlFor="profile_edit_photo_zoom">
            {t("zoom")}
          </label>
          <div className={css.inputContainer}>
            <input
              className={classNames(css.photoEditInput, css.zoom)}
              name="profile_edit_photo_zoom"
              id="profile_edit_photo_zoom"
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(event) => setZoom(event.target.value)}
            />
          </div>
        </div>
        <div className={css.cropperWrapper}>
          <Cropper
            image={imageSrc ?? defaultAvatarUrl}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            classes={{ cropAreaClassName: css.cropArea }}
            disabled
          />
        </div>
        <div className={css.photoEditInputWrapper}>
          <label className={css.photoEditInputLabel} htmlFor="profile_edit_photo_rotation">
            {t("rotation")}
          </label>
          <div className={css.inputContainer}>
            <input
              className={css.photoEditInput}
              name="profile_edit_photo_rotation"
              id="profile_edit_photo_rotation"
              type="range"
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(event) => setRotation(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={css.uploadInputWrapper}>
        <label className={css.uploadInputLabel} htmlFor="profile_edit_photo_upload">
          <UploadFileIcon height={15} />
          {t("uploadPhoto")}
        </label>
        <input
          className={css.uploadInput}
          id="profile_edit_photo_upload"
          type="file"
          onChange={onFileChange}
          accept="image/*"
        />
      </div>
    </Modal>
  );
};

PhotoEditModal.propTypes = {
  profile: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func
};

const AvatarUploader = ({ profile, isEditable = true }) => {
  const { data: currentProfile } = useCurrentProfile();
  const setCurrentProfile = useCurrentProfileSet();
  const growl = useGrowl();
  const growlErrors = useErrorsGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "common.avatarUploader" });
  const editButtonRef = useRef(null);
  const removeButtonRef = useRef(null);

  const { mutateAsync: deleteProfileAvatar, isLoading: isDeletingAvatar } = useProfileAvatarDelete();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const isCustom = profile?.avatar?.custom;

  const handleAvatarProcessed = useCallback(
    ({ source, huge, rounded, normal }) => {
      if (source !== "Profile#picture") {
        return;
      }

      setCurrentProfile({
        ...currentProfile,
        avatar: {
          custom: true,
          full_url: huge.url,
          rounded_url: rounded.url,
          thumbnail_url: normal.url
        }
      });
      growl({ message: t("profilePicture.saved") });
      setLoading(false);
      setTimeout(() => editButtonRef.current?.focus(), 0);
    },
    [currentProfile, growl, setCurrentProfile, t]
  );

  usePusherEvent(
    `private-profile-${currentProfile.id}`,
    "current_user:picture_processed",
    handleAvatarProcessed
  );

  const handleAvatarRemoved = () => {
    setCurrentProfile({
      ...currentProfile,
      avatar: {
        custom: false
      }
    });
    growl({ message: t("profilePicture.removed") });
  };

  const handleRemove = () =>
    deleteProfileAvatar(profile.id)
      .then(handleAvatarRemoved)
      .catch((err) => {
        growlErrors(err.responseJSON, { display_all: true, version: "v2" });
      });

  const isLoading = loading || isDeletingAvatar;

  return (
    <div className={css.avatarUploader}>
      <div className={css.avatarWrapper}>
        {isLoading ? (
          <LoadingDots circleSize="xs" circlesEnabled />
        ) : (
          <ProfileAvatar qaId="profile-edit-picture-picture" profile={profile} size={120} />
        )}
      </div>
      {isEditable && (
        <div className={css.buttons}>
          <Button
            ref={editButtonRef}
            style={{ position: "relative", zIndex: 9 }}
            kind="secondary"
            type="button"
            qaId="profile-edit-update-button"
            disabled={isLoading}
            onClick={() => setShowModal(true)}
            aria-label={t("profilePicture.editProfilePhoto")}
          >
            {t("profilePicture.edit")}
          </Button>
          {isCustom && (
            <Button
              ref={removeButtonRef}
              style={{ position: "relative", zIndex: 9 }}
              kind="danger"
              type="button"
              onClick={() => setShowConfirmRemove(true)}
              disabled={isLoading}
              qaId="profile-edit-picture-remove"
              aria-label={t("profilePicture.removeProfilePhoto")}
            >
              {t("translation:remove")}
            </Button>
          )}
        </div>
      )}
      {showModal && (
        <PhotoEditModal
          profile={profile}
          onClose={() => {
            setShowModal(false);
            setTimeout(() => editButtonRef.current?.focus(), 0);
          }}
          onSave={() => setLoading(true)}
          onError={() => setLoading(false)}
        />
      )}
      {showConfirmRemove && (
        <Modal
          title={t("profilePicture.removeProfilePhoto")}
          onOK={handleRemove}
          onClose={() => {
            setShowConfirmRemove(false);
            setTimeout(() => (removeButtonRef.current ?? editButtonRef.current)?.focus(), 0);
          }}
        >
          {t("profilePicture.confirmRemove")}
        </Modal>
      )}
    </div>
  );
};

AvatarUploader.propTypes = {
  profile: PropTypes.object.isRequired,
  isEditable: PropTypes.bool
};

export default AvatarUploader;
