import { useMutation } from "@tanstack/react-query";
import { cleanupCustomValues } from "PFCore/services/admin/custom_fields";
import { MutationOptions } from "PFTypes/request_options";

type MutationFnParams = {
  customTypeId: number;
};

export const useCustomValuesCleanup = (queryParams: MutationOptions<MutationFnParams>) =>
  useMutation({
    mutationFn: ({ customTypeId }: MutationFnParams): Promise<void> => cleanupCustomValues(customTypeId),
    ...queryParams
  });
