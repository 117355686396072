import classNames from "classnames";
import { compact } from "lodash";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { AccessLevel, Profile } from "PFTypes";
import { LocationState } from "PFTypes/router";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useTargets } from "../../../common/use_targets";
import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import { ContactInformationSection } from "./contact_information_section";
import { LastUpdatedSection } from "./last_updated_section";
import { ProfileSection } from "./profile_section";
import css from "./side_panel.module.scss";
import { SummarySection } from "./summary_section";

const isSummaryPermitted = (currentProfile: Profile): boolean => {
  // Cross Group summary permissions
  const summaryPermissions = currentProfile?.permissions_group?.cross_group_permissions?.[
    currentProfile?.permissions_group?.id
  ]?.profile_fields_access_levels?.["summary"] as AccessLevel;

  return [AccessLevel.ReadWrite, AccessLevel.ReadOnly].includes(summaryPermissions);
};

type Menu = {
  id: string;
  key: string;
  text?: string;
};

type ExperienceCustomType = {
  display_as?: string;
  name?: string;
  id: number;
};

type SidePanelProps = {
  isMe?: boolean;
  profile: Profile;
  experienceCustomTypes: ExperienceCustomType[];
  interestsCount: number;
  initSection?: string;
  editProfilePage?: object;
  onProfileUpdate: OnProfileUpdateHandler;
};

const SidePanel = ({
  isMe,
  profile,
  experienceCustomTypes,
  interestsCount,
  editProfilePage,
  initSection,
  onProfileUpdate
}: SidePanelProps): JSX.Element => {
  const { t } = useTranslation("profiles");

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const additionalMenuRef = useRef<HTMLDivElement>(null);
  const location = useLocation<LocationState>();

  const me = profile?.id && profile?.id === currentProfile?.id;

  const {
    section: scrollSection,
    scrollToItem,
    recalcTargets
  } = useTargets({
    me,
    profile,
    initSection
  });

  useEffect(() => {
    recalcTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { section, additionalSection } = useParams<{ section: string; additionalSection?: string }>();

  const isAccomplishmentsSectionVisible = currentAccount.profile_view_customization.accomplishment_section;

  const navLinks = useMemo(
    () =>
      compact([
        { id: "summary", text: t("show.parts.summary") },
        {
          id: "experience",
          text: t("show.parts.experience")
        },
        isAccomplishmentsSectionVisible && {
          id: "accomplishments",
          text: t("show.parts.accomplishments")
        },
        {
          id: "additional",
          text: t("show.parts.additional")
        }
      ]),
    [isAccomplishmentsSectionVisible, t]
  );

  const menuItems = useMemo(() => {
    const menu: Menu[] = [];

    const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);

    experienceCustomTypes
      .filter((item) => [AccessLevel.ReadWrite, AccessLevel.ReadOnly].includes(accessLevels[item.id]))
      .forEach((customType) => {
        menu.push({
          text: customType?.display_as,
          id: `cf_${customType?.name}`,
          key: `cf_${customType?.name}`
        });
      });

    if (interestsCount) {
      menu.push({ text: t("show.parts.interestedIn"), id: "interested_in", key: "interested_in" });
    }

    return menu;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceCustomTypes.length, interestsCount, editProfilePage]);

  useEffect(() => {
    const item = menuItems.find((item) => item.id === additionalSection);
    if (item) {
      scrollToItem(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalSection]);

  const progressCompletion = profile?.statistics?.progress_completion ?? 0;
  const isCompleted = progressCompletion === 100;

  const summaryPermitted = isSummaryPermitted(currentProfile);

  let navLinksSelectedIndex = navLinks.findIndex((link) => link.id === (section || navLinks[0].id));
  if (navLinksSelectedIndex === -1) {
    navLinksSelectedIndex = 0;
  }

  return (
    <aside className={css.sidePanel}>
      <section
        className={classNames(css.section1, {
          [css.noRadius]: section === "accomplishments"
        })}
      >
        <div className={css.innerSection}>
          <ProfileSection
            profile={profile}
            progressCompletion={progressCompletion}
            isCompleted={isCompleted}
            isMe={Boolean(isMe)}
            onProfileUpdate={onProfileUpdate}
          />
          {profile.summary && summaryPermitted && <SummarySection profile={profile} />}
          <LastUpdatedSection updatedAt={profile.updated_at} />
          <ContactInformationSection profile={profile} />
        </div>
        <div className={css.separator} />
      </section>
      <nav className={css.scrollable} tabIndex={-1} role="navigation">
        <div className={css.section}>
          {navLinks.slice(0, navLinksSelectedIndex).map((link) => (
            <Link
              key={link.text}
              className={css.navLink}
              role="link"
              to={`/profiles/${isMe ? "me" : profile.id}/${link.id}`}
            >
              {link.text}
            </Link>
          ))}
        </div>
        <div className={css.section2}>
          <Link
            className={classNames(css.navLink, css.selected)}
            aria-current="page"
            role="link"
            to={`/profiles/${isMe ? "me" : profile.id}/${navLinks[navLinksSelectedIndex].id}`}
          >
            {navLinks[navLinksSelectedIndex].text}
          </Link>
        </div>
        <div className={css.section3}>
          {navLinks.slice(navLinksSelectedIndex + 1).map((link) => (
            <Link
              key={link.text}
              className={css.navLink}
              role="link"
              to={`/profiles/${isMe ? "me" : profile.id}/${link.id}`}
            >
              {link.text}
            </Link>
          ))}
          <div className={css.additionalMenu}>
            <div ref={additionalMenuRef}>
              {menuItems.map((item, index) => (
                <Link
                  key={`${item.id}_${index}`}
                  to={`/profiles/${isMe ? "me" : profile.id}/additional/${item.id}`}
                  className={classNames(css.navLink, css.additional, {
                    [css.active]: item.id === scrollSection
                  })}
                  title={t("show.parts.jumpToLink", { link: item.text })}
                  role="link"
                  aria-current={item.id === scrollSection && "page"}
                >
                  <span style={{ marginRight: 5 }}> — </span>
                  <span style={{ flex: 1 }}>{item.text}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default SidePanel;
