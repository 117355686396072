import css from "../organisation_data.module.scss";

type PairProps = {
  title: string;
  qaId: string;
  children: React.ReactNode;
};

export const Pair = ({ title, qaId, children }: PairProps) => (
  <div data-qa-id={qaId} className={css.item} key={qaId}>
    <span className={css.title}>{title}</span> <span className={css.val}>{children}</span>
  </div>
);
