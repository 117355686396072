import ActivityPreviewLink from "PFApp/components/activity_commons/activity_preview_link";
import BadgesList from "PFComponents/badges_list/badges_list";
import { useActivityNameAndId } from "PFCore/helpers/activities";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import PinIcon from "PFIcons/pin";
import PropTypes from "prop-types";

import css from "./content.module.scss";
import Description from "./description";

const RolePositionContent = ({ task }) => {
  const skills = task.custom_fields.find((item) => item.type.name === "skills");
  const { customTypes } = useCustomTypes();
  const getRoleNameId = useActivityNameAndId();
  const { name, id } = getRoleNameId(task);

  const customField = task.custom_fields.find(({ type }) => type.name === "languages");
  const values = customField && customField.values;
  const customType = customField && customTypes.find(({ id }) => id === customField.type.id);
  const displayAs = values && values.length > 0 && customType && customType.display_as;
  const metadata = task.metadata || {};

  const locationItems = Array.isArray(metadata.location) ? metadata.location : [metadata.location];
  const locations = locationItems
    .map((item) => `${item?.value || item || ""}`)
    .filter(Boolean)
    .join(", ");

  return (
    <div className={css.wrapper}>
      <ActivityPreviewLink activity={task} className={css.fullWidth}>
        <div className={css.header}>
          <h3>{name}</h3>
          {id && <div>{`ID: ${id}`}</div>}
        </div>
        <div className={css.description}>
          <Description description={task.description} id={task.id} />
        </div>
        {skills && (
          <BadgesList
            values={skills.values}
            moreLimit={5}
            customButton={
              skills.values.length > 5 && (
                <div>
                  <strong>{`+ ${skills.values.length - 5}`}</strong>&nbsp;skills
                </div>
              )
            }
          />
        )}

        <div className={css.finalDataRow}>
          {locations && (
            <span>
              <PinIcon style={{ fill: "#555", opacity: 0.3 }} />
              <span>{locations}</span>
            </span>
          )}
          {values && values.length > 0 && (
            <span>
              <span>{`${displayAs}: `}</span>
              <span>{values.map(({ text }) => text).join(", ")}</span>
            </span>
          )}
        </div>
      </ActivityPreviewLink>
    </div>
  );
};

RolePositionContent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    template_id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.shape({
      location: PropTypes.string
    }),
    custom_fields: PropTypes.array,
    created_at: PropTypes.string
  }),
  infoString: PropTypes.string
};

export default RolePositionContent;
