import { ColumnHelper } from "@tanstack/react-table";
import Table from "PFComponents/table/table";
import { Typography } from "PFComponents/typography";
import { EconomicsVacancy, Id, ProfileMinimized } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ProfileAvatarConnected } from "../../../../../../components/profile_avatar_connected";
import { useCostFieldLabel } from "../../use_cost_field_label";
import { getPercentValue } from "../../utils";
import css from "./vacancies_table.module.scss";
import { VacancyActions } from "./vacancy_actions";

type VacanciesTableProps = {
  vacancies: EconomicsVacancy[];
  scenarioId: Id;
  isEditable?: boolean;
};

export const VacanciesTable = ({ vacancies, scenarioId, isEditable = true }: VacanciesTableProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  const costFieldLabel = useCostFieldLabel();

  const columnsGenerator = useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) => [
      columnHelper.accessor(({ assignment }) => assignment?.profile, {
        id: "workforceMember",
        header: t("workforceMember"),
        cell: (info) => {
          const profile = info.getValue();

          if (!profile) {
            return "-";
          }

          return (
            <span className={css.profileCellContent}>
              <ProfileAvatarConnected profile={profile as unknown as ProfileMinimized} size={32} />
              <Typography variant="bodyButtons" noMargin>
                {`${profile.firstName} ${profile.lastName}`}
              </Typography>
            </span>
          );
        },
        size: 300
      }),
      columnHelper.accessor(({ costFieldValue }) => costFieldValue, {
        id: "costFieldValue",
        header: () => (
          <Typography className={css.costFieldHeader} variant="bodyRegular" noMargin clipOverflow>
            {costFieldLabel}
          </Typography>
        ),
        cell: (info) => (
          <Typography className={css.gradeCellContent} variant="bodyRegular" noMargin clipOverflow>
            {info.getValue() || "-"}
          </Typography>
        ),
        size: 100
      }),
      columnHelper.accessor(({ scores }) => scores?.normalizedScore, {
        id: "match",
        header: () => <span className={css.headerRightAlign}>{t("match")}</span>,
        cell: (info) => <span className={css.cellRightAlign}>{getPercentValue(info.getValue())}</span>
      }),
      columnHelper.accessor(({ scores }) => scores?.availabilityScore, {
        id: "availability",
        header: () => <span className={css.headerRightAlign}>{t("availability")}</span>,
        cell: (info) => <span className={css.cellRightAlign}>{getPercentValue(info.getValue())}</span>
      }),
      columnHelper.accessor((row) => row, {
        id: "actions",
        header: "",
        cell: (info) => (
          <span className={css.cellRightAlign}>
            <VacancyActions vacancy={info.getValue()} scenarioId={scenarioId} disabled={!isEditable} />
          </span>
        ),
        enableSorting: false,
        size: 64
      })
    ],
    [t, scenarioId, isEditable]
  );

  return (
    <Table<EconomicsVacancy>
      compact
      generateColumns={columnsGenerator}
      tableData={vacancies}
      disableAutoResizing
    />
  );
};
