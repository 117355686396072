import { Importance } from "PFTypes";

import { useRoleMatchCustomFieldData } from "./use_role_match_custom_field_data";

export const useRequiredSkills = () => {
  const { getCustomFieldValuesOfImportance } = useRoleMatchCustomFieldData();
  const { roleCustomValues, matchCustomValues, missingMatchCustomValues } = getCustomFieldValuesOfImportance(
    "skills",
    Importance.Required
  );

  return {
    roleRequiredSkills: roleCustomValues,
    matchRequiredSkills: matchCustomValues,
    missingMatchRequiredSkills: missingMatchCustomValues
  };
};
