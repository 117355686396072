import { isArray, mapValues } from "lodash";
import AppContext from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import Cover from "PFCore/components/cover";
import scroll from "PFCore/helpers/scroll";
import useConfirm from "PFCore/helpers/use_confirm";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./legacy_form.module.scss";

const LegacyForm = ({
  isNew,
  isDirty,
  blockNavigationOnChange,
  children,
  isSubmitDisabled,
  growlErrors,
  onCancel,
  onError,
  onRemove,
  onSubmit,
  onSuccess,
  qaIdPrefix,
  submitLabel
}) => {
  const { t } = useTranslation("core");
  const { dispatch } = useContext(AppContext);

  const [locked, setLocked] = useState(false);

  const confirmNavigation = useConfirm(isDirty);
  const { getFormattedErrors } = useResponseErrors();

  const growlError = (options = {}) => {
    dispatch({
      type: GROWL_ACTIONS.GROWL_RENDER,
      payload: {
        message: options.message || t("form.notSaved"),
        kind: "error",
        onClick: () => {
          scroll($("[data-target-id='input_field_set__error']").first(), { offset: -100 });
        }
      }
    });
  };

  const handleSuccess = (...args) => {
    setLocked(false);

    onSuccess?.(...args);
  };

  const handleError = (resp, ...rest) => {
    setLocked(false);

    const errors = mapValues(getFormattedErrors(resp?.response || resp), (val) =>
      isArray(val) ? val.join(", ") : val
    );

    onError?.(errors, resp, ...rest);

    if (growlErrors) {
      growlError();
    }
  };

  const handleSubmit = () => {
    setLocked(true);

    const submitPromise = onSubmit(handleSuccess, handleError);

    if (!submitPromise) {
      return;
    }
    // If onSubmit returns a promise, we'll wait for it to resolve, and execute form handlers
    Promise.resolve(submitPromise).then(handleSuccess, handleError);
  };

  const handleCancel = () => {
    if (blockNavigationOnChange && isDirty && confirmNavigation()) {
      return;
    }

    return onCancel?.();
  };

  return (
    <div className={css.form}>
      <RouterPrompt when={blockNavigationOnChange && isDirty} />
      {locked && <Cover />}
      {children}

      <div className={css.modelFormActions}>
        {onRemove && (
          <Button
            text={isNew ? t("cancel") : t("remove")}
            kind={isNew ? "secondary" : "danger"}
            qaId={`${qaIdPrefix}-remove`}
            onClick={onRemove}
          />
        )}
        {onCancel && (
          <Button
            text={t("cancel")}
            kind={"secondary"}
            qaId={`${qaIdPrefix}-cancel`}
            onClick={handleCancel}
          />
        )}
        <Button
          kind="primary"
          onClick={handleSubmit}
          qaId={`${qaIdPrefix}-button`}
          disabled={isSubmitDisabled || !isDirty}
        >
          {submitLabel || (isNew ? t("create") : t("update"))}
        </Button>
      </div>
    </div>
  );
};

export default LegacyForm;

LegacyForm.propTypes = {
  blockNavigationOnChange: PropTypes.bool,
  children: PropTypes.node,
  growlErrors: PropTypes.bool,
  isDirty: PropTypes.bool,
  isNew: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  qaIdPrefix: PropTypes.string,
  submitLabel: PropTypes.string,

  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

LegacyForm.defaultProps = {
  blockNavigationOnChange: false,
  growlErrors: false,
  isNew: false,
  isDirty: false,
  isSubmitDisabled: false,
  qaIdPrefix: "form",
  submitLabel: null
};
