import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Select } from "PFComponents/select/select";
import Toggle from "PFComponents/toggle/toggle";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { getTemplateVisibleProperties } from "../helpers";
import css from "./table_properties.module.scss";

const allowedFieldsGroups = ["state", "workflow_state"];

const TableProperties = ({
  isVisible,
  onCloseClick,
  onColumnsChange,
  selectedColumns,
  defaultColumns,
  selectedGroupBy = null,
  onGroupByChange = null,
  blockedProperties,
  template,
  meta
}) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t, optionalT } = useOptionalTranslation("workflow");

  const showGrouping = !!selectedGroupBy;
  const visibleTemplateProperties = useMemo(
    () => getTemplateVisibleProperties(customTypes, currentProfile, blockedProperties, template),
    [customTypes, currentProfile, blockedProperties, template]
  );

  const groupByOptions = useMemo(() => {
    const fieldsGroups = Object.values(meta?.filters.fields || {}).filter(({ name }) =>
      allowedFieldsGroups.includes(name)
    );
    const stringsGroups = Object.values(meta?.filters.strings || {});

    return [...fieldsGroups, ...stringsGroups].map(({ name, title }) => ({
      id: name,
      item: name,
      displayElement: title
    }));
  }, [meta]);

  const [selectedGroupByIndex, selectedGroupByName] = useMemo(() => {
    if (selectedGroupBy) {
      const index = groupByOptions.findIndex(({ item }) => item === selectedGroupBy);
      const name = groupByOptions.find(({ item }) => item === selectedGroupBy)?.displayElement;
      return [index, name];
    } else {
      return [];
    }
  }, [selectedGroupBy, groupByOptions]);

  const allColumns = useMemo(() => {
    const availableColumns = (defaultColumns || []).concat(
      visibleTemplateProperties.map(({ name, type, title, kind }) => {
        const columnData = {
          name,
          type,
          title
        };
        if (type === "custom_field" && kind === "single") {
          columnData.orderKey = `custom_fields.${name}`;
        }
        return columnData;
      })
    );
    const columns = selectedColumns
      .filter((column) => column.locked || availableColumns.find(({ name }) => name === column.name))
      .map((column) => ({ ...column, selected: true }));
    const unselectedColumns = availableColumns.filter(
      (column) => !selectedColumns.find(({ name }) => column.name === name)
    );
    return columns.concat(unselectedColumns);
  }, [selectedColumns, visibleTemplateProperties, defaultColumns]);

  const handleColumnUpdate = (columnData) => {
    if (columnData.default) {
      return;
    }
    const newSelectedColumns = [...selectedColumns];
    if (columnData.selected) {
      // removing
      const index = newSelectedColumns.findIndex((item) => item.name === columnData.name);
      newSelectedColumns.splice(index, 1);
    } else {
      // adding
      newSelectedColumns.push(columnData);
    }
    onColumnsChange(newSelectedColumns);
  };

  return (
    <div className={classNames(css.root, { [css.show]: isVisible })}>
      <div className={css.header}>
        <h2>{t("parts.tableProperties")}</h2>
        <ActionIcon name="cross" size="xs" onClick={onCloseClick} />
      </div>

      {onGroupByChange && (
        <Toggle
          label={t("parts.enableTableProperties")}
          checked={showGrouping}
          inline
          disabled={groupByOptions.length === 0}
          onChange={(value) => {
            onGroupByChange(value ? groupByOptions[0]?.item : null);
          }}
          qaId="TablePropertiesGroupToggle"
        />
      )}

      {showGrouping && (
        <Select
          onChange={onGroupByChange}
          value={selectedGroupByName}
          options={groupByOptions}
          selectedIndex={selectedGroupByIndex}
          controlledValue
        />
      )}

      <span className={css.columnButtonsTitle}>{t("parts.editColumns")}</span>
      <div className={css.columnButtonsContainer} data-qa-id="ActivityInterestedTabTableProperties">
        {allColumns.map((columnData, index) => (
          <Button
            key={`${columnData.name}-${index}`}
            className={classNames(css.columnButton, { [css.inactive]: !columnData.selected })}
            kind="blank"
            onClick={() => handleColumnUpdate(columnData)}
            disabled={columnData.locked}
            qaId={`TablePropertiesVisibilityButton.${columnData.name}`}
            title={optionalT(columnData.title)}
            icon={columnData.selected ? "shown" : "hidden"}
            text={optionalT(columnData.title)}
          />
        ))}
      </div>
    </div>
  );
};

TableProperties.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onColumnsChange: PropTypes.func.isRequired,
  template: PropTypes.object,
  selectedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  blockedProperties: PropTypes.arrayOf(PropTypes.string),
  defaultColumns: PropTypes.array,
  selectedGroupBy: PropTypes.string,
  onGroupByChange: PropTypes.func,
  meta: PropTypes.object
};

TableProperties.defaultProps = {
  blockedProperties: []
};

export default TableProperties;
