import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useMatchCardContext } from "../../match_card_context";
import css from "./match_card_scores.module.scss";
import { MatchScore } from "./match_score";

export const MatchCardScores = () => {
  const {
    scores: { availability_score, normalized_score },
    isCompressed
  } = useMatchCardContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card.scores" });

  return (
    <div
      className={classNames(css.matchCardScores, {
        [css.multiline]: !isCompressed
      })}
    >
      <MatchScore title={t("match")} score={normalized_score} unit="%" isBold isLarge={!isCompressed} />
      {!isCompressed && <div className={css.break} />}
      <MatchScore title={t("availability")} score={availability_score} unit="%" />
    </div>
  );
};
