import { _MatchedProfile, Shortlist, Snakify } from "PFTypes";

export type MatchReason = "developmental";

type _Match = _MatchedProfile & {
  shortlist: Pick<Shortlist, "id" | "state" | "applicable_custom_values"> | null;
};

export type Match = Snakify<Omit<_Match, "profile" | "customValues" | "shortlist">> &
  Pick<_Match, "profile" | "customValues" | "shortlist"> & {
    custom_values: _Match["customValues"];
  };

export enum MatchFetchState {
  Pending = "pending",
  Synced = "synced",
  Error = "error",
  Timeout = "timeout"
}
