import classNames from "classnames";

import css from "./month_selector.module.scss";
import { CalendarSelectProps } from "./month_selector.types";
import { Months } from "./months";
import { Years } from "./years";

type MonthSelectorProps = CalendarSelectProps & {
  className?: string;
};

export const MonthSelector = ({ className, ...props }: MonthSelectorProps) => (
  <div className={classNames(css.monthSelectorContainer, className)} role="gridcell">
    <Months {...props} />
    <Years {...props} />
  </div>
);
