import { trim, uniqBy } from "lodash";
import useStorage from "PFCore/helpers/use_storage";
import { RecentSearch, RecentSearchesPageKey } from "PFTypes/recent_searches";

export const RECENT_SEARCHES_PAGE_LIMIT = 30;

export const useRecentSearches = (pageKey: RecentSearchesPageKey) => {
  const [recentSearches, setRecentSearches] = useStorage<RecentSearch[]>(`${pageKey}_recent_searches`, []);

  const saveSearchAsRecent = (term: string): void => {
    const trimmedTerm = trim(term);
    if (!trimmedTerm) {
      return;
    }

    const newItem: RecentSearch = {
      value: trimmedTerm,
      timestamp: Date.now()
    };

    const updatedPageRecentSearches = uniqBy([newItem, ...recentSearches], "value").slice(
      0,
      RECENT_SEARCHES_PAGE_LIMIT
    );

    setRecentSearches(updatedPageRecentSearches);
  };

  const getRecentSearches = (term: string, limit: number = 3): RecentSearch[] =>
    recentSearches
      .filter(({ value }) => value.toLowerCase().includes(trim(term).toLowerCase()))
      .slice(0, limit);

  return {
    getRecentSearches,
    saveSearchAsRecent
  };
};
