import { Modal } from "PFComponents/modal";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NoteInput } from "./note_input/note_input";

export type NoteForm = {
  content: string;
};

type NoteModalProps = {
  mode: "add" | "edit";
  content?: string;
  onClose: () => void;
  onSave: (content: string) => void;
};

export const NoteModal = ({ mode, content, onClose, onSave }: NoteModalProps) => {
  const { t } = useTranslation(["core", "translation"]);
  const formMethods = useForm<NoteForm>({
    defaultValues: {
      content: content ?? ""
    },
    mode: "onChange"
  });
  const {
    watch,
    handleSubmit,
    formState: { errors }
  } = formMethods;

  const watchedContent = watch("content");

  const handleSave: SubmitHandler<NoteForm> = ({ content }) => onSave(content);
  const isNoteValid = watchedContent && !errors.content;

  return (
    <Modal
      title={mode === "add" ? t("core:notes.addNote") : t("core:notes.editNote")}
      labelOK={t("translation:save")}
      onClose={onClose}
      onOK={isNoteValid ? handleSubmit(handleSave) : undefined}
    >
      <FormProvider {...formMethods}>
        <NoteInput />
      </FormProvider>
    </Modal>
  );
};
