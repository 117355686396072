import Color from "color";
import PropTypes from "prop-types";

import { BasicPill } from "./basic_pill";
import NoCategoryBookingPill from "./no_category_booking_pill";

export const getCategoryBorderColor = (category) => Color(category?.color).darken(0.5).hex();

const BookingPill = ({
  title,
  children,
  onClick,
  onMouseLeave,
  onMouseMove,
  className,
  style,
  category,
  withNoCategoryIcon
}) => {
  if (!category) {
    return (
      <NoCategoryBookingPill
        withIcon={withNoCategoryIcon}
        style={style}
        className={className}
        onClick={onClick}
      >
        {children}
      </NoCategoryBookingPill>
    );
  }
  const finalStyle = {
    background: category.color,
    border: `2px solid ${getCategoryBorderColor(category)}`,
    ...style
  };
  return (
    <BasicPill
      title={title}
      className={className}
      style={finalStyle}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {children}
    </BasicPill>
  );
};

BookingPill.defaultProps = {
  withNoCategoryIcon: true
};

BookingPill.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  category: PropTypes.shape({
    color: PropTypes.string.isRequired
  }),
  withNoCategoryIcon: PropTypes.bool
};

export default BookingPill;
