import { useTemplate } from "PFApp/hooks";
import { Activity } from "PFTypes";
import { useMemo } from "react";

import { isAudit, isEngagement, isRole } from "../helpers";

export const useActivityTemplateType = (activity: Partial<Pick<Activity, "template_id">> | undefined) => {
  const template = useTemplate(activity?.template_id);

  return useMemo(
    () => ({
      isRole: isRole(template),
      isEngagement: isEngagement(template),
      isAudit: isAudit(template)
    }),
    [template]
  );
};
