import { Modal } from "PFComponents/modal";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./avatar_preview_modal.module.scss";

type AvatarPreviewModalProps = {
  profile: Profile;
  onClose: VoidFunction;
  onEditClick: VoidFunction;
};

export const AvatarPreviewModal = ({ profile, onClose, onEditClick }: AvatarPreviewModalProps) => {
  const { t } = useTranslation();
  const profileName = getProfileName(profile);

  return (
    <Modal
      title={profileName}
      classes={{ modal: css.modal }}
      labelCancel={t("close")}
      labelOK={t("edit")}
      onClose={onClose}
      onOK={onEditClick}
    >
      <div className={css.avatar}>
        <ProfileAvatar fullSize profile={profile} />
      </div>
    </Modal>
  );
};
