import { useTranslation } from "react-i18next";

import { truncateTitle } from "../booking_form_common_inputs";

type UseCloneTitleMapperReturn = (title?: string) => string;

export const useCloneTitleMapper = (): UseCloneTitleMapperReturn => {
  const { t } = useTranslation("bookingModule");
  return (title) => {
    const cloneTitle = `[${t("bookings.clone.cloneAction").toUpperCase()}] ${title ?? ""}`;
    return truncateTitle(cloneTitle);
  };
};
