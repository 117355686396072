import get from "lodash/get";
import { PHASES_ERROR_KEY } from "PFComponents/availability";
import { PhasesSelect } from "PFComponents/availability/rules_carousel/phases_select";
import { Phase } from "PFTypes/phase";
import { Controller, FieldPath, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import { BookingFormValues } from "../booking_form_provider";
import { useArePhasesRequired } from "./use_are_phases_required";

type PhasesSelectorProps = {
  phaseFormValueName: FieldPath<BookingFormValues>;
  errorKeys: string[];
};

export const PhasesSelector = ({ phaseFormValueName, errorKeys }: PhasesSelectorProps) => {
  const { t } = useTranslation();
  const {
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext<BookingFormValues>();
  const activityId = useWatch<BookingFormValues>({
    name: "activityId"
  });

  const isPhasesSelectorVisible = useArePhasesRequired();

  if (!isPhasesSelectorVisible) {
    return null;
  }

  const errorKey = errorKeys.find((key) => !!get(errors, key));
  const error = errorKey ? get(errors, errorKey) : undefined;

  const handleChange =
    (onChange: (sourceId: string) => void) =>
    ({ sourceId }: Phase) => {
      clearErrors(errorKeys as (keyof BookingFormValues)[]);
      onChange(sourceId);
    };

  return (
    <BookingDetailItem
      label={`${t("availabilityRequirement.phasesSelectLabel")} *`}
      value={
        <Controller
          name={phaseFormValueName}
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => (
            <PhasesSelect
              activityId={activityId}
              selectedPhaseSourceId={field.value}
              onChange={handleChange(field.onChange)}
              errors={error?.message ? { [PHASES_ERROR_KEY]: error.message } : undefined}
            />
          )}
        />
      }
    />
  );
};
