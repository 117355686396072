import { ActionIcon } from "PFComponents/action_icon";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfileBookings } from "PFCore/hooks/queries/profile/use_profile_bookings";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DeleteMode } from "../../../../booking/components/delete_booking_modal/delete_booking.types";
import { useDeleteBookingModalContext } from "../../../../booking/components/delete_booking_modal/delete_booking_modal_context_provider";
import { useProfileBookingsContext } from "../../profile_bookings_provider";
import css from "./action_column.module.scss";

export const getDeleteMode = (
  activityId: number | null,
  roleRelatedBookingsCount: number | undefined
): DeleteMode => {
  if (!activityId) {
    return DeleteMode.NoActivityBooking;
  }
  if (roleRelatedBookingsCount && roleRelatedBookingsCount > 1) {
    return DeleteMode.MultipleBookings;
  }
  return DeleteMode.LastBooking;
};

type ActionColumnProps = {
  booking: Booking;
  readOnly: boolean;
  onEdit: (booking: Booking) => void;
};
export const ActionColumn = ({ booking, readOnly, onEdit }: ActionColumnProps): JSX.Element => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.availability" });
  const { data: currentProfile } = useCurrentProfile();

  const { getCategory } = useBookingCategories();
  const category = getCategory(booking);

  const { refreshBookings } = useProfileBookingsContext();

  const { modal } = useDeleteBookingModalContext();
  const isReadOnly = readOnly || booking.readonly || booking.source !== "ui";

  const { data: roleRelatedBookings } = useProfileBookings({
    profileId: currentProfile.id,
    activityId: booking.activity_id ?? -1,
    perPage: 0,
    options: {
      enabled: !isReadOnly && !!category && !!booking.activity_id
    }
  });

  const deleteMode = useMemo(
    () => getDeleteMode(booking.activity_id, roleRelatedBookings?.meta.total),
    [booking, roleRelatedBookings]
  );

  return (
    <div className={css.root}>
      {!isReadOnly && !!category && (
        <>
          <ActionIcon
            title={t("editBooking")}
            name="edit"
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              onEdit(booking);
            }}
          />
          <ActionIcon
            title={t("deleteBooking")}
            name="remove"
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              modal.open({
                booking,
                deleteMode,
                profileFullName: getProfileName(currentProfile),
                onDeleted: () => {
                  refreshBookings();
                  return Promise.resolve();
                }
              });
            }}
          />
        </>
      )}
    </div>
  );
};
