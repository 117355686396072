import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Activity } from "PFTypes";

export const useActivityCurrentUserRole = (activity: Activity) => {
  const { data: currentProfile } = useCurrentProfile();

  const ownGroup = currentProfile.permissions_group;

  const isResourcer = !!ownGroup?.permissions.find(
    ({ name, permitted }) => name === "resourcer" && permitted
  );

  const userAsActivityReviewer = activity.reviewers?.find(
    ({ profile_id }) => profile_id === currentProfile.id
  );

  const isReviewer = !!activity.reviewed_by_requester || !!userAsActivityReviewer;

  const isReviewerUnbiased = !!userAsActivityReviewer?.unbiased;

  const isOwner = activity.owners?.find((owner) => owner.profile_id === currentProfile.id);

  const isAssignee = activity.assignee?.profile.id === currentProfile.id;

  return { isResourcer, isReviewer, isReviewerUnbiased, isOwner, isAssignee };
};
