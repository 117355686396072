import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { InlineRangeCalendar } from "PFComponents/calendar/inline_calendar";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./position_period_picker.module.scss";

const PositionPeriodPicker = (props) => {
  const {
    currentPosition,
    letCurrentPosition,
    letDuration,
    showLabel,
    startDate,
    endDate,
    duration,
    maxDate,
    error,
    handleChange,
    handleToggleClick: handleCurrentToggle,
    locked,
    disabledToogles,
    ...calendarProps
  } = props;
  const { t } = useTranslation("profiles", { keyPrefix: "edit.fields" });

  const [showDuration, setShowDuration] = useState(
    !currentPosition && letDuration && !!duration && !startDate
  );
  const { formatISODate } = useDateFormatter();

  const CalculatedCalendar = currentPosition && letCurrentPosition ? InlineCalendar : InlineRangeCalendar;

  return (
    <div className={css.root}>
      <div data-qa-id="period-picker" className={css.periodPicker}>
        {showDuration ? (
          <InputFieldSet
            label={t("periodPicker.duration")}
            tip={t("periodPicker.numberOfMonths")}
            value={duration}
            inputType="number"
            error={error}
            onChange={(val) => handleChange(startDate, endDate, val ? parseInt(val) : null, showDuration)}
            locked={locked}
            min={0}
          />
        ) : (
          <CalculatedCalendar
            {...calendarProps}
            label={
              showLabel ? (currentPosition ? t("periodPicker.startDate") : t("periodPicker.period")) : ""
            }
            maxDate={formatISODate(maxDate)}
            handleChange={(start, end) => handleChange(start, end, duration, showDuration)}
            selectedDate={currentPosition && startDate ? formatISODate(startDate) : null}
            startDate={startDate && endDate ? formatISODate(startDate) : null}
            endDate={startDate && endDate ? formatISODate(endDate) : null}
            error={error}
            locked={locked}
          />
        )}
      </div>
      {letDuration && (
        <Toggle
          label={t("periodPicker.durationQuestion")}
          qaId="period-picker-duration"
          checked={showDuration}
          disabled={disabledToogles}
          onChange={(value) => {
            if (value) {
              handleCurrentToggle(false);
            }
            handleChange(startDate, endDate, duration, value); // clear start/end dates
            setShowDuration(value);
          }}
        />
      )}
      {letCurrentPosition && (
        <Toggle
          label={t("periodPicker.currentQuestion")}
          qaId="period-picker-current"
          checked={currentPosition}
          disabled={calendarProps.locked || disabledToogles}
          onChange={(value) => {
            if (value) {
              setShowDuration(false);
              handleChange(startDate, endDate, duration, showDuration); // clear duration
            }
            handleCurrentToggle(value);
          }}
        />
      )}
    </div>
  );
};

PositionPeriodPicker.propTypes = {
  ...InlineRangeCalendar.propTypes,

  showLabel: PropTypes.bool,
  currentPosition: PropTypes.bool.isRequired,
  letCurrentPosition: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleToggleClick: PropTypes.func.isRequired
};

PositionPeriodPicker.defaultProps = {
  showLabel: true,
  letCurrentPosition: true
};

export default PositionPeriodPicker;
