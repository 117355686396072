import AppContext from "PFApp/app_context";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { getProfileName } from "PFCore/helpers/profile";
import useDebounce from "PFCore/helpers/use_debounce";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import SearchFatIcon from "PFIcons/search_fat.svg";
import { CHAT_ACTIONS } from "PFReducers/chat_reducer";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import OpenNewChatButton from "./open_new_chat_button";
import css from "./show_new_chat_modal.less";

const ShowNewChatModal = ({ task, handleClose }) => {
  const { dispatch } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation();

  const [items, setItems] = useState(null);
  const [term, setTerm] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const profileId = currentProfile.id;
  const [participants, setParticipants] = useState(null);

  useEffect(() => {
    $.ajax({
      api_version: 2,
      url: ApiRoute(`/api/chat/participants`),
      data: {
        conversation_open: false,
        interlocutor_profile_id: profileId,
        target: { type: "Task", id: task.id }
      }
    }).then((resp) => setParticipants(resp.entries));
  }, []);

  useEffect(
    useDebounce(() => {
      const update = (response) => {
        let items = response?.entries ?? null;
        items = items.filter((item) => currentProfile.id !== item.id);
        setItems(items);
        setIsFetching(false);
      };
      if (term) {
        setIsFetching(true);
        fetchProfilesOptions({ term: encodeURI(term) })
          .then(update)
          .catch(() => update([]));
      } else if (items && items.length === 0) {
        update(null);
      }
    }, 350),
    [term]
  );

  return (
    <Modal
      onClose={handleClose}
      qaId="ShowNewChatModal"
      classes={{ modal: css.modal }}
      title={t("conversations.whoToTalkTo")}
      showOKButton={false}
      showCancelButton={false}
    >
      <div className={css.inputWrap}>
        <input
          className={css.input}
          onChange={({ target }) => setTerm(target.value.trim())}
          placeholder={t("conversations.userNamePlaceholder")}
          data-qa-id="ShowNewChatModal.nameInput"
        />
        {isFetching ? <LoadingDots className={css.loading} /> : <SearchFatIcon width={20} height={20} />}
      </div>

      {term && items && items.length === 0 && (
        <div className={css.noResults}>{t("conversations.noResults")}</div>
      )}
      {term && items && items.length > 0 && (
        <ul className={css.list} style={{ opacity: isFetching ? 0.6 : 1 }}>
          {items.map((item) => (
            <li key={item.id} data-qa-id={`ShowNewChatModal.profile-${item.id}`}>
              <span>{getProfileName({ firstName: item.firstName, lastName: item.lastName })}</span>
              <OpenNewChatButton
                item={item}
                task={task}
                currentProfile={currentProfile}
                participants={participants}
                setParticipants={setParticipants}
                renderChat={(id) => {
                  dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id } });
                  handleClose();
                }}
                handleClose={handleClose}
              />
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

ShowNewChatModal.propTypes = {
  task: PropTypes.shape({ id: PropTypes.number }).isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ShowNewChatModal;
