import { ActivityCategory } from "PFApp/activities/show/details/activity_details_category_section/activity_category";
import { useStringifyFunctions } from "PFApp/components/activity_commons/use_property_to_string";
import Rows from "PFApp/components/table/rows";
import { RoleAvailability } from "PFComponents/availability";
import { UserShortlistLabel } from "PFComponents/user_shortlist_label/user_shortlist_label";
import { RoleStatusBadge } from "PFCore/components/role/role_status_badge";
import RoleVacanciesBadge from "PFCore/components/role/role_vacancies_badge";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import CheckIcon from "PFIcons/check.svg";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import ThreeDotsMenu from "../three_dots_menu";

const RolesRows = ({
  items,
  isLoading,
  columns,
  pinnedIds,
  setPinnedIds,
  selectedIds,
  setSelectedIds,
  markAsRead,
  utmSource,
  roleKey,
  onRoleDeleted,
  onRoleShare
}) => {
  const { formatDate } = useDateFormatter();
  const { bookingCategories } = useBookingCategories();
  const { checkboxes: stringifyCheckboxesField } = useStringifyFunctions();

  const renderCell = useCallback(
    (item, column) => {
      if (column.name === "id") {
        return (
          <Link
            to={{
              pathname: `/activities/${item.id}`,
              state: { utmSource }
            }}
          >
            {item.id}
          </Link>
        );
      } else if (column.name === "engagement") {
        return item.engagement?.name;
      } else if (column.name === "status") {
        return <RoleStatusBadge state={item.state} />;
      } else if (column.name === "workflow_state") {
        return <RoleStatusBadge workflowState={item.workflow_state} />;
      } else if (column.type === "availability") {
        return <RoleAvailability role={item} />;
      } else if (column.name === "vacancies") {
        return <RoleVacanciesBadge role={item} />;
      } else if (column.name === "interested") {
        return item?.interested ? <CheckIcon width={16} height={16} /> : "-";
      } else if (column.name === "shortlist_status") {
        return <UserShortlistLabel status={item?.shortlist?.state} />;
      } else if (column.name === "start_date") {
        return <div>{item?.start_date && formatDate(item?.start_date)}</div>;
      } else if (column.name === "booking_category_id") {
        const bookingCategory = bookingCategories.find(({ id }) => item?.booking_category_id === id);
        return bookingCategory ? (
          <ActivityCategory category={bookingCategory} bookingPillSize="small" shouldSliceText />
        ) : null;
      } else if (column.type === "checkboxes") {
        const value = item[column.name];

        return value ? stringifyCheckboxesField({ value }) : "";
      }
    },
    [bookingCategories, formatDate, stringifyCheckboxesField, utmSource]
  );

  const renderActions = (item) => (
    <ThreeDotsMenu
      item={item}
      type={roleKey}
      afterRemove={() => onRoleDeleted()}
      coowned={item.coowned}
      pinned={pinnedIds}
      setPinned={setPinnedIds}
      share={onRoleShare}
    />
  );

  return (
    <Rows
      items={items}
      selectedIds={selectedIds}
      pinnedIds={pinnedIds}
      setSelectedIds={setSelectedIds}
      isSelectMode={true}
      dimUnselectedRows={false}
      columns={columns}
      isLoading={isLoading}
      renderCell={renderCell}
      renderActions={renderActions}
      markAsRead={markAsRead}
      utmSource={utmSource}
    />
  );
};

RolesRows.propTypes = {
  items: PropTypes.array.isRequired,
  roleKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
  onRoleDeleted: PropTypes.func,
  onRoleShare: PropTypes.func,
  pinnedIds: PropTypes.arrayOf(PropTypes.number),
  setPinnedIds: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  markAsRead: PropTypes.func,
  utmSource: PropTypes.string
};

export default RolesRows;
