import { camelizeKeys } from "humps";
import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";
import { mapRequirementToBookingData } from "PFApp/booking/components/booking_form/requirement_selector";
import { isGroupOption } from "PFApp/booking/components/booking_form/workforce_member_select/workforce_member_select_dropdown";
import { BookingDataToUpdate } from "PFApp/booking/services/api";
import { BookingRequirementData } from "PFTypes";

export const getProfileActivityOrSearchObject = (
  values: BookingFormValues
): { searchId: number } | { profileId: number; activityId?: number } => {
  const { workforceMember, activityId } = values;
  if (isGroupOption(workforceMember)) {
    return { searchId: workforceMember.searchId };
  } else {
    return { profileId: workforceMember?.id || -1, activityId };
  }
};

type MapBasicSingleBookingReturn = Pick<
  BookingDataToUpdate,
  "startDate" | "endDate" | "requirementType" | "requirementValue" | "phaseSourceId"
>;
export const mapBasicSingleBooking = (
  formBooking: BookingFormValues["bookings"][number]
): MapBasicSingleBookingReturn => {
  const { startDate, endDate, requirement, phaseSourceId } = formBooking;
  return {
    startDate,
    endDate,
    phaseSourceId,
    ...camelizeKeys<BookingRequirementData>(mapRequirementToBookingData(requirement))
  };
};
