import AppContext from "PFApp/app_context";
import { ContactInformation } from "PFApp/components/contact_information";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ProfilePreviewHeader } from "./profile_preview_header";

export const ProfilePreviewContact = () => {
  const { t } = useTranslation("core");

  const {
    store: {
      profilePreview: { profile }
    }
  } = useContext(AppContext);

  if (!profile) {
    return null;
  }

  return (
    <section data-qa-id="ProfilePreviewContact">
      <ProfilePreviewHeader>{t("profilePreview.contact")}</ProfilePreviewHeader>
      <ContactInformation profile={profile} showLabels />
    </section>
  );
};
