import AppContext from "PFApp/app_context";
import ButtonLink from "PFComponents/button/button_link";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import SearchFatIcon from "PFIcons/search_fat.svg";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { Importance } from "PFTypes";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { matchesTab, modalFakeTab } from "../tabs_constants";
import css from "./search_box.less";

/* eslint-disable jsx-a11y/no-autofocus */
const SearchBox = ({ defaultTerm, buttonHref }) => {
  const {
    dispatch,
    store: {
      search: {
        tab,
        term,
        choosenKeywords,
        suggestedKeywords,
        trackKeywordsIds,
        autoselectEnabled,
        matchTypes,
        filterTypes
      }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");

  const availableSearchables = useMemo(
    () =>
      [...matchTypes, ...filterTypes]
        // We want to at max show 5 custom fields, so we randomise the list, and pick the first 5
        .sort(() => 0.5 - Math.random())
        .slice(0, 5),
    [matchTypes, filterTypes]
  );

  // We always want to show skills in the search bar
  const displaySearchText = availableSearchables.map((customType) => customType.display_as).join(" / ");

  useEffect(() => {
    if (autoselectEnabled) {
      const type = SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD;
      suggestedKeywords
        .filter(({ autoSelect }) => autoSelect)
        .filter(({ id }) => !trackKeywordsIds.includes(id)) // avoid being pushy with auto-select
        .filter(({ value }) => value?.length > 2)
        .forEach((keyword) => dispatch({ type, payload: { ...keyword, importance: Importance.Essential } }));
    }
  }, [JSON.stringify(suggestedKeywords)]);

  const showKeywords =
    term !== "" &&
    (tab === matchesTab || tab === modalFakeTab) &&
    (choosenKeywords.length > 0 || suggestedKeywords.length > 0);

  const inputRef = useRef();

  const handleChange = useDebounce(() => {
    const term = inputRef.current.value;

    dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload: { autoselectEnabled: true } });
    dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TERM, payload: term });

    if (term) {
      const termOrder = {
        direction: "desc",
        name: ["scores.term_score"],
        text: t("searchBox.mostRelevant")
      };
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_ORDER,
        payload: termOrder
      });
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER,
        payload: termOrder
      });
    }
  }, 1000);

  const { windowWidth } = useWindowSize();

  const triggerSubmit = buttonHref
    ? null
    : (event) => {
        event.preventDefault();
        dispatch({ type: SEARCH_ACTIONS.SEARCH_TRIGGER });
      };

  return (
    <label className={css.box} style={{ marginBottom: showKeywords ? 0 : 20 }} data-qa-id="SearchBox">
      <SearchFatIcon width={23} height={23} className={css.icon} />

      <div className={css.input}>
        <input
          ref={inputRef}
          autoFocus
          defaultValue={defaultTerm !== undefined ? defaultTerm : term}
          onChange={handleChange}
          placeholder={
            windowWidth > 700
              ? t("searchBox.searchByText", { displayText: displaySearchText })
              : t("searchBox.searchByTextShort", { displayText: displaySearchText })
          }
          onKeyDown={handleChange}
        />
      </div>

      <ButtonLink
        kind="primary"
        href={buttonHref || "/"}
        onClick={triggerSubmit}
        disabled={term.trim() === ""}
      >
        {t("searchBox.search")}
      </ButtonLink>
    </label>
  );
};

SearchBox.propTypes = {
  defaultTerm: PropTypes.string,
  buttonHref: PropTypes.string
};

export default SearchBox;
