import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./legend.module.scss";
import basicLegendEngagements from "./legend_basic_engagements";
import basicLegendRoles from "./legend_basic_roles";
import basicLegendWarnings from "./legend_basic_warnings";
import LegendItem from "./legend_item";

const WARNING_CLASSNAMES = {
  error: "warningIconError",
  warning: "warningIconWarning",
  critical: "warningIconCritical"
};

type LegendProps = {
  display?: {
    roles?: boolean;
    engagements?: boolean;
    warnings?: boolean;
  };
  theme: {
    root?: string;
    expanded?: string;
    button?: string;
    legendContainer?: string;
    legendContainerItems?: string;
    title?: string;
  };
};

export const Legend = ({ theme = {}, display = {} }: LegendProps) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("bookingModule");
  const displaySettings = { roles: true, engagements: true, warnings: true, ...display };
  const { bookingCategories } = useBookingCategories();

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      setExpanded(false);
    }
  };

  const renderLegend = () => (
    <div
      role={expanded ? "dialog" : "presentation"}
      className={classNames(
        css.root,
        { [css.expanded]: expanded },
        theme.root,
        theme.expanded && { [theme.expanded]: expanded }
      )}
    >
      <button
        aria-label={expanded ? t("legend.ariaCollapse") : t("legend.ariaExpand")}
        aria-haspopup="dialog"
        aria-expanded={expanded}
        className={classNames(css.button, theme.button)}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <Icon name="info" size="xs" />
        {t("legend.label")}
        <Icon name={expanded ? "chevron-down" : "chevron-up"} size="xs" />
      </button>
      {expanded && (
        <div
          role="grid"
          aria-label={t("legend.bookingLegendList")}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          className={classNames(css.legendContainer, theme.legendContainer)}
        >
          <div className={classNames(css.legendContainerItems, theme.legendContainerItems)}>
            <h3 className={classNames(css.title, theme.title)}>{t("legend.label")}</h3>
            {displaySettings.roles &&
              basicLegendRoles.map(({ translationKey, state, Component }) => (
                <LegendItem key={translationKey} title={t(translationKey)}>
                  <Component state={state} className={css.legendPill} />
                </LegendItem>
              ))}
            {displaySettings.engagements &&
              basicLegendEngagements.map(({ translationKey, state, Component }) => (
                <LegendItem key={translationKey} title={t(translationKey)}>
                  <Component state={state} className={css.legendPill} />
                </LegendItem>
              ))}
            {bookingCategories.map((category) => (
              <LegendItem key={category.id} title={category.display_as}>
                <BookingPill category={category} style={{ height: 20, width: 80 }} />
              </LegendItem>
            ))}
            {displaySettings.warnings &&
              basicLegendWarnings.map(({ translation, IconComponent, type }, index) => (
                <LegendItem
                  key={`${translation.key}_${type}`}
                  title={t(translation.key, translation.variables)}
                  className={classNames({ [css.warningsDivider]: index === 0 })}
                >
                  <IconComponent width={15} height={15} className={css[WARNING_CLASSNAMES[type]]} />
                </LegendItem>
              ))}
          </div>
        </div>
      )}
    </div>
  );

  return expanded ? (
    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>{renderLegend()}</FocusTrap>
  ) : (
    renderLegend()
  );
};
