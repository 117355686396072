import useIsBookingModulePermitted from "PFApp/use_is_booking_module_permitted";
import { Booking } from "PFTypes";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form/dist/types/form";

import { BookingFormValues, BookingType } from "../booking_form_provider";

type UseInitialBookingType = {
  initialBooking?: Booking;
  setValue: UseFormSetValue<BookingFormValues>;
};

export const useInitialBookingType = ({ initialBooking, setValue }: UseInitialBookingType) => {
  const isBookingPermitted = useIsBookingModulePermitted();

  useEffect(() => {
    if (isBookingPermitted && initialBooking?.booking_template_id) {
      setValue("bookingType", BookingType.Repeated);
    } else {
      setValue("bookingType", BookingType.Single);
    }
  }, [initialBooking]);
};
