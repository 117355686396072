import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel, PagePrefix } from "PFApp/components/filters/filters_panel";
import FiltersPreview from "PFApp/components/filters/filters_preview";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { useGrowl } from "PFApp/use_growl";
import { Typography } from "PFComponents/typography";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { fetchProfilesV2 } from "PFCore/services/profile";
import { PermissionRule } from "PFTypes";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { useProfilesPageContext } from "../../profiles_page_context";
import css from "./profiles_header.module.scss";

export const ProfilesHeader = () => {
  const { t } = useTranslation("profiles");
  const { data: currentProfile } = useCurrentProfile();
  const {
    profiles,
    meta,
    isFetching,
    filters,
    updateFilter,
    resetFilters,
    setFilters,
    areFiltersClear,
    order
  } = useProfilesPageContext();
  const growl = useGrowl();
  const { termValue, termFilter, isTermFilterAvailable } = getTermFilterData(meta);
  const [filtersOpen, toggleFiltersOpen] = useToggle(false);

  const canExportDocs = useIsPermittedTo(currentProfile)(PermissionRule.ExportDocuments);

  const getProfilesForExport = useCallback(
    (page = 1, perPage = 50) =>
      fetchProfilesV2({
        filters,
        order: order ?? undefined,
        perPage,
        page
      })
        .then((data) => {
          const profileIdsToExport = data?.entries.map(({ profile }) => profile.id) ?? [];
          return getExportProfiles({
            profileIds: profileIdsToExport
          }).then((resp) => ({
            entries: resp.map((profile) => ({
              profile
            })),
            meta: { total: data.meta.total, page }
          }));
        })
        .catch(() => growl({ kind: "error", message: t("export.errorFetchingExportData") })),
    [filters, order, growl, t]
  );

  return (
    <div>
      <Typography variant="h1">{t("list.profiles")}</Typography>
      <div className={css.actionsRow}>
        <SearchInput
          term={termValue}
          pageKey={RecentSearchesPageKey.Profiles}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              redirect: true
            }
          ]}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          disabled={!isTermFilterAvailable}
        />
        {!areFiltersClear && (
          <div className={css.preview}>
            <FiltersPreview
              meta={meta}
              filtersAreClear={areFiltersClear}
              clearFilters={() => resetFilters({})}
              onFilterChange={updateFilter}
              onMoreClicked={() => toggleFiltersOpen(true)}
            />
          </div>
        )}
        <span className={css.actionButtons}>
          <FiltersButton filtersAreClear={areFiltersClear} onClick={toggleFiltersOpen} />
          {canExportDocs && profiles && (
            <LazyLocalExportTrigger
              buttonKind="secondary"
              label=""
              icon="export"
              getProfilesCollection={getProfilesForExport}
            />
          )}
        </span>
      </div>
      {filtersOpen && (
        <FiltersPanel
          keyPrefix={PagePrefix.Profiles}
          meta={meta}
          isLoading={isFetching}
          closeFilters={toggleFiltersOpen}
          filtersAreClear={areFiltersClear}
          clearFilters={resetFilters}
          onFilterChange={updateFilter}
          onFiltersChange={setFilters}
        />
      )}
    </div>
  );
};
