import { useMutation } from "@tanstack/react-query";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { deleteActivity } from "PFCore/services/activities";
import { Id, MutationOptions } from "PFTypes";

import { useActivitiesInvalidate } from "../activities/use_activities_invalidate";

export const useActivityDelete = (options: MutationOptions<Id> = {}) => {
  const { invalidate: invalidateCustomValues } = useCustomValuesInvalidate();
  const { invalidateByCollection } = useActivitiesInvalidate();

  return useMutation<void, unknown, Id>({
    mutationFn: (id: Id) => deleteActivity(id),
    ...options,
    onSuccess: (...args) => {
      invalidateCustomValues();
      invalidateByCollection();
      options.onSuccess?.(...args);
    }
  });
};
