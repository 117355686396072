import { getJobTitle } from "PFCore/helpers/profile";
import { ProfileNameLink } from "PFCore/helpers/profile_name_link";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { CustomType, FeatureFlag, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { EXPIRABLE_CUSTOM_TYPES } from "../../../../common/expirable_custom_types";
import css from "../organisation_data.module.scss";
import { CustomFieldItem } from "./custom_field_item";
import { Pair } from "./pair";

type OrganisationDataPreviewProps = {
  profile: Profile;
  basicCustomTypes: CustomType[];
};

export const OrganisationDataPreview = ({ profile, basicCustomTypes }: OrganisationDataPreviewProps) => {
  const { t } = useTranslation("profiles");

  const { data: currentProfile } = useCurrentProfile();
  const isEnabled = useIsFeatureEnabled();

  return (
    <div className={css.columns}>
      <Pair title={t("show.parts.jobTitle")} qaId={"profile-role"}>
        {getJobTitle(profile) || ""}
      </Pair>

      {isEnabled(FeatureFlag.ReportsTo) && profile.supervisor && (
        <Pair title={t("show.parts.reportsTo")} qaId="profile-reports-to">
          <ProfileNameLink profile={profile.supervisor} />
        </Pair>
      )}

      {basicCustomTypes.map((customType) => (
        <CustomFieldItem
          key={customType.name}
          profile={profile}
          isMe={currentProfile.id === profile.id}
          customType={customType}
          hasValuesWithExpiryDate={EXPIRABLE_CUSTOM_TYPES.includes(customType?.name)}
          translation={t}
        />
      ))}
    </div>
  );
};
