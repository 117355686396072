import { uniqBy } from "lodash";
import {
  getActivityCustomFieldFilteredValues,
  getActivityCustomFieldValues
} from "PFCore/helpers/activity_custom_fields";
import { Importance, ProfileCustomValue } from "PFTypes";

import { useMatchCardContext } from "../match_card_context";

export const useRoleMatchCustomFieldData = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  const applicableCustomValues = shortlist?.applicable_custom_values || ([] as ProfileCustomValue[]);

  const getCustomFieldValuesOfImportance = (customFieldName: string, importance?: Importance) => {
    const roleCustomValues =
      importance === undefined
        ? getActivityCustomFieldValues(role, customFieldName)
        : getActivityCustomFieldFilteredValues(role, customFieldName, { importance });

    const directCustomValues = customValues.direct.map((direct) => {
      const { custom_value: customValue, ...other } = direct;
      return {
        ...other,
        ...customValue
      };
    });

    const matchCustomValues = uniqBy([...directCustomValues, ...applicableCustomValues], "id").filter(
      ({ id: matchedCustomValueId }) =>
        roleCustomValues.findIndex(({ id }) => id === matchedCustomValueId) >= 0
    );

    const missingMatchCustomValues = roleCustomValues.filter(
      ({ id: roleCustomValueId }) => matchCustomValues.findIndex(({ id }) => id === roleCustomValueId) < 0
    );

    return {
      roleCustomValues,
      matchCustomValues,
      missingMatchCustomValues
    };
  };

  return {
    getCustomFieldValuesOfImportance
  };
};
