import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";
import { ReactNodeLike } from "prop-types";

import css from "./tooltip_icon.module.scss";

interface IProps {
  className?: string;
  content: ReactNodeLike;
  IconComponent: React.FC<{ width: number; height: number; style: object }>;
  height?: number;
  width?: number;
  tooltipProps?: any;
  iconColor?: string | null;
}

const TooltipIcon = ({
  className,
  content,
  IconComponent,
  width = 12,
  height = 14,
  tooltipProps = {},
  iconColor
}: IProps) => (
  <div className={classNames(className, css.icon, { [css.textColor]: iconColor === undefined })}>
    <Tooltip content={<div>{content}</div>} {...tooltipProps}>
      <span>
        <IconComponent width={width} height={height} style={iconColor ? { fill: iconColor } : {}} />
      </span>
    </Tooltip>
  </div>
);

export default TooltipIcon;
