import classNames from "classnames";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import AutoSelect from "PFComponents/select/autoselect";
import SidePanel from "PFComponents/side_panel/side_panel";
import { getProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { CustomType, FeatureFlag, Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "../organisation_data.module.scss";

type OrganisationDataEditProps = {
  profile: Profile;
  basicCustomTypes: CustomType[];
  isLoading: boolean;
  errors: string | string[];
  onProfileUpdate: (profileFieldsToUpdate: Partial<Profile>) => Promise<void>;
  onClose: () => void;
};

export const OrganisationDataEdit = ({
  profile,
  basicCustomTypes,
  isLoading,
  errors,
  onProfileUpdate,
  onClose
}: OrganisationDataEditProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const { data: currentProfile } = useCurrentProfile();
  const isEnabled = useIsFeatureEnabled();
  const growl = useGrowl();
  const { invalidate: invalidateCustomValues } = useCustomValuesInvalidate();

  const { supervisor } = profile;

  const [supervisorValues, setSupervisorValues] = useState(
    supervisor
      ? [
          {
            id: supervisor.id,
            text: supervisor.text || getProfileName(supervisor)
          }
        ]
      : []
  );
  const [profileFieldsToUpdate, setProfileFieldsToUpdate] = useState({});

  const renderFooter = () => (
    <div className={css.editFooter}>
      <Button
        aria-label={t("profiles:show.parts.closeEditOrganisationData")}
        kind="secondary"
        text={t("translation:cancel")}
        onClick={onClose}
      />
      <Button
        kind="primary"
        aria-label={t("profiles:show.parts.saveEditOrganisationData")}
        text={t("translation:save")}
        onClick={() =>
          onProfileUpdate(profileFieldsToUpdate).then(() => {
            setProfileFieldsToUpdate({});
            invalidateCustomValues();

            growl({
              message: t("profiles:show.parts.organisationDataUpdated"),
              kind: "success"
            });
          })
        }
        disabled={isLoading}
      />
    </div>
  );

  return (
    <SidePanel
      show
      fullHeight
      isOnTop
      size="medium"
      title={t("profiles:show.parts.editOrganisationData")}
      onClose={onClose}
      footerRenderer={renderFooter}
    >
      <div>
        <div className={classNames(css.editPanel, { [css.disabled]: isLoading })}>
          {isEnabled(FeatureFlag.ReportsTo) && (
            <AutoSelect
              controlledValue
              multi={false}
              query={(term) => fetchProfilesOptions({ term })}
              filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
              values={supervisorValues}
              label={t("profiles:show.parts.reportsTo")}
              handleChange={(value) => {
                setSupervisorValues(value);
                setProfileFieldsToUpdate((prev) => ({ ...prev, supervisor_id: value[0]?.id || null }));
              }}
              closeOnChange
              tip={t("profiles:show.parts.yourSupervisor")}
              qaId="profile-basic-info-reports-to"
              letClear
              lockedTipPosition="bottom"
            />
          )}
          {basicCustomTypes.map((customType) => (
            <CustomValuesEditField
              key={customType.name}
              customType={customType}
              values={profileFieldsToUpdate[customType.name] || profile[customType.name]}
              errors={errors && errors[customType.name]}
              qaIdPrefix="profile-basic-info"
              adminPage={false}
              profile={profile}
              handleChange={(values) =>
                setProfileFieldsToUpdate((prev) => ({ ...prev, [customType.name]: values }))
              }
              lockedTipPosition="bottom"
            />
          ))}
        </div>
      </div>
    </SidePanel>
  );
};
