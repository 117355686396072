import { compact, isEmpty, sortBy } from "lodash";
import { Filter, Filters } from "PFTypes";

/*
 * We need to flatten this array and order it in order to render the filters in many areas
 * filterElements returns the filters flattened, ordered and excludes filters
 * (it is quite common to not render certain filters in FilterBox)
 *
 * Returns MetaFilter[]
 * */
export const filterElements = (
  metaFilters: Filters | undefined | null,
  conditionOrExcludeList: string[] | ((filter: Filter) => boolean) = [],
  excludeListChildren: string[] = []
): Filter[] => {
  if (isEmpty(metaFilters)) {
    return [];
  }

  const { availability, children, ...filters } = metaFilters;

  const getFilteredElements = (filters: Filters, isChildren?: boolean) => {
    const filtersByFamily = compact(Object.values(filters));

    return filtersByFamily.flatMap((familyFilters) =>
      Object.keys(familyFilters).map((filterFamily) => ({
        ...familyFilters[filterFamily],
        name: filterFamily,
        children: isChildren
      }))
    );
  };

  const filtersArray: Filter[] = [
    ...getFilteredElements(filters),
    ...(children ? getFilteredElements(children, true) : [])
  ];

  const condition = Array.isArray(conditionOrExcludeList)
    ? ({ name, children }: Filter) =>
        name &&
        ((!children && !conditionOrExcludeList.includes(name)) ||
          (!!children && !(excludeListChildren || []).includes(name)))
    : conditionOrExcludeList;

  const response = availability ? [...filtersArray, availability] : [...filtersArray];
  const filteredResponse = response.filter(({ hidden }) => !hidden).filter(condition);

  return sortBy(filteredResponse, ["ordinal", "name"]);
};
