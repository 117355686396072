import { useIsMutating, useMutation } from "@tanstack/react-query";
import { decamelizeKeys } from "humps";
import { useRefreshBookingData } from "PFApp/booking/hooks/use_refresh_booking_data";
import { useSyncRows } from "PFApp/booking/parts/overview/use_sync_rows";
import { useMatchesShortlistReplace } from "PFCore/hooks/queries/matches/use_matches_shortlist_replace";
import {
  acceptShortlist,
  assignShortlist,
  declineShortlist,
  fillAndBookShortlist,
  fillShortlist,
  rejectShortlist,
  resetShortlist
} from "PFCore/services/shortlist";
import { Activity, Shortlist, ShortlistMinimized } from "PFTypes";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity";
import { shortlistsKeys } from "./query_keys";
import { useShortlistsInvalidate } from "./use_shortlists_invalidate";

export type ShortlistDecisionParams = {
  shortlistId: Id;
  reasons?: Shortlist["reasons"];
  action: "accept" | "decline";
};

export const useShortlistDecision = (
  options: MutationOptions<ShortlistDecisionParams, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async ({
      shortlistId,
      reasons,
      action
    }: ShortlistDecisionParams): Promise<ShortlistMinimized> => {
      const queryFunction = action === "accept" ? acceptShortlist : declineShortlist;
      return queryFunction(shortlistId, { reasons });
    },
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;
      if (withInvalidate) {
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    }
  });
};

export const useShortlistAssign = (
  options: MutationOptions<Id, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async (id: Id): Promise<ShortlistMinimized> => assignShortlist(id),
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;
      if (withInvalidate) {
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    }
  });
};

export const useShortlistFill = (
  options: MutationOptions<Id, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const mutationKey = shortlistsKeys.fillMutation();
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  const fillMutation = useMutation({
    mutationFn: async (id: Id): Promise<ShortlistMinimized> => fillShortlist(id),
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;
      if (withInvalidate) {
        invalidateActivity([shortlist.activity_id]);
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    },
    mutationKey
  });

  const isUpdating = useIsMutating({
    mutationKey
  });

  return { ...fillMutation, isUpdating };
};

type FillAndBookParams = {
  shortlistId: Id;
  profileId: number;
  task: Activity;
  skipBookingsCreation?: boolean;
};

export const useShortlistFillAndBook = (
  options: MutationOptions<FillAndBookParams, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const mutationKey = shortlistsKeys.fillAndBookMutation();
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();
  const syncRows = useSyncRows();
  const refreshBookingData = useRefreshBookingData();

  const fillAndBookMutation = useMutation({
    mutationFn: ({ shortlistId, skipBookingsCreation }: FillAndBookParams): Promise<ShortlistMinimized> =>
      fillAndBookShortlist(shortlistId, { skipBookingsCreation }),
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;
      if (withInvalidate) {
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
        const { profileId, task } = args[1];
        const parentActivityIds = task?.parent_activity_id ? [task.parent_activity_id] : [];
        syncRows({
          profileId,
          activityId: task?.id,
          parentActivityIds
        });
        refreshBookingData({
          profileIds: [profileId],
          activityId: task?.id,
          shortlistId: shortlist.id,
          refreshProfile: true
        });
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    },
    mutationKey
  });

  const isUpdating = useIsMutating({
    mutationKey
  });

  return { ...fillAndBookMutation, isUpdating };
};

export const useShortlistReset = (
  options: MutationOptions<Id, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async (id: Id): Promise<ShortlistMinimized> => resetShortlist(id),
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;

      if (withInvalidate) {
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    }
  });
};

export const useShortlistReject = (
  options: MutationOptions<Id, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async (id: Id): Promise<ShortlistMinimized> => rejectShortlist(id),
    ...options,
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;

      if (withInvalidate) {
        invalidateShortlists(shortlist.activity_id);
        invalidateRankedShortlists(shortlist.activity_id);
      }
      replaceMatchesShortlistInCache(shortlist);

      options?.onSuccess?.(...args);
    }
  });
};
