import { BookingDetailCategorySettings } from "PFApp/booking/components/booking_detail_category_settings";
import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import {
  convertWeekDayMaskToDays,
  WeekDaysSelect
} from "PFApp/booking/components/booking_form/week_days_select";
import { useBookingsTotal } from "PFApp/booking/parts/overview/use_bookings_total";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, BookingCategory, BookingTemplate } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingDetailCategory } from "../booking_details_category";
import { JobCodeValueBookingRef } from "./job_code_value_booking_ref";
import { Phase } from "./phase";
import css from "./single_booking.module.scss";
import { StartEndDates } from "./start_end_dates";

type SingleBookingDetailsProps = {
  booking: Booking;
  bookingTemplate?: BookingTemplate;
  category: BookingCategory;
};

export const SingleBookingDetails = ({ booking, bookingTemplate, category }: SingleBookingDetailsProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { formatDate } = useDateFormatter();

  const {
    start_date: startDate,
    end_date: endDate,
    calculated_load: calculatedLoad,
    description,
    created_at: createdAt,
    phase_source_id: phaseSourceId
  } = booking;

  const { total, warning } = useBookingsTotal({ bookings: [booking] });

  const loadValue = Math.round(calculatedLoad * 100);
  const totalValue = total
    ? t("bookingModule:details.totalValue", { days: total.days, hours: total.hours })
    : "-";

  return (
    <div className={css.root}>
      <Phase phaseSourceId={phaseSourceId} />
      <div className={css.detailsRow}>
        <BookingDetailItem
          label={t("bookingModule:details.startEndDates")}
          value={<StartEndDates startDate={startDate} endDate={endDate} />}
        />
        <BookingDetailItem label={t("bookingModule:details.load")} value={loadValue} />
        <BookingDetailItem
          label={t("bookingModule:details.totalLabel")}
          value={totalValue}
          infoContent={warning}
        />
      </div>
      {bookingTemplate && (
        <BookingDetailItem
          label={t("bookingModule:bookings.create.repeats")}
          value={
            <WeekDaysSelect selectedDays={convertWeekDayMaskToDays(bookingTemplate.wday_mask)} readonly />
          }
        />
      )}
      <BookingDetailCategory category={category} />
      <BookingDetailCategorySettings
        billable={category.billable}
        affectsAvailability={category.affects_availability}
      />
      {description && <BookingDetailItem label={t("translation:description")} value={description} />}
      <BookingDetailItem label={t("translation:createdAt")} value={formatDate(createdAt)} />
      <JobCodeValueBookingRef booking={booking} />
    </div>
  );
};
