import DrafIcon from "PFIcons/draft_icon.svg";
import PrivateIcon from "PFIcons/private.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./status.module.scss";

const Status = ({ isPrivate, isDraft }) => {
  const { t } = useTranslation();
  return (
    <div className={css.wrapper}>
      {isPrivate && (
        <div className={css.tag}>
          <PrivateIcon />
          {t("private")}
        </div>
      )}
      {isDraft && (
        <div className={css.tag}>
          <DrafIcon />
          {t("draft")}
        </div>
      )}
    </div>
  );
};

Status.propTypes = {
  isPrivate: PropTypes.bool,
  isDraft: PropTypes.bool
};
export default Status;
