import { AxiosError } from "axios";
import { useBookingNoteCreate } from "PFCore/hooks/queries/bookings/notes/use_booking_note_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { useTranslation } from "react-i18next";

import BookingNoteForm from "./booking_note_form/booking_note_form";

interface IProps {
  bookingId: number;
}

const NewBookingNote = ({ bookingId }: IProps) => {
  const growlError = useErrorsGrowl();
  const {
    mutation: { mutate },
    isMutating: loading
  } = useBookingNoteCreate(bookingId, {
    onError: ({ response }: AxiosError) => {
      growlError(response);
    }
  });
  const { t } = useTranslation("bookingModule");

  const onSubmit = ({ data: { content }, onSuccess }) => {
    mutate(content.trim(), {
      onSuccess: () => {
        onSuccess();
      }
    });
  };

  return (
    <BookingNoteForm
      onSubmit={onSubmit}
      disabled={!!loading}
      submitLabel={t("details.notes.add")}
      placeholder={t("details.notes.newNotePlaceholder")}
    />
  );
};

export default NewBookingNote;
