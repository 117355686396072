import classNames from "classnames";
import { useTemplate } from "PFApp/hooks";
import EditInternalExperienceForm from "PFApp/profiles/common/experience/edit_internal_experience_form";
import EditPositionForm from "PFApp/profiles/common/experience/edit_position_form";
import { useGrowl } from "PFApp/use_growl";
import { Accordion } from "PFComponents/accordion";
import { Button } from "PFComponents/button";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import scrollTo from "PFCore/helpers/scroll_to";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { AccessLevel, CustomType, Profile } from "PFTypes";
import React, { KeyboardEvent, MouseEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./experience_section.modules.scss";
import { InternalExperience } from "./internal_experience";
import { MainJob } from "./main_job";

type ExperienceProps = {
  experience: any;
  profile: Profile;
  currentProfile: Profile;
  adminPage: boolean;
  customTypes: CustomType[];
  fetchInternalExperience: () => any;
  handleProfileUpdate: (attributes: Partial<Profile>, options?: object) => Promise<void>;
  isMe: boolean;
};

export const Experience = ({
  experience,
  profile,
  adminPage,
  currentProfile,
  customTypes,
  fetchInternalExperience,
  handleProfileUpdate,
  isMe
}: ExperienceProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const growl = useGrowl();
  const { getFormattedErrors } = useResponseErrors();
  const { internalPositions } = experience;

  const [addNewInternalExperience, setAddNewInternalExperience] = useState(false);
  const [editPosition, setEditPosition] = useState(false);

  const internalExperienceTemplate = useTemplate({ key: "work" });

  const ownGroup = currentProfile.permissions_group;
  const ownGroupPermissions = ownGroup.cross_group_permissions[ownGroup.id];

  const internalExperiencePermissions =
    ownGroupPermissions.activities_access_levels[internalExperienceTemplate?.id!];
  const isInternalExperienceVisible =
    internalExperienceTemplate &&
    [AccessLevel.ReadOnly, AccessLevel.ReadWrite].includes(internalExperiencePermissions);

  const newExperience = {
    custom_fields: [],
    name: "",
    description: "",
    metadata: {
      engagement_hours: undefined,
      position_id: experience.id, // positionId, this is the main experiecne position ID
      date_range: { start_date: null, end_date: null, current: false },
      location: ""
    }
  };

  const isCfPermitted = useCallback(
    (name) => {
      const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);
      const customType = customTypes.find((ct) => ct.name === name);

      return [AccessLevel.ReadOnly, AccessLevel.ReadWrite].includes(accessLevels[customType?.id!]);
    },
    [profile, currentProfile, customTypes]
  );

  const handleRemovePosition = (data) =>
    handleProfileUpdate(data, { renderGrowl: false }).catch((resp) => {
      const errors = getFormattedErrors(resp);
      let message = t("translation:form.notSaved");
      const key = errors && Object.keys(errors).find((key) => /internal_experience/.test(key));
      if (key) {
        message = errors[key];
      }
      growl({
        message,
        kind: "error"
      });
    });

  const handleNewInternalExperience = <TEvent extends MouseEvent | KeyboardEvent>(event: TEvent) => {
    event.stopPropagation();
    setAddNewInternalExperience(true);
  };

  const handlNewInternalExperienceKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      handleNewInternalExperience(event);
    }
  };

  const navigateToParent = (id) => {
    const ieElement = document.querySelector(`[data-scroll-anchor="internal-experience-${id}"]`);
    scrollTo(window, 0, ieElement ? ieElement.getBoundingClientRect().top + window.pageYOffset - 50 : 0);
  };

  return (
    <section className={css.experienceWrapper}>
      <MainJob
        position={experience}
        profile={profile}
        currentProfile={currentProfile}
        customTypes={customTypes}
        isCfPermitted={isCfPermitted}
        onEdit={() => setEditPosition(true)}
      />
      {isInternalExperienceVisible && (
        <Accordion
          animated={false}
          headerSize="small"
          header={
            <div className={css.internalExperienceHeader}>
              {t("profiles:common.experience.internalExperience.title")}
              {isMe && "rw" === internalExperiencePermissions && (
                <Button
                  aria-label={t("profiles:common.experience.internalExperience.newInteralExperience")}
                  aria-expanded={addNewInternalExperience}
                  aria-haspopup="dialog"
                  icon="add"
                  kind="secondary"
                  onClick={handleNewInternalExperience}
                  onKeyDown={handlNewInternalExperienceKeyDown}
                />
              )}
            </div>
          }
          defaultState="closed"
        >
          <div className={classNames(css.internalExperienceContent)}>
            {internalPositions.map((position, index) => (
              <React.Fragment key={`${position.id}+${index}`}>
                <InternalExperience
                  experience={position}
                  profile={profile}
                  fetchInternalExperience={fetchInternalExperience}
                  customTypes={customTypes}
                  isCfPermitted={isCfPermitted}
                />
              </React.Fragment>
            ))}
          </div>
        </Accordion>
      )}

      {editPosition && (
        <EditPositionForm
          adminPage={adminPage}
          position={experience}
          profile={profile}
          handleSuccess={() => {
            setEditPosition(false);
            handleProfileUpdate({}); // just refetch profile
          }}
          handleRemove={(data) => {
            handleRemovePosition(data);
            setEditPosition(false);
          }}
          onClose={() => setEditPosition(false)}
        />
      )}

      {addNewInternalExperience && (
        <EditInternalExperienceForm
          experience={newExperience}
          profile={profile}
          adminPage={false}
          handleSuccess={() => {
            fetchInternalExperience();
            navigateToParent(experience.id);
            setAddNewInternalExperience(false);
          }}
          handleRemove={() => {
            navigateToParent(experience.id);
            setAddNewInternalExperience(false);
          }}
          onClose={() => setAddNewInternalExperience(false)}
        />
      )}
    </section>
  );
};
