import { getProperty } from "PFCore/helpers/templates";
import { Id, SkillsFrameworkCustomType } from "PFTypes";
import { useCallback } from "react";

import { useActivityEditPageContext } from "../../activity_edit_page_context";
import { SkillsFrameworkCustomField } from "./skills_framework_select_modal.types";

type UseConflictResolverReturn = {
  handleConflictResolve: (
    selectedValue: Id,
    type: SkillsFrameworkCustomType,
    customFields: SkillsFrameworkCustomField[]
  ) => SkillsFrameworkCustomField[];
  getCustomFieldsWithConflicts: (customFields: SkillsFrameworkCustomField[]) => SkillsFrameworkCustomField[];
};

export const useConflictResolver = (): UseConflictResolverReturn => {
  const { template } = useActivityEditPageContext();

  const handleConflictResolve = useCallback(
    (
      selectedValue: Id,
      type: SkillsFrameworkCustomType,
      customFields: SkillsFrameworkCustomField[]
    ): SkillsFrameworkCustomField[] => {
      const newCustomFields = [...customFields];
      const customField = newCustomFields.find((customField) => customField.type.id === type.id);
      if (customField) {
        const newValues = customField.values.map((customValue) => ({
          ...customValue,
          selected: customValue.id === selectedValue
        }));
        customField.values = newValues;
      }
      return newCustomFields;
    },
    []
  );

  const getCustomFieldsWithConflicts = useCallback(
    (customFields: SkillsFrameworkCustomField[]) =>
      customFields.map((customField) => {
        const templateProperty = getProperty(template, customField.type.name);
        const newValues = [...customField.values];
        const isConflict = templateProperty?.kind === "single" && newValues.length > 1;
        if (isConflict) {
          const valueSelected = newValues.find(({ primary }) => primary) || newValues[0];
          valueSelected.selected = true;
        }
        return { ...customField, values: newValues, conflict: isConflict };
      }),
    [template]
  );

  return { handleConflictResolve, getCustomFieldsWithConflicts };
};
