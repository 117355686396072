import { Activity } from "PFTypes";

type BookingMetadata = {
  job_code?: string;
  booking_ref?: string;
};

export const enum RequirementType {
  Load = "load_percentage",
  TotalHours = "total_duration",
  HoursPerDay = "duration_per_day"
}

export type BookingRequirementData = {
  requirement_type: RequirementType;
  requirement_value: number;
};

export type Booking = {
  id: number;
  profile_id: number;
  booking_category_id: number;
  booking_template_id?: number;
  booking_group_id?: number;
  _localId: string;
  activity_id: null | number;
  activity_siblings_count: number;
  duration: number;
  end_date: string; // ISO datetime
  start_date: string; // ISO datetime
  load: number; // %
  calculated_load: number;
  title: string;
  description: string;
  partial: { date: string; available_minutes: number }[];
  source: "ui" | string;
  state: BookingState;
  created_at: string;
  readonly: boolean;
  activity?: Pick<Activity, "id" | "name" | "template_id"> & {
    parent_activity?: Pick<Activity, "id" | "name">;
  };
  metadata: BookingMetadata;
  overrides_calendar?: boolean;
  overrides_non_working_days?: boolean;
  phase_source_id?: string;
  available_minutes?: number;
} & BookingRequirementData;

export type BookingState = "pending" | string;
