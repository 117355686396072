import { useActivityTemplateType } from "PFApp/activities/hooks";
import { useTranslation } from "react-i18next";

import { TEMPLATE_KEYS } from "../../../../constants/templates";
import { useRowIconComponent } from "../../../hooks/use_row_icon_component";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { DetailsHeader } from "./details_header";

export const EngagementDetailHeader = () => {
  const { t } = useTranslation();
  const { activity, id } = useBookingActivityContext();
  const ActivityIconComponent = useRowIconComponent({
    templateId: activity?.template_id ?? null,
    type: TEMPLATE_KEYS.ENGAGEMENT
  });

  const { isAudit, isEngagement } = useActivityTemplateType(activity);
  const isAuditEngagement = isAudit && isEngagement;

  const linkUrl = isAuditEngagement ? `/audit_planner/control_centre/${id}` : `/activities/${id}`;
  return (
    <DetailsHeader
      title={activity?.name}
      subtitle={isAuditEngagement ? t("auditEngagement") : t("engagement")}
      linkUrl={linkUrl}
    >
      <ActivityIconComponent />
    </DetailsHeader>
  );
};
