import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { fetchActivity as fetchBookingActivity } from "PFApp/booking/services/api";
import { fetchActivity } from "PFCore/services/activities";
import { Id } from "PFTypes/id";

import { Activity } from "../../../types";
import { useIsExternalUsage } from "../bookings/use_is_external_usage";
import activityKeys from "./query_keys";

export const useBookingActivity = (
  activityId?: Id,
  options: Omit<UseQueryOptions<Activity>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
): UseQueryResult<Activity> => {
  const isExternalUsage = useIsExternalUsage();
  return useQuery<Activity>({
    queryKey: isExternalUsage ? activityKeys.activity(activityId) : activityKeys.bookingActivity(activityId),
    queryFn: () =>
      (isExternalUsage
        ? fetchActivity(activityId!)
        : fetchBookingActivity(activityId)) as unknown as Promise<Activity>,
    retry: false,
    enabled: !!activityId,
    ...options
  });
};
