import { useTranslation } from "react-i18next";

import { BarChart } from "./parts/bar_chart";

type HoursChartProps = { hoursTotal: number; coverage: number };

export const HoursChart = ({ hoursTotal, coverage }: HoursChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.hours" });

  return (
    <BarChart
      coverage={coverage}
      title={t("title")}
      hideLegend
      series={[
        {
          name: "required",
          data: [{ value: hoursTotal }]
        },
        {
          name: "filled",
          data: [{ value: hoursTotal * (coverage / 100) }]
        }
      ]}
    />
  );
};
