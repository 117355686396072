import { ActionIcon } from "PFComponents/action_icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";

import { ContactInfo } from "../use_contact_info_array";

type ContactIconProps = ContactInfo;

export const ContactIcon = ({ value, url, target, iconName, title, onClick }: ContactIconProps) => (
  <TooltipIcon
    content={value}
    iconColor={null}
    IconComponent={() =>
      url ? (
        <ActionIcon
          tag="a"
          href={url}
          target={target}
          rel="noopener noreferrer"
          name={iconName}
          size="sm"
          color="paletteBlue0"
          title={title}
        />
      ) : (
        <ActionIcon onClick={onClick} name={iconName} size="sm" color="paletteBlue0" title={title} />
      )
    }
  />
);
