import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { ActionIcon } from "../../../action_icon";
import SimpleBadge from "../../../badges_list/simple_badge";
import css from "../autoselect_values.module.scss";
import { AutoSelectValueGenericContentProps, RemoveHandler } from "./auto_select_value";

type AutoSelectValueGenericProps = AutoSelectValueGenericContentProps & {
  onRemove: RemoveHandler;
  multi?: boolean;
};

export const AutoSelectValueGeneric = ({
  displayValue,
  value,
  onRemove,
  locked,
  multi
}: AutoSelectValueGenericProps) => {
  const { t } = useTranslation("core", {
    keyPrefix: "components.autoselect"
  });

  if (multi) {
    return (
      <SimpleBadge
        small
        text={displayValue}
        kind={value.item?.kind}
        icon={
          !locked ? (
            <ActionIcon
              name="cross"
              size="xs"
              title={t("removeOption", {
                label: displayValue
              })}
              className={css.removeIcon}
              onClick={(e) => onRemove(value, e)}
              color="paletteBlue2"
            />
          ) : null
        }
      />
    );
  }

  return (
    <Typography variant="bodyBold" tag="span">
      {displayValue as string}
    </Typography>
  );
};
