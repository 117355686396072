import { useGrowl } from "PFApp/use_growl";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { requestHelp } from "PFCore/services/activity/request_help";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useActivityHistoryInvalidate } from "../../parts/history/hooks/use_activity_history_invalidate";

type RequestHelpModalProps = {
  activity: Activity;
  onClose: () => void;
};

export const RequestHelpModal = ({ activity, onClose }: RequestHelpModalProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities");
  const { getFirstError } = useResponseErrors();
  const invalidateHistory = useActivityHistoryInvalidate(activity.id);

  const handleClick = () =>
    requestHelp(activity.id)
      .then(() => {
        growl({ kind: "success", message: t("show.parts.helpRequested") });
        invalidateHistory();
        onClose();
      })
      .catch(({ response }) => {
        const error = getFirstError(response);
        growl({
          message: error || t("show.parts.unknownError"),
          kind: "error"
        });
      });

  return (
    <Modal
      title={t("show.parts.requestHelp")}
      labelOK={t("show.parts.requestHelp")}
      onOK={handleClick}
      onClose={onClose}
    >
      <Typography variant="bodyRegular">{t("show.parts.pleaseRequestHelp")}</Typography>
    </Modal>
  );
};
