import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { getJobTitle } from "PFCore/helpers/profile";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { CustomType, CustomValue, Profile } from "PFTypes";

export type ProfileInfoEntry = {
  customType: CustomType;
  value: string;
};

type UseProfileCustomValuesReturn = {
  getCustomValue: (
    profile: Partial<Profile> & Pick<Profile, "positions">,
    sectionKey: string
  ) => ProfileInfoEntry | undefined;
  getJobTitleEntry: (profile: Partial<Profile> & Pick<Profile, "positions">) => ProfileInfoEntry | undefined;
  getCustomType: (sectionKey: string) => CustomType | undefined;
};

export const useProfileCustomValues = (): UseProfileCustomValuesReturn => {
  const { customTypes } = useCustomTypes();
  const { getCustomValuesListString } = useCustomValueToString();

  const getCustomType = (sectionKey: string) => {
    const customType = customTypes.find(({ sections }) =>
      (sections || []).find(({ key }) => key === sectionKey)
    );
    return customType;
  };

  const getCustomValue = (profile: Profile, sectionKey: string) => {
    const customType = customTypes.find(({ sections }) =>
      (sections || []).find(({ key }) => key === sectionKey)
    );
    const customValues: CustomValue[] = (customType && profile[customType.name]) || [];
    const valueAndTypeExist = !!customValues.length && !!customType;

    if (!valueAndTypeExist) {
      return undefined;
    }

    return {
      customType,
      value: getCustomValuesListString(customValues, customType.value_type)
    };
  };

  const getJobTitleEntry = (profile: Profile) => {
    const jobTitleCustomType = customTypes.find(({ name }) => name === "job_title");
    const jobTitle = getJobTitle(profile) as string | null;

    if (!jobTitleCustomType || !jobTitle) {
      return undefined;
    }

    return { customType: jobTitleCustomType, value: jobTitle };
  };

  return {
    getCustomValue,
    getJobTitleEntry,
    getCustomType
  };
};
