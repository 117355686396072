import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useBookingNotesInfinite } from "PFCore/hooks/queries/bookings/notes/use_booking_notes";
import { useInfiniteScroll } from "PFCore/hooks/use_infinite_scroll";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BookingNotesList from "./booking_notes_list";
import css from "./booking_notes_list.module.scss";
import DeleteNoteModal from "./delete_note_modal";
import NewBookingNote from "./new_booking_note";
import type { IDeleteModalState } from "./types";

interface BookingNotesProps {
  readonly: boolean;
  profileId: number;
  bookingId: number;
}

export const BookingNotes = ({ readonly, profileId, bookingId }: BookingNotesProps) => {
  const [deleteModalState, setDeleteModalState] = useState<IDeleteModalState>({
    show: false
  });
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: loading
  } = useBookingNotesInfinite(profileId, bookingId, 15, {
    enabled: !!bookingId
  });

  const { t } = useTranslation("bookingModule");

  const totalCount = data?.pages?.[0]?.meta?.total;
  const bookingNotes = data?.pages?.reduce((acc, current) => [...acc, ...current.entries], []) || [];

  const lastItemRef = useInfiniteScroll<HTMLDivElement>({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
    loading
  });

  const closeDeleteModal = () =>
    setDeleteModalState({
      show: false
    });

  const openDeleteModal = (modalState: Omit<IDeleteModalState, "show">) => {
    setDeleteModalState({
      show: true,
      ...modalState
    });
  };

  return (
    <div>
      {loading && <LoadingDots circlesEnabled circleSize="xs" />}
      {!readonly && <NewBookingNote bookingId={bookingId} />}
      {totalCount === 0 && !loading && (
        <Typography variant="bodyRegular" noMargin className={css.noNotes}>
          {t("details.notes.noNotes")}
        </Typography>
      )}
      <div>
        {!!bookingNotes?.length && (
          <BookingNotesList
            readonly={readonly}
            bookingId={bookingId}
            notes={bookingNotes}
            openDeleteModal={openDeleteModal}
            lastItemRef={lastItemRef}
          />
        )}
      </div>
      {deleteModalState.show && (
        <DeleteNoteModal closeModal={closeDeleteModal} onOK={deleteModalState.handleOK} />
      )}
    </div>
  );
};
