import classNames from "classnames";
import { IconName } from "PFTheme/graphics/icons";
import { Id } from "PFTypes";
import React, { ReactNode } from "react";

import { Icon } from "../icon";
import Tooltip from "../tooltip/tooltip";
import { Typography } from "../typography";
import css from "./radio.module.scss";

export type RadioItem<ID_TYPE extends Id> = {
  id: ID_TYPE;
  /**
   * @deprecated Use label instead
   */
  customLabel?: ReactNode;
  iconName?: IconName;
  tooltipContent?: ReactNode;
  label?: string;
  disabled?: boolean;
  disabledMessage?: string;
};

type RadioProps<ID_TYPE extends Id> = {
  item: RadioItem<ID_TYPE>;
  onChange: (item: ID_TYPE) => void;
  checked: boolean;
  verticalItems?: boolean;
  name: string;
};

export const Radio = <ID_TYPE extends Id>({
  checked,
  item,
  onChange,
  verticalItems,
  name
}: RadioProps<ID_TYPE>) => {
  const showLabel = item.label || item.customLabel || item.iconName || item.tooltipContent;
  return (
    <label
      className={classNames(css.customRadio, {
        [css.disabled]: item.disabled,
        [css.vertical]: verticalItems
      })}
    >
      <input
        className={css.input}
        id={`${item.id}`}
        name={name}
        type="radio"
        checked={checked}
        onChange={() => onChange(item.id)}
        value={item.id}
        disabled={item.disabled}
      />
      <div className={css.customRadioWrapper}>
        <span className={css.customRadioButton} />
      </div>
      {showLabel && (
        <Tooltip content={item.disabledMessage} disabled={!(item.disabled && item.disabledMessage)}>
          <div className={css.labelContainer}>
            {item.customLabel && item.customLabel}
            {item.iconName && <Icon name={item.iconName} />}
            {item.label && (
              <Typography
                variant="bodyRegular"
                tag="span"
                className={classNames(css.label, {
                  [css.disabled]: item.disabled
                })}
              >
                {item.label}
              </Typography>
            )}
            {item.tooltipContent && (
              <Tooltip content={item.tooltipContent}>
                <div className={css.infoIcon}>
                  <Icon name="info" />
                </div>
              </Tooltip>
            )}
          </div>
        </Tooltip>
      )}
    </label>
  );
};
