/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import { uniqueId } from "lodash";
import isSafari from "PFCore/helpers/is_safari";
import { PropsWithChildren, useMemo, useRef } from "react";
import { createPortal } from "react-dom";

import { Card } from "../card";
import { useCloseOnEscapeClick } from "./hooks/use_close_on_escape_click";
import { useModalVisibility } from "./hooks/use_modal_visibility";
import css from "./modal.module.scss";
import { ModalContextProvider } from "./modal_context";
import { ModalLayout, ModalLayoutProps } from "./modal_layout/modal_layout";

export type ModalProps = PropsWithChildren<{
  qaId?: string;
  noPadding?: boolean;
  classes?: {
    modal?: string;
    card?: string;
  };
  onClose: VoidFunction;
  onBeforeClose?: (closeFunction: VoidFunction) => boolean;
}> &
  ModalLayoutProps;

export const Modal = ({
  children,
  qaId = "Modal",
  noPadding,
  classes = {},
  onClose,
  onBeforeClose,
  ...layoutProps
}: ModalProps) => {
  const dialogRef = useRef(null);
  const modalId = useMemo(() => uniqueId(), []);

  const { fadeIn, renderContent, closeWithAnimation } = useModalVisibility({
    dialogRef,
    onClose,
    onBeforeClose
  });
  useCloseOnEscapeClick({ modalId, closeWithAnimation });

  const modalTarget = document.getElementById("modal_region");

  if (!modalTarget) {
    return null;
  }

  // Portal is needed to avoid inheriting cursor: "pointer" (e.g. Profile Card)
  // and to fix LocalExportTrigger when it's nested in a dropdown (e.g. MyProfile > Export)
  return createPortal(
    <dialog
      className={classNames(css.dialog, { [css.in]: fadeIn })}
      // There is a background color issue (too dark) on Safari that seems to be fixed by adding border
      style={{ border: isSafari() ? "1px solid rgba(0, 0, 0, 0.8)" : "none" }}
      ref={dialogRef}
      data-qa-id={qaId}
      onClick={(event) => {
        event.stopPropagation();
        closeWithAnimation();
      }}
      data-dialog="true"
      data-dialog-id={modalId}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(css.wrapper, { [css.in]: fadeIn }, classes.modal)}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {renderContent && (
          <ModalContextProvider closeWithAnimation={closeWithAnimation}>
            <Card paddingVariant={noPadding ? "none" : "xl"} shadowVariant="none" className={classes.card}>
              <ModalLayout {...layoutProps} classes={{ body: classes.body }}>
                {children}
              </ModalLayout>
            </Card>
          </ModalContextProvider>
        )}
      </div>
    </dialog>,
    modalTarget
  );
};
