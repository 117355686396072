import { useActivityTemplateType } from "PFApp/activities/hooks";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useTranslation } from "react-i18next";

import { TEMPLATE_KEYS } from "../../../../constants/templates";
import { useRowIconComponent } from "../../../hooks/use_row_icon_component";
import { useActivityLink } from "../../../parts/overview/detail_view/booking_detail/use_activity_link";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { DetailsHeader } from "./details_header";

export const RoleDetailHeader = () => {
  const { t } = useTranslation();
  const { activity } = useBookingActivityContext();
  const { data: parentActivity } = useBookingActivity(activity?.parent_activity_id);
  const linkUrl = useActivityLink({ activity, parentActivity });
  const ActivityIconComponent = useRowIconComponent({
    templateId: activity?.template_id ?? null,
    type: TEMPLATE_KEYS.ROLE
  });

  const { isAudit, isRole } = useActivityTemplateType(activity);
  const isAuditRole = isAudit && isRole;

  return activity ? (
    <DetailsHeader
      title={activity?.name}
      subtitle={isAuditRole ? t("auditRole") : t("role")}
      linkUrl={linkUrl ?? undefined}
    >
      <ActivityIconComponent />
    </DetailsHeader>
  ) : null;
};
