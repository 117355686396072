import { groupBy, map, orderBy, values } from "lodash";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useProfileSkillsFrameworks } from "PFCore/hooks/queries/skills_frameworks/use_profile_skills_frameworks";
import { useSkillsFrameworksInvalidate } from "PFCore/hooks/queries/skills_frameworks/use_skills_frameworks_invalidate";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { CurrentProfile, CustomValue, Profile, SkillsFramework } from "PFTypes";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "./skills_frameworks_suggested_skills_modal.module.scss";

type SkillsFrameworksSuggestedSkillsModalProps = {
  profile: Profile | CurrentProfile;
  onSkillRanked?: (customValue: Partial<CustomValue>) => void;
  handleClose: VoidFunction;
};

export const SkillsFrameworksSuggestedSkillsModal = ({
  profile,
  onSkillRanked,
  handleClose
}: SkillsFrameworksSuggestedSkillsModalProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.sectionSkills.suggestions.framework" });
  const { t: tCore } = useTranslation("core");

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const skillsType = useSkillsType();
  const { invalidateProfileSkillsFrameworks } = useSkillsFrameworksInvalidate();

  const { data, isLoading } = useProfileSkillsFrameworks({
    profileId: profile.id,
    params: {
      page: 1,
      perPage: PER_PAGE_UNPAGINATED
    }
  });

  const getMatchedCustomFields = useCallback(
    (skillsFramework: SkillsFramework) => {
      const customFieldsGrouped = values(groupBy(skillsFramework.customFields, "customType.id")).map(
        (customFields) => ({
          customTypeName: customFields[0].customType.displayAs,
          customValues: map(customFields, "customValue")
        })
      );

      const customFieldsValues = customFieldsGrouped.map(({ customTypeName, customValues }) => {
        let formattedValues = map(customValues, "value").join(", ");
        if (customValues.length > 1) {
          formattedValues = `(${formattedValues})`;
        }

        return `${customTypeName} = ${formattedValues}`;
      });

      return customFieldsValues.join(` ${tCore("and")} `);
    },
    [tCore]
  );

  const getSuggestedSkills = useCallback(
    (skillsFramework: SkillsFramework) =>
      orderBy(
        skillsFramework.skills.map(({ customValue }) => {
          const profileExperience = profile.skills?.find(({ id }) => id === customValue.id)?.experience ?? 0;

          return {
            ...customValue,
            experience: customValue.requiredExperience || profileExperience,
            state: "approved"
          };
        }),
        [({ experience }) => experience === 0, "value"]
      ),
    [profile.skills]
  );

  const sortedSkillsFrameworks = orderBy(data?.entries || [], "name");

  return (
    <Modal title={t("title")} onClose={handleClose} showOKButton={false} classes={{ modal: css.modal }}>
      <div className={css.content}>
        {isLoading && <LoadingDots circlesEnabled circleSize="xs" centered />}
        {sortedSkillsFrameworks.map((skillsFramework) => (
          <div key={skillsFramework.id}>
            <Typography variant="h5" className={css.sectionTitle} noMargin>
              {t("skillsFrameworkName", { name: skillsFramework.name })}
            </Typography>
            <Typography variant="bodyRegular" className={css.sectionDescription} noMargin>
              {t("description", { matchedCustomFields: getMatchedCustomFields(skillsFramework) })}
            </Typography>
            <CustomValuesList
              type={skillsType}
              customValues={getSuggestedSkills(skillsFramework)}
              profileId={profile.id}
              currentProfile={currentProfile}
              currentAccount={currentAccount}
              onRateChange={(customValue) => {
                onSkillRanked?.(customValue);
                invalidateProfileSkillsFrameworks(profile.id);
              }}
              sort={false}
              isEditMode
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};
