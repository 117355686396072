import isEmpty from "lodash/isEmpty";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useBookingsFromAvailability } from "PFCore/hooks/queries/bookings/bookings_from_availability/use_bookings_from_availability";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Template } from "PFTypes";
import { useEffect, useState } from "react";

import { truncateTitle } from "../booking_form_common_inputs";
import { useBookingFormContext } from "../booking_form_context_provider";
import { BookingDataItem, BookingFormDataCreateMode } from "../use_booking_form";
import { useDefaultBookingsMapper } from "./use_default_bookings_mapper";

const INITIAL_BOOKINGS_FOR_CREATE_LOADING = "initial-bookings-for-create-loading";

type UseInitialBookingsForCreate = {
  initialData: BookingFormDataCreateMode;
  enabled: boolean;
};

type UseInitialBookingsForCreateReturn = {
  bookings: BookingDataItem[];
  globalTitle?: string;
  globalDescription?: string;
  loading: boolean;
};

export const useInitialBookingsForCreate = ({
  initialData,
  enabled
}: UseInitialBookingsForCreate): UseInitialBookingsForCreateReturn => {
  const { formatISODate, utc } = useDateFormatter();
  const { loadingState, setLoadingState } = useBookingFormContext();
  const { activity } = useBookingActivityContext();

  const [bookingItems, setBookingItems] = useState<BookingDataItem[]>();

  const { mapDefaultBookings, mapAPIBookings } = useDefaultBookingsMapper({});
  const engagementTemplate = useTemplate(TEMPLATE_KEYS.ENGAGEMENT as Template["key"]);

  const { bookings, profileId, activityId } = initialData;

  const isBookingsFromAvailabilityEnabled =
    enabled &&
    !!profileId &&
    !!activity &&
    engagementTemplate?.id !== activity.template_id &&
    isEmpty(bookings);
  const { data: bookingsFromAvailabilityData, isFetching: isBookingsFromAvailabilityFetching } =
    useBookingsFromAvailability({
      activityId,
      profileId,
      options: {
        enabled: isBookingsFromAvailabilityEnabled
      }
    });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const defaultEmptyBookings = mapDefaultBookings([
      {
        startDate: formatISODate(utc()),
        endDate: formatISODate(utc())
      }
    ]);

    if (isBookingsFromAvailabilityEnabled) {
      if (bookingsFromAvailabilityData?.ranges && !isBookingsFromAvailabilityFetching) {
        const bookingsFromAvailability = mapAPIBookings(bookingsFromAvailabilityData?.ranges ?? []);
        setBookingItems(!isEmpty(bookingsFromAvailability) ? bookingsFromAvailability : defaultEmptyBookings);
      }
      return;
    }
    if (!isEmpty(bookings)) {
      setBookingItems(mapDefaultBookings(bookings));
      return;
    }

    setBookingItems(defaultEmptyBookings);
  }, [bookingsFromAvailabilityData, bookings]);

  useEffect(() => {
    if (isBookingsFromAvailabilityEnabled && isBookingsFromAvailabilityFetching) {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_CREATE_LOADING]: true }));
    } else {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_CREATE_LOADING]: false }));
    }
  }, [isBookingsFromAvailabilityEnabled, isBookingsFromAvailabilityFetching]);

  return {
    bookings: bookingItems ?? [],
    globalTitle: truncateTitle(bookingsFromAvailabilityData?.title),
    globalDescription: bookingsFromAvailabilityData?.description,
    loading: loadingState[INITIAL_BOOKINGS_FOR_CREATE_LOADING]
  };
};
