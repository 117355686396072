import ActivityProfilesList from "PFApp/activities/parts/activity_profiles_list";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Modal } from "PFComponents/modal";
import { Activity, ActivityProfileType } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useOwnerRemove } from "./hooks/use_owner_remove";

type RemoveOwnersProps = { onClose: VoidFunction; task: Activity };

const RemoveOwners = ({ onClose, task }: RemoveOwnersProps) => {
  const { isUnbiasedEnabled } = useActivityPageContext();
  const { t } = useTranslation(["activities", "translation"]);

  const { handleRemove, getRemoveModalContent } = useOwnerRemove(task);

  return (
    <Modal
      title={t("activities:show.parts.removeOwners")}
      onClose={onClose}
      labelCancel={t("translation:close")}
      showOKButton={false}
    >
      <ActivityProfilesList
        isUnbiasedEnabled={isUnbiasedEnabled}
        activityProfiles={task.owners}
        title={t("activities:show.parts.sharedWith")}
        onRemove={task.owners.length > 1 ? handleRemove : null}
        getRemoveModalContent={getRemoveModalContent}
        activityProfileType={ActivityProfileType.Owner}
      />
    </Modal>
  );
};

export default RemoveOwners;
