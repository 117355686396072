import { useTemplate } from "PFApp/hooks";
import { isAdmin } from "PFCore/helpers/profile";
import { useIsShortlistingPermitted } from "PFCore/helpers/shortlists";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule } from "PFTypes";
import { Activity } from "PFTypes";
import { useMemo } from "react";

import { useActivityCurrentUserRole } from "./use_activity_current_user_role";
import { useActivityTemplateType } from "./use_activity_template_type";
import { useEngagementFeedbackParticipants } from "./use_engagement_feedback_participants";

export const useActivityPermissions = (activity: Activity) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { data: feedbackParticipants } = useEngagementFeedbackParticipants(activity);

  const isPermittedTo = useIsPermittedTo(currentProfile);

  const template = useTemplate(activity.template_id);
  const needMatches = template?.need_matches;

  const { isRole, isEngagement, isAudit } = useActivityTemplateType(activity);
  const { isResourcer, isReviewer } = useActivityCurrentUserRole(activity);

  const isShortlistingPermitted = useIsShortlistingPermitted()(activity);

  // todo: [PROF-6072] allowing to see matches in the audit planner
  //  without being resourcer or audit_resourcer is most likely an invalid behaviour
  //  check: https://profinda.slack.com/archives/C02J0HP1ZRP/p1713272096105739?thread_ts=1712749315.559599&cid=C02J0HP1ZRP for details
  const canSeeMatches = useMemo(
    () => !!(needMatches && activity.coowned && (!isRole || isResourcer || isAudit)),
    [activity.coowned, isAudit, isResourcer, isRole, needMatches]
  );

  const canSeeShortlist = useMemo(
    () => !!(((needMatches && isShortlistingPermitted) || isReviewer) && isRole),
    [isReviewer, isRole, isShortlistingPermitted, needMatches]
  );

  const canAddShortlist = isResourcer && isShortlistingPermitted;
  const canReviewShortlists = activity.coowned || isReviewer;

  const canSeeVacancies = activity.coowned && isRole;
  const canSeeRoles = activity.coowned && isEngagement;
  const canSeeInterested = activity.coowned && isRole;
  const canSeeFeedback = !!(
    activity.coowned &&
    isEngagement &&
    feedbackParticipants &&
    feedbackParticipants?.meta.total > 0
  );

  const canSeeEconomics =
    isEngagement &&
    isFeatureEnabled(currentAccount)(FeatureFlag.EngagementEconomics) &&
    (activity.scenarios_count > 0 ||
      isPermittedTo(PermissionRule.EngagementEconomicsCreateScenarios) ||
      isPermittedTo(PermissionRule.EngagementEconomicsManage));

  const canSeeHistory =
    !!activity?.coowned || isAdmin(currentProfile) || isPermittedTo(PermissionRule.IntegrationUser);

  const canBecomeCoOwner =
    (template?.create_permitted || isEngagement) &&
    isResourcer &&
    isPermittedTo(PermissionRule.AddYourselfAsOwner) &&
    !activity.coowned;

  return {
    canSeeMatches,
    canSeeShortlist,
    canSeeVacancies,
    canSeeRoles,
    canSeeInterested,
    canSeeFeedback,
    canAddShortlist,
    canReviewShortlists,
    canSeeEconomics,
    canSeeHistory,
    canBecomeCoOwner
  };
};
