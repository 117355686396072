import { every, extend } from "lodash";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const isMsTeams = () => {
  const isIframe = window.self !== window.parent;
  const ancestorOrigins = document.location.ancestorOrigins?.[0] || "";
  const isMsTeamsReferrer =
    ancestorOrigins.indexOf("teams.microsoft.com") > -1 ||
    ancestorOrigins.indexOf("teams.cloud.microsoft") > -1;
  const isFirefox = navigator.userAgent.indexOf("Firefox") > -1; // Firefox does not support ancestorOrigins

  return isIframe && (isMsTeamsReferrer || isFirefox);
};

export const openChatInMsTeams = (emails) => {
  const chatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${emails}`;
  return isMsTeams() && !!window.microsoftTeams.executeDeepLink(chatUrl);
};

const STORAGE_KEY = "ms_teams_domain";

export const applyTeamsDomain = () => {
  const domain = storage.getItem(STORAGE_KEY);
  if (domain) {
    extend(PF.config, { ms_teams_domain: domain });
  }
};

export const setTeamsDomain = (domain) => storage.setItem(STORAGE_KEY, domain);

export const initializeMsTeams = (params = {}) => {
  if (params.force || isMsTeams()) {
    applyTeamsDomain();

    return import(/* webpackChunkName: "microsoft_teams" */ "@microsoft/teams-js").then((microsoftTeams) => {
      microsoftTeams.initialize();
      window.microsoftTeams = microsoftTeams;
      return Promise.resolve(microsoftTeams);
    });
  } else {
    applyTeamsDomain();
    return Promise.resolve();
  }
};

export const useInitializeMsTeams = () => {
  const queryParams = useQueryParams();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeMsTeamsFnc = (params) => initializeMsTeams(params).then(() => setInitialized(true));
    const forceInitializeMsTeam =
      queryParams.get("msTeams") && queryParams.get("oauthRedirectMethod") !== "deeplink";
    initializeMsTeamsFnc({ force: forceInitializeMsTeam, account: queryParams.get("account") });
  }, []);

  return initialized;
};

export const useStoreMsTeamsLastVisitedPath = () => {
  const location = useLocation();
  useEffect(() => {
    const blocklistedPaths = ["/sign_out", "/ms_teams", "/onboarding"];
    if (isMsTeams() && every(blocklistedPaths, (path) => (location.pathname || "").indexOf(path) === -1)) {
      storage.setItem("ms_teams_last_visited_path", location.pathname);
    }
  }, [location.pathname]);
};
