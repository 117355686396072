import { useMatchesShortlistReplace } from "PFCore/hooks/queries/matches/use_matches_shortlist_replace";

import { useRefreshBookingData } from "../../../hooks/use_refresh_booking_data";
import { bookRoleByShortlist } from "../../../services/api";
import { BookingSelectOption } from "../../booking_questions/booking_questions";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormData, BookingFormMode } from "../use_booking_form";
import { isGroupOption } from "../workforce_member_select/workforce_member_select_dropdown";
import { BookingResponse } from "./use_handle_submit";

const getProfileIds = (initialData: BookingFormData, { workforceMember }: BookingFormValues) => {
  if (isGroupOption(workforceMember)) {
    return [];
  } else {
    return workforceMember ? [workforceMember.id] : [];
  }
};

type UsePostSubmitActions = {
  initialData: BookingFormData;
  mode: BookingFormMode;
  shortlistId: number | undefined;
  bookingGroupId: number | undefined;
};

export const usePostSubmitActions = ({
  initialData,
  mode,
  shortlistId,
  bookingGroupId
}: UsePostSubmitActions) => {
  const refreshBookingData = useRefreshBookingData();

  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  const postSubmitActions = async (bookings: BookingResponse[], values: BookingFormValues) => {
    const { activityId, workforceMember, updateGroupOptionSelected, bookingType } = values;
    const isRepeated = bookingType === BookingType.Repeated;
    if (shortlistId) {
      const shortlist = await bookRoleByShortlist(shortlistId);
      replaceMatchesShortlistInCache(shortlist);
    }
    const refreshGroup =
      updateGroupOptionSelected === BookingSelectOption.All ||
      (mode === "create" && isGroupOption(workforceMember));
    const profileIds = getProfileIds(initialData, values);
    refreshBookingData({
      bookingIds: bookings.map(({ id }) => id),
      bookingGroupId,
      shortlistId,
      activityId,
      profileIds,
      refreshGroup,
      refreshProfile: isRepeated
    });
  };

  return {
    postSubmitActions
  };
};
