import { useMemo } from "react";

import { checkIfIsDefaultPrivate } from "../../../helpers";
import { useActivityTemplateType } from "../../../hooks";
import { useActivityEditPageContext } from "../activity_edit_page_context";
import { useIsAssigneeRequired } from "./use_is_assignee_required";

export const useActivityEditPageFlags = () => {
  const { activity, template, isClone, parentActivity } = useActivityEditPageContext();

  const isNew = !activity.id;
  const activityDetails = useActivityTemplateType(
    isNew ? { ...activity, template_id: template.id } : activity
  );
  const isForceVisibilitySet = !!template.force_visibility;
  const isForcePrivate = template.force_visibility === "private";
  const isAssigneeRequiredFnc = useIsAssigneeRequired();

  const isDefaultPrivate = isForceVisibilitySet
    ? isForcePrivate
    : checkIfIsDefaultPrivate(template, parentActivity);

  return useMemo(
    () => ({
      ...activityDetails,
      isNew,
      isEdit: !isNew,
      isClone,
      isDraft: activity.state === "draft",
      isForceVisibilitySet,
      isForcePrivate,
      isDefaultPrivate,
      isAssigneeRequired: isAssigneeRequiredFnc(template)
    }),
    [
      activityDetails,
      isNew,
      isClone,
      activity.state,
      isForceVisibilitySet,
      isForcePrivate,
      isDefaultPrivate,
      isAssigneeRequiredFnc,
      template
    ]
  );
};
