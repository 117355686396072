import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { useMatchesStatusText } from "PFApp/activities/show/hooks/use_matches_status_text";
import { MatchesEmpty } from "PFApp/activities/show/matches/matches_empty";
import { MatchesTimeout } from "PFApp/activities/show/matches/matches_timeout";
import { MatchCard } from "PFApp/components/activity_match_card";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import BrainLoading from "PFComponents/brain_loading/brain_loading";
import { LoadingDots } from "PFComponents/loading_dots";
import SimplePagination from "PFComponents/pagination/simple_pagination";
import { Activity } from "PFTypes";
import { ReactNode, useMemo, useState } from "react";

import { MatchesActivityClosed } from "../../../../../matches/matches_activity_closed";
import { MatchesHeader } from "./matches_header";
import css from "./matches_panel.module.scss";

export const MatchesPanel = () => {
  const { openFiltersPanel } = useFiltersPanelContext();
  const [isCardsCompressed, setIsCardsCompressed] = useState(true);

  const {
    task,
    matches,
    checkActivityPending,
    matchesFetchState,
    matchesMeta,
    updateMatchesParams,
    matchesParams,
    isActivityUnbiased,
    vacanciesLoading,
    existsOpenVacancy
  } = useActivityPageContext();

  const activity = task as Activity;
  const template = useActivityTemplate();

  const isLoading = !matchesFetchState || matchesFetchState === "pending";
  const isTimeout = matchesFetchState === "timeout";
  const isClosed = task.state === "complete";
  const showLoadingDots = isLoading && !matches;

  const matchingStateText: string | ReactNode = useMatchesStatusText(matchesMeta.status);

  const MATCHES_INFO = useMemo(
    () => ({
      brainLoading: <BrainLoading text={matchingStateText} />,
      matchesTimeout: <MatchesTimeout pending={checkActivityPending()} template={template || {}} />,
      matchesEmpty: (
        <MatchesEmpty task={activity} matchesMeta={matchesMeta} openFilters={() => openFiltersPanel(true)} />
      ),
      activityClosed: <MatchesActivityClosed activity={activity} />
    }),
    [matchingStateText, checkActivityPending, template, activity, matchesMeta, openFiltersPanel]
  );

  const matchesInfoKey = useMemo<keyof typeof MATCHES_INFO>(() => {
    if (isClosed) {
      return "activityClosed";
    }
    if (matchesMeta.status !== "ready") {
      return "brainLoading";
    }
    if (isLoading && isTimeout) {
      return "matchesTimeout";
    }

    return "matchesEmpty";
  }, [matchesMeta.status, isLoading, isTimeout, isClosed]);

  return (
    <div className={commonCss.pageCardMainPanel}>
      <MatchesHeader isCardsCompressed={isCardsCompressed} setIsCardsCompressed={setIsCardsCompressed} />
      {showLoadingDots && <LoadingDots className={commonCss.loading} circlesEnabled circleSize="xs" />}
      <div className={commonCss.pageCardEntriesList} data-qa-id="ActivityMatchesTabMatchesContainer">
        {matches?.map((match) => (
          <MatchCard
            role={activity}
            key={match.id}
            match={match}
            isCompressed={isCardsCompressed}
            isBlindModeOn={isActivityUnbiased}
            isFillDisabled={vacanciesLoading || !existsOpenVacancy}
            areButtonsDisabled={isLoading}
          />
        ))}
        {matches && matches.length > 0 ? (
          <SimplePagination
            currentPage={matchesParams?.page || 1}
            handleClick={(num) => updateMatchesParams({ page: num })}
            totalPages={matchesMeta.totalPages}
          />
        ) : (
          <div className={css.matchesInfo}>{MATCHES_INFO[matchesInfoKey]}</div>
        )}
      </div>
    </div>
  );
};
