import { Modal } from "PFComponents/modal";
import { useTranslation } from "react-i18next";

interface IProps {
  closeModal: VoidFunction;
  onOK?: VoidFunction;
}

const DeleteNoteModal = ({ closeModal, onOK }: IProps) => {
  const { t } = useTranslation(["translation", "bookingModule"]);
  return (
    <Modal
      title={t("bookingModule:details.notes.deleteNote")}
      labelOK={t("translation:delete")}
      onOK={onOK}
      onClose={closeModal}
      kindOK="danger"
    >
      {t("bookingModule:details.notes.areYouSureToDeleteNote")}
    </Modal>
  );
};

export default DeleteNoteModal;
