/* eslint-disable camelcase */
import { Modal } from "PFComponents/modal";
import PageLayout from "PFComponents/page/page_layout";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import { Typography } from "PFComponents/typography";
import Cover from "PFCore/components/cover";
import { useTranslation } from "react-i18next";

import css from "./activity_edit_form.module.scss";
import { ActivityEditFormProvider, ModalKey, useActivityEditFormContext } from "./activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "./activity_edit_page_context";
import { CancelButton } from "./components/cancel_button";
import { RestoreEngagementLinkButton } from "./components/restore_engagement_link_button";
import { SetSkillsFrameworkButton } from "./components/set_skills_framework_button";
import { useActivityEditFormAutoSubmit, useEngagementWizard } from "./hooks";
import { useActivityEditForm } from "./hooks/use_activity_edit_form";
import { ActivityEditFormSidePanel } from "./sections/activity_edit_form_side_panel";
import { AttributesSection } from "./sections/attributes_section";
import AvailabilitySection from "./sections/availability_section";
import BasicSection from "./sections/basic_section";
import ButtonSection from "./sections/button_section";
import EngagementWizardButtonSection from "./sections/engagement_wizard_button_section";
import HeaderSection from "./sections/header_section";
import buttonCss from "./sections/header_section.module.scss";

const ActivityEditFormBody = () => {
  const { t } = useTranslation("activities");

  const { template, activity } = useActivityEditPageContext();
  const { isNew, isRole } = useActivityEditPageFlags();
  const {
    locked,
    setSubmitOptions,
    setIsPrivate,
    setAudiences,
    subtemplateOptions,
    hasSubtemplates,
    subtemplate,
    subtemplateKey,
    isLoading,
    handleSubtemplateChange,
    showSkillsFrameworkButton,
    isModalDisplayed,
    setModalDisplayed,
    setModalConfirmed
  } = useActivityEditFormContext();

  const { engagementWizardState } = useEngagementWizard();

  useActivityEditFormAutoSubmit();

  const qaIdPrefix = `activity-${activity.id ? "edit" : "create"}`;

  const { isChanged } = useActivityEditForm();

  const header = () => <HeaderSection />;

  const cancelButton = () => <CancelButton />;

  const buttons = ({ isOneColumn }) =>
    isRole && engagementWizardState ? (
      <EngagementWizardButtonSection isOneColumn={isOneColumn} />
    ) : (
      <ButtonSection />
    );

  const main = (
    <div className={css.formWrapper}>
      <div style={{ position: "relative" }}>
        <div className="row">
          <div>
            <BasicSection isEngagementWizard={isRole && !!engagementWizardState} qaIdPrefix={qaIdPrefix} />
            <AttributesSection
              title={t("edit.matchAttributes")}
              buttons={
                showSkillsFrameworkButton ? (
                  <SetSkillsFrameworkButton disabled={hasSubtemplates && !subtemplate} />
                ) : null
              }
              usedOn={isNew ? "Activity_creation" : "Activity_edition"}
              qaIdPrefix={qaIdPrefix}
              withMatch
            />
            <AvailabilitySection qaIdPrefix={qaIdPrefix} />
            <AttributesSection
              title={t("edit.attributes")}
              qaIdPrefix={qaIdPrefix}
              subtemplateOptions={subtemplateOptions}
              hasSubtemplates={hasSubtemplates}
              subtemplateKey={subtemplateKey || undefined}
              onSubtemplateChange={handleSubtemplateChange}
              subtemplate={subtemplate || undefined}
              isLoading={isLoading}
              buttons={<RestoreEngagementLinkButton />}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const sidePanel = () => <ActivityEditFormSidePanel qaIdPrefix={qaIdPrefix} />;

  return (
    <div style={{ margin: "auto", maxWidth: 1230 }}>
      <RouterPrompt when={!locked && isChanged} />
      <PageLayout mode="withSidebar">
        <div>
          <PageLayout.WhenTwoColumns>
            <div style={{ display: "flex", alignItems: "center" }}>
              {locked && <Cover />}
              {header()}
              {cancelButton()}
            </div>
          </PageLayout.WhenTwoColumns>
          <PageLayout.WhenOneColumn>
            {locked && <Cover />}
            {header()}
          </PageLayout.WhenOneColumn>
        </div>
        <div>
          <PageLayout.WhenTwoColumns>{buttons({ isOneColumn: false })}</PageLayout.WhenTwoColumns>
        </div>
      </PageLayout>
      <PageLayout mode="withSidebar">
        <div>
          <PageLayout.WhenTwoColumns>{main}</PageLayout.WhenTwoColumns>

          <PageLayout.WhenOneColumn>
            {main}
            {sidePanel()}
            <div className={buttonCss.alignment}>
              {cancelButton()}
              {buttons({ isOneColumn: true })}
            </div>
          </PageLayout.WhenOneColumn>
        </div>
        <div>
          <PageLayout.WhenTwoColumns>{sidePanel()}</PageLayout.WhenTwoColumns>
        </div>
      </PageLayout>

      {isModalDisplayed[ModalKey.NoRequirements] && (
        <Modal
          title={t("edit.noRequirements")}
          onOK={() => {
            setModalConfirmed(ModalKey.NoRequirements, true);
          }}
          onClose={() => {
            setModalDisplayed(ModalKey.NoRequirements, false);
            setSubmitOptions({});
          }}
        >
          <Typography variant="bodyRegular" noMargin>
            {t("edit.creatingNoRequirementsWarning", { templateName: template.name })}
          </Typography>
        </Modal>
      )}
      {isModalDisplayed[ModalKey.Public] && (
        <Modal
          title={t("edit.makePublic")}
          onOK={() => {
            setIsPrivate(false);
            setAudiences([]);
            setModalConfirmed(ModalKey.Public, true);
            setModalDisplayed(ModalKey.Public, false);
          }}
          onClose={() => {
            setModalDisplayed(ModalKey.Public, false);
          }}
        >
          <Typography variant="bodyRegular" noMargin>
            {t("edit.makingRolePublicWarning")}
          </Typography>
        </Modal>
      )}
      {isModalDisplayed[ModalKey.QuestionsChanged] && (
        <Modal
          title={t("edit.showInterestQuestions")}
          onOK={() => {
            setModalConfirmed(ModalKey.QuestionsChanged, true);
          }}
          onClose={() => {
            setModalDisplayed(ModalKey.QuestionsChanged, false);
            setModalConfirmed(ModalKey.NoRequirements, false);
            setSubmitOptions({});
          }}
        >
          <Typography variant="bodyRegular" noMargin>
            {t("edit.changedQuestionsWarning")}
          </Typography>
        </Modal>
      )}
      {isModalDisplayed[ModalKey.Misalignment] && (
        <Modal
          title={t("edit.existingBooking")}
          onOK={() => {
            setModalConfirmed(ModalKey.Misalignment, true);
          }}
          onClose={() => {
            setModalDisplayed(ModalKey.Misalignment, false);
            setModalConfirmed(ModalKey.NoRequirements, false);
            setModalConfirmed(ModalKey.QuestionsChanged, false);
            setSubmitOptions({});
          }}
        >
          <Typography variant="bodyRegular" noMargin>
            {t("edit.potentialMisalignmentWarning")}
          </Typography>
        </Modal>
      )}
    </div>
  );
};

export const ActivityEditForm = () => (
  <ActivityEditFormProvider>
    <ActivityEditFormBody />
  </ActivityEditFormProvider>
);
