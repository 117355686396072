import { camelizeKeys } from "humps";
import { Avatar, AvatarProps } from "PFComponents/avatar/avatar";
import { fromCache } from "PFComponents/avatar/avatar_cache";
import { isProfileActive } from "PFCore/helpers/profile";
import { getProfileName } from "PFCore/helpers/profile";
import { ProfileMinimized } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type ProfileAvatarProps = {
  profile: ProfileMinimized;
} & Pick<
  AvatarProps,
  | "size"
  | "fullSize"
  | "fontSize"
  | "href"
  | "onClick"
  | "qaId"
  | "Icon"
  | "handleIconClick"
  | "isHighlighted"
  | "tooltipContent"
> &
  React.AriaAttributes;

export const ProfileAvatar = ({
  href,
  size = 72,
  fullSize = false,
  qaId,
  fontSize,
  onClick,
  profile,
  Icon,
  handleIconClick,
  tooltipContent,
  isHighlighted,
  "aria-label": ariaLabel
}: ProfileAvatarProps) => {
  const { t } = useTranslation("core", { keyPrefix: "avatar" });
  const camelizedProfile = camelizeKeys(profile) as ProfileMinimized; // TODO: [PROF-5746] Remove "camelizeKeys" when all ProfileItem usages get camelized profile prop
  const avatar = camelizedProfile.avatar || {};

  const fullName = getProfileName(profile);

  const inactive = !isProfileActive(camelizedProfile);
  const initialsArray = ["firstName", "lastName"].map((key) =>
    camelizedProfile[key] ? camelizedProfile[key][0] : ""
  );
  const text = initialsArray.join("").toUpperCase() || "?";

  const imagePath: string = useMemo(() => {
    if (avatar.custom) {
      if (size > 50 || fullSize) {
        return fromCache(camelizedProfile.id, "full", avatar.fullUrl);
      } else {
        return fromCache(camelizedProfile.id, "thumb", avatar.thumbnailUrl);
      }
    }

    return "";
  }, [avatar.fullUrl, avatar.thumbnailUrl, avatar.custom, fullSize, camelizedProfile.id, size]);

  const defaultAriaLabel = t("profileAvatar", { name: fullName });
  const buttonTitle = t("openChatWith", { name: fullName });

  return (
    <Avatar
      key={camelizedProfile.id}
      href={href}
      size={size}
      fullSize={fullSize}
      fontSize={fontSize}
      inactive={inactive}
      qaId={qaId}
      text={text}
      imagePath={imagePath}
      onClick={onClick}
      Icon={Icon}
      handleIconClick={handleIconClick}
      buttonTitle={buttonTitle}
      tooltipContent={tooltipContent}
      isHighlighted={isHighlighted}
      aria-label={ariaLabel || defaultAriaLabel}
    />
  );
};
