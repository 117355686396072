import { JSX, ReactNode } from "react";

export type AccordionProps = {
  childrenTitle: string;
  filtersTitle: ReactNode;
  filtersTip?: JSX.Element;
  filtersDisabled: boolean;
  allDisabled?: boolean;
  activeState?: Record<number, boolean | undefined>;
  onActiveIndexChange?: (index: number | null) => void;
  allowMultipleActive?: boolean;
};

export enum PagePrefix {
  ActivityInterested = "activityInterested",
  ActivityMatches = "activityMatches",
  ActivityShortlists = "activityShortlists",
  AuditPlannerMatches = "auditPlannerMatches",
  AuditPlannerShortlists = "auditPlannerShortlists",
  AuditPlanner = "auditPlanner",
  Analytics = "analytics",
  Insights = "insights",
  InsightsBookingReports = "insightsBookingReports",
  WorkflowRoles = "workflowRoles",
  WorkflowEngagements = "workflowEngagements",
  WorkflowPlanner = "workflowProfiles",
  MarketplaceOpportunities = "marketplaceOpportunities",
  ActivityFeed = "activityFeed",
  BookingModuleOverview = "bookingModuleOverview",
  BookingModuleApprovals = "bookingModuleApprovals",
  Profiles = "profiles",
  ProfileMatchingRoles = "profileMatchingRoles",
  Search = "search",
  AdminBookingCalendars = "adminBookingCalendars",
  AdminCustomFields = "adminCustomFields",
  AdminUsers = "adminUsers"
}
