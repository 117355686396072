import PropTypes from "prop-types";

import css from "./footer.module.scss";

const Footer = ({ participants }) => (
  <div className={css.wrapper}>
    {participants.length > 0 && <div className={css.right}>{`${participants.length} Discussing`}</div>}
  </div>
);

Footer.propTypes = {
  participants: PropTypes.array
};
export default Footer;
