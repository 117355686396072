import classNames from "classnames";
import PropTypes from "prop-types";

import { BasicPill } from "./basic_pill";
import css from "./pill.module.scss";
const EngagementPill = ({ children, onClick, className, style, state }) => {
  const engagementClassName =
    {
      booked: "engagementBooked",
      partially_booked: "engagementPartiallyBooked"
    }[state] || "engagementNotBooked";

  return (
    <BasicPill className={classNames(css[engagementClassName], className)} style={style} onClick={onClick}>
      {children}
    </BasicPill>
  );
};

EngagementPill.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  state: PropTypes.string.isRequired
};

export default EngagementPill;
