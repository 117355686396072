import { FetchCustomValuesParams } from "PFCore/services/admin/custom_fields";
import { FetchCtCustomValuesParams } from "PFCore/services/admin/custom_values";
import { Id } from "PFTypes";

export const customValueKeys = {
  all: () => ["admin", "customValues"],
  list: (
    customTypeId: Id,
    page?: number,
    perPage?: number,
    params?: FetchCustomValuesParams | FetchCtCustomValuesParams
  ) => [
    ...customValueKeys.all(),
    customTypeId,
    ...(page ? [page] : []),
    ...(perPage ? [perPage] : []),
    ...(params ? [params] : [])
  ],
  single: (customTypeId: Id, customValueId: Id) => [...customValueKeys.all(), customTypeId, customValueId]
};
