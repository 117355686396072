import { isNil } from "lodash";

type GetMatchDisplayValueOptions = {
  defaultValue?: string;
  showPercentage?: boolean;
  digits?: number;
  skipTrailingZeros?: boolean;
};

const getValueWithUnit = (value: string | number, unit: string) => `${value}${unit}`;

export const getMatchDisplayValue = (
  rawScore: number | undefined | null,
  options: GetMatchDisplayValueOptions = {
    defaultValue: "-",
    showPercentage: false,
    digits: 1,
    skipTrailingZeros: false
  }
) => {
  if (isNil(rawScore)) {
    return options.defaultValue;
  }

  const scoreFixedDigits = (rawScore * 100).toFixed(options.digits);
  const unit = options.showPercentage ? "%" : "";

  if (options.skipTrailingZeros) {
    return getValueWithUnit(Number(scoreFixedDigits), unit);
  }

  return getValueWithUnit(scoreFixedDigits, unit);
};
