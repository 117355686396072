import useWindowSize from "PFCore/helpers/use_window_size";
import HotIcon from "PFIcons/hot";
import PropTypes from "prop-types";

import css from "./footer.module.scss";

const RolePositionFooter = ({ isHotVisible, interestedIn }) => {
  const { windowWidth } = useWindowSize();
  return (
    <div className={css.wrapper}>
      {isHotVisible && (
        <div className={css.icons}>
          <HotIcon style={{ fill: "#ffa800" }} />
          <div>&nbsp;{windowWidth > 320 ? "Hot - Lots of people are talking about this" : "Hot"}</div>
        </div>
      )}
      {interestedIn > 0 && <div className={css.right}>{`${interestedIn || 0} interested`}</div>}
    </div>
  );
};

RolePositionFooter.propTypes = {
  isHotVisible: PropTypes.bool,
  interestedIn: PropTypes.number
};

export default RolePositionFooter;
