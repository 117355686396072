import { Modal } from "PFComponents/modal";
import { EconomicsScenarioDetails } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BarChart } from "../../parts/bar_chart";
import { CompletionRateBadge } from "../../parts/completion_rate_badge";
import css from "./skills_chart_modal.module.scss";
import { useSkillsChartModal } from "./use_skills_chart_modal";

type SkillsModalProps = {
  skillsRadarData: EconomicsScenarioDetails["skillsRadarData"];
  coverage: number;
  onClose: VoidFunction;
};

export const SkillsChartModal = ({ skillsRadarData, coverage, onClose }: SkillsModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.skills" });

  const { skillsNames, series, customOptions } = useSkillsChartModal(skillsRadarData);

  return (
    <Modal
      title={
        <span className={css.titleWithCoverage}>
          {t("title")}
          <CompletionRateBadge series={series} coverage={coverage} />
        </span>
      }
      onClose={onClose}
      showOKButton={false}
      showCancelButton={false}
      classes={{
        modal: css.modal
      }}
    >
      <BarChart
        hideLegend
        names={skillsNames}
        series={series}
        barVerticalSpacing={20}
        customOptions={customOptions}
      />
    </Modal>
  );
};
