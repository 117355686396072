import Panel from "PFComponents/panel/panel";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { useTranslation } from "react-i18next";

import { Question } from "../activity_edit_form_context/hooks";
import commonCss from "./sections.module.scss";
import css from "./show_interest_questions.module.scss";

interface Props {
  isToggleVisible: boolean;
  toggleValue: boolean;
  questions: Question[];
  areQuestionsEditable: boolean;
  errors?: {
    interestQuestions?: string;
  };
  onToggleChange: (value: boolean) => void;
  onQuestionChange: (question: Question) => void;
}

const ShowInterestQuestions = ({
  isToggleVisible,
  toggleValue,
  questions,
  areQuestionsEditable,
  errors,
  onToggleChange,
  onQuestionChange
}: Props) => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections" });

  const QuestionsWrapperTag = areQuestionsEditable ? "div" : "ol";

  return (
    <Panel className={commonCss.wrapper}>
      <div className={commonCss.header}>{t("showInterestQuestions")}</div>
      {isToggleVisible && (
        <Toggle
          onChange={onToggleChange}
          checked={toggleValue}
          label={toggleValue ? t("on") : t("off")}
          inline
        />
      )}
      {toggleValue && (
        <QuestionsWrapperTag className={css.questionsWrapper}>
          {questions.map(({ id, value }, index) =>
            areQuestionsEditable ? (
              <InputFieldSet
                key={id}
                value={value}
                onChange={(newValue: string) => onQuestionChange({ id, value: newValue })}
                error={index === 0 && errors?.interestQuestions}
                inputType="textarea"
                maxLength={120}
                minVisibleMaxLengthFactor={1}
              />
            ) : (
              !!value && (
                <li key={id} className={css.questionText}>
                  <h6 className={css.questionText}>{value}</h6>
                </li>
              )
            )
          )}
        </QuestionsWrapperTag>
      )}
    </Panel>
  );
};

export default ShowInterestQuestions;
