import { SkillsSelect, SkillsSelectProps } from "PFApp/components/skills_select/skills_select";
import { allowsNewValues } from "PFCore/helpers/custom_type";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";

export const SkillsEditField = ({ customType, ...props }: SkillsSelectProps) => {
  const isEnabled = useIsFeatureEnabled();

  const activityCanCreateValues = isEnabled(FeatureFlag.ActivityCanCreateValues);

  return (
    <SkillsSelect
      {...props}
      customType={customType}
      isActivity
      letCreate={activityCanCreateValues && customType && allowsNewValues(customType)}
      withProficiency={true}
      letClear
    />
  );
};
