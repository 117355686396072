import { setupChameleon } from "PFApp/bootstrapper/setup_chameleon";
import { setupWrapAjax } from "PFApp/bootstrapper/setup_wrap_ajax";
import { useAccountSwitched } from "PFApp/bootstrapper/use_account_switched";
import { useChameleonProfilePayload } from "PFApp/bootstrapper/use_chameleon_profile_payload";
import { useDataDogRum } from "PFApp/bootstrapper/use_datadog_rum";
import { useInjectTheme } from "PFApp/bootstrapper/use_inject_theme";
import { useLogInOutBroadcasts } from "PFApp/bootstrapper/use_log_in_out_broadcasts";
import { useNativeNotifications } from "PFApp/bootstrapper/use_native_notifications";
import { useNotificationSettings } from "PFApp/bootstrapper/use_notification_settings";
import { useNotifyAboutCustomConfig } from "PFApp/bootstrapper/use_notify_about_custom_config";
import { useTemplatesConfiguration } from "PFApp/bootstrapper/use_templates_configuration";
import { useGlobalsForLocale } from "PFApp/use_globals_for_locale";
import { useGrowl } from "PFApp/use_growl";
import { useSession } from "PFApp/use_session";
import { useThemeVars } from "PFApp/use_theme_vars";
import { setupAxiosInstance } from "PFCore/api";
import { usePusher } from "PFCore/base/pusher";
import { useInitializeMsTeams } from "PFCore/helpers/ms_teams";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { removeLoadingState } from "PFCore/initializers/remove_loading_state";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppContext from "./app_context";
import { useChangeLanguage } from "./use_change_language";

export const useBootstrapper = () => {
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  const { store, dispatch } = useContext(AppContext);

  useNotifyAboutCustomConfig();
  useGlobalsForLocale();
  useDataDogRum();
  useLogInOutBroadcasts();
  useThemeVars(dispatch);
  useNotificationSettings(dispatch, store);
  useNativeNotifications(dispatch, store);
  const { isReady: isTemplateConfigurationReady } = useTemplatesConfiguration(dispatch, store);

  useChameleonProfilePayload();

  const session = useSession();
  const growl = useGrowl();

  const { isSignedIn, refetch: fetchCurrentProfile } = useCurrentProfile();

  const isMsTeamsInitialized = useInitializeMsTeams();

  const {
    data: currentAccount,
    error: currentAccountError,
    refetch: fetchCurrentAccount
  } = useCurrentAccount();

  useEffect(() => {
    if (isMsTeamsInitialized) {
      fetchCurrentAccount();
    }
  }, [isMsTeamsInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentAccountError) {
      if (currentAccountError.status === 404) {
        window.location.replace("https://profinda.com");
      } else {
        handleServerError();
      }
    }
  }, [currentAccountError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleServerError = () => {
    growl({
      id: "INTERNAL_SERVER_ERROR",
      message: t("internalServerError"),
      kind: "error"
    });
  };

  // fetch current profile and set up the rest
  useEffect(() => {
    const fetchCurrentProfileAndSetUp = async () => {
      if (currentAccount.id) {
        setupChameleon(currentAccount);
        const theme = PF.config.theme || currentAccount.customization?.theme || "profinda";
        const { themeConfig } = PF.config;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useInjectTheme(theme, { themeConfig });
        const token = await session.setup();
        setupWrapAjax($, session, currentAccount, handleServerError);
        setupAxiosInstance(session, currentAccount.full_domain, handleServerError);
        try {
          if (token || session.getAccessToken()) {
            await fetchCurrentProfile();
            await fetchCurrentAccount();
          } else {
            throw "not signed in";
          }
        } catch {}

        removeLoadingState();
        setInitialized(true);

        if (PF.config.environment === "staging" && PF.config.ui_branch !== "staging") {
          growl({
            message: `Be aware: You are using git branch ${PF.config.ui_branch}`,
            kind: "alert"
          });
        }

        if (
          PF.config.environment !== "development" &&
          PF.config.force_ssl !== "false" &&
          location.protocol !== "https:"
        ) {
          window.location = document.URL.replace(/^http:/, "https:");
        }
      }
    };
    fetchCurrentProfileAndSetUp();
  }, [currentAccount.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSignedIn) {
      session.startHeartbeat();
    }
  }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useAccountSwitched();
  useChangeLanguage();
  usePusher();

  return { initialized: initialized && isTemplateConfigurationReady };
};
