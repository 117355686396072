import { UnregisterCallback } from "history";
import { Modal } from "PFComponents/modal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Typography } from "../typography";

type RouterPromptProps = {
  when: boolean;
  onOK?: () => boolean;
  onCancel?: () => boolean;
  title?: string;
  labelOk?: string;
  labelCancel?: string;
  message?: string;
};

export const RouterPrompt = ({
  when,
  onOK = () => true,
  onCancel = () => false,
  title,
  labelOk,
  labelCancel,
  message
}: RouterPromptProps) => {
  const history = useHistory();
  const { t } = useTranslation(["translation", "core"]);

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef<UnregisterCallback | null>(null);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return "false";
      }
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        unblockRef.current && unblockRef.current();
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        unblockRef.current && unblockRef.current();
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title ?? t("core:routerPrompt.title")}
      onOK={handleOK}
      onCancel={handleCancel}
      onClose={() => setShowPrompt(false)}
      labelOK={labelOk ?? t("translation:confirm")}
      labelCancel={labelCancel}
    >
      <Typography variant="bodyRegular" tag="span">
        {message ?? t("core:routerPrompt.message")}
      </Typography>
    </Modal>
  ) : null;
};
