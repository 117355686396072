import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import Hr from "PFComponents/hr";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import cssCommon from "./activity_details_common.module.scss";
import ActivityDetailsWatcher from "./activity_details_watcher";
import css from "./activity_details_watchers.module.scss";

const ActivityDetailsWatchers = () => {
  const {
    task,
    task: { watchers },
    shortlists
  } = useActivityPageContext();
  const { t } = useTranslation("activities");
  const isChatEnabled = useIsChatEnabled();

  const showWatchers = !!watchers && watchers.length > 0;

  if (!shortlists || !showWatchers) {
    return null; // wait for the shortlists to be feched to avoid flicker
  }

  return (
    <>
      <Hr />
      <div
        className={classNames(cssCommon.bigItem, css.root, { [css.isOwned]: task.coowned })}
        data-qa-id="ActivityDetailsWatchers"
      >
        <Typography variant="h5" noMargin>
          {t("show.details.whoIsInterested")}
        </Typography>
        {watchers.map((watcher) => (
          <ActivityDetailsWatcher key={watcher.profile.id} watcher={watcher} isChatEnabled={isChatEnabled} />
        ))}
      </div>
    </>
  );
};

export default ActivityDetailsWatchers;
