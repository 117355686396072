import classNames from "classnames";
import { LoadingDots } from "PFComponents/loading_dots";
import TrashIcon from "PFIcons/trash.svg";
import PropTypes from "prop-types";
import { Component } from "react";

import css from "./chat_attachments.module.scss";

class ChatAttachment extends Component {
  constructor(props) {
    super(props);

    this.state = { showName: false };

    const image = new Image();
    image.onerror = () => this.setState({ showName: true });
    image.src = props.attachment.fileObjectUrl;
  }

  render() {
    const { attachment, removeAttachment } = this.props;
    const { showName } = this.state;

    const { fake, fileObjectUrl } = attachment;

    return (
      <div className={classNames(css.item, { [css.fake]: fake })}>
        <div className={css.wrap}>
          {showName ? (
            <div className={css.name}>{attachment.file_name}</div>
          ) : (
            <div className={css.img} style={{ backgroundImage: `url(${fileObjectUrl})` }} />
          )}
        </div>
        {fake && (
          <div className={css.loading}>
            <LoadingDots />
          </div>
        )}
        {/* eslint-disable max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={css.remove} onClick={() => removeAttachment(attachment)}>
          <TrashIcon width={10} height={12} />
        </div>
      </div>
    );
  }
}

ChatAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  removeAttachment: PropTypes.func.isRequired
};

export default class ChatAttachments extends Component {
  render() {
    const { attachments, removeAttachment } = this.props;

    return (
      <div className={css.root}>
        {attachments.map((attachment) => (
          <ChatAttachment key={attachment.id} attachment={attachment} removeAttachment={removeAttachment} />
        ))}
      </div>
    );
  }
}

ChatAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  removeAttachment: PropTypes.func.isRequired
};
