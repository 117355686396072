import isEmpty from "lodash/isEmpty";
import { useBookingSiblings } from "PFApp/booking/parts/overview/detail_view/booking_detail/booking_detail_content/booking_siblings/use_booking_siblings";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { Booking } from "PFTypes";
import { useEffect, useState } from "react";

import { useBookingFormContext } from "../booking_form_context_provider";
import { BookingDataItem, BookingDataItemLimitation } from "../use_booking_form";
import { useCloneTitleMapper } from "./use_clone_title_mapper";
import { useDefaultBookingsMapper } from "./use_default_bookings_mapper";

const INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING = "initial-bookings-for-edit-or-clone-loading";

type UseInitialBookingsForEdit = {
  initialBooking?: Booking;
  enabled: boolean;
  isCloneMode: boolean;
};

type UseInitialBookingsForEditReturn = {
  bookings: BookingDataItem[];
  loading: boolean;
};

export const useInitialBookingsForEditOrClone = ({
  initialBooking,
  enabled,
  isCloneMode
}: UseInitialBookingsForEdit): UseInitialBookingsForEditReturn => {
  const { loadingState, setLoadingState } = useBookingFormContext();
  const [bookingItems, setBookingItems] = useState<BookingDataItem[]>();

  const mapCloneTitle = useCloneTitleMapper();
  const mapTitle = isCloneMode ? mapCloneTitle : undefined;
  const { mapAPIBookings, mapBookingTemplate } = useDefaultBookingsMapper({ withId: !isCloneMode, mapTitle });

  const isRepeatedBooking = !!initialBooking?.booking_template_id;

  const { data: siblingBookings, isFetching: isBookingSiblingsFetching } = useBookingSiblings({
    bookingId: initialBooking?.id,
    profileId: initialBooking?.profile_id,
    activityId: initialBooking?.activity_id ?? undefined,
    enabled: enabled && !isRepeatedBooking
  });

  const { data: bookingTemplate, isFetching: isBookingTemplateFetching } = useBookingTemplate(
    initialBooking?.booking_template_id,
    {
      enabled: enabled && isRepeatedBooking
    }
  );

  useEffect(() => {
    if (!enabled || !initialBooking) {
      return;
    }
    if (isRepeatedBooking) {
      if (bookingTemplate && !isBookingTemplateFetching) {
        const items = mapBookingTemplate(bookingTemplate);
        setBookingItems(
          items.filter(({ limitation }) =>
            isCloneMode ? limitation === BookingDataItemLimitation.None : true
          )
        );
      }
      return;
    } else {
      if (!isEmpty(siblingBookings) && !isBookingSiblingsFetching) {
        const items = mapAPIBookings(siblingBookings);
        setBookingItems(
          items.filter(({ limitation }) =>
            isCloneMode ? limitation === BookingDataItemLimitation.None : true
          )
        );
      }
      return;
    }
  }, [siblingBookings, bookingTemplate]);

  useEffect(() => {
    if (enabled && (isBookingSiblingsFetching || isBookingTemplateFetching)) {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]: true }));
    } else {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]: false }));
    }
  }, [isBookingSiblingsFetching, isBookingTemplateFetching]);

  return {
    bookings: bookingItems ?? [],
    loading: loadingState[INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]
  };
};
