import { SkillsList } from "PFApp/components/skills_list";
import { useMatchProfileInfo } from "PFApp/hooks/use_match_profile_info";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { InfoSection } from "../../components/info_section";
import { useMatchCardContext } from "../../match_card_context";
import { ActivityMatchCustomFields } from "../match_activity_custom_fields";
import { MatchActivitySkills } from "../match_activity_skills";
import css from "./match_card_body.module.scss";
import { ShortlistStateSection } from "./parts/ShortlistStateSection";

export const MatchCardBody = () => {
  const {
    isCompressed,
    custom_values: customValues,
    id: profileId,
    profile,
    animate
  } = useMatchCardContext();
  const { sideInfo } = useMatchProfileInfo(profile);
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });

  const relatedSkills = (customValues?.related || []).map((related) => {
    const { custom_value: customValue, ...other } = related;
    return {
      ...other,
      ...customValue
    };
  });

  return (
    <div className={css.cardBodyContainer}>
      <Divider color="paletteNeutral0" />
      <div className={css.cardBody}>
        <div className={css.sideArea}>
          <ActivityMatchCustomFields />
          {sideInfo.map(({ customType, value }) => (
            <InfoSection title={customType.display_as || customType.name} key={customType.id}>
              <Typography variant="labelBold">{value}</Typography>
            </InfoSection>
          ))}
        </div>
        <div className={css.mainArea}>
          {isCompressed && <MatchActivitySkills />}
          {relatedSkills.length > 0 && (
            <InfoSection title={t("relatedSkills")} tooltipContent={t("relatedSkillsTooltip")}>
              <SkillsList
                customFieldName="skills"
                skills={relatedSkills}
                profileId={profileId}
                onMoreLessButtonClick={animate}
              />
            </InfoSection>
          )}
          <ShortlistStateSection />
        </div>
      </div>
    </div>
  );
};
