import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag } from "PFTypes";
import { useEffect } from "react";

export const useChameleonProfilePayload = () => {
  const { data: currentProfile, isSignedIn } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();

  const isEnabled = useIsFeatureEnabled();

  useEffect(() => {
    if (!isEnabled(FeatureFlag.Chameleon) || !window.chmln) {
      return;
    }

    if (isSignedIn) {
      window.chmln.identify(currentProfile.id, {
        role: currentProfile.roles[0],
        permission_group: currentProfile.permissions_group.name,
        company_name: currentAccount.full_domain,
        grade: currentProfile.grade?.[0]?.["value"],
        location: currentProfile.location?.[0]?.["value"],
        service_line: currentProfile.service_line?.[0]?.["value"]
      });
    } else {
      let uid;
      try {
        uid = document.cookie.match(/\bvisitor-uid=([a-z0-9-]+)(;|$)/)[1];
      } catch (e) {
        uid = Math.random().toString(36).substring(2);
        document.cookie = `visitor-uid=${uid}; expires=Tue, Oct 13 2037 04:24:07 UTC; path=/;`;
      }
      window.chmln.identify(uid, { visitor: true });
    }
  }, [!!window.chmln, isSignedIn]);
};
