import { find } from "lodash";
import { useActivityCurrentUserRole } from "PFApp/activities/hooks";
import { useActiveTab } from "PFApp/activities/show/hooks/use_active_tab";
import { useTemplate } from "PFApp/hooks";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { isProfileActive } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { PermissionRule, ShortlistState } from "PFTypes";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";

const STATES_ALLOWING_FILL_AND_BOOK: Readonly<ShortlistState[]> = [
  "pending",
  "accepted",
  "assigned",
  "rejected"
];

type UseShortlistButtonsVisibilityReturn = Record<
  | "displayApprovalButtons"
  | "displayInviteButton"
  | "displayFillButton"
  | "displayFillAndBookButton"
  | "displayBookButton"
  | "displayUndoButton",
  boolean
>;

export const useShortlistButtonsVisibility = (): UseShortlistButtonsVisibilityReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const { role, profile, shortlist } = useRoleProfileActionButtonsContext();
  const { isOnMatchesTab } = useActiveTab();
  const { canProfileCreateBooking } = useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);
  const template = useTemplate(role?.template_id);

  const isAutoBookingCreationEnabled = !!template?.automations?.auto_create_bookings?.enabled;
  const hasAcceptForEndUser = useIsCurrentUserPermittedTo(PermissionRule.AcceptForEndUser);
  const hasShortlistWithoutOwnership = useIsCurrentUserPermittedTo(PermissionRule.ShortlistWithoutOwnership);
  const shortlistInvites = !!find(currentProfile.templates, ["id", role.template_id])?.shortlist_invites;
  const canBypassInvite = !shortlistInvites || hasAcceptForEndUser;

  const { isReviewer, isResourcer } = useActivityCurrentUserRole(role);
  const isDraft = role.state === "draft";
  const canBook = canProfileCreateBooking(profile.id) && hasWriteAccessToAtLeastOneCategory;
  const canFill = role.coowned && isResourcer;
  const isCandidateActive = isProfileActive(profile);
  const isPending = shortlist?.state === "pending";
  const reviewCompleted = role.review?.state === "complete";

  const displayPrerequisite = isCandidateActive && (role.coowned || isReviewer);

  const displayInviteButton = shortlist?.state === "accepted" && shortlistInvites && !isDraft;

  const displayFillButton =
    canFill &&
    ((shortlist?.state === "accepted" && !shortlistInvites) ||
      (shortlist?.state === "assigned" && hasAcceptForEndUser)) &&
    !isDraft;

  const displayFillAndBookButton =
    isAutoBookingCreationEnabled &&
    canFill &&
    canBook &&
    canBypassInvite &&
    (!shortlist || STATES_ALLOWING_FILL_AND_BOOK.includes(shortlist.state)) &&
    !isDraft;

  const displayBookButton = shortlist?.state === "filled" && canBook && !isDraft;

  const removeShortlistCondition = role.coowned || hasShortlistWithoutOwnership;
  const resetShortlistCondition = role.coowned || isReviewer;
  const isAllowedToUndo = isPending ? removeShortlistCondition : resetShortlistCondition;
  const isReviewerWithReviewCompleted = isReviewer && !role.coowned && reviewCompleted;
  const isUndoAllowedOnCurrentPage = isPending || !isOnMatchesTab;

  const displayUndoButton =
    isCandidateActive && isAllowedToUndo && !isReviewerWithReviewCompleted && isUndoAllowedOnCurrentPage;

  return {
    displayApprovalButtons: displayPrerequisite && isPending,
    displayInviteButton: displayPrerequisite && displayInviteButton,
    displayFillButton: displayPrerequisite && displayFillButton,
    displayFillAndBookButton: displayPrerequisite && displayFillAndBookButton,
    displayBookButton: displayPrerequisite && displayBookButton,
    displayUndoButton
  };
};
