import BadgesList from "PFComponents/badges_list/badges_list";
import { Button } from "PFComponents/button";
import { getCustomFieldValues } from "PFCore/helpers/activities";
import NoProfilesIcon from "PFIcons/no_profiles.svg";
import { Importance } from "PFTypes";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import css from "./matches_empty.module.scss";

const _getRequiredCfVals = (task) =>
  getCustomFieldValues(task)
    .filter(({ importance }) => importance && importance >= Importance.Essential)
    .sort((cf1, cf2) => cf1.importance < cf2.importance)
    .map(({ value }) => value);

const _getFiltersOfType = (filters, ...typeNames) => {
  const titles = [];

  Object.entries(filters).forEach(([key, config]) => {
    if (typeNames.includes(key)) {
      titles.push(config.title);
    }
  });
  return titles;
};

export const EmptyMatchesTip = ({ values, openFilters, i18nKey }) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.tryChanging" });

  if (values.length === 0) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    openFilters();
  };

  const ValuesList = () => (
    <div className={css.badgesList}>
      <BadgesList values={values.map((val) => ({ text: val }))} />
    </div>
  );

  return (
    <Trans
      i18nKey={i18nKey}
      t={t}
      values={{
        count: values.length
      }}
      components={[
        <Button key="0" kind="text" onClick={handleClick} className={css.button} />,
        <ValuesList key="1" />
      ]}
    />
  );
};

EmptyMatchesTip.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFilters: PropTypes.func.isRequired,
  i18nKey: PropTypes.oneOf(["importance", "range", "date", "location"])
};

export const MatchesEmpty = ({ task, matchesMeta, openFilters }) => {
  const { t } = useTranslation("activities");

  const anyFilters = matchesMeta.filters;
  const requiredVals = _getRequiredCfVals(task);
  const integerFilters = _getFiltersOfType(matchesMeta, "integer");
  const dateFilters = _getFiltersOfType(matchesMeta, "before_date", "date");
  const locationFilters = _getFiltersOfType(matchesMeta, "location");
  const anyTips =
    requiredVals.length + integerFilters.length + dateFilters.length + locationFilters.length > 0;

  return (
    <div className={css.container}>
      <NoProfilesIcon className={css.noProfilesIcon} />
      <br />
      {anyTips ? (
        <>
          <div>
            {t("show.matches.noMatchesAvailable")}
            <br />
            {t("show.matches.tryFollowingTips")}
          </div>
          <div className={css.tipsContainer}>
            <EmptyMatchesTip values={requiredVals} openFilters={openFilters} i18nKey="importance" />
            <EmptyMatchesTip values={integerFilters} openFilters={openFilters} i18nKey="range" />
            <EmptyMatchesTip values={dateFilters} openFilters={openFilters} i18nKey="date" />
            <EmptyMatchesTip values={locationFilters} openFilters={openFilters} i18nKey="location" />
          </div>
        </>
      ) : (
        <>
          {t("show.matches.noMatchesAvailable")}
          <br />
          {anyFilters && (
            <>
              {`${t("show.matches.pleaseTry")} `}
              <Button
                text={t("show.matches.addMoreAttributes")}
                kind="text"
                onClick={openFilters}
                className={css.button}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

MatchesEmpty.propTypes = {
  matchesMeta: PropTypes.shape({ filters: PropTypes.object }),
  task: PropTypes.shape({
    id: PropTypes.number,
    custom_fields: PropTypes.array
  }).isRequired,
  openFilters: PropTypes.func
};
