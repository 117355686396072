import { decamelizeKeys } from "humps";
import moment from "moment";
import { useScrollLeft } from "PFCore/helpers/use_scroll";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import Arrows from "./arrows";
import Bar from "./bar";
import Clash from "./clash";
import DayStrips from "./day_strips";
import css from "./gantt.module.scss";
import { getClashesFromBars, getDaysDifference } from "./gantt.utils";
import Header from "./header";
import HorizontalLines from "./horizontal_lines";
import markRows from "./mark_rows";
import TooltipContent from "./tooltip_content";
import Zoom from "./zoom";

const Gantt = ({
  rowItems,
  rowItemLabelComponent,
  getBarsFromItem = getAvailabilityBarsFromItem,
  startDate,
  availabilityInfo,
  infoPanel,
  monthsLimit,
  matchMonthsLimit,
  showToday,
  showClashes,
  getClashesFromRowItem,
  jobCodeDisplayAs,
  utmSource,
  style
}) => {
  const { windowWidth } = useWindowSize();
  const [viewportHeight, setViewportHeight] = useState(0);
  const [bars, setBars] = useState([]);
  const [clashes, setClashes] = useState([]);
  const [rowHeights, setRowHeights] = useState([]);
  const [dayWidth, setDayWidth] = useState(15);

  const viewportRef = useRef(); // for scrolling
  const peoplesElRef = useRef(); // for viewport height calculation
  const viewportScrollLeft = useScrollLeft(viewportRef.current);

  const dateFormatter = useDateFormatter();

  const startOfAxis = useMemo(() => moment.utc(startDate).startOf("day"), [startDate]);
  const endOfAxis = useMemo(
    () => moment(startOfAxis).add(monthsLimit, "months").endOf("month"),
    [startOfAxis, monthsLimit]
  );

  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);

  useEffect(() => {
    let newBars = [];
    const barsByItemId = {};
    let newClashes = [];

    rowItems.forEach((rowItem) => {
      newBars = newBars.concat(
        getBarsFromItem(rowItem, jobCodeDisplayAs, utmSource, dateFormatter, startOfAxis, endOfAxis) || []
      );
    });

    // bars position
    newBars.forEach((bar) => {
      bar.startTime = moment(moment.utc(bar.start)).startOf("day");
      bar.endTime = moment(moment.utc(bar.end)).endOf("day");

      const timeDiff = bar.endTime.diff(bar.startTime, "days") + 1;
      const leftDiff = bar.startTime.diff(startOfAxis, "days");

      bar.top = 0; // update later because you need to calculate overlaps first
      bar.left = leftDiff * dayWidth;
      bar.width = timeDiff * dayWidth;

      barsByItemId[bar.itemId] = barsByItemId[bar.itemId] || [];
      barsByItemId[bar.itemId].push(bar);
    });

    // OVERLAPS
    const newRowHeights = [];

    const INIT_TOP = 20;
    let _top = INIT_TOP;
    rowItems.forEach((rowItem) => {
      const bars = barsByItemId[rowItem.id] || [];
      const maxRowIndex = markRows(bars);
      bars.forEach((bar) => (bar.top = _top + bar.row * 30));
      const rowHeight = 20 + (maxRowIndex + 1) * 30 + 20;
      newRowHeights.push(rowHeight);
      _top += rowHeight;
    });

    const el = viewportRef.current;
    const scrollbarsSize = el ? el.offsetHeight - el.clientHeight : 0;
    const peopleHeight = peoplesElRef.current ? peoplesElRef.current.offsetHeight : 100;
    const headerHeight = 77; // constant so no need to read from DOM

    setViewportHeight(peopleHeight + headerHeight + scrollbarsSize);
    setRowHeights(newRowHeights);
    setBars(newBars);

    // clashes position
    if (showClashes) {
      rowItems.forEach((rowItem, index) => {
        const bars = barsByItemId[rowItem.id] || [];
        const rowClashes = getClashesFromBars(bars).concat(
          getClashesFromRowItem ? getClashesFromRowItem(rowItem) : []
        );
        const clashTop = newRowHeights.reduce((acc, row, i) => (i < index ? acc + row : acc), 0);
        const startOfAxisDate = startOfAxis.toDate();
        rowClashes.forEach((clash) => {
          clash.top = clashTop;

          const timeDiff = Math.floor(getDaysDifference(clash.start, clash.end)) + 1;
          const leftDiff = Math.floor(getDaysDifference(startOfAxisDate, clash.start));

          clash.left = leftDiff * dayWidth;
          clash.width = timeDiff * dayWidth;
        });

        newClashes = newClashes.concat(rowClashes);
      });
      setClashes(newClashes);
    }
  }, [windowWidth, JSON.stringify(rowItems), startDate, dayWidth, forceUpdateCounter]);

  // this is a hack but helps with height mismatch between panel and viewport resonably well
  useEffect(() => {
    window.setTimeout(() => setForceUpdateCounter(forceUpdateCounter + 1), 100);
  }, [windowWidth, rowItems, startDate, dayWidth]);

  const scrollWidth = useMemo(() => {
    const numOfDaysShown = endOfAxis.diff(startOfAxis, "days") + 1;
    return numOfDaysShown * dayWidth;
  }, [startOfAxis, endOfAxis, dayWidth]);

  return (
    <div className={css.root} style={style}>
      <HorizontalLines rowHeights={rowHeights} />
      <Arrows scrollEl={viewportRef.current} scrollLeft={viewportScrollLeft} />
      <Zoom dayWidth={dayWidth} setDayWidth={setDayWidth} />

      <div className={css.panel}>
        <div className={css.infoPanel}>{infoPanel}</div>

        <div className={css.people} ref={peoplesElRef}>
          {rowItems.map((rowItem, i) => (
            <div key={`${rowItem.id},${i}`} className={css.label} style={{ height: rowHeights[i] }}>
              {rowItemLabelComponent(rowItem)}
            </div>
          ))}
        </div>
      </div>

      <div className={css.viewport} ref={viewportRef} style={{ height: viewportHeight }}>
        <div className={css.viewportInner} style={{ width: scrollWidth }}>
          <div className={css.calendarHeader}>
            <Header startDate={startDate} dayWidth={dayWidth} calculatedMonthsLimit={monthsLimit} />
          </div>
          <DayStrips
            dayWidth={dayWidth}
            startDate={startDate}
            availabilityInfo={availabilityInfo}
            calculatedMonthsLimit={monthsLimit}
            matchMonthsLimit={matchMonthsLimit}
            showToday={showToday}
          />
          <div className={css.calendarBars}>
            {bars.map((bar) => (
              <Bar {...bar} key={bar.key} dayWidth={dayWidth} />
            ))}
          </div>
          {showClashes && (
            <div className={css.clashes}>
              {clashes.map((clash) => (
                <Clash {...clash} key={clash.key} dayWidth={dayWidth} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getAvailabilityBarsFromItem = (
  item,
  jobCodeDisplayAs,
  utmSource,
  dateFormatter,
  startOfAxisMoment,
  endOfAxisMoment
) => {
  const list = [];
  const profile = item.profile || item;
  const { utc, timeFormatString } = dateFormatter;

  if (!(profile.availability && profile.availability.bookings && profile.availability.bookings.length > 0)) {
    return list;
  }
  return profile.availability.bookings.reduce((result, bp, i) => {
    const start = bp.start_date || bp.startDate;
    const end = bp.end_date || bp.endDate;
    if (new Date(end) <= startOfAxisMoment.toDate() || new Date(start) >= endOfAxisMoment.toDate()) {
      return result;
    }
    // We always align <Bar>s to days even if it starts at some hour;
    // user can consult the tooltip for details
    const partialDayContent = {};

    (bp.partial || []).forEach((item) => {
      const index = utc(item.date).diff(utc(bp.start_date || bp.startDate), "day");

      return (partialDayContent[index] = utc()
        .startOf("day")
        .hour(0)
        .add(item.available_minutes || item.availableMinutes, "minutes")
        .format(timeFormatString));
    });

    const newBar = {
      kind: bp.kind,
      itemId: item.id,
      key: `${item.id} ${i}`,
      bookingCategoryId: bp.booking_category_id || bp.bookingCategoryId,
      start,
      end,
      load: bp.load, // 0..100 where both null/undefined and 100 is fully loaded
      partial: bp.partial,
      partialDayContent: partialDayContent,
      tooltipContent: (
        <TooltipContent
          // TODO: [PROF-5099] remove after profile is camelized across the app
          booking={decamelizeKeys(bp)}
          jobCodeDisplayAs={jobCodeDisplayAs}
          utmSource={utmSource}
        />
      )
    };

    return [...result, newBar];
  }, []);
};

Gantt.propTypes = {
  rowItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  ).isRequired,
  startDate: PropTypes.string.isRequired,
  availabilityInfo: PropTypes.object.isRequired, // see AvailabilityInfoPanel for details
  rowItemLabelComponent: PropTypes.func.isRequired,
  getBarsFromItem: PropTypes.func,
  getClashesFromRowItem: PropTypes.func,
  infoPanel: PropTypes.node.isRequired,
  monthsLimit: PropTypes.number.isRequired,
  matchMonthsLimit: PropTypes.number,
  jobCodeDisplayAs: PropTypes.string,
  utmSource: PropTypes.string,

  style: PropTypes.object,
  showToday: PropTypes.bool,
  showClashes: PropTypes.bool
};

export default Gantt;
