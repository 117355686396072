import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import some from "lodash/some";
import { BookingFormValues } from "PFApp/booking/components/booking_form/booking_form_provider";

export const isBookingFormForBookingTemplateCreateModeValid = (values: BookingFormValues): boolean => {
  const { workforceMember, bookings: formBookings } = values;
  if (!workforceMember || isEmpty(formBookings)) {
    return false;
  }
  const areSomeBookingsInvalid = formBookings.some((formBooking) =>
    some(["startDate", "endDate", "startTime", "endTime", "repeatDays"], (path) =>
      isNil(get(formBooking, path))
    )
  );
  const areGlobalValuesInvalid = some(
    ["globalCategory", "globalOverridesNonWorkingTime", "globalOverridesDiaryTime"],
    (path) => isNil(get(values, path))
  );
  return !areSomeBookingsInvalid && !areGlobalValuesInvalid;
};

export const isBookingFormForBookingTemplateEditModeValid = (values: BookingFormValues): boolean => {
  const { workforceMember, bookings: formBookings } = values;
  if (!workforceMember || isEmpty(formBookings)) {
    return false;
  }
  return !formBookings.some((formBooking) =>
    some(
      [
        "bookingTemplateId",
        "startDate",
        "endDate",
        "startTime",
        "endTime",
        "repeatDays",
        "category",
        "overridesNonWorkingTime",
        "overridesDiaryTime"
      ],
      (path) => isNil(get(formBooking, path))
    )
  );
};
