import { Experience } from "PFTypes";

import { ExperienceColorVariant, ExperienceRadialProps } from "./parts/experience_radial";

const getExperienceSegmentIndex = (experience: Experience): number =>
  ({
    [Experience.Basic]: 0,
    [Experience.Intermediate]: 1,
    [Experience.Advanced]: 2
  }[experience]);

const getIsMoreExperienceExpected = (experience: Experience | null, expectedExperience: Experience) =>
  !experience || experience < expectedExperience;

const getIsLessExperienceExpected = (experience: Experience | null, expectedExperience: Experience) =>
  experience && experience > expectedExperience;

export const getColorVariantsForActiveExperience = (
  experience: Experience | null
): ExperienceRadialProps["colorVariants"] => {
  if (!experience) {
    return [ExperienceColorVariant.Neutral, ExperienceColorVariant.Neutral, ExperienceColorVariant.Neutral];
  }

  return [
    [Experience.Basic, Experience.Intermediate, Experience.Advanced].includes(experience)
      ? ExperienceColorVariant.Active
      : ExperienceColorVariant.Neutral,
    [Experience.Intermediate, Experience.Advanced].includes(experience)
      ? ExperienceColorVariant.Active
      : ExperienceColorVariant.Neutral,
    [Experience.Advanced].includes(experience)
      ? ExperienceColorVariant.Active
      : ExperienceColorVariant.Neutral
  ];
};

export const mapColorVariantsForMissingExperience = (
  colorVariants: ExperienceRadialProps["colorVariants"],
  expectedExperience: Experience,
  experience: Experience | null
) =>
  colorVariants.map((color, index) => {
    const isMoreExperienceExpected = getIsMoreExperienceExpected(experience, expectedExperience);

    if (
      color === ExperienceColorVariant.Neutral &&
      isMoreExperienceExpected &&
      index <= getExperienceSegmentIndex(expectedExperience)
    ) {
      return ExperienceColorVariant.Missing;
    }

    return color;
  }) as ExperienceRadialProps["colorVariants"];

export const mapColorVariantsForExtraExperience = (
  colorVariants: ExperienceRadialProps["colorVariants"],
  expectedExperience: Experience,
  experience: Experience
) =>
  colorVariants.map((color, index) => {
    if (
      color === ExperienceColorVariant.Active &&
      expectedExperience < experience &&
      index > getExperienceSegmentIndex(expectedExperience)
    ) {
      return ExperienceColorVariant.Extra;
    }

    return color;
  }) as ExperienceRadialProps["colorVariants"];

export const getExperienceIcon = (
  isMissing: boolean = false,
  experience: Experience | null,
  expectedExperience: Experience | null
): ExperienceRadialProps["icon"] => {
  if (isMissing) {
    return "cross";
  }

  if (experience === expectedExperience) {
    return "check";
  }

  if (!expectedExperience) {
    return null;
  }

  const isMoreExperienceExpected = getIsMoreExperienceExpected(experience, expectedExperience);
  const isLessExperienceExpected = getIsLessExperienceExpected(experience, expectedExperience);

  if (isMoreExperienceExpected) {
    return "arrow-down";
  }

  if (isLessExperienceExpected) {
    return "arrow-up";
  }
};
