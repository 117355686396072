import { useActivityTemplateType } from "PFApp/activities/hooks";
import useIsAuditPlannerPermitted from "PFApp/use_is_audit_planner_permitted";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity } from "PFTypes";
import { useCallback } from "react";

export const useActivityLink = ({
  activity,
  parentActivity
}: {
  activity?: Activity;
  parentActivity?: Activity;
}) => {
  const { data: currentProfile } = useCurrentProfile();
  const isAuditPlannerPermitted = useIsAuditPlannerPermitted();
  const { isAudit: isAuditActivity, isEngagement } = useActivityTemplateType(activity);
  const isAuditEngagement = isAuditActivity && isEngagement;

  const shouldDisplayActivityLink = useCallback(() => {
    if (!activity) {
      return false;
    } else if (!isAuditActivity) {
      return true;
    } else if (!isAuditPlannerPermitted) {
      return false;
    } else {
      const isActivityPublic = !activity.private;
      const isOwnerOfActivity = (activity.owners || []).some(
        ({ profile_id: profileId }) => profileId === currentProfile.id
      );
      const hasAccessToActivity = isActivityPublic || isOwnerOfActivity;
      const isParentActivityPublic = parentActivity && !parentActivity.private;
      const isOwnerOfParentActivity =
        parentActivity &&
        (parentActivity.owners || []).some(({ profile_id: profileId }) => profileId === currentProfile.id);
      const hasAccessToParentActivity = isParentActivityPublic || isOwnerOfParentActivity;
      return hasAccessToActivity && (!parentActivity || hasAccessToParentActivity);
    }
  }, [activity, parentActivity, isAuditActivity, isAuditPlannerPermitted, currentProfile.id]);

  if (!shouldDisplayActivityLink()) {
    return null;
  }

  return isAuditEngagement ? `/audit_planner/control_centre/${activity!.id}` : `/activities/${activity!.id}`;
};
