import AppContext from "PFApp/app_context";
import NotificationsIcon from "PFIcons/bell.svg";
import { APP_LAYOUT_ACTIONS } from "PFReducers/app_layout_reducer";
import { NOTIFICATIONS_ASYNC_ACTIONS } from "PFReducers/notifications_reducer";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";

export const NOTIFICATIONS_PANEL_COLLAPSED = "notifications-collapsed";

export const NotificationsNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  const {
    dispatch,
    store: {
      appLayout: { leftActionPanel },
      notifications: {
        notifications: {
          meta: { total_unread: totalUnread }
        }
      }
    }
  } = useContext(AppContext);

  const fetchCounter = () => {
    dispatch({
      type: NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION,
      payload: { perPage: 0 }
    });
  };
  useEffect(() => {
    fetchCounter();
  }, []);

  const isOpen = !leftActionPanel.collapsed && leftActionPanel.id === "notifications_panel";

  return (
    <NavItem
      icon={<NotificationsIcon width={20} height={20} />}
      name={t("notifications")}
      id="notifications"
      onClick={() => {
        if (isOpen) {
          dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: true, userDriven: true }));
        } else {
          dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: false, userDriven: true }));
        }
      }}
      forceOpen={isOpen}
      counter={totalUnread}
      {...props}
    />
  );
};

export const notificationsNavItemOpenDispatchOptions = ({ collapsed, userDriven = false }) => {
  if (userDriven) {
    storage.setItem(NOTIFICATIONS_PANEL_COLLAPSED, collapsed);
  }

  return {
    type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_LEFT_ACTIONS_PANEL,
    payload: { id: "notifications_panel", collapsed: !!collapsed }
  };
};
