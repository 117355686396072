import ChatWithIcon from "PFApp/activities/show/parts/chat_with_icon";
import useChat from "PFApp/use_chat";
import api from "PFCore/api";
import { isMsTeams, openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ProfileMinimized } from "PFTypes";
import { MouseEventHandler, useMemo } from "react";

export const useProfileChatIcon = (
  profile: ProfileMinimized,
  Icon = ChatWithIcon
): { handleClick: MouseEventHandler; Icon?: React.FC<{ width?: number; height?: number }> } => {
  const { data: currentProfile } = useCurrentProfile();

  const { renderChat } = useChat();

  const handleIconClick = (event) => {
    event.preventDefault();

    if (isMsTeams()) {
      return openChatInMsTeams(profile.email);
    }

    const uniqueProfileTargetId = Math.min(profile.id, currentProfile.id);

    const url = "chat/conversations";
    const target = `gid://profinda-api/Profile/${uniqueProfileTargetId}`;
    const createTarget = `gid://profinda-api/Profile/${profile.id}`;
    const data = { target, oneToOneProfileId: profile.id };

    api({ url, params: data }).then(({ entries }) => {
      if (entries && entries.length > 0) {
        renderChat(entries[0].id);
      } else {
        api({ url, method: "POST", data: { target: createTarget } }).then((response) => {
          renderChat(response.id);
        });
      }
    });
  };

  const IconOrEmpty = useMemo(() => {
    const isCurrentProfile = currentProfile?.id === profile.id;
    return isCurrentProfile ? undefined : Icon;
  }, [currentProfile, profile, Icon]);

  return {
    handleClick: handleIconClick,
    Icon: IconOrEmpty
  };
};
