import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import {
  CustomValueBulkResponse,
  CustomValueMergeResponse
} from "PFCore/hooks/queries/custom_fields/admin/custom_values_bulk/response";
import { ApiRoute } from "PFCore/utilities/routes";
import { CustomValue, OrderOption } from "PFTypes";
import { Id } from "PFTypes/id";

import { CustomValuesByTypeResponse } from "../../hooks/queries/custom_fields/admin";
import { CreateCustomValuePayload } from "./custom_fields";

export type FetchCtCustomValuesParams = {
  order?: Partial<OrderOption>;
  filters?: Record<string, unknown>;
};

type FetchParams = {
  page: number;
  perPage: number;
  order?: OrderOption;
};

const rootPath = (customTypeId: Id) => `/api/admin/custom_types/${customTypeId}/custom_values`;

export const fetchCustomValuesByType = (
  customTypeId: Id,
  page: number,
  perPage: number = 30,
  params: FetchCtCustomValuesParams
) => {
  const fetchParams = {
    page,
    perPage,
    ...params
  } as FetchParams;

  return fetchPaginatedCollection<CustomValuesByTypeResponse>(ApiRoute(rootPath(customTypeId)), fetchParams);
};

export const fetchCustomValue = (customTypeId: Id, customValueId: Id): Promise<CustomValue> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/${customValueId}`,
    method: "GET"
  });

export const createCustomValueByType = (
  customTypeId: Id,
  data: CreateCustomValuePayload
): Promise<CustomValue> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values`,
    method: "POST",
    data
  });

export const updateCustomValue = (
  customTypeId: Id,
  customValueId: Id,
  data: CreateCustomValuePayload
): Promise<CustomValue> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/${customValueId}`,
    method: "PUT",
    data
  });

export const approveCustomValuesByType = (customTypeId: Id, ids: Id[]): Promise<CustomValueBulkResponse> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/bulk/approve`,
    method: "PUT",
    data: { ids }
  });

export const blacklistCustomValuesByType = (customTypeId: Id, ids: Id[]): Promise<CustomValueBulkResponse> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/bulk/block`,
    method: "PUT",
    data: { ids }
  });

export const deleteCustomValuesByType = (customTypeId: Id, ids: Id[]) =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/bulk/delete`,
    method: "PUT",
    data: { ids }
  });

export const mergeCustomValuesByType = (
  customTypeId: Id,
  targetCustomValueId: Id,
  ids: Id[]
): Promise<CustomValueMergeResponse> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/bulk/merge`,
    method: "PUT",
    data: {
      ids,
      event: "merge",
      parameters: { targetCustomValueId }
    }
  });

export const exportCustomValuesByType = (customTypeId: Id, ids: Id[]): Promise<string> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/export.csv`,
    method: "POST",
    data: { filters: { fields: { id: ids } } }
  });

export const resetCustomValueChangeByType = (customTypeId: Id, ids: Id[]): Promise<CustomValueBulkResponse> =>
  api({
    url: `admin/custom_types/${customTypeId}/custom_values/bulk/reset`,
    method: "PUT",
    data: { ids }
  });
