import { flatten, last } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { normalizeList } from "PFApp/search/helpers/normalize_keywords";
import KeywordsSliders from "PFApp/search/parts/keywords_sliders";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useActivityInvalidate, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

const ActivityKeywords = ({ typesOpened = [], onChangeTypeOpen }) => {
  const { customTypes } = useCustomTypes();
  const { task } = useActivityPageContext();
  const growlErrors = useErrorsGrowl();

  const { isAutoRefresh, setApplyChangesCallback } = useFiltersContext();
  const [taskCustomFields, setTaskCustomFields] = useState(task.custom_fields);

  const { id, template_id } = task;

  const { update: updateActivity } = useActivityUpdate(id);
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const { invalidate: invalidateCustomValues } = useCustomValuesInvalidate();

  const [errors, setErrors] = useState({});

  const matchableTypes = useMatchableTypes(template_id);

  const choosenKeywords = useMemo(
    () =>
      normalizeList(
        flatten(taskCustomFields.map(({ values, type }) => (values || []).map((val) => ({ ...val, type })))),
        customTypes,
        matchableTypes,
        []
      ),
    [customTypes, matchableTypes, taskCustomFields]
  );

  useEffect(() => setTaskCustomFields(task.custom_fields), [task.custom_fields]);

  const saveTask = (data) =>
    updateActivity(data)
      .then(() => {
        invalidateCustomValues();
      })
      .catch(({ response }) => {
        const errors = {};
        (response.errors || [])
          .filter((error) => error.source?.pointer?.includes("attribute"))
          .forEach((error) => {
            const key = last(error.source.pointer.split("/"));
            if (key) {
              errors[key] = error.detail;
            }
          });
        setErrors(errors);
        // restore task in case chosen keywords are removed and unable to select
        invalidateActivities([id]);
        growlErrors(response, { display_all: true, version: "v2" });
      }); //Patch gives API error on _sort now?

  const handleCustomFieldChange = (typeName, process) => {
    const newCustomFields = [...taskCustomFields];
    const customField = newCustomFields.find((cf) => cf.type?.name === typeName);
    const newValues = process(customField?.values);

    if (customField) {
      customField.values = newValues || [];
    } else {
      newCustomFields.push({ type: { name: typeName }, values: newValues || [] });
    }
    setTaskCustomFields(newCustomFields);
    isAutoRefresh
      ? saveTask({ custom_fields: newCustomFields })
      : setApplyChangesCallback("activityKeywords", () => saveTask({ custom_fields: newCustomFields }));
  };

  return (
    <KeywordsSliders
      search={false}
      matchableTypes={matchableTypes}
      style={{ marginBottom: -30 }}
      choosenKeywords={choosenKeywords}
      remove={(keyword) => {
        handleCustomFieldChange(keyword.type.name, (values) => values.filter(({ id }) => id !== keyword.id));
      }}
      pushWithNewImportance={(keyword, importance) => {
        handleCustomFieldChange(keyword.type.name, (values) => {
          const value = values.find(({ id }) => id === keyword.id);
          value.importance = importance;
          return values;
        });
      }}
      handleAddOne={(keyword) => {
        handleCustomFieldChange(keyword.type.name, (values) => [
          ...(values || []),
          { id: keyword.id, global_id: keyword.globalId, value: keyword.value || keyword.text }
        ]);
      }}
      errors={errors}
      typesOpened={typesOpened}
      onChangeOpen={onChangeTypeOpen}
    />
  );
};

ActivityKeywords.propTypes = {
  typesOpened: PropTypes.arrayOf(PropTypes.string),
  onChangeTypeOpen: PropTypes.func
};

export default ActivityKeywords;
