import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFCore/types";

import { customValueKeys } from "./query_keys";

export const useCustomValueInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id: Id, profileId: Id) => {
      queryClient.invalidateQueries(customValueKeys.single(id, profileId));
    }
  };
};
