import { InlineCalendar } from "PFComponents/calendar/inline_calendar";

import ComponentPropTypes from "./component_prop_types";

const InlineCalendarWrapper = ({
  property: { type },
  value,
  label,
  onChange,
  qaId,
  style,
  error,
  required
}) => (
  <InlineCalendar
    selectedDate={value}
    label={label}
    qaId={qaId}
    style={{ ...style, marginBottom: 30 }}
    error={error}
    required={required}
    showTime={type === "date-time"}
    shouldCloseOnSelection={type !== "date-time"}
    handleChange={onChange}
    letClear
  />
);

InlineCalendarWrapper.propTypes = ComponentPropTypes;

export default InlineCalendarWrapper;
