import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { AccessLevel, PermissionsGroup, Profile } from "PFTypes";
import { useMemo } from "react";

export const useIsAvailabilityPermitted = (
  profile: Profile | null = null,
  accessLevel: AccessLevel[] = [AccessLevel.ReadWrite, AccessLevel.ReadOnly],
  permissionsGroup: PermissionsGroup | null = null
) => {
  const { data: currentProfile } = useCurrentProfile();

  const selectedProfile = profile || currentProfile;

  const availabilityPermissions = useMemo(() => {
    const internalPermissionsGroup = permissionsGroup || selectedProfile.permissions_group || {};

    return accessLevel.includes(
      (internalPermissionsGroup.cross_group_permissions ||
        currentProfile.permissions_group.cross_group_permissions ||
        // @ts-ignore
        {})[internalPermissionsGroup.id]?.profile_fields_access_levels?.availability
    );
  }, [profile, currentProfile]);

  return availabilityPermissions;
};

export default useIsAvailabilityPermitted;
