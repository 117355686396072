import { filter } from "lodash";
import { Activity, ActivityCustomValue, ActivityPureCustomValue } from "PFTypes";

export const getActivityCustomFieldValues = (
  activity: Pick<Activity, "custom_fields">,
  customFieldName: string
): ActivityPureCustomValue[] =>
  activity.custom_fields.find((cf) => cf.type.name === customFieldName)?.values || [];

export const getActivityCustomFieldFilteredValues = (
  role: Activity,
  customFieldName: string,
  filters: Partial<ActivityCustomValue>
) => {
  const activityCustomValues = getActivityCustomFieldValues(role, customFieldName);

  return filter(activityCustomValues, filters) as ActivityPureCustomValue[];
};
