import isEmpty from "lodash/isEmpty";
import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import { Booking, BookingTemplate } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useBookingsTotal } from "../../../../use_bookings_total";
import css from "./overall_duration.module.scss";
import { StartEndDates } from "./start_end_dates";

type BookingSiblingsProps = {
  bookings: Booking[];
  bookingTemplate?: BookingTemplate;
};

export const OverallDuration = ({ bookings = [], bookingTemplate }: BookingSiblingsProps) => {
  const { t } = useTranslation("bookingModule");

  const minStartDate = bookingTemplate
    ? bookingTemplate.start_date
    : Math.min(...bookings.map(({ start_date: startDate }) => new Date(startDate).getTime()));
  const maxEndDate = bookingTemplate
    ? bookingTemplate.end_date
    : Math.max(...bookings.map(({ end_date: endDate }) => new Date(endDate).getTime()));
  const { total, warning } = useBookingsTotal({ bookings });

  if (isEmpty(bookings)) {
    return null;
  }

  const totalValue = total ? total.hours : "-";

  return (
    <div className={css.root}>
      <BookingDetailItem
        label={t("details.duration")}
        value={<StartEndDates startDate={minStartDate} endDate={maxEndDate} />}
      />
      <BookingDetailItem label={t("details.totalHours")} value={totalValue} infoContent={warning} />
    </div>
  );
};
