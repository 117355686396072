import classNames from "classnames";
import { PropsWithChildren } from "react";

import css from "./typography.module.scss";
import { VariantBody, VariantHeader, VariantLabel } from "./typography.types";
import { VARIANTS } from "./typography.utils";

type CommonProps = {
  id?: string;
  className?: string;
  noMargin?: boolean;
  qaId?: string;
  clipOverflow?: boolean;
  wrapText?: boolean;
  title?: string;
};

type ConditionalVariantBody =
  | {
      variant: VariantBody;
      tag?: "a";
      href?: string;
      htmlFor?: never;
    }
  | {
      variant: VariantBody;
      tag?: "span" | "p";
      href?: never;
      htmlFor?: never;
    };

type ConditionalVariantLabel =
  | {
      variant: VariantLabel;
      tag?: "a";
      href?: string;
      htmlFor?: string;
    }
  | {
      variant: VariantLabel;
      tag?: "span" | "p" | "label";
      href?: never;
      htmlFor?: string;
    };

type ConditionalProps =
  | ConditionalVariantBody
  | ConditionalVariantLabel
  | {
      variant: VariantHeader;
      tag?: never;
      href?: never;
      htmlFor?: never;
    };

type TypographyProps = CommonProps & ConditionalProps;

export const Typography = ({
  id,
  variant,
  tag,
  href,
  htmlFor,
  className,
  noMargin = false,
  qaId,
  clipOverflow = false,
  wrapText = false,
  title,
  children
}: PropsWithChildren<TypographyProps>): JSX.Element => {
  const { variantTag, className: variantClassName } = VARIANTS[variant];
  const Element = tag ?? variantTag;

  return (
    <Element
      id={id}
      className={classNames(
        variantClassName,
        { [css.noMargin]: noMargin, [css.clipOverflow]: clipOverflow, [css.wrap]: wrapText },
        className
      )}
      href={href}
      htmlFor={htmlFor}
      data-qa-id={qaId}
      title={title}
    >
      {children}
    </Element>
  );
};
