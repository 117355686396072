import SimplePagination from "PFComponents/pagination/simple_pagination";
import { useTranslation } from "react-i18next";

import { UseInterestsReturn } from "../../query/use_interests";
import { PageSection } from "../page_section";
import Interests from "./interests";

type InterestsSectionProps = {
  className: string;
  updateHighlight: (recalc?: boolean) => void;
  interestsCollection: UseInterestsReturn;
};

const InterestsSection = ({
  interestsCollection,
  className,
  updateHighlight
}: InterestsSectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const { isLoading, entries, currentPage, totalPages, setCurrentPage } = interestsCollection;

  return (
    <PageSection
      title={t("show.parts.interestedIn")}
      className={className}
      style={{ opacity: isLoading ? 0.5 : 1, marginBottom: 20, minHeight: 938 }}
    >
      <Interests interests={entries} updateHighlight={updateHighlight} />
      <SimplePagination
        totalPages={totalPages}
        currentPage={currentPage}
        handleClick={setCurrentPage}
        scrollOnClick={false}
      />
    </PageSection>
  );
};

export default InterestsSection;
