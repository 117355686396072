import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCertificate } from "PFApp/profiles/services/api";
import { Id, MutationOptions } from "PFTypes";
import { Certificate, CertificatePayload } from "PFTypes/certificate";

import { certificateKeys } from "./query_keys";

export const useCertificateUpdate = ({ onSuccess, ...queryParams }: MutationOptions<Id>) => {
  const queryClient = useQueryClient();

  return useMutation<CertificatePayload, unknown, any>({
    mutationFn: (payload: CertificatePayload): any => updateCertificate(payload),
    onSuccess: (resp: Certificate, vars, context) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.all() });
      queryClient.invalidateQueries({ queryKey: certificateKeys.single(resp.id) });
      onSuccess?.(resp, vars, context);
    },
    ...queryParams
  });
};
