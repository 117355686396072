import classNames from "classnames";
import { AssessmentCheckmarks } from "PFComponents/badges_list/assessment_checkmarks";
import { DropDownItem } from "PFComponents/dropdown/dropdown_item";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import CheckIcon from "PFIcons/check_2.svg";
import CheckCredlyIcon from "PFIcons/check_credly.svg";
import CloseIcon from "PFIcons/close.svg";
import DragIcon from "PFIcons/drag.svg";
import DumbbellIcon from "PFIcons/dumbbell.svg";
import StarIcon from "PFIcons/star_2.svg";
import VerticalDots from "PFIcons/vertical_dots.svg";
import { ProfileCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import { coreSkillsLimit, developmentalSkillsLimit } from "./constants";
import css from "./section_skills.module.scss";

const getStaticDropdownItemClassName = (
  optionId: string,
  staticSelectedOptionId?: string
): string | undefined => {
  if (!staticSelectedOptionId) {
    return undefined;
  }
  return staticSelectedOptionId !== optionId ? css.staticDropdownItemNoHover : css.staticDropdownItem;
};

export type SkillProps = {
  addToLog?: Function;
  skill: Partial<ProfileCustomValue>;
  coreSkillsCount?: number;
  developmentalSkillsCount?: number;
  draggableProps?: { provided: any; snapshot: any };
  /**
   * staticProps - to display static component in the info tooltip to inform user how to take an action
   */
  staticProps?: Partial<{
    cursorPointer: boolean;
    keepOptionsOpen: boolean;
    selectedOptionId: string;
  }>;
  classes?: Record<string, string>;
};

export const Skill = ({
  skill,
  addToLog,
  coreSkillsCount = 0,
  developmentalSkillsCount = 0,
  staticProps,
  draggableProps,
  classes = {}
}: SkillProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.sectionSkills" });
  const { data: currentAccount } = useCurrentAccount();

  const {
    cursorPointer: staticCursorPointer,
    keepOptionsOpen: staticKeepOptionsOpen,
    selectedOptionId: staticSelectedOptionId
  } = staticProps || {};

  const actionOptions = [
    {
      id: "tag_as_core",
      disabled: !skill.experience || (coreSkillsCount >= coreSkillsLimit && !skill.top),
      item: () => skill.experience && addToLog?.({ id: skill.id, data: { top: !skill.top }, type: "ranked" }),
      displayElement: (
        <div className={css.skillAction}>
          <span className={css.iconWrap}>
            <StarIcon className={classNames(css.icon, css.core)} height={15} />
          </span>
          <span
            className={css.skillActionText}
            title={skill.top ? t("untagAsCoreSkill") : t("tagAsCoreSkill")}
          >
            {skill.top ? t("untagAsCoreSkill") : t("tagAsCoreSkill")}
          </span>
        </div>
      )
    },
    {
      id: "tag_as_developmental",
      disabled:
        !skill.experience || (developmentalSkillsCount >= developmentalSkillsLimit && !skill.developmental),
      item: () =>
        skill.experience &&
        addToLog?.({
          id: skill.id,
          data: {
            developmental: !skill.developmental
          },
          type: "ranked"
        }),
      displayElement: (
        <div className={css.skillAction}>
          <span className={css.iconWrap}>
            <DumbbellIcon className={classNames(css.icon, css.developmental)} height={13} />
          </span>
          <span
            className={css.skillActionText}
            title={skill.developmental ? t("untagAsDevelopmentSkill") : t("tagAsDevelopmentSkill")}
          >
            {skill.developmental ? t("untagAsDevelopmentSkill") : t("tagAsDevelopmentSkill")}
          </span>
        </div>
      )
    },
    {
      id: "delete",
      item: () =>
        addToLog?.({
          id: skill.id,
          data: { experience: 0, interest: false, top: false, developmental: false },
          type: "delete"
        }),
      displayElement: (
        <div className={css.skillAction}>
          <span className={css.iconWrap}>
            <CloseIcon className={classNames(css.icon, css.close)} height={20} />
          </span>
          <span className={css.skillActionText} title={t("deleteSkill")}>
            {t("deleteSkill")}
          </span>
        </div>
      )
    }
  ];

  const tooltipContent = (
    <ul className={classNames(css.actionsList, classes.actionsList)}>
      {actionOptions.map((option) => (
        <DropDownItem
          key={option.id}
          value={option}
          handleChange={(fn) => fn && !option.disabled && fn()}
          selected={staticSelectedOptionId === option.id}
          dropDownItemClassName={getStaticDropdownItemClassName(option.id, staticSelectedOptionId)}
        />
      ))}
    </ul>
  );

  const credlySource = skill.assessment_source === "credly";
  const skillLabel = String(skill.text || skill.value || "");
  const isValidatedIconVisible = currentAccount.profile_view_customization.validated_skill_text;
  const { provided, snapshot } = draggableProps || {};
  const verticalDots = (
    <span className={classNames(css.icon)}>
      <VerticalDots
        className={classNames(css.optionsIcon, { [css.iconNonClickable]: !!staticProps })}
        height={17}
        width={20}
      />
      {staticCursorPointer && <Icon name="mouse-cursor" className={css.staticCursorPointerIcon} />}
    </span>
  );

  return (
    <>
      <div
        className={classNames(css.skill, { [css.dragging]: draggableProps && snapshot.isDragging })}
        ref={draggableProps && provided.innerRef}
        {...(draggableProps ? provided.draggableProps : {})}
      >
        {isValidatedIconVisible && (
          <AssessmentCheckmarks title={skillLabel}>
            {skill.assessed && (
              <span title={t("assessment.verified")}>
                {credlySource ? (
                  <CheckCredlyIcon className={css.assessedIcon} height={16} />
                ) : (
                  <CheckIcon className={css.assessedIcon} height={16} />
                )}
              </span>
            )}
            {skill.em_verified && (
              <span title={t("assessment.feedback")}>
                <CheckIcon height={16} className={css.feedbackIcon} />
              </span>
            )}
          </AssessmentCheckmarks>
        )}
        <span
          className={classNames(css.icon, css.drag)}
          {...(draggableProps ? provided.dragHandleProps : {})}
        >
          <DragIcon height={20} fill="#D5D5D5" />
        </span>
        <span className={css.text} title={skillLabel}>
          {skillLabel}
        </span>
        {skill.top && (
          <span className={classNames(css.icon, css.core)} title={t("coreSkill")}>
            <StarIcon height={15} />
          </span>
        )}
        {skill.developmental && (
          <span className={classNames(css.icon, css.developmental)} title={t("developmentalSkill")}>
            <DumbbellIcon height={15} />
          </span>
        )}
        {staticKeepOptionsOpen ? (
          verticalDots
        ) : (
          <Tooltip
            key={`${skill.top}_${skill.developmental}_${skill.experience}`}
            trigger="click"
            theme="pf-dropdown"
            hideOnClick
            interactive
            content={tooltipContent}
            placement="bottom-end"
            className={css.skill}
          >
            {verticalDots}
          </Tooltip>
        )}
      </div>
      {staticKeepOptionsOpen && <div className={classes.tooltipContentContainer}>{tooltipContent}</div>}
    </>
  );
};
