import ActivityDetailsMoreInfo from "PFApp/components/activity_commons/activity_details_more_info";
import ActivityDetailsVacancies from "PFApp/components/activity_commons/activity_details_vacancies";
import { ActivityProfilesAvatarStack } from "PFApp/components/activity_profiles_avatar_stack";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { Button } from "PFComponents/button";
import Hr from "PFComponents/hr";
import { useRoleName } from "PFCore/helpers/activities";
import { useInvites } from "PFCore/hooks/queries/invites/use_invites";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityPageContext } from "../activity_page_context";
import { ActivityDetailsCategorySection } from "../details/activity_details_category_section/activity_details_category_section";
import { ActivityDetailsSkills } from "../details/activity_details_skills";
import ActivityDetailsToggleableDesc from "../details/activity_details_toggleable_desc";
import AddOwnersModal from "./add_owners_modal";
import ChangeAssigneeModal from "./change_assignee_modal";
import ChatsSectionMainChat from "./chats_section_main_chat";
import ChatsSectionOtherChat from "./chats_section_other_chats";
import { useActivityChat } from "./hooks/use_activity_chat";
import { useAssignee } from "./hooks/use_assignee";
import { useOwners } from "./hooks/use_owners";
import { useReviewers } from "./hooks/use_reviewers";
import AddReviewersModal from "./review_section/add_reviewers_modal";
import css from "./side_details.module.scss";

type SideDetailsProps = {
  handleTitleClick?: VoidFunction;
  isEngagement: boolean;
  isRole: boolean;
  isDraft: boolean;
  tab?: string;
};

export const SideDetails = ({ handleTitleClick, isEngagement, tab, isRole, isDraft }: SideDetailsProps) => {
  const { task, otherConversations, participantsOfConversations, primaryConversation, vacancies } =
    useActivityPageContext();
  const { data: invites } = useInvites(task?.id);
  const { t } = useTranslation("activities");
  const { startChat } = useActivityChat();
  const getRoleName = useRoleName();
  const { bookingCategories } = useBookingCategories();

  // Chat setup
  const [mainChatsCollapsed, setMainChatsCollapsed] = useState(true);
  const [otherChatsCollapsed, setOtherChatsCollapsed] = useState(true);

  //Assignee
  const [showAssignee, setShowAssignee] = useState(false);
  //Reviewer
  const [showRequestReview, setShowRequestReview] = useState(false);
  //add co-owner
  const [showShareShortlist, setShowShareShortlist] = useState(false);

  const hasInvites = !!invites && invites?.length > 0;
  const hasOwners = task?.owners && task.owners.length > 0;
  const allParticipants = (primaryConversation && participantsOfConversations[primaryConversation.id]) || [];
  const nonOwnerParticipants = allParticipants && allParticipants.filter(({ kind }) => kind !== "owner");
  const hasParticipants = nonOwnerParticipants && nonOwnerParticipants.length > 0;

  const isChatEnabled = useIsChatEnabled();
  const showGroupChat = isChatEnabled && task?.private ? hasOwners || hasInvites || hasParticipants : true;

  const showMainChat =
    isChatEnabled &&
    !isDraft &&
    ((!task?.private && task?.communication_type === "group") || (task?.private && primaryConversation)) &&
    showGroupChat;

  const showOtherChats =
    isChatEnabled &&
    !isDraft &&
    !isEngagement &&
    (task?.coowned || (otherConversations && otherConversations.length > 0));

  const { owners, principalOwner, canWrite: canWriteOwners, canView: canViewOwners } = useOwners(task);
  const { reviewers, canWrite: canWriteReviewers } = useReviewers(task);
  const { assignee, canWrite: canWriteAssignee, canView: canViewAssignee } = useAssignee(task);
  const avatarStackStyle = {
    flexDirection: "column",
    marginTop: 26,
    alignItems: "flex-start",
    gap: 5
  } as React.CSSProperties;
  const bookingCategory = bookingCategories.find(({ id }) => task?.booking_category_id === id);

  return (
    <>
      <Button
        kind="blank"
        className={css.sideTitle}
        onClick={handleTitleClick}
        data-qa-id="ActivityShortlist.title"
      >
        {getRoleName(task)}
      </Button>
      <>
        {principalOwner && (
          <ActivityProfilesAvatarStack
            label={t("show.parts.principalOwner")}
            profiles={[principalOwner]}
            style={avatarStackStyle}
            actionLabel={t("show.parts.addCoOwner")}
            qaId={"ActivityPageAddCoOwnerButton"}
            handleActionClick={canWriteOwners ? () => setShowShareShortlist(true) : undefined}
            displayChatIcon={!isDraft}
            handleIconClick={startChat}
          />
        )}
        {canViewOwners && (
          <ActivityProfilesAvatarStack
            label={t("show.parts.owner", { count: owners.length })}
            profiles={owners}
            style={avatarStackStyle}
            actionLabel={principalOwner ? undefined : t("show.parts.addCoOwner")}
            handleActionClick={
              canWriteOwners && !principalOwner ? () => setShowShareShortlist(true) : undefined
            }
            displayChatIcon={!isDraft}
            handleIconClick={startChat}
            qaId={principalOwner ? undefined : "ActivityPageAddCoOwnerButton"}
          />
        )}
        <Hr />
      </>

      {canWriteAssignee && (
        <>
          <ActivityProfilesAvatarStack
            label={t("show.parts.assignee", { count: 1 })}
            profiles={assignee && canViewAssignee ? [assignee] : []}
            style={avatarStackStyle}
            actionLabel={t("show.parts.setAssignee")}
            qaId={"ActivityPageSetAssigneeButton"}
            handleActionClick={canWriteAssignee ? () => setShowAssignee(true) : undefined}
            displayChatIcon={!isDraft}
            handleIconClick={startChat}
          />
          <Hr />
        </>
      )}
      {canWriteReviewers && (
        <>
          <ActivityProfilesAvatarStack
            label={t("show.parts.reviewer", { count: reviewers.length })}
            profiles={reviewers}
            style={avatarStackStyle}
            actionLabel={t(`show.shortlist.review.${tab === "matches" ? "addReviewer" : "requestReview"}`)}
            qaId={"ActivityPageReviewButton"}
            handleActionClick={canWriteReviewers ? () => setShowRequestReview(true) : undefined}
            displayChatIcon={!isDraft}
            handleIconClick={startChat}
          />
          <Hr />
        </>
      )}
      {showMainChat && (
        <>
          <ChatsSectionMainChat
            collapsed={mainChatsCollapsed}
            handleChatSectionClick={() => setMainChatsCollapsed((prev) => !prev)}
            isEngagement={isEngagement}
            primaryConversation={primaryConversation}
            allParticipants={allParticipants}
            nonOwnerParticipants={nonOwnerParticipants}
            qaId={"ActivityPageToggleChatButton"}
          />
          <Hr />
        </>
      )}
      {isRole && task?.coowned && (
        <>
          <ActivityDetailsVacancies task={task} vacancies={vacancies} />
          <Hr />
        </>
      )}
      <ActivityDetailsToggleableDesc description={task?.description} />
      <Hr />
      {task && <ActivityDetailsSkills task={task} />}
      <ActivityDetailsMoreInfo />
      {task && bookingCategory && <ActivityDetailsCategorySection task={task} category={bookingCategory} />}
      {showOtherChats && (
        <ChatsSectionOtherChat
          collapsed={otherChatsCollapsed}
          handleChatSectionClick={() => setOtherChatsCollapsed((prev) => !prev)}
          participantsOfConversations={participantsOfConversations}
          otherConversations={otherConversations}
          showMainChat={showMainChat}
          activity={task}
        />
      )}
      {showAssignee && <ChangeAssigneeModal onClose={() => setShowAssignee(false)} />}
      {showRequestReview && <AddReviewersModal handleClose={() => setShowRequestReview(false)} />}
      {showShareShortlist && (
        <AddOwnersModal handleClose={() => setShowShareShortlist(false)} activity={task} />
      )}
    </>
  );
};
