import { BookingFormValues } from "../booking_form_provider";
import { mapRequirementToBookingData } from "../requirement_selector";
import { BookingDataItem, BookingFormMode } from "../use_booking_form";
import { convertToWeekDayMask } from "../week_days_select";

export const mapToSingleBookingPayload = (
  {
    id,
    startDate,
    endDate,
    requirement,
    category,
    overridesDiaryTime,
    overridesNonWorkingTime
  }: BookingDataItem,
  values: BookingFormValues,
  mode: BookingFormMode
) => {
  const { activityId, globalCategory, globalOverridesDiaryTime, globalOverridesNonWorkingTime } = values;
  const isCreateMode = mode === BookingFormMode.Create;
  const isCloneMode = mode === BookingFormMode.Clone;
  return {
    id: isCreateMode || isCloneMode ? undefined : id,
    startDate,
    endDate,
    ...mapRequirementToBookingData({ type: requirement?.type!, value: requirement?.value! }),
    ...(activityId && { activityId }),
    bookingCategoryId: isCreateMode ? globalCategory?.id : category?.id,
    overridesCalendar: isCreateMode ? globalOverridesDiaryTime : overridesDiaryTime,
    overridesNonWorkingDays: isCreateMode ? globalOverridesNonWorkingTime : overridesNonWorkingTime
  };
};

export const mapToBookingTemplatePayload = (
  {
    bookingTemplateId,
    startDate,
    endDate,
    category,
    startTime,
    endTime,
    repeatDays,
    overridesDiaryTime,
    overridesNonWorkingTime
  }: BookingDataItem,
  values: BookingFormValues,
  mode: BookingFormMode,
  profileId: number
) => {
  const { activityId, globalCategory, globalOverridesDiaryTime, globalOverridesNonWorkingTime } = values;
  const isCreateMode = mode === BookingFormMode.Create;
  const isCloneMode = mode === BookingFormMode.Clone;
  return {
    profileId,
    id: isCreateMode || isCloneMode ? undefined : bookingTemplateId,
    startDate,
    endDate,
    startTime,
    endTime,
    wdayMask: convertToWeekDayMask(repeatDays?.selectedWeekDays ?? []),
    ...(activityId && { activityId }),
    bookingCategoryId: isCreateMode ? globalCategory?.id : category?.id,
    overridesCalendar: isCreateMode ? globalOverridesDiaryTime : overridesDiaryTime,
    overridesNonWorkingDays: isCreateMode ? globalOverridesNonWorkingTime : overridesNonWorkingTime
  };
};
