import WorkflowIcon from "PFIcons/workflow.svg";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";

export const WorkflowNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<WorkflowIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/workflow/)}
      name={t("workflow")}
      id="workflow"
      href="/workflow"
      {...props}
    />
  );
};
