import { useMutation } from "@tanstack/react-query";
import { CreateCustomValuePayload } from "PFCore/services/admin/custom_fields";
import { updateCustomValue } from "PFCore/services/admin/custom_values";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

import { useCustomValuesInvalidate } from "./use_custom_values_invalidate";

type CustomValueUpdateParams = {
  customTypeId: Id;
  customValueId: Id;
  payload: CreateCustomValuePayload;
};

export const useCustomValueUpdate = ({
  onSuccess,
  ...queryParams
}: MutationOptions<CustomValueUpdateParams> = {}) => {
  const { invalidateForType } = useCustomValuesInvalidate();

  return useMutation({
    mutationFn: ({ customTypeId, customValueId, payload }: CustomValueUpdateParams): any =>
      updateCustomValue(customTypeId, customValueId, payload),
    onSuccess: (...args) => {
      invalidateForType(args[1].customTypeId);
      onSuccess?.(...args);
    },
    ...queryParams
  });
};
