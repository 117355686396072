import { first, isNil } from "lodash";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import AutoSelect from "PFComponents/select/autoselect";
import { allowsNewValues } from "PFCore/helpers/custom_type";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import {
  CustomValuesOptionsQueryParams,
  fetchCustomValuesOptions,
  fetchProfilesAutocompleteCustomValues
} from "PFCore/services/custom_values";
import { fetchLocationsOptions } from "PFCore/services/locations";
import { CustomField, CustomType, Position, Profile, PureCustomValue, PureCustomValueData } from "PFTypes";
import { RefObject } from "react";

import { EXPIRABLE_CUSTOM_TYPES } from "../../profiles/common/expirable_custom_types";
import { useCommonProps } from "./use_common_props";

export type CustomValuesEditFieldProps = {
  handleChange: (values: PureCustomValueData[]) => void;
  values?: PureCustomValueData[]; // in a legacy code, values can be undefined (for example `basic_info.jsx on profile page)
  customType: CustomType | null;
  kind?: "bordered" | "blank";
  position?: Position;
  profile?: Profile;
  connectedFields?: { values: Pick<CustomField["values"][number], "id">[] }[] | null;
  filterOptions?: (response: any, currentValue?: string) => any;
  parseResponse?: (response: any) => any;
  adminPage?: boolean;
  multi?: boolean;
  required?: boolean;
  letClear?: boolean;
  letCreate?: boolean | null;
  tip?: string;
  label?: string;
  placeholder?: string;
  lockedTipPosition?: "top" | "bottom";
  errors?: string | string[];
  hasValuesWithExpiryDate?: boolean;
  disabledValues?: string[];
  isActivity?: boolean;
  closeOnChange?: boolean;
  useProfilesEndpoint?: boolean;
  disabled?: boolean;
  forceUnlocked?: boolean;
  displayValues?: JSX.Element;
  qaIdPrefix?: string;
  classes?: {
    autoSelect?: string;
    root?: string;
  };
  portalRef?: RefObject<HTMLElement>;
  onRestore?: VoidFunction;
  restoreLabel?: string;
  displayValuesBelow?: boolean;
};

export const CustomValuesEditField = ({
  kind,
  position,
  customType,
  errors,
  adminPage,
  profile,
  label,
  placeholder,
  tip,
  required,
  lockedTipPosition,
  filterOptions,
  isActivity,
  forceUnlocked,
  multi,
  values = [],
  qaIdPrefix,
  letClear,
  disabled,
  letCreate = null,
  hasValuesWithExpiryDate,
  closeOnChange,
  classes = {},
  parseResponse,
  disabledValues,
  displayValues,
  displayValuesBelow,
  handleChange,
  connectedFields = null,
  useProfilesEndpoint,
  portalRef,
  onRestore,
  restoreLabel
}: CustomValuesEditFieldProps): JSX.Element => {
  const growlErrors = useErrorsGrowl();
  const { formatISODate } = useDateFormatter();
  const commonProps = useCommonProps({
    adminPage,
    customType,
    profile,
    position,
    isActivity,
    forceUnlocked,
    label,
    errors,
    placeholder,
    tip,
    lockedTipPosition,
    required,
    kind,
    filterOptions
  });

  const autoSelectQuery = (term: string) => {
    const type = customType!.name;

    if (useProfilesEndpoint) {
      return fetchProfilesAutocompleteCustomValues({ term, type });
    }

    if (customType?.value_type === "location") {
      return term.length < 3
        ? Promise.resolve({})
        : fetchLocationsOptions({
            term,
            customTypeId: customType.id
          });
    }

    const queryParams: CustomValuesOptionsQueryParams = {
      term,
      type
    };

    const isConnected = !isNil(connectedFields);

    if (isConnected) {
      queryParams.connectedOptions = true;
      queryParams.customValueIds = (connectedFields || [])
        .map(({ values }) => values.map(({ id }) => id))
        .flat();
    }

    return fetchCustomValuesOptions(queryParams);
  };

  const handleQueryError = ({ response }) => {
    growlErrors(response);
  };

  const handleCalendarChange = (date: string) => {
    // backend does not update date value if it is provided with existing id
    // (contrary to other custom values updates)
    handleChange([{ value: formatISODate(date) } as PureCustomValueData]);
  };

  const handleAutoSelectChange = (values: (PureCustomValue & { created?: boolean })[]) => {
    const decoratedValues = values.map((customValue) => {
      if (customValue.created) {
        // value is needed when the customValue is created (letCreate prop)
        return { ...customValue, value: String(customValue.id) };
      }
      return customValue;
    });
    handleChange(decoratedValues);
  };

  const isCalendar = customType?.value_type === "date";

  const hasExpiryDate = isNil(hasValuesWithExpiryDate)
    ? EXPIRABLE_CUSTOM_TYPES.includes(customType?.name || "")
    : hasValuesWithExpiryDate;

  // When the value is not passed we default to custom type config
  const finalLetCreate = isNil(letCreate) && customType ? allowsNewValues(customType) : letCreate;

  return (
    <div className={classes.root} key={customType?.id} data-qa-id={`${qaIdPrefix}-${customType?.name}`}>
      {isCalendar ? (
        <InlineCalendar
          {...commonProps}
          selectedDate={first(values)?.value ?? null}
          handleChange={handleCalendarChange}
          portalRef={portalRef}
        />
      ) : (
        <AutoSelect
          {...commonProps}
          cache={false} // Important since connectedFields change the response
          values={values}
          showValues={!displayValuesBelow}
          letCreate={finalLetCreate}
          multi={multi ?? customType?.kind === "multiple"}
          closeOnChange={closeOnChange || customType?.kind !== "multiple"}
          query={autoSelectQuery}
          onQueryError={handleQueryError}
          handleChange={handleAutoSelectChange}
          letClear={letClear}
          disabled={disabled}
          hasValuesWithExpiryDate={hasExpiryDate}
          parseResponse={parseResponse}
          disabledValues={disabledValues}
          displayValues={displayValues}
          displayValuesBelow={displayValuesBelow}
          rootClassName={classes.autoSelect}
          onRestore={onRestore}
          restoreLabel={restoreLabel}
        />
      )}
    </div>
  );
};
